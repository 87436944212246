import { openGlobalDialog } from "../actions/globalEditDialogActions";

import { createNewFolder, changeFolderName } from "../actions/documentActions";

function showNewFolderDialog({
	matterID,
	apiBasepath,
	currentLocationFilesAndFolders,
}) {
	return function(dispatch) {
		openGlobalDialog(
			{
				label: "Create New Folder",
				placeHolder: "New folder name",
				submitButtonLabel: "Create Folder",
			},

			(state) => {
				createNewFolder(matterID, "/" + apiBasepath, state.value)(
					dispatch
				);
			},
			(state) => {
				const notEmpty = state.value.trim() !== "";
				const hasIllegalCharacters = state.value.match(
					/[<>:"/\\|?*%#]/
				);
				const matchesFilename = currentLocationFilesAndFolders
					.filter((item) => item.type === "Folder")
					.filter(
						(item) =>
							item.filename.toUpperCase().trim() ===
							state.value.toUpperCase().trim()
					).length;
				const matchesDeletedFilename = currentLocationFilesAndFolders
					.filter((item) => item.type === "Folder")
					.filter(
						(item) =>
							item.filename.toUpperCase().trim() ===
								state.value.toUpperCase().trim() &&
							item.isDeleted
					).length;
				const matchesReservedDirectoryNames = state.value.match(
					/^\.{1,2}$/
				);
				var folderType = matchesDeletedFilename
					? " deleted "
					: " current ";
				return {
					canSubmit:
						notEmpty &&
						!hasIllegalCharacters &
							!matchesFilename &
							!matchesReservedDirectoryNames,
					errorMessage: hasIllegalCharacters
						? 'Folder names cannot contain any of the following characters: < > : " / \\ | ? * % #'
						: matchesFilename
						? "There is already a folder in the " +
						  folderType +
						  " directory with this name"
						: !notEmpty
						? "Folder names cannot be empty"
						: matchesReservedDirectoryNames
						? "Folder name cannot be . or .."
						: "",
				};
			}
		)(dispatch);
	};
}

function showEditFolderDialog({
	folderID,
	matterID,
	currentName,
	apiBasepath,
	currentLocationFilesAndFolders,
}) {
	return function(dispatch) {
		openGlobalDialog(
			{
				label: "Change Folder Name",
				value: currentName,
				submitButtonLabel: "Rename Folder",
			},
			(state) => {
				changeFolderName(matterID, folderID, state.value, apiBasepath)(
					dispatch
				);
			},
			(state) => {
				state.value = state.value.trim();
				const notEmpty = state.value.trim() !== "";
				const different = state.value !== currentName;
				const hasIllegalCharacters = state.value.match(
					/[<>:"/\\|?*%#]/
				);
				const matchesFilename = currentLocationFilesAndFolders
					.filter((item) => item.type === "Folder")
					.filter(
						(item) =>
							item.filename.toUpperCase().trim() ===
								state.value.toUpperCase().trim() &&
							item.id !== folderID
					).length;
				const deletedFolderMatch = currentLocationFilesAndFolders.filter(
					(item) =>
						item.type === "Folder" &&
						item.filename.toUpperCase().trim() ===
							state.value.toUpperCase().trim() &&
						item.id !== folderID &&
						item.isDeleted
				).length;
				const matchesReservedDirectoryNames = state.value.match(
					/^\.{1,2}$/
				);
				return {
					canSubmit:
						notEmpty &&
						!hasIllegalCharacters &&
						different &&
						!matchesFilename,
					errorMessage: hasIllegalCharacters
						? 'Folder names cannot contain any of the following characters: < > : " / \\ | ? * % #'
						: matchesFilename
						? "There is already a " +
						  (deletedFolderMatch ? "deleted" : "") +
						  " folder in the current directory with this name"
						: !notEmpty
						? "Folder names cannot be empty"
						: matchesReservedDirectoryNames
						? "Folder name cannot be . or .."
						: "",
				};
			}
		)(dispatch);
	};
}

export { showNewFolderDialog, showEditFolderDialog };
