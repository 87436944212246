import API from "../utilities/LocalApiProxy";

import {
	GET_PERMISSION_GROUPS_REQUEST,
	GET_PERMISSION_GROUPS_SUCCESS,
	GET_PERMISSION_GROUPS_FAILURE,
	CREATE_PERMISSION_GROUP_REQUEST,
	CREATE_PERMISSION_GROUP_SUCCESS,
	CREATE_PERMISSION_GROUP_FAILURE,
	EDIT_PERMISSION_GROUP_REQUEST,
	EDIT_PERMISSION_GROUP_SUCCESS,
	EDIT_PERMISSION_GROUP_FAILURE,
	DELETE_PERMISSION_GROUP_REQUEST,
	DELETE_PERMISSION_GROUP_SUCCESS,
	DELETE_PERMISSION_GROUP_FAILURE,
} from "./types";

export function getPermissionGroups(groupID) {
	return function (dispatch) {
		dispatch({ type: GET_PERMISSION_GROUPS_REQUEST });
		API.post("/permissions/groups/list", { body: { groupID } })
			.then((res) => {
				dispatch({
					type: GET_PERMISSION_GROUPS_SUCCESS,
					payload: {
						availableGroups: res.mysql.groups,
						groupMembership: res.mysql.groupMembership,
						users: res.mysql.users,
					},
				});
			})
			.catch(() => {
				dispatch({
					type: GET_PERMISSION_GROUPS_FAILURE,
				});
			});
	};
}
export function setGroupsEnabled() {
	return function () {};
}
export function createPermissionGroup(groupData) {
	return function (dispatch) {
		dispatch({ type: CREATE_PERMISSION_GROUP_REQUEST });
		API.post("/permissions/groups/create", { body: { groupData } })
			.then((res) => {
				dispatch({
					type: CREATE_PERMISSION_GROUP_SUCCESS,
					payload: {
						newPermissionGroupID: res.mysql.newPermissionGroupID,
					},
				});
				window.globalHistory.push(`/permissions/groups/list`);
			})
			.catch(() => {
				dispatch({
					type: CREATE_PERMISSION_GROUP_FAILURE,
				});
			});
	};
}
export function editPermissionGroup(groupID, groupData) {
	return function (dispatch) {
		dispatch({ type: EDIT_PERMISSION_GROUP_REQUEST });
		API.post("/permissions/groups/edit", {
			body: { groupData: { ...groupData, groupID } },
		})
			.then(() => {
				dispatch({
					type: EDIT_PERMISSION_GROUP_SUCCESS,
				});
				window.globalHistory.push(`/permissions/groups/list`);
			})
			.catch(() => {
				dispatch({
					type: EDIT_PERMISSION_GROUP_FAILURE,
				});
			});
	};
}
export function deletePermissionGroup(groupID) {
	return function (dispatch) {
		dispatch({ type: DELETE_PERMISSION_GROUP_REQUEST });
		API.post("/permissions/groups/delete", {
			body: { groupID },
		})
			.then(() => {
				dispatch({
					type: DELETE_PERMISSION_GROUP_SUCCESS,
				});
				getPermissionGroups()(dispatch);
			})
			.catch(() => {
				dispatch({
					type: DELETE_PERMISSION_GROUP_FAILURE,
				});
			});
	};
}
