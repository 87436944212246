import {
	CLOSE_REACT_DIALOG,
	OPEN_REACT_DIALOG,
	UPDATE_REACT_DIALOG_CONTENT,
} from "../actions/types";

const initialState = {
	dialogOpen: false,
	showSpinnerInTitle: false,
	title: "Title goes here",
	mainContent: "main content goes here",
	counter: 0,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case CLOSE_REACT_DIALOG:
			return {
				...initialState,
			};
		case OPEN_REACT_DIALOG:
			return {
				...state,
				dialogOpen: true,
				showSpinnerInTitle: action.payload.showSpinnerInTitle,
				title: action.payload.title,
				mainContent: action.payload.mainContent,
				counter: action.payload.counter,
			};
		case UPDATE_REACT_DIALOG_CONTENT:
			return {
				...state,
				mainContent: action.payload.mainContent,
				counter: action.payload.counter,
			};
		default:
			return state;
	}
}
