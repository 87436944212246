import React from "react";
import { SmallNoPaddingIconButton } from "../ButtonWithIcon";
import MaterialThreeDotMenu from "../MaterialThreeDotMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import FavoriteStar from "../FavoriteStar";

function InteractiveTableCell(props) {
	return (
		<div
			className="InteractiveTableCell"
			style={{
				display: "flex",
			}}
		>
			<div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
				{props.icon ? (
					<FontAwesomeIcon
						icon={props.icon}
						style={{ color: props.iconColor, marginRight: 8 }}
					/>
				) : null}
				<span>{props.children}</span>
			</div>

			<div
				style={{
					position: "relative",
					marginLeft: "auto",
					display: "flex",
					gap: 8,
				}}
			>
				{props.otherButtons && props.otherButtons.length
					? props.otherButtons.map(({ icon, onClick }, index) => {
							return (
								<div className="TableButton" key={index}>
									<Button onClick={onClick}>
										<FontAwesomeIcon icon={icon} />
									</Button>
								</div>
							);
						})
					: null}

				<MaterialThreeDotMenu
					className="TableButton"
					menuItems={props.menuItems}
				/>
			</div>
		</div>
	);
}
function ActionsTableCell(props) {
	return (
		<div
			style={{
				display: "grid",
				gridTemplateColumns: `repeat(${props.numColumns || 1},1fr)`,
			}}
		>
			{props.trashClickHandler ? (
				<div>
					<SmallNoPaddingIconButton
						aria-label="delete"
						className="trash"
						onClick={props.trashClickHandler}
					>
						<FontAwesomeIcon
							icon={props.isDeleted ? "undo" : "trash"}
							className={
								props.isDeleted ? "isDeleted" : "notDeleted"
							}
						/>
					</SmallNoPaddingIconButton>
				</div>
			) : null}
			{props.starClickHandler ? (
				<div>
					<SmallNoPaddingIconButton
						aria-label="favorite"
						className="favoriteStar"
						onClick={props.starClickHandler}
					>
						<FavoriteStar isFavorite={props.isFavorite} />
					</SmallNoPaddingIconButton>
				</div>
			) : null}

			{props.showCheckedOutSpace ? (
				<div>
					{props.isCheckedOut ? (
						<div
							style={{
								textAlign: "center",
								color: "var(--lw-blue)",
								paddingTop: 2,
							}}
						>
							<FontAwesomeIcon icon="share-square" />
						</div>
					) : null}
				</div>
			) : null}
			{props.showCheckedOutSpace ? (
				<div>
					{props.isInUse ? (
						<div
							style={{
								textAlign: "center",
								color: "orange",
								paddingTop: 2,
							}}
						>
							<FontAwesomeIcon icon="user" />
						</div>
					) : null}
				</div>
			) : null}
		</div>
	);
}
export default InteractiveTableCell;
export { ActionsTableCell };
