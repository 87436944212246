import React, { Component } from "react";
import { connect } from "react-redux";
import { setSubheaderText } from "../actions/navigateActions";
import { getHomepageData } from "../actions/homepageActions";
import { iconMap } from "../utilities/utilities";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TenantUsageWidget from "./TenantUsageWidget";

import "react-table/react-table.css";
import "./Homepage.css";
import CustomPagination from "./Tables/CustomPagination";
import HomepageTable from "./Tables/HomepageTable";

class Homepage extends Component {
	constructor(props, context) {
		super(props, context);

		this.openMatterColumns = [
			{
				Header: () => <div style={{ textAlign: "left" }}>ID</div>,
				accessor: "matterID",
				width: 100,
				Cell: (row) => (
					<div>
						<FontAwesomeIcon
							icon={"briefcase"}
							size="1x"
							style={{
								marginRight: 8,
							}}
						/>
						<span>{row.original.matterID}</span>
					</div>
				),
			},
			{
				Header: () => <div style={{ textAlign: "left" }}>Name</div>,
				accessor: "matterName",
				Cell: (row) => (
					<div title={row.original.matterName}>
						<Link to={`/matters/${row.original.matterID}/docs/`}>
							{row.original.matterName}
						</Link>
					</div>
				),
			},
			{
				Header: () => <div style={{ textAlign: "left" }}>Client</div>,
				accessor: "clientName",
				Cell: (row) =>
					row.original.clientName && (
						<div
							title={row.original.clientName}
							style={{ overflow: "hidden" }} // allows the final columns padding to be set if it overflows
						>
							<Link to={`/clients/${row.original.clientID}/`}>
								{row.original.clientName}
							</Link>
						</div>
					),
				width: 150,
			},
		];
		this.favoriteDocumentColumns = [
			{
				Header: <div style={{ textAlign: "left" }}>ID</div>,
				accessor: "id",
				width: 100,
				Cell: (row) => {
					const icon = iconMap(row);
					return (
						<div>
							<FontAwesomeIcon
								icon={icon.icon}
								size="1x"
								style={{
									marginRight: 8,
									color: icon.color,
								}}
							/>
							<span>{row.original.id}</span>
						</div>
					);
				},
			},
			{
				Header: <div style={{ textAlign: "left" }}>Name</div>,
				accessor: "filename",
				Cell: (row) => (
					<div title={row.original.filename}>
						<Link to={`/docs/${row.original.id}`}>
							{row.original.filename}
						</Link>
					</div>
				),
			},
			{
				Header: <div style={{ textAlign: "left" }}>Type</div>,
				accessor: "documentType",
				width: 120,
			},
		];
		this.favoriteFolderColumns = [
			{
				Header: <div style={{ textAlign: "left" }}>Name</div>,
				accessor: "filename",
				Cell: (row) => {
					const icon = iconMap(row);
					return (
						<div style={{ display: "flex", alignItems: "center" }}>
							<FontAwesomeIcon
								icon={icon.icon}
								size="1x"
								style={{
									marginRight: 8,
									color: icon.color,
								}}
							/>
							<div title={row.original.filename}>
								<Link
									to={`${row.original.folderPrefix}${row.original.path}${row.original.filename}/`}
								>
									{row.original.filename}
								</Link>
							</div>
						</div>
					);
				},
			},
			{
				Header: <div style={{ textAlign: "left" }}>Matter</div>,
				accessor: "matterName",
				Cell: (row) => (
					<div
						title={row.original.matterName}
						style={{ overflow: "hidden" }} // allows the final columns padding to be set if it overflows
					>
						<Link to={row.original.linkToMatter}>
							{row.original.matterName}
						</Link>
					</div>
				),
			},
		];
	}
	componentDidMount() {
		this.props.getHomepageData();
		this.props.setSubheaderText("Welcome to LexWorkplace");
	}

	render() {
		return (
			<div className="fullHeightFlex">
				<div
					className="MainFontSemiBold"
					style={{
						color: "var(--lw-dark-grey)",
						marginLeft: 24,
						fontSize: 24,
						marginTop: 12,
					}}
				>
					{this.props.firmName}
				</div>
				<div
					className="Homepage"
					style={{
						height: "100%",
						display: "grid",
						gridTemplateColumns: "1fr 1fr 1fr",
					}}
				>
					<div
						style={{
							padding: 24,
							display: "flex",
							flexDirection: "column",
							height: "100%",
						}}
					>
						<div>
							<div
								className="MainFontBold"
								style={{
									marginBottom: 8,
								}}
							>
								My Favorite Folders
							</div>
							<HomepageTable
								className="fullWidth"
								style={{ height: 350 }}
								columns={this.favoriteFolderColumns}
								data={this.props.myFavoriteFolders}
								defaultPageSize={8}
								defaultSorted={[
									{
										id: "filename",
										desc: true,
									},
								]}
								uncontrolledPagination
								PaginationComponent={CustomPagination}
								loading={this.props.loading}
								noResultsText="No Favorite Folders"
								tableUniqueID={"myFavoriteFolders-homepage"}
							/>
						</div>
						<TenantUsageWidget />
					</div>

					<div style={{ padding: 24 }}>
						<div
							className="MainFontBold"
							style={{
								marginBottom: 8,
							}}
						>
							My Favorite Documents
						</div>
						<HomepageTable
							className="fullWidth"
							style={{ height: 595 }}
							columns={this.favoriteDocumentColumns}
							data={this.props.myFavoriteDocuments}
							defaultPageSize={15}
							defaultSorted={[
								{
									id: "id",
									desc: true,
								},
							]}
							uncontrolledPagination
							PaginationComponent={CustomPagination}
							loading={this.props.loading}
							noResultsText="No Favorite Documents"
							tableUniqueID={"myFavoriteDocuments-homepage"}
						/>
					</div>

					<div style={{ padding: 24 }}>
						<div
							className="MainFontBold"
							style={{
								marginBottom: 8,
							}}
						>
							My Open Matters
						</div>
						<HomepageTable
							className="fullWidth"
							style={{ height: 595 }}
							columns={this.openMatterColumns}
							data={this.props.myOpenMatters}
							defaultPageSize={15}
							defaultSorted={[
								{
									id: "matterID",
									desc: true,
								},
							]}
							uncontrolledPagination
							PaginationComponent={CustomPagination}
							loading={this.props.loading}
							noResultsText="No Open Matters"
							tableUniqueID={"myOpenMatters-homepage"}
						/>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	myOpenMatters: state.homepage.myOpenMatters,
	myFavoriteDocuments: state.homepage.myFavoriteDocuments,
	myFavoriteFolders: state.homepage.myFavoriteFolders,
	firmName: state.user.firmName,
});
export default connect(mapStateToProps, { setSubheaderText, getHomepageData })(
	Homepage
);
