import React, { Component, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconMap, shortDateFormat } from "../utilities/utilities";
import ButtonLink from "./ButtonLink";
import CircularProgress from "@material-ui/core/CircularProgress";
import FavoriteStar from "./FavoriteStar";
import DocumentAIPane from "./DocumentAIPane";
import { ButtonWithIcon } from "./ButtonWithIcon";
import MaterialThreeDotMenu from "./MaterialThreeDotMenu";
import config from "../config/legalworks.frontend.config";

const sanitizePreviewTextContent = (documentPreviewResponse) => {
	/*
		The document contents comes from the search index,
		which as of 2024 is extracted via Apache Tika.  These replacements
		were decided on based on what was seen from some PDFs and DOCX files
	*/

	var sanitizedContent = documentPreviewResponse.content.trim();

	sanitizedContent = sanitizedContent.replace(
		/\[[\bbookmark: _GoBack\b^\]]*\]/g,
		""
	); // This regex will only replace "[bookmark: _GoBack]"
	sanitizedContent = sanitizedContent.replace(
		/[^a-zA-Z0-9`!@#$%^&*()_+|\-=\\{}[\]:"";'<>?,.\w\sèìòùÈÌÒÙéíóúýêîôûÊÎÔÛñõÑÕëïöüÿËÏÖÜŸ/]/gi,
		""
	); // This will remove all the characters which are not included in the given list.
	sanitizedContent = sanitizedContent.replace(
		new RegExp(/^\s*\n{2,}/gm),
		"\n"
	); // This will replace multiple \n into single \n.Also replace single \n into \n and 0 or more whitespace characters
	sanitizedContent = sanitizedContent.replace(new RegExp(/^\s*Sheet1/gm), "");
	return sanitizedContent;
};

const Loading = () => {
	return (
		<div style={{ marginTop: 200 }}>
			<center>
				<CircularProgress size={28} thickness={5} />
			</center>
		</div>
	);
};

const RightPaneHeader = ({
	leftPart,
	middlePart,
	buttonsArray,
	dropdownItems,
	closePane,
}) => {
	return (
		<div className="preview-header">
			<div className="preview-header-left">{leftPart}</div>
			<div style={{ marginLeft: "auto", display: "flex" }}>
				{middlePart ? (
					<div
						style={{
							padding: 15,
							textWrap: "nowrap",
						}}
					>
						{middlePart}
					</div>
				) : null}
				<div className="preview-header-right">
					<div className="preview-icon-box">
						<div>
							<MaterialThreeDotMenu
								position={"relative"}
								menuItems={[
									...dropdownItems,
									{ divider: true },
									{ text: "Close", onSelect: closePane },
								]}
							/>
						</div>
						<div className="preview-buttons">
							{buttonsArray.map((buttonComponent, index) => (
								<div key={index}>{buttonComponent}</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

/*
	There are 2 document contexts for the right pane:
		- document preview
		- document AI conversation

	They have different buttons and dropdown menu options, but the same filename
	and favorite star functionality
*/
const DocumentHeader = ({
	filename,
	setFavorite,
	isFavorite,
	buttonsArray,
	...restProps
}) => {
	return (
		<RightPaneHeader
			leftPart={
				<div>
					{documentPreviewFileIcon({ filename, type: "File" })}

					<span>{filename}</span>
				</div>
			}
			buttonsArray={[
				<ButtonWithIcon
					handler={setFavorite}
					title={
						isFavorite
							? "Un-Favorite this Document"
							: "Favorite this Document"
					}
					iconComponent={
						<FavoriteStar
							isFavorite={isFavorite}
							style={{
								float: "left",
								top: 3,
								left: 3,
								fontSize: "1.2em",
							}}
						/>
					}
					key="favoriteButton"
				/>,
				...buttonsArray,
			]}
			{...restProps}
		/>
	);
};

const DocumentPreviewHeader = ({
	openProperties,
	openDocument,
	...restProps
}) => {
	return (
		<DocumentHeader
			buttonsArray={[
				restProps.docCanBeOpenedInLauncher ? (
					<ButtonWithIcon
						handler={openDocument}
						title={"Open this Document"}
						iconName="external-link-alt"
						buttonText="Open Document"
						key="openDocumentButton"
					/>
				) : (
					<ButtonWithIcon
						handler={restProps.downloadCopyOfDocument}
						title={"Download a Copy of this Document"}
						iconName="download"
						buttonText="Download a Copy"
						key="openDocumentButton"
					/>
				),
			]}
			dropdownItems={[
				{
					text: "Document Properties",
					onSelect: openProperties,
				},
			]}
			{...restProps}
		/>
	);
};
const DocumentAIHeader = ({
	openProperties,
	openDocument,
	headerExtraInfoDisplay,
	headerExtraInfoTooltip,
	saveConversation,
	saveConversationAllowed,
	...restProps
}) => {
	return (
		<DocumentHeader
			middlePart={
				headerExtraInfoDisplay ? (
					<div style={{ display: "flex" }}>
						<div>{headerExtraInfoDisplay}</div>
						{headerExtraInfoTooltip ? (
							<div title={headerExtraInfoTooltip}>
								<FontAwesomeIcon
									icon="info-circle"
									style={{ marginLeft: 4, marginTop: 2 }}
								/>
							</div>
						) : null}
					</div>
				) : null
			}
			buttonsArray={[
				<ButtonWithIcon
					handler={() => {
						saveConversation();
					}}
					key="saveConversationButton"
					buttonText="Save as Matter Note"
					disabled={!saveConversationAllowed}
					iconName="save"
					title="Save this conversation as a Matter Note"
				/>,
			]}
			dropdownItems={[
				restProps.docCanBeOpenedInLauncher
					? {
							text: "Open Document",
							onSelect: openDocument,
						}
					: {
							text: "Download a Copy",
							onSelect: restProps.downloadCopyOfDocument,
						},
				{
					text: "Document Properties",
					onSelect: openProperties,
				},
			]}
			{...restProps}
		/>
	);
};
const EmailHeader = ({
	subjectline,
	originalFilename,
	openEmailShortNoteEditor,
	openEmail,
	...restProps
}) => {
	return (
		<RightPaneHeader
			leftPart={
				<div>
					{documentPreviewFileIcon({
						filename: originalFilename,
						type: "Email",
					})}

					<span>{subjectline}</span>
				</div>
			}
			buttonsArray={[
				<ButtonWithIcon
					handler={() => {
						openEmail();
					}}
					key="downloadEmailButton"
					buttonText="Download Email"
					iconName="download"
					title="Download this Email"
				/>,
			]}
			dropdownItems={[
				{
					text: "Edit Email Short Note",
					onSelect: () => {
						console.log(
							"active element is",
							document.activeElement
						);

						openEmailShortNoteEditor();
						document.activeElement.blur();
					},
				},
			]}
			{...restProps}
		/>
	);
};

function documentPreviewFileIcon({ filename, type }) {
	var rowFileName = {
		original: {
			filename: filename,
			type,
		},
	};

	if (filename) {
		const { icon, color } = iconMap(rowFileName);
		return (
			<FontAwesomeIcon
				icon={icon}
				style={{ color: color, marginRight: 8 }}
			/>
		);
	}
}

const SlidingPaneWrapper = ({ isOpen, onRequestClose, children }) => {
	return (
		<SlidingPane
			className="sliding-pane"
			/* 
					The SlidingPane component is placed at the top
					of the DOM, so it can't easily use a container element
					to set its height.  The two height options are
					hard-coded in classes in App.css.  If this component
					needs to be used outside of Matter Detail / Firm Docs
					refs could be used to explicitly
					set the height based on a container.

					FYI:
					<domElement>.getBoundingClientRect() will return the absolute
					screen position and dimensions of an element
				*/
			overlayClassName="previewPaneOverlay"
			isOpen={isOpen}
			width="900px"
			hideHeader={true}
			onRequestClose={onRequestClose}
		>
			{children}
		</SlidingPane>
	);
};

const DocumentAISlidingPane = ({
	isOpen,
	onRequestClose,
	filename,
	closePane,
	openProperties,
	openDocument,
	isFavorite,
	setFavorite,
	headerExtraInfoTooltip,
	headerExtraInfoDisplay,
	openSaveToMatterNoteDialog,
	documentID,
	setHeaderExtraInfo,
	downloadCopyOfDocument,
	docCanBeOpenedInLauncher,
}) => {
	const [messagesSerialization, setMessagesSerialization] = useState("");
	const [numMessages, setNumMessages] = useState(0);

	return (
		<SlidingPaneWrapper isOpen={isOpen} onRequestClose={onRequestClose}>
			<div>
				<DocumentAIHeader
					filename={filename}
					closePane={closePane}
					openProperties={openProperties}
					openDocument={openDocument}
					downloadCopyOfDocument={downloadCopyOfDocument}
					docCanBeOpenedInLauncher={docCanBeOpenedInLauncher}
					isFavorite={isFavorite}
					setFavorite={setFavorite}
					headerExtraInfoDisplay={headerExtraInfoDisplay}
					headerExtraInfoTooltip={headerExtraInfoTooltip}
					saveConversation={() =>
						openSaveToMatterNoteDialog(messagesSerialization)
					}
					//require at least 2 messages (1 from user, 1 from AI) in order to allow saving
					saveConversationAllowed={numMessages >= 2}
				/>
			</div>
			<DocumentAIPane
				documentID={documentID}
				setHeaderExtraInfo={setHeaderExtraInfo}
				messagesChanged={(messages) => {
					const serialization = messages
						.map(({ source, content }) => {
							return `${source}:\n${content}`;
						})
						.join("\n\n");

					setMessagesSerialization(serialization);
					setNumMessages(messages.length);
				}}
				openDocument={openDocument}
				downloadCopyOfDocument={downloadCopyOfDocument}
				docCanBeOpenedInLauncher={docCanBeOpenedInLauncher}
			/>
		</SlidingPaneWrapper>
	);
};

class DocumentSlidingPane extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = { headerExtraInfoDisplay: "", headerExtraInfoTooltip: "" };
		this.openPropertiesForDocPreview =
			this.openPropertiesForDocPreview.bind(this);
		this.shortNoteFromEmailPreview =
			this.shortNoteFromEmailPreview.bind(this);
		this.generateDocumentPreviewContent =
			this.generateDocumentPreviewContent.bind(this);

		this.openDocumentHandler = this.openDocumentHandler.bind(this);
		this.downloadCopyOfDocument = this.downloadCopyOfDocument.bind(this);
		this.openEmailHandler = this.openEmailHandler.bind(this);
		this.setHeaderExtraInfo = this.setHeaderExtraInfo.bind(this);
		this.openSaveToMatterNoteDialog =
			this.openSaveToMatterNoteDialog.bind(this);
	}

	openDocumentHandler = () => {
		this.props.clickedFile(this.props.selectedRowData.id);
	};
	downloadCopyOfDocument = () => {
		this.props.downloadCopyOfDocument(this.props.selectedRowData.id);
	};

	openEmailHandler = () => {
		this.props.clickedEmail(this.props.selectedRowData.id);
	};

	generateEmailPreviewContent(previewText) {
		return (
			<div>
				<div className="emailPreviewInfoContainer">
					<label className="emailPreviewInfoLabel">From : </label>
					<span className="emailPreviewInfoValue">
						{this.props.selectedRowData.emailFrom}
					</span>
					<label className="emailPreviewInfoLabel">To : </label>
					<span className="emailPreviewInfoValue">
						{this.props.selectedRowData.emailTo}
					</span>
					<label className="emailPreviewInfoLabel">Date : </label>
					<span className="emailPreviewInfoValue">
						{shortDateFormat(this.props.selectedRowData.date)}
					</span>
				</div>
				<div>
					<p className="preview-text">{previewText}</p>
				</div>
			</div>
		);
	}

	generateDocumentPreviewContent(
		documentPreviewResponse,
		docCanBeOpenedInLauncher
	) {
		if (documentPreviewResponse) {
			var previewText;
			// need to catch empty string or the object will get set as the preview text and it'll crash the front end
			if (
				documentPreviewResponse.content ||
				documentPreviewResponse.content === ""
			) {
				previewText = sanitizePreviewTextContent(
					documentPreviewResponse
				);
			} else {
				previewText = documentPreviewResponse;
			}

			if (documentPreviewResponse.url) {
				return (
					<div className="preview-image">
						<img
							alt="documentImagePreview"
							src={documentPreviewResponse.url}
						/>
					</div>
				);
			} else if (
				documentPreviewResponse.content &&
				previewText.length > 0
			) {
				if (this.props.selectedRowData.type === "File") {
					return (
						<div>
							<p className="preview-text">{previewText}</p>
						</div>
					);
				} else if (this.props.selectedRowData.type === "Email") {
					return this.generateEmailPreviewContent(previewText);
				}
			}
		}
		return (
			<div className="previewOpenDocumentContainer">
				<div className="previewOpenDocumentCenter">
					<p>Preview Not Available</p>
					{this.props.selectedRowData.type === "File" ? (
						docCanBeOpenedInLauncher ? (
							<ButtonLink onClick={this.openDocumentHandler}>
								Open Document
							</ButtonLink>
						) : (
							<ButtonLink onClick={this.downloadCopyOfDocument}>
								Download a Copy of Document
							</ButtonLink>
						)
					) : null}
					{this.props.selectedRowData.type === "Email" ? (
						<ButtonLink onClick={this.openEmailHandler}>
							Download Email
						</ButtonLink>
					) : null}
				</div>
			</div>
		);
	}
	shortNoteFromEmailPreview() {
		this.props.openGlobalDialog(
			{
				label: "Edit Email Short Note",
				value: this.props.selectedRowData.notes,
				submitButtonLabel: "Update Note",
			},
			(state) => {
				this.props.updateEmailNote(
					this.props.selectedRowData.id,
					state.value
				);
			},
			(state) => {
				return {
					canSubmit: state.value !== this.props.selectedRowData.notes,
					erroMessage: "",
				};
			}
		);
	}
	openPropertiesForDocPreview() {
		window
			.open(
				`https://${config.domain}/docs/${this.props.selectedRowData.id}`,
				"_blank"
			)
			.focus();
	}
	setHeaderExtraInfo({ display, tooltip }) {
		this.setState({
			headerExtraInfoDisplay: display,
			headerExtraInfoTooltip: tooltip,
		});
	}
	openSaveToMatterNoteDialog({
		documentName,
		documentID,
		conversationContent,
	}) {
		const now = new Date();
		const dateDisplay = now.toLocaleString();

		// example matterNoteName:
		// Shelley v. Kraemer.pdf - AI Conversation on 4/29/2024 @ 2:40pm

		const matterNoteName = `${documentName} - AI Conversation on ${dateDisplay}`;

		this.props.openGlobalDialog(
			{
				label: "Save Conversation as Matter Note",
				textAreaLabel: "New Matter Note Name",
				placeHolder: "Matter Note Name",
				value: matterNoteName,
				submitButtonLabel: "Save",
				canSubmit: true,
			},
			(state) => {
				const noteContent = `Conversation with LexWorkplace AI on ${dateDisplay} regarding "${documentName}" (LexWorkplace Document ID: ${documentID})\n\n${conversationContent}`;

				this.props.createNoteInRootOfMatter({
					matterID: this.props.matterID,
					name: state.value,
					content: noteContent,
				});
			},
			(state) => {
				const noteNameNonEmpty = state.value.trim() !== "";
				const noteNameUnderMaxLength = state.value.length <= 255;
				return {
					canSubmit: noteNameNonEmpty && noteNameUnderMaxLength,
					erroMessage: !noteNameUnderMaxLength
						? "Matter Note Name can only be 255 characters long"
						: "",
				};
			}
		);
	}

	render() {
		if (this.props.aiMode && this.props.openPane) {
			const documentName = this.props.selectedRowData.filename;

			return (
				<DocumentAISlidingPane
					isOpen={this.props.openPane}
					onRequestClose={this.props.closeRightPane}
					filename={documentName}
					closePane={this.props.closeRightPane}
					openProperties={this.openPropertiesForDocPreview}
					openDocument={this.openDocumentHandler}
					downloadCopyOfDocument={this.downloadCopyOfDocument}
					isFavorite={this.props.selectedRowData.isFavorite}
					setFavorite={this.props.setFavoriteFromPreview}
					headerExtraInfoDisplay={this.state.headerExtraInfoDisplay}
					headerExtraInfoTooltip={this.state.headerExtraInfoTooltip}
					openSaveToMatterNoteDialog={(conversationSerialization) => {
						this.openSaveToMatterNoteDialog({
							documentName,
							documentID: this.props.selectedRowData.id,
							conversationContent: conversationSerialization,
						});
					}}
					documentID={this.props.selectedRowData.id}
					setHeaderExtraInfo={this.setHeaderExtraInfo}
					docCanBeOpenedInLauncher={
						this.props.docCanBeOpenedInLauncher
					}
				/>
			);
		}
		return (
			<SlidingPaneWrapper
				isOpen={this.props.openPane}
				onRequestClose={this.props.closeRightPane}
			>
				{this.props.openPane ? (
					<div>
						{this.props.selectedRowData.type === "File" ? (
							<DocumentPreviewHeader
								filename={this.props.selectedRowData.filename}
								closePane={this.props.closeRightPane}
								openProperties={
									this.openPropertiesForDocPreview
								}
								openDocument={this.openDocumentHandler}
								downloadCopyOfDocument={
									this.downloadCopyOfDocument
								}
								isFavorite={
									this.props.selectedRowData.isFavorite
								}
								setFavorite={this.props.setFavoriteFromPreview}
								docCanBeOpenedInLauncher={
									this.props.docCanBeOpenedInLauncher
								}
							/>
						) : null}
						{this.props.selectedRowData.type === "Email" ? (
							<EmailHeader
								subjectline={
									this.props.selectedRowData.subjectline
								}
								originalFilename={
									this.props.selectedRowData.originalFilename
								}
								closePane={this.props.closeRightPane}
								openEmailShortNoteEditor={
									this.shortNoteFromEmailPreview
								}
								openEmail={this.openEmailHandler}
							/>
						) : null}
					</div>
				) : null}
				{this.props.previewLoading ? (
					<Loading />
				) : (
					<div className="preview-content">
						{this.generateDocumentPreviewContent(
							this.props.documentPreview,
							this.props.docCanBeOpenedInLauncher
						)}
					</div>
				)}
			</SlidingPaneWrapper>
		);
	}
}
export default DocumentSlidingPane;
