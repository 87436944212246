import { listDirectory, openDocumentInLauncher } from "./documentActions";

import {
	CREATE_NEW_DOCUMENT_FROM_TEMPLATE_REQUEST,
	CREATE_NEW_DOCUMENT_FROM_TEMPLATE_FAILURE,
} from "./types";

import API from "../utilities/LocalApiProxy";

export function createNewDocumentFromTemplate(
	directoryID,
	newFilename,
	newFileExtension,
	cognitoSub
) {
	return function (dispatch) {
		dispatch({ type: CREATE_NEW_DOCUMENT_FROM_TEMPLATE_REQUEST });
		API.post("/document/createFromTemplate", {
			body: {
				directoryID,
				newFilename,
				newFileExtension,
			},
		})
			.then((res) => {
				var { matterID, basepath, newDocumentID } = res.mysql;
				if (basepath[0] === "/") {
					basepath = basepath.substr(1, basepath.length - 1);
				}
				//dispatch({ type: CREATE_NEW_DOCUMENT_FROM_TEMPLATE_SUCCESS });
				listDirectory(matterID, basepath)(dispatch);
				openDocumentInLauncher(newDocumentID, cognitoSub)(dispatch);
			})
			.catch(() => {
				dispatch({ type: CREATE_NEW_DOCUMENT_FROM_TEMPLATE_FAILURE });
			});
	};
}
