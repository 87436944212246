import {
	SET_PAGINATION_INFO,
	NEXT_PAGE,
	PREVIOUS_PAGE,
	GO_TO_PAGE,
	SET_FILTERED_COUNT,
} from "./types";

//store a global with the desired submit action
var myPageChange = null;

export function setComponent(pageChangeFn, totalResults, resultsPerPage) {
	return function (dispatch) {
		myPageChange = pageChangeFn;
		dispatch({
			type: SET_PAGINATION_INFO,
			payload: {
				resultsPerPage,
				totalResults,
			},
		});
	};
}

export function nextPage() {
	return function (dispatch) {
		dispatch({
			type: NEXT_PAGE,
			payload: { fn: myPageChange },
		});
	};
}

export function previousPage() {
	return function (dispatch) {
		dispatch({
			type: PREVIOUS_PAGE,
			payload: { fn: myPageChange },
		});
	};
}
export function goToPage(pageNum) {
	return function (dispatch) {
		dispatch({
			type: GO_TO_PAGE,
			payload: { fn: myPageChange, pageNum },
		});
	};
}
export function setFilteredCount(filteredCount) {
	return function (dispatch) {
		dispatch({
			type: SET_FILTERED_COUNT,
			payload: { filteredCount },
		});
	};
}
