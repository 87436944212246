import React, { Component } from "react";

import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

import { components } from "react-select";

const filterOutHiddenItemsAndAddFooterIfNecessary = ({
	options, // options item format: { label: "Display Label", value: "value", id: ID,hidden }
	moreResultsText = "Please modify your filter to see fewer results",
	maxResultsToShowInDropdown = 200,
	// eg: an async search, the server found 203 results that match
	// the user's query but only returns a page of 50 at a time
	totalResults,
	showHiddenItemsToggle = false,
	showHiddenItemsValue = false,
}) => {
	let optionsLessHiddenItemsIfNecessary = options;
	let totalResultsLessHiddenItems = totalResults || options.length;

	if (showHiddenItemsToggle) {
		optionsLessHiddenItemsIfNecessary = options.filter((x) =>
			showHiddenItemsValue ? true : !x.hidden
		);
		totalResultsLessHiddenItems = optionsLessHiddenItemsIfNecessary.length;
	}

	let optionsToShow = optionsLessHiddenItemsIfNecessary;

	if (optionsLessHiddenItemsIfNecessary.length > maxResultsToShowInDropdown) {
		optionsToShow = optionsLessHiddenItemsIfNecessary.slice(
			0,
			maxResultsToShowInDropdown
		);
	}
	if (totalResultsLessHiddenItems > optionsToShow.length) {
		optionsToShow.push({
			label: `...and ${
				totalResultsLessHiddenItems - optionsToShow.length
			} more.  ${moreResultsText}`,
			value: "",
			isDisabled: true,
		});
	}
	if (showHiddenItemsToggle) {
		optionsToShow.push({
			label: ``,
			value: "",
			isDisabled: true,
			showAllToggle: true,
		});
	}
	return optionsToShow;
};

export class AsyncSelectComponent extends Component {
	state = {
		inputValue: "",
		allOptions: this.props.allOptions,
	};
	handleInputChange = (inputValue) => {
		this.setState({ inputValue });
		this.props.onInputChange && this.props.onInputChange(inputValue);
		return inputValue;
	};
	loadOptionsAndFilterSync = (inputValue) => {
		return this.filterOptions(inputValue, this.props.options);
	};
	filterOptions = (inputValue, options) => {
		let matches = options.filter((option) =>
			option.label.toLowerCase().includes(inputValue.toLowerCase())
		);

		return filterOutHiddenItemsAndAddFooterIfNecessary({
			options: matches,
			moreResultsText: this.props.moreResultsText,
			maxResultsToShowInDropdown: this.props.limitNumber,
			showHiddenItemsToggle: this.props.showHiddenItemsToggle,
			showHiddenItemsValue: this.props.dropdownSelectShowAll,
		});
	};
	makeDefaultOptions = () => {
		return this.filterOptions("", this.props.options);
	};

	loadOptions = (inputValue) => {
		if (this.props.loadOptions) {
			return this.props.loadOptions(inputValue).then((options) => {
				return filterOutHiddenItemsAndAddFooterIfNecessary({
					options,
					moreResultsText: this.props.moreResultsText,
					maxResultsToShowInDropdown: this.props.limitNumber,
					totalResults: this.props.totalResults,
					showHiddenItemsToggle: this.props.showHiddenItemsToggle,
					showHiddenItemsValue: this.props.dropdownSelectShowAll,
				});
			});
		} else {
			return Promise.resolve(this.loadOptionsAndFilterSync(inputValue));
		}
	};

	OptionWithShowAllToggle = (props) => {
		return props?.data?.showAllToggle ? (
			<div
				style={{
					textAlign: "center",
					padding: "8px 12px",
					cursor: "pointer",
					color: "var(--lw-dark-blue)",
				}}
				onClick={() => {
					this.props.toggleDropdownSelectShowAll();
				}}
				title={
					this.props.dropdownSelectShowAll
						? `Only show active users from my Firm`
						: `Show all users including inactive and Uptime Admins`
				}
			>
				{this.props.dropdownSelectShowAll
					? "Only Show Active Users"
					: "Show All Users (Including Inactive)"}
			</div>
		) : (
			<components.Option {...props} />
		);
	};

	render() {
		if (this.props.isCreatable) {
			return (
				<div>
					<AsyncCreatableSelect
						{...this.props}
						cacheOptions={this.props.disableCache ? false : true}
						loadOptions={this.loadOptions}
						onInputChange={this.handleInputChange}
						defaultOptions={
							this.props.defaultOptions ||
							this.makeDefaultOptions()
						}
					/>
				</div>
			);
		} else {
			return (
				<div>
					<AsyncSelect
						{...this.props}
						cacheOptions={this.props.disableCache ? false : true}
						loadOptions={this.loadOptions}
						onInputChange={this.handleInputChange}
						defaultOptions={
							this.props.defaultOptions ||
							this.makeDefaultOptions()
						}
						components={{
							Option: this.OptionWithShowAllToggle,
							...this.props.components,
						}}
					/>
				</div>
			);
		}
	}
}
