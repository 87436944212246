import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import { save as reduxLocalstorageSave, load } from "redux-localstorage-simple";

const history = createBrowserHistory();

/*
	The namespace tableSorting was originally the only place we used
	redux-localstorage-simple, but the library only supports one namespace
	so the namespace cant be changed unless we are okay with effectively
	resetting all users saved columns/sorting/tab filters

	So... the namespace remains "lxw_tableviews" for everything we
	want to save/load from localStorage
*/
const localstorageConfig = {
	namespace: "lxw_tableviews",
	states: ["tableSorting", "browserLocalstorage"],
};

const middleware = [thunk, reduxLocalstorageSave(localstorageConfig)];

// if browser extension is not installed app wont run
const store = window.__REDUX_DEVTOOLS_EXTENSION__
	? createStore(
			rootReducer(history),
			load(localstorageConfig),
			compose(
				applyMiddleware(routerMiddleware(history), ...middleware),
				window.__REDUX_DEVTOOLS_EXTENSION__()
			)
		)
	: createStore(
			rootReducer(history),
			load(localstorageConfig),
			compose(applyMiddleware(routerMiddleware(history), ...middleware))
		);

export default store;
