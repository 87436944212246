import {} from "./types";

export function setScreenSize(vec) {
	return function (dispatch) {
		dispatch({
			type: "SET_SCREEN_SIZE",
			payload: vec,
		});
	};
}
export function setResizeElement(elementID) {
	return function (dispatch) {
		dispatch({
			type: "SET_RESIZE_ELEMENT_ID",
			payload: elementID,
		});
	};
}
export function setResizeElementSize(vec) {
	return function (dispatch) {
		dispatch({
			type: "SET_RESIZE_ELEMENT_SIZE",
			payload: vec,
		});
	};
}
export function setHeaderOverflow(val) {
	return function (dispatch) {
		dispatch({
			type: "SET_HEADER_OVERFLOW",
			payload: val,
		});
	};
}
export function setForcePagination(val) {
	return function (dispatch) {
		dispatch({
			type: "SET_FORCE_PAGINATION_VISIBLE",
			payload: val,
		});
	};
}
export function setScrollbarWidth(width) {
	return function (dispatch) {
		dispatch({ type: "SET_SCROLLBAR_WIDTH", payload: width });
	};
}
