import {
	GET_NOTE_SUCCESS,
	UNMOUNT_NOTE_EDITOR,
	MOUNT_CREATE_NOTE,
	ACCESS_CREATE_NOTE,
} from "../actions/types";

//

const initialState = {
	loading: true,
	details: {},
	directoryID: null,
	fullpath: "",
	matterID: null,
	matterName: "",
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ACCESS_CREATE_NOTE:
			return {
				loading: false,
				fullpath: action.payload.fullpath,
				matterID: action.payload.matterID,
				directoryID: action.payload.directoryID,
			};
		case MOUNT_CREATE_NOTE:
			return {
				...state,
				loading: false,
				details: action.payload.note,
			};
		case GET_NOTE_SUCCESS:
			return {
				...state,
				loading: false,
				details: action.payload.note,
			};
		case UNMOUNT_NOTE_EDITOR:
			return {
				...state,
				loading: true,
				details: {},
			};
		default:
			return state;
	}
}
