import {
	GET_EVERYTHING_FOR_NEW_TENANT_REQUEST,
	GET_EVERYTHING_FOR_NEW_TENANT_SUCCESS,
	GET_EVERYTHING_FOR_EDIT_TENANT_REQUEST,
	GET_EVERYTHING_FOR_EDIT_TENANT_SUCCESS,
	UNMOUNT_NEW_EDIT_TENANT,
	CREATE_TENANT_DUPLICATE_ID_FAILURE,
	CREATE_TENANT_SUCCESS,
	EDIT_TENANT_SUCCESS,
} from "../actions/types";

const initialState = {
	newEditTenantLoading: true,
	editMatterRedirectLocation: "/admin",
	firmDetails: {},
	failedCreateAttemptDuplicateID: false,
	navigateAwayFromCreatePage: false,
	usageLimits: {},
	tenantFeatures: {},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_EVERYTHING_FOR_NEW_TENANT_REQUEST:
			return {
				...state,
				newEditTenantLoading: true,
			};
		case GET_EVERYTHING_FOR_NEW_TENANT_SUCCESS:
			return {
				...state,
				firmDetails: action.payload.firmDetails,
				newEditTenantLoading: false,
			};
		case GET_EVERYTHING_FOR_EDIT_TENANT_REQUEST:
			return {
				...state,
				newEditTenantLoading: true,
			};
		case GET_EVERYTHING_FOR_EDIT_TENANT_SUCCESS:
			return {
				...state,
				firmDetails: action.payload.firmDetails,
				usageLimits: action.payload.usageLimits,
				tenantFeatures: action.payload.tenantFeatures,
				newEditTenantLoading: false,
			};
		case UNMOUNT_NEW_EDIT_TENANT:
			return {
				...state,
				newEditTenantLoading: true,
				failedCreateAttemptDuplicateID: false,
				navigateAwayFromCreatePage: false,
			};
		case CREATE_TENANT_DUPLICATE_ID_FAILURE:
			return {
				...state,
				failedCreateAttemptDuplicateID: true,
			};
		case CREATE_TENANT_SUCCESS:
			return {
				...state,
				navigateAwayFromCreatePage: true,
			};
		case EDIT_TENANT_SUCCESS:
			return {
				...state,
				navigateAwayFromCreatePage: true,
			};
		default:
			return state;
	}
}
