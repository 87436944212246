import {
	CLOSE_GLOBAL_EDIT_DIALOG,
	OPEN_GLOBAL_EDIT_DIALOG,
	GLOBAL_DIALOG_VALIDATE,
	OPEN_GLOBAL_SNACKBAR,
} from "./types";

//store a global with the desired submit action
var onSubmit = null;
var validate = null;
var onCancel = null;

export function closeGlobalDialog() {
	return function (dispatch) {
		onCancel && onCancel();
		dispatch({
			type: CLOSE_GLOBAL_EDIT_DIALOG,
		});
	};
}

export function openGlobalDialog(
	mixin,
	submitAction,
	validateAction,
	cancelAction
) {
	onSubmit = submitAction;
	validate = validateAction;
	onCancel = cancelAction;
	return function (dispatch) {
		dispatch({
			type: OPEN_GLOBAL_EDIT_DIALOG,
			payload: mixin,
		});
	};
}
export function globalDialogSubmit(editDialogState) {
	return function (dispatch) {
		onSubmit(editDialogState);
		closeGlobalDialog()(dispatch);
	};
}
export function globalDialogValidate(editDialogState) {
	return function (dispatch) {
		dispatch({
			type: GLOBAL_DIALOG_VALIDATE,
			payload: validate && validate(editDialogState),
		});
	};
}
export function openValueCopyDialog(
	value,
	label = "",
	snackbarMessage = "Value Copied"
) {
	return function (dispatch) {
		openGlobalDialog(
			{
				label,
				value,
				canSubmit: true,
				hideTextfield: true,
				readOnly: true,
				type: "copyValue",
				hideCancelButton: true,
			},
			null,
			() => ({
				canSubmit: true,
			}),
			() => {
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						message: snackbarMessage,
						variant: "success",
						loadingSpinnerVisible: false,
					},
				});
			}
		)(dispatch);
	};
}
