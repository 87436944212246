import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		justifyContent: "left",
		flexWrap: "wrap",
		overflow: "hidden",
	},
	chip: {
		margin: theme.spacing(0.5),
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
}));

export default function ChipsArray(props) {
	const classes = useStyles();

	return (
		<div
			className={props.ignoreRootClass ? "" : classes.root}
			style={props.style || null}
		>
			{props.chips.map((data) => {
				return (
					<Chip
						key={data.key}
						label={data.label}
						className={classes.chip}
						style={data.style || null}
						title={data.title || null}
						onDelete={data.handleDelete || null}
						onClick={data.handleClick || null}
					/>
				);
			})}
		</div>
	);
}
