import React, { Component } from "react";
import { connect } from "react-redux";

import TextDisplayWithCopyToClipboard from "./TextDisplayWithCopyToClipboard";
import { fetchUserDetail, adminUpdateUser } from "../actions/adminActions";

import { extractCognitoAttributesAsObject } from "../utilities/awsUtilities";

import { EditUserEmailWithValidation } from "./EditUserAttributesWithValidation";

import TwoColumnWrapper from "./TwoColumnWrapper";

class EditUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emailAddress: "",
			username: "",
			displayName: "",
			sub: "",
			loading: true,
			original: {},
			dbOriginal: {},

			emailIsValid: false,
			tenantID: null,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		fetchUserDetail(this.props.match.params.sub).then((res) => {
			const databaseUserInfo = res.mysql.db;
			const cognitoUserInfo = res.mysql.cognito;

			if (databaseUserInfo) {
				this.setState({
					loading: false,
					tenantID: databaseUserInfo.tenantID,
					dbOriginal: databaseUserInfo,
				});
			}
			//console.log(res.mysql.db);
			if (cognitoUserInfo) {
				const {
					Attributes,
					//Enabled,
					//UserStatus,
					Username,
				} = cognitoUserInfo;
				const attributesObj =
					extractCognitoAttributesAsObject(Attributes);

				const username = Username;
				const emailAddress = attributesObj.email;
				const displayName = attributesObj.name;
				const sub = attributesObj.sub;

				this.setState({
					loading: false,
					...{ username, emailAddress, displayName, sub },
					original: { username, emailAddress, displayName },
				});
			} else {
				console.error("no user");
			}
		});
	}

	handleChange(key, value) {
		this.setState({ [key]: value });
	}

	submit() {
		const { username, emailAddress } = this.state;
		this.props.adminUpdateUser({
			username,
			emailAddress,
			tenantID: this.state.tenantID,
		});
	}
	render() {
		if (this.state.loading) {
			return (
				<div>
					<h1>
						Loading<span className="Loading">...</span>
					</h1>
				</div>
			);
		} else {
			const emailChanged =
				this.state.emailAddress !== this.state.original.emailAddress;

			const displayNameChanged =
				this.state.displayName !== this.state.original.displayName;

			return (
				<TwoColumnWrapper
					headingText="User Information"
					iconName="user"
					submit={this.submit.bind(this)}
					canSubmit={
						this.state.emailIsValid &&
						(emailChanged || displayNameChanged)
					}
					submitButtonText={"Update User"}
					leftChildren={
						<div>
							<TextDisplayWithCopyToClipboard
								label="Display Name"
								value={this.state.displayName}
							/>

							<EditUserEmailWithValidation
								label="Email Address"
								name="emailAddress"
								placeholder="test@example.com"
								onChange={this.handleChange}
								defaultValue={this.state.original.emailAddress}
								fieldIsValidAndReadyChanged={(emailIsValid) => {
									this.setState({
										emailIsValid,
									});
								}}
							/>
						</div>
					}
					rightChildren={
						<div>
							<div>
								<TextDisplayWithCopyToClipboard
									label="Username"
									value={this.state.username}
								/>
							</div>
							<div>
								<TextDisplayWithCopyToClipboard
									label="Cognito Sub"
									value={this.state.sub}
								/>
							</div>
						</div>
					}
				/>
			);
		}
	}
}

export default connect(() => ({}), {
	adminUpdateUser,
})(EditUser);
