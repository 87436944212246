import React, { Component } from "react";
import { connect } from "react-redux";

import { setSubheaderText } from "../actions/navigateActions";
import { getEWSUrl, setEWSUrl } from "../actions/tenantActions";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { LabeledTextInput } from "./TextInput";

class ChangeUserAttributes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canSubmit: false,
			ewsUrl: "",
			loading: true,
		};
		this.handleChange = this.handleChange.bind(this);
		this.submit = this.submit.bind(this);
		this.validate = this.validate.bind(this);
	}
	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = {
				loading: false,
				ewsUrl: props.ewsUrl,
			};
			return newState;
		} else {
			return null;
		}
	}
	componentDidMount() {
		this.validate();
		this.props.setSubheaderText("Update Microsoft Exchange Server URL");
		this.props.getEWSUrl();
	}
	handleChange(key, value) {
		this.setState({ [key]: value }, this.validate);
	}

	validate() {
		const urlIsDifferent = this.state.ewsUrl !== this.props.ewsUrl;
		const urlConforms = true;

		this.setState({
			canSubmit: urlIsDifferent && urlConforms,
		});
	}

	submit() {
		if (this.state.canSubmit) {
			this.props.setEWSUrl(this.state.ewsUrl);
		}
	}

	render() {
		return (
			<div style={{ width: 600 }}>
				{this.state.loading ? null : (
					<div>
						<LabeledTextInput
							label="Exchange Server URL"
							name="ewsUrl"
							defaultValue={this.props.ewsUrl}
							onChange={this.handleChange}
							disabled={!this.props.canEditExchangeUrl}
						/>

						{this.props.canEditExchangeUrl ? (
							<ButtonWithIcon
								disabled={!this.state.canSubmit}
								handler={this.submit}
								buttonText="Update Exchange Server URL"
								iconName="check"
							/>
						) : (
							<span>
								Only Firm Administrators can edit this setting
							</span>
						)}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	userDisplayName: state.user.userDisplayName,
	email: state.user.userEmail,
	emailVerified: state.user.emailVerified,
	errorMessage: state.user.errorMessage,
	ewsUrl: state.tenant.ewsUrl,
	loading: state.tenant.loading,
	canEditExchangeUrl: state.user.canEditExchangeUrl,
});
export default connect(mapStateToProps, {
	getEWSUrl,
	setEWSUrl,
	setSubheaderText,
})(ChangeUserAttributes);
