import React, { useState, useRef, useEffect } from "react";

const TypingAnimation = ({
	fullText,
	startTyping = () => {},
	onType = () => {},
	finishedTyping = () => {},
	charsPerFrame = 31,
	animationTimeMS = 89,
}) => {
	const [currentText, setCurrentText] = useState("");

	const animationInterval = useRef();

	useEffect(() => {
		let lastCharIndex = 0;
		startTyping();
		clearInterval(animationInterval.current);

		animationInterval.current = setInterval(() => {
			if (lastCharIndex < fullText.length) {
				lastCharIndex += charsPerFrame;
				setCurrentText(fullText.substring(0, lastCharIndex));
				onType();
			} else {
				clearInterval(animationInterval.current);
				finishedTyping();
			}
		}, animationTimeMS);

		//the return function is called if the component is un-mounted
		return () => {
			clearInterval(animationInterval.current);
		};
	}, [fullText, charsPerFrame, animationTimeMS]);

	return <span>{currentText}</span>;
};

export default TypingAnimation;
