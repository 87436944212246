/*
	This is used for client tags, matter tags, matter types, office/branch, document types, document statuses
*/

import API from "../utilities/LocalApiProxy";

const actionMap = {
	"/client/tags": "FETCH_CLIENT_TAGS",
	"/matter/tags": "FETCH_MATTER_TAGS",
	"/matters/types": "FETCH_MATTER_TYPES",
	"/officeBranch": "FETCH_OFFICE_BRANCH",
	"/document/tags": "FETCH_DOCUMENT_TAGS",
	"/document/types": "FETCH_DOCUMENT_TYPES",
	"/document/status": "FETCH_DOCUMENT_STATUSES",
};

//prefix: eg "/client/tags"
export function makeTagActions(prefix) {
	return {
		fetchTags: getTags(prefix),
		deactivateTags: deactivateTags(prefix),
		activateTags: activateTags(prefix),
		deleteTags: deleteTags(prefix),
		updateTags: updateTags(prefix),
		createTags: createTags(prefix),
	};
}

export function getTags(prefix) {
	return () => {
		return function (dispatch) {
			dispatch({
				type: actionMap[prefix] + "_REQUEST",
			});
			API.get(prefix)
				.then((result) => {
					dispatch({
						type: actionMap[prefix] + "_SUCCESS",
						payload: {
							tags: result.mysql,
						},
					});
				})
				.catch((err) => {
					dispatch({
						type: actionMap[prefix] + "_FAILURE",
					});
					console.log(err);
				});
		};
	};
}
export function deactivateTags(prefix) {
	return (tagIds) => {
		return function (dispatch) {
			API.put(prefix + "/deactivate", {
				body: { tagIds: tagIds },
			})
				.then(() => {
					getTags(prefix)()(dispatch);
				})
				.catch((err) => console.log(err));
		};
	};
}
export function activateTags(prefix) {
	return (tagIds) => {
		return function (dispatch) {
			API.put(prefix + "/activate", {
				body: { tagIds: tagIds },
			})
				.then(() => {
					getTags(prefix)()(dispatch);
				})
				.catch((err) => console.log(err));
		};
	};
}
export function deleteTags(prefix) {
	return (tagIds) => {
		return function (dispatch) {
			API.put(prefix + "/delete", {
				body: { tagIds: tagIds },
			})
				.then(() => {
					getTags(prefix)()(dispatch);
				})
				.catch((err) => console.log(err));
		};
	};
}
export function updateTags(prefix) {
	return (tags) => {
		return function (dispatch) {
			API.put(prefix, {
				body: { tags },
			})
				.then(() => {
					getTags(prefix)()(dispatch);
				})
				.catch((err) => console.log(err));
		};
	};
}
export function createTags(prefix) {
	return (newTags) => {
		return function (dispatch) {
			API.post(prefix, {
				body: { newTags },
			})
				.then(() => {
					getTags(prefix)()(dispatch);
				})
				.catch((err) => console.log(err));
		};
	};
}
