import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
	fetchClientList,
	deleteClients,
	deactivateClients,
	activateClients,
} from "../actions/clientActions";
import { setSubheaderText } from "../actions/navigateActions";

import CustomizableTable from "./Tables/CustomizableTable";
import InteractiveTableCell from "./Tables/InteractiveTableCell";
import { ButtonWithRouter } from "./ButtonWithIcon";
import "react-table/react-table.css";

const tableUniqueID = "/clients";

class ClientList extends Component {
	constructor(props, context) {
		super(props, context);

		this.columnConfig = [
			{ Header: "ID", accessor: "clientID", width: 40 },
			{
				Header: "Client Name",
				accessor: "clientName",
				Cell: (row) => (
					<InteractiveTableCell
						menuItems={
							row.original.clientStatus === "active"
								? [
										{
											text: "Edit Client",
											href:
												row.original.clientID + "/edit",
										},
										{
											text: "Deactivate Client",
											onSelect: () =>
												this.props.deactivateClients([
													row.original.clientID,
												]),
										},
									]
								: [
										{
											text: "Edit Client",
											href:
												row.original.clientID + "/edit",
										},
										{
											text: "Activate Client",
											onSelect: () =>
												this.props.activateClients([
													row.original.clientID,
												]),
										},
										{
											text: "Delete Client",
											onSelect: () =>
												this.props.deleteClients([
													row.original.clientID,
												]),
										},
									]
						}
					>
						<Link to={row.original.clientID + "/"}>
							{row.value}
						</Link>
					</InteractiveTableCell>
				),
				wildcard: true,
			},
			{
				Header: "Status",
				accessor: "clientStatus",
				Cell: (row) => (
					<span style={{ textTransform: "capitalize" }}>
						{row.value}
					</span>
				),
			},
			{
				Header: "Tags",
				accessor: "tags",
				Cell: (row) =>
					row.value
						? row.value.map((x) => (
								<div className="tagPill" key={x.tagName}>
									{x.tagName}
								</div>
							))
						: "",

				filterValueMethod: (x) =>
					(x.tags || []).map((t) => t.tagName).join(", "),
			},
			{
				Header: "Primary Attorney(s)",
				accessor: "primaryAttorneys",
				Cell: (row) =>
					row.value
						? row.value.map((x) => x.displayName).join(", ")
						: "",
				filterValueMethod: (x) =>
					(x.primaryAttorneys || [])
						.map((s) => s.displayName)
						.join(", "),
			},
		];
		this.filterMap = {
			All: () => true,
			Active: (row) => row.clientStatus === "active",
			Inactive: (row) => row.clientStatus === "inactive",
			My: (row) => {
				return (
					row.primaryAttorneys &&
					row.primaryAttorneys.length &&
					row.primaryAttorneys.reduce(
						(acc, cur) =>
							acc || cur.cognitoSub === this.props.cognitoSub,
						false
					)
				);
			},
		};
	}
	UNSAFE_componentWillMount() {
		this.props.setSubheaderText("Clients");
		this.props.fetchClientList();
	}

	render() {
		return (
			<div className="fullHeightFlex noDefaultMargin">
				<CustomizableTable
					actionButtons={
						<ButtonWithRouter
							redirect="/clients/new"
							buttonText="New Client"
							iconName="user"
							disabled={!this.props.canCreateClient}
							disabledHoverText="You do not have permissions to create new clients"
						/>
					}
					tabsFilterMap={this.filterMap}
					defaultTabFilterValue="Active"
					leftSideIcon="user"
					className="fullWidth"
					loading={this.props.loading}
					data={this.props.clientListData}
					columns={this.columnConfig}
					autoResize
					noResultsText={"No Clients in this view"}
					tableUniqueID={tableUniqueID}
				/>
			</div>
		);
	}
}
ClientList.propTypes = {
	fetchClientList: PropTypes.func.isRequired,
	setSubheaderText: PropTypes.func.isRequired,
	clientListData: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
	title: state.router.location.pathname,
	clientListData: state.client.clientListData,
	myUserID: state.user.userID,
	loading: state.request.loading,
	cognitoSub: state.user.cognitoUser.attributes.sub,
	canCreateClient:
		state.permissionFeatures.allowedFeatures.CREATE_CLIENT_ALLOWED,
});
export default connect(mapStateToProps, {
	fetchClientList,
	deleteClients,
	deactivateClients,
	activateClients,
	setSubheaderText,
})(ClientList);
