import {} from "./types";
import legalworksConfig from "../config/legalworks.frontend.config";

export function setLauncherInstalled(launcherInstalled) {
	return function (dispatch) {
		if (launcherInstalled) {
			window.localStorage.setItem("launcherInstalled", launcherInstalled);
		}
		dispatch({
			type: "SET_LAUNCHER_INSTALLED",
			payload: launcherInstalled,
		});
	};
}
export function sendActivateLauncherMessage(refreshToken) {
	return function () {
		openLauncherLink(`login/${refreshToken}`);
	};
}

let launcherLinkTimerObject = {};

const debounceLauncherLinks = (func, timeout) => {
	return (...args) => {
		let arg0 = args[0]; //this is the launcher link and will be used to index the timer object
		let timer = launcherLinkTimerObject[arg0];
		if (!timer) {
			func.apply(this, args);
		}
		clearTimeout(launcherLinkTimerObject[arg0]);
		launcherLinkTimerObject[arg0] = setTimeout(() => {
			launcherLinkTimerObject[arg0] = undefined;
		}, timeout);
	};
};

function openLauncherLinkDebounced(fullURL) {
	window.location.href = fullURL;
}

export function openLauncherLink(url) {
	/*
	Depending on API, the link to open in launcher
	for document with ID:1 will look like:

	local API: lexworkplace://ll/docs/1?cognitoSub=e8f3c1ae-e9c8-4ef8-bec8-e6b31d23daaf
	dev: lexworkplace://dd/docs/1?cognitoSub=e8f3c1ae-e9c8-4ef8-bec8-e6b31d23daaf
	newfeature: lexworkplace://nn/docs/1?cognitoSub=e8f3c1ae-e9c8-4ef8-bec8-e6b31d23daaf
	staging: lexworkplace://ss/docs/1?cognitoSub=e8f3c1ae-e9c8-4ef8-bec8-e6b31d23daaf
	production: lexworkplace://pp/docs/1?cognitoSub=e8f3c1ae-e9c8-4ef8-bec8-e6b31d23daaf
	*/
	let debouncedOpenLauncherLink = debounceLauncherLinks(
		openLauncherLinkDebounced,
		500
	);

	var environmentCode = getLauncherCode();
	if (environmentCode) {
		let fullLauncherURL = `lexworkplace://${environmentCode}/${url}`;
		debouncedOpenLauncherLink(fullLauncherURL);
		return true;
	} else {
		return false;
	}
}
const launcherCodeApiMap = {
	localstack: "l",
	development: "d",
	staging: "s",
	production: "p",
	newfeature: "n",
};
const launcherCodeHostMap = {
	"legalworks.local:3000": "l",
	"lexworkplace.local:3000": "l",
	"dev.legalworks.app": "d",
	"dev.lexworkplace.app": "d",
	"staging.legalworks.app": "s",
	"staging.lexworkplace.app": "s",
	"legalworks.app": "p",
	"lexworkplace.app": "p",
	"newfeature.lexworkplace.app": "n",
};

const getLauncherCode = () => {
	/*
		This is used so the launcher knows which API to communicate with.
		The launcher supports using multiple APIs (dev.lexworkplace.app, and staging.lexworkplace.app for instance)
		at the same time.  It does NOT support 

	*/
	const frontendCode = launcherCodeHostMap[document.location.host];
	const apiCode = launcherCodeApiMap[legalworksConfig.apiName];
	if (frontendCode && apiCode) {
		return frontendCode + apiCode;
	} else {
		console.error(
			"Invalid environment configuration.  One or both of these is invalid:"
		);
		console.error("document.location.host:", document.location.host);
		console.error("legalworksConfig.apiName:", legalworksConfig.apiName);
		console.error(
			"See launcherActions.js for a list of allowed frontend and backend values"
		);
		return null;
	}
};
