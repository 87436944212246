import { SET_LXW_LOCALSTORAGE_VALUE } from "../actions/types";

const initialState = {
	sidenavExpanded: true,
	matterInfoHidden: false,
	whatsNewDropdownLastHashViewed: 0,
	inlineCompareUrl: "",
	dropdownSelectShowAll: {
		users: false,
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_LXW_LOCALSTORAGE_VALUE: {
			const { key, value } = action.payload;

			return { ...state, [key]: value };
		}
		default:
			return state;
	}
}
