import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import green from "@material-ui/core/colors/green";
import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import "./Snackbar.css";

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

const styles1 = (theme) => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: "var(--lw-blue)",
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: 4,
	},
	message: {
		display: "flex",
		alignItems: "center",
	},
});

function MySnackbarContent(props) {
	const {
		classes,
		className,
		message,
		onClose,
		variant,
		loadingSpinnerVisible,
		allowClose,
		...other
	} = props;
	const Icon = variantIcon[variant];

	return (
		<SnackbarContent
			className={classNames(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					{loadingSpinnerVisible ? (
						<div className="snackbarProgressSpinner">
							<CircularProgress
								style={{
									width: 30,
									height: 30,
									marginTop: -15,
									marginBottom: -15,
									verticalAlign: "middle",
								}}
							/>
						</div>
					) : (
						<Icon
							className={classNames(
								classes.icon,
								classes.iconVariant
							)}
						/>
					)}
					{message}
				</span>
			}
			action={[
				allowClose ? (
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						className={classes.close}
						onClick={onClose}
						style={{ height: 30 }}
					>
						<CloseIcon className={classes.icon} />
					</IconButton>
				) : null,
			]}
			{...other}
		/>
	);
}

MySnackbarContent.propTypes = {
	classes: PropTypes.object.isRequired,
	className: PropTypes.string,
	message: PropTypes.node,
	onClose: PropTypes.func,
	variant: PropTypes.oneOf(["success", "warning", "error", "info"])
		.isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = () => ({
	margin: {
		margin: 6,
	},
});

class CustomizedSnackbars extends React.Component {
	//state = { open: true };
	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		this.props.onClose();
	};

	render() {
		return (
			<Snackbar
				key={this.props.snackbarKey}
				height="56px"
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				className={`snackbarSpacer`}
				open={this.props.open}
				autoHideDuration={this.props.autoHideDuration}
				onClose={this.handleClose}
				disableWindowBlurListener={this.props.disableWindowBlurListener}
			>
				<MySnackbarContentWrapper
					onClose={this.handleClose}
					variant={this.props.variant}
					message={this.props.message}
					loadingSpinnerVisible={this.props.loadingSpinnerVisible}
					allowClose={this.props.allowClose}
				/>
			</Snackbar>
		);
	}
}

CustomizedSnackbars.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles2)(CustomizedSnackbars);
