import {
	AUDIT_SEARCH_SUCCESS,
	AUDIT_SEARCH_FAILURE,
	INDEX_MISSING_SEARCH_ITEMS_SUCCESS,
	INDEX_MISSING_SEARCH_ITEMS_FAILURE,
	ACCESS_SEARCH_AUDIT_PAGE,
	SET_ACTIVE_OPERATION_ON_AUDIT_PAGE,
} from "../actions/types";

//
const initialState = {
	tenantID: null,
	resultsLoaded: false,
	auditResults: {},
	auditFailed: false,
	auditFailedData: {},
	jobMetadata: {},
	repairRequestPending: false,
	repairRequestFailed: false,
	repairData: {},
	repairJobMetadata: {},
	activeOperationOnAuditPage: "audit",
};

export default function (state = initialState, action) {
	switch (action.type) {
		case ACCESS_SEARCH_AUDIT_PAGE:
			/*
				The audit results are not loaded from the server, so if the admin user
				navigates to another firm's audit page, the previous results dissappear
			*/
			if (
				action.payload.tenantID > 0 &&
				action.payload.tenantID !== state.tenantID
			) {
				return {
					...initialState,
					tenantID: action.payload.tenantID,
				};
			} else {
				return { ...state };
			}

		case AUDIT_SEARCH_SUCCESS:
			return {
				...state,
				resultsLoaded: true,
				auditResults: action.payload.auditData,
				auditFailed: false,
				jobMetadata: action.payload.jobMetadata,
			};
		case AUDIT_SEARCH_FAILURE:
			return {
				...state,
				resultsLoaded: true,
				auditFailed: true,
				auditFailedData: action.payload.auditData,
				jobMetadata: action.payload.jobMetadata,
			};
		case INDEX_MISSING_SEARCH_ITEMS_SUCCESS:
			return {
				...state,
				repairRequestPending: false,
				repairRequestFailed: false,
				repairData: action.payload.repairData,
				repairJobMetadata: action.payload.jobMetadata,
			};
		case INDEX_MISSING_SEARCH_ITEMS_FAILURE:
			return {
				...state,
				repairRequestPending: false,
				repairRequestComplete: true,
				repairRequestFailed: true,
				repairData: action.payload.repairData,
				repairJobMetadata: action.payload.jobMetadata,
			};
		case SET_ACTIVE_OPERATION_ON_AUDIT_PAGE:
			return {
				...state,
				activeOperationOnAuditPage: action.payload.operation,
			};

		default:
			return state;
	}
}
