import React from "react";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// Be sure to include styles at some point, probably during your bootstraping
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "./LeftSidebar.css";

import { toggleSidenav } from "../actions/browserLocalstorageActions";

const Icon = (props) => (
	<div style={{ paddingTop: 2 }}>
		<FontAwesomeIcon {...props} />
	</div>
);
const sidenavOptions = [
	{
		eventKey: "/",
		pathExact: "/", //exact match
	},
	{ eventKey: "/clients/" },
	{ eventKey: "/matters/" },
	{
		eventKey: "/firmDocs/public/",
		pathPrefix: "/firmDocs", //prefix match is different than the destination when user clicks the nav item
	},
	{ eventKey: "/search" },
	{
		eventKey: "/sharing/outgoing",
		pathPrefix: "/sharing",
	},
	{ eventKey: "/ai" },
	{ eventKey: "/settings" },
	{ eventKey: "/admin" },
	{ eventKey: "/superAdmin" },
];
class LeftSidebar extends React.Component {
	constructor(props) {
		super(props);
		this.checkSelectedItem = this.checkSelectedItem.bind(this);
	}

	checkSelectedItem() {
		const pathname = this.props.browserLocationPathname;

		const selectedItem = sidenavOptions.find((item) => {
			if (item.pathExact) {
				return pathname === item.pathExact;
			} else if (item.pathPrefix) {
				return pathname.indexOf(item.pathPrefix) === 0;
			} else {
				return pathname.indexOf(item.eventKey) === 0;
			}
		});

		const selectedEventKey = selectedItem
			? selectedItem.eventKey
			: undefined;

		return selectedEventKey;
	}
	render() {
		return (
			<SideNav
				expanded={this.props.sidenavExpanded}
				onSelect={(selected) => {
					const { history } = this.props;
					if (selected.match(/^http/)) {
						window.open(selected, "_blank");
					} else {
						history.push(selected);
					}
				}}
				onToggle={this.props.toggleSidenav}
				className="LeftSidebar"
			>
				<SideNav.Toggle />
				<SideNav.Nav selected={this.checkSelectedItem()}>
					<NavItem eventKey="/">
						<NavIcon>
							<Icon icon="home" size="2x" />
						</NavIcon>
						<NavText>
							<div className="NavText">Home</div>
						</NavText>
					</NavItem>

					<NavItem eventKey="/clients/">
						<NavIcon>
							<Icon icon="user" size="2x" />
						</NavIcon>
						<NavText className="NavText">
							<div className="NavText">Clients</div>
						</NavText>
					</NavItem>

					<NavItem eventKey="/matters/">
						<NavIcon>
							<Icon icon="briefcase" size="2x" />
						</NavIcon>
						<NavText className="NavText">
							<div className="NavText">Matters</div>
						</NavText>
					</NavItem>

					<NavItem eventKey="/firmDocs/public/">
						<NavIcon>
							<Icon icon="folder" size="2x" />
						</NavIcon>
						<NavText className="NavText">
							<div className="NavText">Firm Documents</div>
						</NavText>
					</NavItem>

					<NavItem eventKey="/search">
						<NavIcon>
							<Icon icon="search" size="2x" />
						</NavIcon>
						<NavText className="NavText">
							<div className="NavText">Search</div>
						</NavText>
					</NavItem>

					<NavItem eventKey="/sharing/outgoing">
						<NavIcon>
							<Icon icon="share-square" size="2x" />
						</NavIcon>
						<NavText className="NavText">
							<div className="NavText">Sharing</div>
						</NavText>
					</NavItem>

					<NavItem eventKey="/ai">
						<NavIcon>
							<Icon icon="sparkles" size="2x" />
						</NavIcon>
						<NavText className="NavText">
							<div className="NavText">LexWorkplace AI</div>
						</NavText>
					</NavItem>

					<NavItem eventKey="/settings">
						<NavIcon>
							<Icon icon="cog" size="2x" />
						</NavIcon>
						<NavText className="NavText">
							<div className="NavText">Settings</div>
						</NavText>
					</NavItem>

					<NavItem eventKey="https://lexworkplace.com/support/">
						<NavIcon>
							<Icon icon="question-circle" size="2x" />
						</NavIcon>
						<NavText className="NavText">
							<div className="NavText">Help</div>
						</NavText>
					</NavItem>

					<NavItem eventKey="nothing">
						<NavIcon>
							<Icon icon="code" size="2x" />
						</NavIcon>
						<NavText className="NavText">
							<div className="NavText">Development</div>
						</NavText>
						<NavItem eventKey="https://lexworkplace.canny.io">
							<NavIcon>
								<Icon icon={["far", "compass"]} />
							</NavIcon>
							<NavText className="NavText">
								<div className="NavText">Product Roadmap</div>
							</NavText>
						</NavItem>
						<NavItem eventKey="https://lexworkplace.com/whats-new">
							<NavIcon>
								<Icon icon="asterisk" size="2x" />
							</NavIcon>
							<NavText className="NavText">
								<div className="NavText">What&apos;s New</div>
							</NavText>
						</NavItem>
						<NavItem eventKey="https://lexworkplace.canny.io/feature-requests">
							<NavIcon>
								<Icon icon="flask" size="2x" />
							</NavIcon>
							<NavText className="NavText">
								<div className="NavText">Request a Feature</div>
							</NavText>
						</NavItem>
					</NavItem>
					{this.props.isLexworkplaceAdmin && (
						<NavItem eventKey="/admin">
							<NavIcon>
								<Icon icon="key" size="2x" />
							</NavIcon>
							<NavText className="NavText">
								<div className="NavText">Admin</div>
							</NavText>
						</NavItem>
					)}
					{this.props.isSuperAdmin && (
						<NavItem eventKey="/superAdmin">
							<NavIcon>
								<Icon icon="user-group-crown" size="2x" />
							</NavIcon>
							<NavText className="NavText">
								<div className="NavText">Super Admin</div>
							</NavText>
						</NavItem>
					)}
				</SideNav.Nav>
			</SideNav>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		browserLocationPathname: state.globalSearchbar.location,
		sidenavExpanded: state.browserLocalstorage.sidenavExpanded,
	};
};
const LeftSidebarWithRouter = withRouter(
	connect(mapStateToProps, { toggleSidenav })(LeftSidebar)
);
export { LeftSidebarWithRouter };
