import { TOGGLE_COLUMN } from "./types";
import API from "../utilities/LocalApiProxy";
import store from "../store";
import {
	GET_ALL_CUSTOM_COLUMNS_SUCCESS,
	GET_ALL_CUSTOM_COLUMNS_FAILURE,
} from "./types";
window.store = store;

var timer;

export function toggleColumnVisibility(listName, columnName) {
	return function (dispatch) {
		window.clearTimeout(timer);
		timer = window.setTimeout(() => {
			saveAllCustomColumns()(dispatch);
			console.log("Saving custom columns");
		}, 2000);

		dispatch({
			type: TOGGLE_COLUMN,
			payload: { listName, columnName },
		});
	};
}
export function saveAllCustomColumns() {
	return function () {
		var columnsJSON = JSON.stringify(store.getState().customColumns);
		API.put("/user/customColumns", { body: { columnsJSON } }).then(() => {
			console.log("successfully saved custom columns");
		});
	};
}
export function getCustomColumns() {
	return function (dispatch) {
		API.get("/user/customColumns")
			.then((results) => {
				if (
					results &&
					results.mysql &&
					results.mysql.length &&
					results.mysql[0].columnsJSON
				) {
					var json = JSON.parse(results.mysql[0].columnsJSON);
					dispatch({
						type: GET_ALL_CUSTOM_COLUMNS_SUCCESS,
						payload: json,
					});
				}
			})
			.catch(() => {
				dispatch({ type: GET_ALL_CUSTOM_COLUMNS_FAILURE });
			});
	};
}
