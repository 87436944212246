import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { LabeledTextInput } from "./TextInput";
import { Redirect, Link } from "react-router-dom";
import { logIn, confirmSignIn } from "../actions/userAuthActions";
import {
	USER_LOGIN_UNSUCCESSFUL,
	NEW_PASSWORD_REQUIRED,
	SOFTWARE_TOKEN_MFA_CHALLENGE,
	MFA_VERIFICATION_ERROR,
} from "../actions/types";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			mfaCode: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.submit = this.submit.bind(this);
	}
	handleChange(key, value) {
		this.setState({ [key]: value });
	}
	submit() {
		if (this.props.mfaChallenge || this.props.mfaChallengeFailure) {
			this.props.confirmSignIn(
				this.props.cognitoUser,
				this.state.mfaCode
			);
		} else {
			this.props.logIn(this.state.username, this.state.password);
		}
	}
	render() {
		if (this.props.newPasswordRequired) {
			return <Redirect to="/changePassword" />;
		}
		return (
			<div className="center300">
				<h2 className="centeredH2">Sign in to LexWorkplace</h2>

				{this.props.mfaChallenge || this.props.mfaChallengeFailure ? (
					<LabeledTextInput
						label="MFA Code"
						name="mfaCode"
						placeholder="MFA Code"
						onChange={this.handleChange}
						onEnterKey={this.submit}
					/>
				) : (
					<div>
						<LabeledTextInput
							label="Username"
							name="username"
							placeholder="Username"
							onChange={this.handleChange}
							onEnterKey={this.submit}
						/>
						<LabeledTextInput
							label="Password"
							name="password"
							type="password"
							placeholder="Password"
							onChange={this.handleChange}
							onEnterKey={this.submit}
						/>
					</div>
				)}
				<div style={{ minHeight: 40 }}>
					{this.props.unsuccessfulLoginAttempt ||
					this.props.mfaChallengeFailure ? (
						<div className="inlineErrorIndicator">
							{this.props.errorMessage}
						</div>
					) : null}
				</div>
				<div>
					<ButtonWithIcon
						handler={this.submit}
						buttonText="Log In"
						iconName="check"
					/>
				</div>
				<div style={{ marginTop: 16 }}>
					<Link to="/forgotPassword">Forgot Password</Link>
				</div>
				<div
					className="MainFontBold"
					style={{
						marginTop: 48,
						marginBottom: 24,
					}}
				>
					Not a LexWorkplace Customer?
				</div>
				<div>See what you&apos;re missing.</div>
				<div>
					<a href="https://lexworkplace.com/contact-us">Contact Us</a>
				</div>
			</div>
		);
	}
}
Login.propTypes = {
	logIn: PropTypes.func.isRequired,
	unsuccessfulLoginAttempt: PropTypes.bool.isRequired,
	newPasswordRequired: PropTypes.bool.isRequired,
	mfaChallenge: PropTypes.bool.isRequired,
	errorMessage: PropTypes.string.isRequired,
	cognitoUser: PropTypes.object,
};
const mapStateToProps = (state) => ({
	unsuccessfulLoginAttempt: state.user.authState === USER_LOGIN_UNSUCCESSFUL,
	newPasswordRequired: state.user.authState === NEW_PASSWORD_REQUIRED,
	mfaChallenge: state.user.authState === SOFTWARE_TOKEN_MFA_CHALLENGE,
	mfaChallengeFailure: state.user.authState === MFA_VERIFICATION_ERROR,
	errorMessage: state.user.errorMessage,
	cognitoUser: state.user.cognitoUser,
});
export default connect(mapStateToProps, { logIn, confirmSignIn })(Login);
