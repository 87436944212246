import React, { Component } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from "./Footer";

const LoadingBlocker = (props) => {
	return (
		<div
			style={{
				position: "fixed",
				zIndex: 1600,
				height: "100%",
				width: "100%",
				background: "rgba(100, 100, 100, 0.3)",
				textAlign: "center",
			}}
		>
			<div
				style={{
					position: "relative",
					top: "34%",
				}}
			>
				<div>
					<CircularProgress />
				</div>
				<h3 style={{ whiteSpace: "pre" }}>{props.loadingMessage}</h3>
			</div>
		</div>
	);
};

class UnderSubHeader extends Component {
	render() {
		return (
			<div
				style={{
					flex: 1,
					width: "100%",
					display: "flex",
					flexDirection: "column",
					minHeight: "0px",
				}}
			>
				{this.props.loading ? (
					<LoadingBlocker
						loadingMessage={this.props.loadingMessage}
					/>
				) : null}
				<div className="UnderSubHeader">{this.props.children}</div>
				<Footer />
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	loading: state.global.loading,
	loadingMessage: state.global.message,
});
export default connect(mapStateToProps, {})(UnderSubHeader);

export { LoadingBlocker };
