import React, { Component } from "react";
import { connect } from "react-redux";
import Snackbar from "./Snackbar";
import { closeSnackbar } from "../actions/globalSnackbarActions";

class GlobalSnackbar extends Component {
	render() {
		return Object.keys(this.props.snackbars).length
			? Object.keys(this.props.snackbars).map((snackbarKey, i) => {
					const {
						open,
						message,
						variant,
						autoHideDuration,
						loadingSpinnerVisible,
						allowClose,
						disableWindowBlurListener,
					} = this.props.snackbars[snackbarKey];
					return (
						<Snackbar
							key={snackbarKey}
							onClose={() =>
								this.props.closeSnackbar({ snackbarKey })
							}
							open={open}
							message={message}
							variant={variant}
							autoHideDuration={autoHideDuration}
							loadingSpinnerVisible={loadingSpinnerVisible}
							allowClose={allowClose}
							disableWindowBlurListener={
								disableWindowBlurListener
							}
							snackbarKey={snackbarKey}
						/>
					);
				})
			: null;
	}
}
const mapStateToProps = (state) => ({
	snackbars: state.snackbar,
});
export default connect(mapStateToProps, { closeSnackbar })(GlobalSnackbar);
