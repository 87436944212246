import {
	SET_TABLE_COLUMN_SORTING,
	SET_TABLE_TAB_FILTER,
	SET_TABLE_COLUMN_WIDTHS,
	RESET_TABLE_COLUMN_WIDTHS,
} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_TABLE_COLUMN_SORTING: {
			const { tableUniqueID, newSorted, additive } = action.payload;

			let newState = { ...state };

			if (tableUniqueID) {
				newState[tableUniqueID] = {
					...state[tableUniqueID],
					sort: newSorted,
					additive,
				};
			}

			return newState;
		}
		case SET_TABLE_TAB_FILTER: {
			const { tableUniqueID, tabName } = action.payload;
			let newState = { ...state };

			newState[tableUniqueID] = {
				...state[tableUniqueID],
				tabFilter: tabName,
			};

			return newState;
		}
		case SET_TABLE_COLUMN_WIDTHS: {
			const { tableUniqueID, newResized } = action.payload;
			let newState = { ...state };

			newState[tableUniqueID] = {
				...state[tableUniqueID],
				resizedColumns: newResized,
			};

			return newState;
		}
		case RESET_TABLE_COLUMN_WIDTHS: {
			const { tableUniqueID } = action.payload;
			let newState = { ...state };

			newState[tableUniqueID] = {
				...state[tableUniqueID],
				resizedColumns: [],
			};

			return newState;
		}

		default:
			return state;
	}
}
