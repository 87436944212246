import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@material-ui/core/IconButton";
const styles = {
	root: {
		backgroundColor: "var(--lw-light-grey-a)",
		height: "48px",
	},
	tabsIndicator: {
		backgroundColor: "var(--lw-light-blue)",
	},
	tabRoot: {
		"&:hover": {
			color: "var(--lw-light-blue)",
			opacity: 1,
		},
		"&$tabSelected": {
			color: "var(--lw-light-blue)",
		},
		"&:focus": {
			color: "var(--lw-light-blue)",
		},
	},
	tabSelected: {},
};

class CenteredTabs extends React.Component {
	state = {
		value: 0,
		hidden: false,
	};

	handleChange = (event, value) => {
		this.setState({ value }, () => {
			this.props.switchedTab && this.props.switchedTab(value);
		});
	};
	toggleHide = () => {
		this.setState({ hidden: !this.state.hidden }, () => {
			this.props.toggleHide && this.props.toggleHide();
		});
	};
	static getDerivedStateFromProps(props) {
		const newState = { value: props.activeTab || 0, hidden: props.hidden };
		return newState;
	}
	render() {
		const { classes } = this.props;

		return (
			<Paper className={classes.root} style={{ position: "relative" }}>
				<Tabs
					value={this.state.value}
					onChange={this.handleChange}
					indicatorColor="primary"
					textColor="primary"
					classes={{
						root: classes.tabsRoot,
						indicator: classes.tabsIndicator,
					}}
					// centered
				>
					{this.props.tabs.map((item, i) => {
						return (
							<Tab
								label={item.label}
								key={"Tab_" + i}
								classes={{
									root: classes.tabRoot,
									selected: classes.tabSelected,
								}}
							/>
						);
					})}
				</Tabs>
				{this.props.toggleHide ? (
					<span
						onClick={this.toggleHide.bind(this)}
						style={{
							position: "absolute",
							right: 16,
							top: 0,
							zIndex: 100,
						}}
					>
						<IconButton aria-label="toggle">
							<FontAwesomeIcon
								icon={
									this.state.hidden
										? "chevron-down"
										: "chevron-up"
								}
							/>
						</IconButton>
					</span>
				) : null}
			</Paper>
		);
	}
}

CenteredTabs.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CenteredTabs);
