import React, { Component } from "react";
import { connect } from "react-redux";

import { setLauncherInstalled } from "../actions/launcherActions";
import { setSubheaderText } from "../actions/navigateActions";

class ActivateLauncher extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tokensSentToLauncher: false,
		};
	}
	componentDidMount() {
		console.log("ActivateLauncher called");
		this.props.setLauncherInstalled(true);
		this.props.setSubheaderText("Launcher Successfully Activated");
	}

	render() {
		return (
			<div>You&apos;re all set up with the LexWorkplace Launcher!</div>
		);
	}
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {
	setLauncherInstalled,
	setSubheaderText,
})(ActivateLauncher);
