import {
	BULK_DOWNLOAD_SUCCESS,
	BULK_DOWNLOAD_FAILURE,
	BULK_EXTERNAL_SEND_SUCCESS,
	BULK_EXTERNAL_SEND_FAILURE,
	CONVERT_TO_PDF_FAILURE,
	AUDIT_SEARCH_SUCCESS,
	AUDIT_SEARCH_FAILURE,
	INDEX_MISSING_SEARCH_ITEMS_SUCCESS,
	INDEX_MISSING_SEARCH_ITEMS_FAILURE,
} from "./types";

import API from "../utilities/LocalApiProxy";

const generalErrorMessage =
	"LexWorkplace Encountered an Error; Please Contact Support";

const bulkServerUnreachableMessage =
	"The Bulk Operations server could not be reached";

export function beginRenameJob(jobID, successCallback, failureCallback) {
	return function (dispatch) {
		beginLongJob(
			jobID,
			successCallback,
			failureCallback,
			"Renaming Folder...",
			"Renaming",
			"Folder Renamed"
		)(dispatch);
	};
}
export function beginDeleteJob(
	jobID,
	successCallback,
	failureCallback,
	deleteIfTrue
) {
	return function (dispatch) {
		beginLongJob(
			jobID,
			successCallback,
			failureCallback,
			`${deleteIfTrue ? "Deleting" : "Restoring"} Items`,
			`${deleteIfTrue ? "Delete" : "Restore"}`,
			`Items ${deleteIfTrue ? "Deleted" : "Restored"}`
		)(dispatch);
	};
}
export function beginCutPasteJob(jobID, successCallback, failureCallback) {
	return function (dispatch) {
		beginLongJob(
			jobID,
			successCallback,
			failureCallback,
			"Moving Items...",
			"Move",
			"Items Moved"
		)(dispatch);
	};
}
export function beginCopyPasteJob(jobID, successCallback, failureCallback) {
	return function (dispatch) {
		beginLongJob(
			jobID,
			successCallback,
			failureCallback,
			"Calculating Copy Operation...",
			"Copy",
			"Copy Operation Complete"
		)(dispatch);
	};
}
export function beginReindexJob(jobID, successCallback, failureCallback) {
	return function (dispatch) {
		beginLongJob(
			jobID,
			successCallback,
			failureCallback,
			"Generating manifest file for rebuilding search index...",
			"Copy",
			"Manifest file generated and uploaded.  Click refresh to see in-progress re-index job"
		)(dispatch);
	};
}
export function beginBulkExternalSendJob(jobID) {
	return function (dispatch) {
		const tooManyItemsMessage =
			"The selected folders have too many files to download in a single ZIP file.  Please navigate to sub-folders as necessary to individually download folders with fewer items";

		const successCallback = (jobInfo) => {
			console.log(jobInfo);
			dispatch({
				type: BULK_EXTERNAL_SEND_SUCCESS,
				payload: {
					message: "Sent email with download link to recipients",
				},
			});
		};
		const failureCallback = (jobInfo) => {
			var jobData = JSON.parse(jobInfo.jobData);
			var message = generalErrorMessage;
			if (
				jobData.message &&
				jobData.message.error &&
				jobData.message.error.code
			) {
				if (jobData.message.error.code === 7002) {
					message =
						"Outgoing share could not be completed because the email could not be sent";
				}
			} else if (jobData.message === "BULK_SERVER_UNREACHABLE") {
				message =
					"Outgoing share failed:" + bulkServerUnreachableMessage;
			} else {
				if (jobData.message === "FAILED_TOO_MANY_FILES") {
					message = tooManyItemsMessage;
				}
			}

			dispatch({
				type: BULK_EXTERNAL_SEND_FAILURE,
				payload: { message },
			});
		};

		beginLongJob(
			jobID,
			successCallback,
			failureCallback,
			"Preparing selected files...",
			"File Send"
		)(dispatch);
	};
}
export function beginBulkDownloadJob(jobID, fullMatter) {
	return function (dispatch) {
		const tooManyItemsMessage = `${
			fullMatter ? "This matter has" : "The selected folders have"
		} too many files to download in a single ZIP file.  Please navigate to sub-folders as necessary to individually download folders with fewer items`;

		const successCallback = (jobInfo) => {
			var jobData = JSON.parse(jobInfo.jobData);
			window.location.href = jobData.signedUrlResult;
			dispatch({
				type: BULK_DOWNLOAD_SUCCESS,
				payload: {
					message: fullMatter
						? "Matter Export Complete - downloading ZIP..."
						: "Data Export Complete - downloading ZIP...",
				},
			});
		};
		const failureCallback = (jobInfo) => {
			var jobData = JSON.parse(jobInfo.jobData);
			var message = generalErrorMessage;
			if (jobData.message) {
				if (jobData.message === "FAILED_TOO_MANY_FILES") {
					message = tooManyItemsMessage;
				} else if (jobData.message === "BULK_SERVER_UNREACHABLE") {
					message =
						"Bulk Download Failed:" + bulkServerUnreachableMessage;
				}
			}
			dispatch({
				type: BULK_DOWNLOAD_FAILURE,
				payload: { message },
			});
		};

		beginLongJob(
			jobID,
			successCallback,
			failureCallback,
			"Generating ZIP File",
			fullMatter ? "Matter Export" : "Data Export"
		)(dispatch);
	};
}
function beginLongJob(
	jobID,
	successCallback,
	failureCallback,
	calculatingMessage,
	progressPrefix,
	successMessage
) {
	return function (dispatch) {
		dispatch({
			type: "COPY_PASTE_STARTED",
			payload: { jobID },
		});
		dispatch({
			type: "OPEN_LOADING_SNACKBAR",
			payload: { message: calculatingMessage },
		});
		if (jobID) {
			var jobPoll = setInterval(() => {
				getJobProgress(jobID).then((res) => {
					if (res.mysql[0].status === 2) {
						//success
						clearInterval(jobPoll);
						dispatch({
							type: "JOB_COMPLETE",
							payload: { id: jobID },
						});
						if (successMessage) {
							dispatch({
								type: "OPEN_GLOBAL_SNACKBAR",
								payload: {
									message: successMessage,
									variant: "success",
									loadingSpinnerVisible: false,
									allowClose: true,
								},
							});
						}
						successCallback && successCallback(res.mysql[0]);
					} else if (res.mysql[0].status === 1) {
						//still in progress
						if (res.mysql[0].type === "convertToPDF") {
							dispatch({
								type: "UPDATE_SNACKBAR_MESSAGE",
								payload: {
									message: `${progressPrefix} In Progress`,
								},
							});
						} else {
							dispatch({
								type: "UPDATE_SNACKBAR_MESSAGE",
								payload: {
									message: `${progressPrefix} ${res.mysql[0].percentDone}% Complete`,
								},
							});
						}
					} else if (res.mysql[0].status === 3) {
						//failure
						clearInterval(jobPoll);
						console.log("Job failed", jobID);
						failureCallback && failureCallback(res.mysql[0]);
					} else {
						//still calculating time, no need to update
					}
				});
			}, 4000);
		}
	};
}
export function testGetAllDocs() {
	return function (dispatch) {
		API.post("/worker/test").then((res) => {
			var jobID = res.mysql.jobID;
			beginCopyPasteJob(jobID)(dispatch);
		});
	};
}

function getJobProgress(jobID) {
	return API.post("/jobs", { body: { jobID } });
}
export function beginConvertToPDFJob(jobID, currentFileName, successCallback) {
	return function (dispatch) {
		const failureCallback = (jobInfo) => {
			var jobData = JSON.parse(jobInfo.jobData);

			const message =
				jobData &&
				jobData.message &&
				jobData.message === "BULK_SERVER_UNREACHABLE"
					? "Convert to PDF Failed:" + bulkServerUnreachableMessage
					: "Convert to PDF Failed:" + generalErrorMessage;
			dispatch({
				type: CONVERT_TO_PDF_FAILURE,
				payload: { message },
			});
		};

		beginLongJob(
			jobID,
			successCallback,
			failureCallback,
			`Converting ${currentFileName} to PDF...`,
			`Converting ${currentFileName} to PDF...`
		)(dispatch);
	};
}
export function beginAuditSearchIndexJob(jobID) {
	return function (dispatch) {
		const successCallback = (jobInfo) => {
			const { jobData, ...jobMetadata } = jobInfo;
			var auditData = JSON.parse(jobData);
			//console.log("audit Success", data);
			dispatch({
				type: AUDIT_SEARCH_SUCCESS,
				payload: {
					message: "Search Index Audit Complete",
					auditData,
					jobMetadata,
				},
			});
		};

		const failureCallback = (jobInfo) => {
			const { jobData, ...jobMetadata } = jobInfo;
			var auditData = JSON.parse(jobData);

			dispatch({
				type: AUDIT_SEARCH_FAILURE,
				payload: {
					message: "Search Index Audit Failed",
					auditData,
					jobMetadata,
				},
			});
		};

		beginLongJob(
			jobID,
			successCallback,
			failureCallback,
			"Auditing Search Index ...",
			"Comparing"
		)(dispatch);
	};
}
export function beginAuditAndIndexMissingItemsJob(jobID) {
	return function (dispatch) {
		const successCallback = (jobInfo) => {
			const { jobData, ...jobMetadata } = jobInfo;
			var repairData = JSON.parse(jobData);
			//console.log("audit Success", data);
			dispatch({
				type: INDEX_MISSING_SEARCH_ITEMS_SUCCESS,
				payload: {
					message: `Indexing Missing Items has started.  Check the "Batch Jobs" tab for this firm to see progress`,
					repairData,
					jobMetadata,
				},
			});
		};

		const failureCallback = (jobInfo) => {
			const { jobData, ...jobMetadata } = jobInfo;
			var repairData = JSON.parse(jobData);

			dispatch({
				type: INDEX_MISSING_SEARCH_ITEMS_FAILURE,
				payload: {
					message: "Indexing Missing Items has failed to start",
					repairData,
					jobMetadata,
				},
			});
		};

		beginLongJob(
			jobID,
			successCallback,
			failureCallback,
			"Setting up Missing Items re-Index Job ...",
			"Comparing"
		)(dispatch);
	};
}
