import {
	USER_LOGGED_IN,
	USER_LOGGED_OUT,
	USER_UNAUTHENTICATED,
	NEW_PASSWORD_REQUIRED,
	USER_LOGIN_UNSUCCESSFUL,
	USER_FORGOT_PASSWORD_REQUEST_SUCCESS,
	USER_FORGOT_PASSWORD_REQUEST_FAILURE,
	CHANGE_PASSWORD_SUCCESS,
	CHANGE_PASSWORD_FAILURE,
	SOFTWARE_TOKEN_MFA_CHALLENGE,
	MFA_VERIFICATION_ERROR,
	MFA_SESSION_EXPIRED,
	TOTP_SETUP_CODE_GENERATED,
	VERIFY_TOTP_TOKEN_SUCCESS,
	VERIFY_TOTP_TOKEN_FAILURE,
	INIT_MFA,
	SET_REDIRECT_URL,
	GET_USER_DATA_SUCCESS,
	GET_USER_SETTINGS_SUCCESS,
	GET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
	GET_EXTERNAL_SAVED_EMAIL_ADDRESSES_SUCCESS,
	GET_PERMISSION_FEATURES_SUCCESS,
	GET_TENANT_FEATURES_USAGE_AND_FEATURES_SUCCESS,
	OPEN_GLOBAL_SNACKBAR,
	OPEN_PERSISTENT_ERROR_SNACKBAR,
} from "./types";

import { Amplify } from "aws-amplify";
import {
	signIn,
	confirmSignIn as amplifyConfirmSignIn,
	getCurrentUser,
	fetchAuthSession,
	resetPassword,
	confirmResetPassword,
	updatePassword,
	updateUserAttributes,
	setUpTOTP,
	verifyTOTPSetup,
	confirmUserAttribute,
	sendUserAttributeVerificationCode,
	signOut,
	updateMFAPreference,
	fetchUserAttributes,
} from "aws-amplify/auth";
import API from "../utilities/LocalApiProxy";

import aws_config from "../config/aws_config";

const handleUpdatePassword = async ({ newPassword }) => {
	return amplifyConfirmSignIn({
		challengeResponse: newPassword,
	});
};

const forgotPasswordSubmit = async ({
	username,
	newPassword,
	confirmationCode,
	clientMetadata,
}) => {
	await confirmResetPassword({
		username,
		newPassword,
		confirmationCode,
		options: {
			clientMetadata,
		},
	});
};

const amplifyForgotPassword = async ({ username, clientMetadata }) => {
	return resetPassword({
		username,
		options: {
			clientMetadata,
		},
	});
};

const currentAuthenticatedUser = async () => {
	const currentUser = await getCurrentUser();

	const { username } = currentUser;
	const { tokens: session } = await fetchAuthSession();
	const attributes = await fetchUserAttributes();

	const cognitoGroups = session.accessToken.payload["cognito:groups"];

	// Note that session will no longer contain refreshToken and clockDrift
	return {
		username,
		session,
		attributes,
		cognitoGroups,
	};
};

function makeJwtData(user) {
	/*
		As of 2024, the LexWorkplace Launcher desktop application
		does not implement its own login/logout functionality.  It
		gets the refreshToken from the website, passed via the custom
		protocol: `lexworkplace://`

		v4 of Amplify directly provided the refreshToken value,
		but in v6 this was removed from the public interface.

		This function manually retrieves the refreshToken value from
		localStorage, assuming it is stored at the key:

		`CognitoIdentityServiceProvider.<userPoolClientId>.<cognitoUsername>.refreshToken`

	*/

	const cognitoStorageKeyPrefix = `CognitoIdentityServiceProvider.${aws_config.Auth.Cognito.userPoolClientId}`;
	const LastAuthUser = localStorage.getItem(
		`${cognitoStorageKeyPrefix}.LastAuthUser`
	);
	const refreshTokenLocalStorageKey = `${cognitoStorageKeyPrefix}.${LastAuthUser}.refreshToken`;
	const refreshToken = localStorage.getItem(refreshTokenLocalStorageKey);

	if (!refreshToken) {
		console.error("Could not get refresh token from localSorage", {
			refreshTokenLocalStorageKey,
		});
	}

	return {
		access: user.session.accessToken.toString(),
		refresh: refreshToken,
		id: user.session.idToken.toString(),
	};
}

export function logIn(username, password) {
	return function (dispatch) {
		signIn({ username, password })
			.then(({ user, isSignedIn, nextStep }) => {
				const signInStep = nextStep.signInStep;

				if (signInStep === "CONFIRM_SIGN_IN_WITH_TOTP_CODE") {
					dispatch({
						type: SOFTWARE_TOKEN_MFA_CHALLENGE,
						payload: { cognitoUser: user },
					});
				} else if (
					signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
				) {
					dispatch({
						type: NEW_PASSWORD_REQUIRED,
						payload: { cognitoUser: user },
					});
				} else {
					currentAuthenticatedUser({
						bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
					}).then((user) => {
						// eslint-disable-next-line

						dispatch({
							type: USER_LOGGED_IN,
							payload: {
								userDisplayName:
									user.attributes.name ||
									user.attributes.email,
								cognitoUser: user,
								attributes: user.attributes,
								jwt: makeJwtData(user),
							},
						});
					});
				}
			})
			.catch((err) => {
				console.log("login error", err);
				dispatch({ type: USER_LOGIN_UNSUCCESSFUL, payload: {} });
			});
	};
}
export function confirmSignIn(user, code) {
	return function (dispatch) {
		amplifyConfirmSignIn({ challengeResponse: code })
			.then(() => {
				currentAuthenticatedUser({
					bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
				}).then((authenticatedUser) => {
					dispatch({
						type: USER_LOGGED_IN,
						payload: {
							cognitoUser: authenticatedUser,
							attributes: authenticatedUser.attributes,
						},
					});
				});
			})
			.catch((e) => {
				console.log("confirmSignIn error", e);
				if (e.code === "NotAuthorizedException") {
					// the user waited to long to enter the MFA code,
					// they must enter their credentials again
					dispatch({
						type: MFA_SESSION_EXPIRED,
					});
				} else {
					dispatch({
						type: MFA_VERIFICATION_ERROR,
					});
				}
			});
	};
}
export function checkCognitoAuthentication() {
	Amplify.configure(aws_config);

	return function (dispatch) {
		currentAuthenticatedUser({
			bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
		})
			.then((user) => {
				dispatch({
					type: USER_LOGGED_IN,
					payload: {
						cognitoUser: user,
						attributes: user.attributes,
						jwt: makeJwtData(user),
					},
				});
			})
			.catch((err) => {
				dispatch({
					type: USER_UNAUTHENTICATED,
					payload: { userDisplayName: "Guest" },
				});
			});
	};
}

export function forgotPassword(username) {
	return function (dispatch) {
		amplifyForgotPassword({ username })
			.then(() => {
				dispatch({
					type: USER_FORGOT_PASSWORD_REQUEST_SUCCESS,
					payload: { username },
				});
			})
			.catch((err) => {
				console.log("forgot password failed", err);
				dispatch({
					type: USER_FORGOT_PASSWORD_REQUEST_FAILURE,
				});
			});
	};
}

/*
username,
	newPassword,
	confirmationCode,
	clientMetadata,
})

*/

// If a user forgets their password, a code is sent to their email address.  Unauthenticated path.
export function changePasswordWithCode(
	username,
	confirmationCode,
	newPassword
) {
	return function (dispatch) {
		forgotPasswordSubmit({ username, newPassword, confirmationCode })
			.then(() => {
				dispatch({
					type: CHANGE_PASSWORD_SUCCESS,
					payload: { isLoggedIn: false },
				});
			})
			.catch((err) => {
				console.log("password change failed", err);
				dispatch({
					type: CHANGE_PASSWORD_FAILURE,
					payload: { errorMessage: err.message },
				});
			});
	};
}

//After creating a new user in the cognito console, the new user is required to change their password before they can log in
export function completeNewPassword(cognitoUser, newPassword) {
	return function (dispatch) {
		handleUpdatePassword({ newPassword })
			.then(() => {
				checkCognitoAuthentication()(dispatch);
				// at this time the user is logged in if no MFA required
				// but this call to completeNewPassword does NOT return the
				// cognitoUser in the same format as a call to login does
			})
			.catch((e) => {
				console.log("completeNewPassword failed", e);
			});
	};
}

//Change of password for a user who is logged in
export function changePassword(cognitoUser, oldPassword, newPassword) {
	return function (dispatch) {
		updatePassword({ oldPassword, newPassword })
			.then(() => {
				// at this time the user is logged in if no MFA required
				dispatch({
					type: CHANGE_PASSWORD_SUCCESS,
					payload: {
						isLoggedIn: true,
					},
				});
			})
			.catch((e) => {
				console.log("changePassword failed", e);
				dispatch({
					type: CHANGE_PASSWORD_FAILURE,
					payload: { errorMessage: e.message },
				});
			});
	};
}

export function updateDisplayName(newName) {
	return function (dispatch) {
		// update the display name both on the database and in cognito
		Promise.all([
			updateUserAttributes({ userAttributes: { name: newName } }),
			API.put("/user/update", {
				body: { displayName: newName },
			}),
		])
			.then(() => {
				checkCognitoAuthentication()(dispatch);
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						message: `Display name updated`,
						variant: "success",
					},
				});
			})
			.catch((e) => {
				console.error(e);
				dispatch({
					type: OPEN_PERSISTENT_ERROR_SNACKBAR,
					payload: {
						message: `Display name was not successfully updated`,
					},
				});
			});
	};
}
export function updateEmailAddress(newEmailAddress) {
	return function (dispatch) {
		/*
			update the email address both on the database and in cognito.  Note that before
			the user can login using the email address, it must be "verified" via
			the code sent to the email
		*/
		Promise.all([
			updateUserAttributes({
				userAttributes: { email: newEmailAddress },
			}),
			API.put("/user/update", {
				body: { emailAddress: newEmailAddress },
			}),
		])
			.then(() => {
				checkCognitoAuthentication()(dispatch);
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						message: `Email address updated.  To complete this change, a verification code has been sent to ${newEmailAddress}`,
						variant: "success",
					},
				});
			})
			.catch((e) => {
				console.error(e);
				dispatch({
					type: OPEN_PERSISTENT_ERROR_SNACKBAR,
					payload: {
						message: `Email address was not successfully updated`,
					},
				});
			});
	};
}
export function getMyUserDataFromDatabase() {
	return function (dispatch) {
		API.get("/me").then((response) => {
			const user = response.mysql.user[0] ? response.mysql.user[0] : {};
			const groups = response.mysql.groups;
			const settingsJSON =
				response.mysql.settings && response.mysql.settings.settingsJSON
					? response.mysql.settings.settingsJSON
					: {};
			const clioIntegrationSettingStatus =
				response.mysql.tenantFeatures.clioIntegration.length &&
				response.mysql.tenantFeatures.clioIntegration[0].status
					? response.mysql.tenantFeatures.clioIntegration[0].status
					: 0;
			const externalEmailAddresses =
				response.mysql.externalEmailAddresses || [];
			const matters = response.mysql.matters;
			const clients = response.mysql.clients;
			const permissionFeatures = response.mysql.permissionFeatures;
			const tenantLimits = response.mysql.tenantLimits;
			const tenantFeaturesV2 = response.mysql.tenantFeaturesV2;
			const tenantUsage = response.mysql.tenantUsage;
			const whatsNew = response.mysql.whatsNew;

			dispatch({
				type: GET_USER_DATA_SUCCESS,
				payload: { user, groups },
			});
			dispatch({
				type: GET_USER_SETTINGS_SUCCESS,
				payload: settingsJSON,
			});
			dispatch({
				type: GET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
				payload: clioIntegrationSettingStatus,
			});
			dispatch({
				type: GET_EXTERNAL_SAVED_EMAIL_ADDRESSES_SUCCESS,
				payload: externalEmailAddresses,
			});
			dispatch({
				type: "GET_ALL_MATTERS_SUCCESS",
				payload: matters,
			});
			dispatch({
				type: "GET_ALL_CLIENTS_SUCCESS",
				payload: clients,
			});
			dispatch({
				type: GET_PERMISSION_FEATURES_SUCCESS,
				payload: { permissionFeatures },
			});

			dispatch({
				type: GET_TENANT_FEATURES_USAGE_AND_FEATURES_SUCCESS,
				payload: {
					tenantLimits,
					tenantFeatures: tenantFeaturesV2,
					tenantUsage,
				},
			});
			dispatch({
				type: "GET_WHATS_NEW_SUCCESS",
				payload: {
					items: whatsNew.items,
					itemsHash: whatsNew.itemsHash,
				},
			});
		});
	};
}
export function initMFA() {
	return function (dispatch) {
		dispatch({
			type: INIT_MFA,
		});
	};
}
export function setupTOTP() {
	return function (dispatch) {
		currentAuthenticatedUser().then((user) => {
			setUpTOTP().then(({ sharedSecret, getSetupUri }) => {
				dispatch({
					type: TOTP_SETUP_CODE_GENERATED,
					payload: { code: sharedSecret },
				});
			});
		});
	};
}
export function verifyTotpToken(challengeAnswer) {
	return function (dispatch) {
		currentAuthenticatedUser().then((user) => {
			verifyTOTPSetup({ code: challengeAnswer })
				.then(() => {
					updateMFAPreference({ totp: "PREFERRED" });
					dispatch({ type: VERIFY_TOTP_TOKEN_SUCCESS });
				})
				.catch((e) => {
					dispatch({
						type: VERIFY_TOTP_TOKEN_FAILURE,
						payload: { error: e },
					});
				});
		});
	};
}
window.setupTOTP = setupTOTP;
window.verifyTotpToken = verifyTotpToken;

export function sendEmailVerificationCode(emailAddress) {
	return function (dispatch) {
		sendUserAttributeVerificationCode({ userAttributeKey: "email" })
			.then(() => {
				//a verification code is sent
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						message: `Verification code sent to ${emailAddress}`,
						variant: "success",
					},
				});
			})
			.catch((e) => {
				console.error(e);
				dispatch({
					type: OPEN_PERSISTENT_ERROR_SNACKBAR,
					payload: {
						message: `The verification code could not be sent to ${emailAddress}`,
					},
				});
			});
	};
}
export function verifyEmail(confirmationCode) {
	return function (dispatch) {
		confirmUserAttribute({ userAttributeKey: "email", confirmationCode })
			.then(() => {
				//email verified
				checkCognitoAuthentication()(dispatch);
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						message: `Email verified`,
						variant: "success",
					},
				});
			})
			.catch((e) => {
				console.error(e);
				dispatch({
					type: OPEN_PERSISTENT_ERROR_SNACKBAR,
					payload: {
						message: "The verification code entered was incorrect",
					},
				});
			});
	};
}

export function logOut() {
	return function (dispatch) {
		signOut({ global: false })
			.then(() => {
				dispatch({
					type: USER_LOGGED_OUT,
					payload: { userDisplayName: "Guest" },
				});
			})
			.catch((err) => console.log(err));
	};
}
export function setRedirectUrl(url) {
	return function (dispatch) {
		dispatch({ type: SET_REDIRECT_URL, payload: { url } });
	};
}
