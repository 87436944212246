import {} from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
	switch (action.type) {
		case "UPDATE_JOB_PROGRESS":
			return {
				...state,
			};

		case "OCR_JOB_PROGRESS_ADD_TRANSACTION":
			return {
				...state,
				[action.payload.transactionID]: {
					status: "REQUESTED",
					documentID: action.payload.documentID,
					filename: action.payload.filename,
				},
			};

		case "OCR_JOB_PROGRESS_UPDATE_TRANSACTION":
			return {
				...state,
				[action.payload.transactionID]: {
					...state[action.payload.transactionID],
					status: action.payload.status,
				},
			};

		case "OCR_JOB_PROGRESS_RESET":
			return initialState;

		default:
			return state;
	}
}
