import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	changePasswordWithCode,
	completeNewPassword,
	changePassword,
} from "../actions/userAuthActions";
import { setSubheaderText } from "../actions/navigateActions";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { LabeledTextInput } from "./TextInput";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	USER_FORGOT_PASSWORD_REQUEST_FAILURE,
	USER_FORGOT_PASSWORD_REQUEST_SUCCESS,
	NEW_PASSWORD_REQUIRED,
	CHANGE_PASSWORD_FAILURE,
	CHANGE_PASSWORD_SUCCESS,
} from "../actions/types";

const allPasswordValidationMessages = {
	newPasswordsMatch: {
		validMessage: "New passwords match",
		invalidMessage: "New passwords do not match",
	},
	newPasswordLength: {
		validMessage: "New password is long enough",
		invalidMessage: "New password must be at least 8 characters",
	},
	oldPasswordEntered: {
		validMessage: "Current password entered",
		invalidMessage: "Please enter existing password",
	},
	specialCharactersCompliant: {
		validMessage: "New password meets character requirements",
		invalidMessage:
			"New password must include a lowercase letter, uppercase letter, number AND special character",
	},
	codeIsEntered: {
		validMessage: "Verification code entered",
		invalidMessage:
			"Please enter the verification code you received in an email",
	},
};
const specialCharactersRE = /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/;
const lowercaseCharactersRE = /[a-z]/;
const uppercaseCharactersRE = /[A-Z]/;
const digitRE = /[0-9]/;

const IconAndMessage = ({ icon, message }) => {
	return (
		<div style={{ display: "flex", gap: 8 }}>
			<div
				style={{
					display: "flex",
					// vertically centers the icon
					flexDirection: "column",
					justifyContent: "space-around",
				}}
			>
				{icon}
			</div>
			<div
				style={{
					height: 40,
					display: "flex",
					// vertically centers the icon
					flexDirection: "column",
					justifyContent: "space-around",
				}}
			>
				{message}
			</div>
		</div>
	);
};

const WarningMessage = ({ message }) => {
	return (
		<IconAndMessage
			icon={
				<FontAwesomeIcon
					style={{
						color: "var(--lw-darker-red)",
						fontSize: "1em",
					}}
					icon="exclamation-triangle"
				/>
			}
			message={message}
		/>
	);
};

const OkMessage = ({ message }) => {
	return (
		<IconAndMessage
			icon={
				<FontAwesomeIcon
					style={{
						color: "var(--lw-info)",
						fontSize: "1em",
					}}
					icon="check"
				/>
			}
			message={message}
		/>
	);
};

class ChangePassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: "",
			oldPassword: "",
			newPassword: "",
			newPasswordAgain: "",
			newPasswordsMatch: false,
			newPasswordLength: false,
			oldPasswordEntered: false,
			specialCharactersCompliant: false,
			codeIsEntered: false,
			canSubmit: false,
			errorMessage: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.submit = this.submit.bind(this);
		this.validate = this.validate.bind(this);
		this.generateErrorMessage = this.generateErrorMessage.bind(this);
	}
	componentDidMount() {
		this.validate();
		this.props.setSubheaderText("Change Password");
	}
	handleChange(key, value) {
		this.setState({ [key]: value }, this.validate);
	}
	generateErrorMessage() {
		const {
			newPasswordsMatch,
			newPasswordLength,
			oldPasswordEntered,
			specialCharactersCompliant,
			codeIsEntered,
		} = allPasswordValidationMessages;

		// all variations include these validations
		let errorMessagesForThisFlow = {
			newPasswordsMatch,
			newPasswordLength,
			specialCharactersCompliant,
		};

		if (this.props.changePasswordFlow === "loggedIn") {
			// if logged in, user must provide existing password in order to change it
			errorMessagesForThisFlow.oldPasswordEntered = oldPasswordEntered;
		} else if (this.props.changePasswordFlow === "forgot") {
			// if user forgot password, they are sent a code via email that must be entered
			errorMessagesForThisFlow.codeIsEntered = codeIsEntered;
		} else if (this.props.changePasswordFlow === "forced") {
			// when user logs in for the first time after creating an account, they are forced to change their password
		}

		return (
			<div
				style={{
					position: "absolute",
					width: 450,
					display: "flex",
					flexDirection: "column",
					gap: 0,
				}}
			>
				{Object.keys(errorMessagesForThisFlow).map((errorKey) => {
					const criteriaIsValid = this.state[errorKey];
					return (
						<div key={errorKey}>
							{criteriaIsValid ? (
								<OkMessage
									message={
										errorMessagesForThisFlow[errorKey]
											.validMessage
									}
								/>
							) : (
								<WarningMessage
									message={
										errorMessagesForThisFlow[errorKey]
											.invalidMessage
									}
								/>
							)}
						</div>
					);
				})}
			</div>
		);
	}
	validate() {
		const newPasswordsMatch =
			this.state.newPassword === this.state.newPasswordAgain;
		const newPasswordLength = this.state.newPassword.length >= 8;
		const oldPasswordEntered =
			this.props.changePasswordFlow === "loggedIn"
				? this.state.oldPassword.length >= 8
				: true;
		const specialCharactersCompliant =
			specialCharactersRE.test(this.state.newPassword) &&
			lowercaseCharactersRE.test(this.state.newPassword) &&
			uppercaseCharactersRE.test(this.state.newPassword) &&
			digitRE.test(this.state.newPassword);
		const codeIsEntered =
			this.props.changePasswordFlow === "forgot"
				? this.state.code.length > 0
				: true;
		const canSubmit =
			newPasswordsMatch &&
			newPasswordLength &&
			oldPasswordEntered &&
			specialCharactersCompliant &&
			codeIsEntered;
		this.setState({
			newPasswordsMatch,
			newPasswordLength,
			oldPasswordEntered,
			specialCharactersCompliant,
			codeIsEntered,
			canSubmit,
		});
	}
	submit() {
		if (this.state.canSubmit) {
			if (
				this.props.successfulForgotPasswordAttempt ||
				this.props.changePasswordFailed
			) {
				this.props.changePasswordWithCode(
					this.props.username,
					this.state.code,
					this.state.newPassword
				);
			} else if (this.props.newPasswordRequired) {
				this.props.completeNewPassword(
					this.props.cognitoUser,
					this.state.newPassword
				);
			} else if (this.props.isLoggedIn) {
				this.props.changePassword(
					this.props.cognitoUser,
					this.state.oldPassword,
					this.state.newPassword
				);
			}
		}
	}
	render() {
		if (
			this.props.changePasswordSucceeded &&
			this.props.changePasswordFlow === "loggedIn"
		) {
			return <Redirect to="/settings" />;
		} else if (
			this.props.changePasswordSucceeded &&
			this.props.changePasswordFlow === "forced"
		) {
			return <Redirect to="/" />;
		} else if (
			this.props.successfulForgotPasswordAttempt ||
			this.props.newPasswordRequired ||
			this.props.changePasswordFailed ||
			this.props.isLoggedIn
		) {
			return (
				<div className="center332" style={{ padding: 16 }}>
					{this.props.changePasswordFlow === "forgot" ||
					this.props.changePasswordFlow === "forced" ? (
						<h2 className="centeredH2">
							Set Your LexWorkplace Password
						</h2>
					) : null}

					{this.props.changePasswordFlow === "forgot" ? (
						<LabeledTextInput
							label="Code"
							name="code"
							placeholder="Code"
							onEnterKey={this.submit}
							onChange={this.handleChange}
						/>
					) : null}
					{this.props.changePasswordFlow === "loggedIn" ? (
						<LabeledTextInput
							label="Current Password"
							name="oldPassword"
							type="password"
							placeholder="Current Password"
							onEnterKey={this.submit}
							onChange={this.handleChange}
						/>
					) : null}
					<LabeledTextInput
						label="Password"
						name="newPassword"
						type="password"
						placeholder="Password"
						onEnterKey={this.submit}
						onChange={this.handleChange}
					/>

					<LabeledTextInput
						label="Confirm Password"
						name="newPasswordAgain"
						type="password"
						placeholder="Confirm Password"
						onEnterKey={this.submit}
						onChange={this.handleChange}
					/>
					<div style={{ height: 200, marginBottom: 24 }}>
						{this.props.unsuccessfulForgotPasswordAttempt ||
						this.props.changePasswordFailed ? (
							<div className="inlineErrorIndicator">
								{this.props.errorMessage}
							</div>
						) : null}
						{this.generateErrorMessage()}
					</div>
					<ButtonWithIcon
						style={{ right: 0 }}
						disabled={!this.state.canSubmit}
						handler={this.submit}
						buttonText="Set Password"
						iconName="check"
					/>
				</div>
			);
		} else {
			return <Redirect to="/login" />;
		}
	}
}
ChangePassword.propTypes = {
	username: PropTypes.string.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
	unsuccessfulForgotPasswordAttempt: PropTypes.bool.isRequired,
	successfulForgotPasswordAttempt: PropTypes.bool.isRequired,
	cognitoUser: PropTypes.object.isRequired,
	changePassword: PropTypes.func.isRequired,
	completeNewPassword: PropTypes.func.isRequired,
	changePasswordWithCode: PropTypes.func.isRequired,
	changePasswordFailed: PropTypes.bool.isRequired,
	changePasswordSucceeded: PropTypes.bool.isRequired,
	errorMessage: PropTypes.string.isRequired,
	changePasswordFlow: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
	isLoggedIn: state.user.isLoggedIn,
	username: state.user.username,
	redirectLocation: state.user.redirectLocation,
	unsuccessfulForgotPasswordAttempt:
		state.user.authState === USER_FORGOT_PASSWORD_REQUEST_FAILURE,
	successfulForgotPasswordAttempt:
		state.user.authState === USER_FORGOT_PASSWORD_REQUEST_SUCCESS,
	changePasswordFailed: state.user.authState === CHANGE_PASSWORD_FAILURE,
	changePasswordSucceeded: state.user.authState === CHANGE_PASSWORD_SUCCESS,
	newPasswordRequired: state.user.authState === NEW_PASSWORD_REQUIRED,
	cognitoUser: state.user.cognitoUser,
	errorMessage: state.user.errorMessage,
	changePasswordFlow: state.user.changePasswordFlow,
});
export default connect(mapStateToProps, {
	changePasswordWithCode,
	completeNewPassword,
	changePassword,
	setSubheaderText,
})(ChangePassword);
