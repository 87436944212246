import React, { Component } from "react";
import { connect } from "react-redux";

import { setSubheaderText } from "../actions/navigateActions";
import { ButtonWithIcon } from "./ButtonWithIcon";

const makeJwtLoginLink = (jwtData) => {
	const { refresh } = jwtData;
	const url = `lexworkplace://loginToLauncher/${refresh}`;
	console.log("jwt login link length:", url.length);
	return url;
};

class Launcher extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tokensSentToLauncher: false,
		};
		this.sendLoginToken = this.sendLoginToken.bind(this);
	}
	componentDidMount() {
		this.props.setSubheaderText("Connect LexWorkplace Launcher");
	}
	sendLoginToken() {
		var link = makeJwtLoginLink(this.props.jwt);
		window.location.href = link;
		this.setState({ tokensSentToLauncher: true });
	}

	render() {
		return (
			<div>
				<div style={{ marginBottom: 16, marginTop: 8 }}>
					{this.state.tokensSentToLauncher ? (
						<div>
							The LexWorkplace Launcher has been connected! You
							can safely close this tab and continue using
							LexWorkplace.
						</div>
					) : (
						<div>
							Please click the button to finish setting up the
							LexWorkplace Launcher.
						</div>
					)}
				</div>
				<ButtonWithIcon
					handler={this.sendLoginToken}
					buttonText={
						this.state.tokensSentToLauncher
							? "Launcher Connected"
							: "Connect LexWorkplace Launcher"
					}
					iconName="check"
					className="blue"
					disabled={this.state.tokensSentToLauncher}
				/>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	jwt: state.user.jwt,
	refreshToken: state.user.refresh,
});
export default connect(mapStateToProps, { setSubheaderText })(Launcher);
