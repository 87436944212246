import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";

import "./ButtonWithIcon.css";

const SmallNoPaddingIconButton = withStyles({
	root: {
		padding: 0,
	},
})((props) => <IconButton {...props}>{props.children}</IconButton>);

const styles = {
	root: {
		background: "var(--lw-light-grey-a)",
		textTransform: "none",
	},
	blueButton: {
		background: "var(--lw-light-blue)",
		textTransform: "none",
		color: "white",
		"&:hover": {
			color: "black",
		},
	},
	goldButton: {
		background: "var(--lw-gold)",
		textTransform: "none",
		color: "black",
		"&:hover": {
			color: "black",
		},
	},
};

class ButtonWithIconUnstyled extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fileUploadTarget: null,
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleFileListChange = this.handleFileListChange.bind(this);
		this.goBack = this.goBack.bind(this);
	}
	handleClick(e) {
		if (
			this.props.type === "multi-upload" ||
			this.props.type === "single-upload"
		) {
			this.setState({
				fileUploadTarget: e.target,
			});
		}
		if (this.props.type === "go-back") {
			this.goBack();
		} else {
			this.props.redirect && this.redirect(this.props.redirect);
			this.props.handler && this.props.handler();
		}
	}
	redirect(eventKey) {
		// history comes from routers withRouter method
		const { history } = this.props;
		history.push(eventKey);
	}
	goBack() {
		this.props.history.goBack();
	}
	handleFileListChange(event) {
		var files = event.target.files;
		if (this.props.fileListChangeHandler && files.length) {
			this.props.fileListChangeHandler(files, () => {
				console.log("setting null filelistchange");
				this.setState({
					fileUploadTarget: { value: null },
				});
			});
		}
	}
	render() {
		return (
			<span
				title={
					this.props.disabled
						? this.props.disabledHoverText
						: this.props.title
				}
			>
				<Button
					variant="contained"
					component="label"
					disabled={this.props.disabled}
					onClick={this.handleClick}
					className={
						(this.props.className === "blue"
							? this.props.classes.blueButton
							: this.props.classes.root) +
						" " +
						(this.props.className || "")
					}
					style={this.props.style || null}
				>
					<div className="ButtonInner">
						{this.props.iconComponent || (
							<FontAwesomeIcon
								icon={this.props.iconName || this.props.icon}
								size="1x"
							/>
						)}
						<span className="ButtonText" style={{ marginLeft: 8 }}>
							{this.props.buttonText}
						</span>
					</div>
					{this.props.type === "multi-upload" ? (
						<input
							disabled={this.props.disabled || null}
							type="file"
							style={{ display: "none" }}
							multiple="multiple"
							onChange={this.handleFileListChange}
							accept={this.props.uploadAccept || "*"}
							onClick={(event) => {
								event.target.value = null;
							}}
						/>
					) : this.props.type === "single-upload" ? (
						<input
							disabled={this.props.disabled || null}
							type="file"
							style={{ display: "none" }}
							onChange={this.handleFileListChange}
							accept={this.props.uploadAccept || "*"}
							onClick={(event) => {
								event.target.value = null;
							}}
						/>
					) : null}
				</Button>
			</span>
		);
	}
}
const ButtonWithIcon = withStyles(styles)(ButtonWithIconUnstyled);
const ButtonWithRouter = withRouter(ButtonWithIcon);
export { ButtonWithIcon, ButtonWithRouter, SmallNoPaddingIconButton };
