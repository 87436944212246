import { SET_DEVELOPER_OVERRIDE } from "../actions/types";

export function setDeveloperOverride(key, value) {
	return function (dispatch) {
		dispatch({
			type: SET_DEVELOPER_OVERRIDE,
			payload: { key, value },
		});
	};
}
