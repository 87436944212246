import React, { Component } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import {
	fetchClientDetail,
	deleteClients,
	deactivateClients,
	activateClients,
} from "../actions/clientActions";
import { fetchMattersList } from "../actions/matterActions";

import { DropDownButtonWithRouter } from "./DropDownButton";
import TextGrid from "./TextGrid";
import MaximumItemsList from "./MaximumItemsList";

import MaterialTabs from "./MaterialTabs";
import { capitalize } from "../utilities/utilities";

import MattersList from "./MattersList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ClientDetail extends Component {
	constructor(props, context) {
		super(props, context);
		this.toggleHide = this.toggleHide.bind(this);

		this.state = { clientInfoHidden: false };

		this.postEditLocation = `/clients/${this.props.match.params.id}`;
	}

	toggleHide() {
		this.setState({ clientInfoHidden: !this.state.clientInfoHidden });
	}

	componentDidMount() {
		this.props.fetchClientDetail(this.props.match.params.id);
		this.props.fetchMattersList({ clientID: this.props.match.params.id });
	}
	componentDidUpdate(prevProps) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.props.fetchClientDetail(this.props.match.params.id);
			this.props.fetchMattersList({
				clientID: this.props.match.params.id,
			});
		}
	}
	render() {
		return (
			<div className="fullHeightFlex noDefaultMargin">
				<div
					className="clientInfoHeader"
					style={{
						position: "relative",
						padding: 16,
						display: this.state.clientInfoHidden ? "none" : "block",
					}}
				>
					<TextGrid
						numColumns={3}
						columns={[
							[
								"Client Status",

								capitalize(
									this.props.clientDetails.clientStatus
								),
							],
							[
								"Primary Attorney(s)",
								<MaximumItemsList
									maxItems={5}
									key={"primaryAttorneys"}
									items={
										this.props.clientDetails
											.primaryAttorneys
											? this.props.clientDetails.primaryAttorneys.map(
													(x) => x.displayName
												)
											: []
									}
								/>,
							],
						]}
					/>

					<div style={{ position: "absolute", top: 12, right: 12 }}>
						<DropDownButtonWithRouter
							title="Edit"
							pullRight
							menuItems={
								this.props.clientDetails.clientStatus ===
								"inactive"
									? [
											{
												text: "Edit Client",
												href:
													"/clients/" +
													this.props.clientDetails
														.clientID +
													"/edit",
											},
											{
												text: "Activate Client",
												onSelect: () =>
													this.props.activateClients([
														this.props.clientDetails
															.clientID,
													]),
											},
											{
												text: "Delete Client",
												onSelect: () =>
													this.props.deleteClients([
														this.props.clientDetails
															.clientID,
													]),
											},
										]
									: [
											{
												text: "Edit Client",
												href:
													"/clients/" +
													this.props.clientDetails
														.clientID +
													"/edit",
											},
											{
												text: "Deactivate Client",
												onSelect: () =>
													this.props.deactivateClients(
														[
															this.props
																.clientDetails
																.clientID,
														]
													),
											},
										]
							}
						/>
					</div>
				</div>
				<MaterialTabs
					activeTab={0}
					tabs={[{ label: "Matters", url: "docs" }]}
					toggleHide={this.toggleHide}
				/>

				<MattersList
					newButtonRedirectLocation={
						"/matters/new/" + this.props.clientDetails.clientID
					}
					matterFilterTabsReplacementContent={
						<div style={{ display: "flex" }}>
							<FontAwesomeIcon
								icon={"briefcase"}
								size="2x"
								style={{
									marginRight: 10,
									marginLeft: 10,
									marginTop: 12,
									fontSize: "1.5em",
								}}
							/>
							<h4
								style={{
									lineHeight: "normal",
									wordBreak: "break-word",
								}}
							>
								{" "}
								Matters for{" "}
								{this.props.clientDetails.clientName}
							</h4>
						</div>
					}
					postEditLocation={this.postEditLocation}
					hiddenColumns={["clientName"]}
					tabFilterOverride="All"
					onlyShowMattersForClientID={
						this.props.clientDetails.clientID
					}
					// used to differentiate between the client detail page and the global matters list
					clientName={this.props.clientDetails.clientName}
				/>
			</div>
		);
	}
}
ClientDetail.propTypes = {
	fetchClientDetail: PropTypes.func.isRequired,
	fetchMattersList: PropTypes.func.isRequired,
	clientDetails: PropTypes.object.isRequired,
	mattersListData: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
	clientDetails: state.client.clientDetails,
	mattersListData: state.client.mattersList,
	loading: state.request.loading,
	hasPermissionsAccess: state.user.hasPermissionsAccess,
});
export default connect(mapStateToProps, {
	fetchClientDetail,
	fetchMattersList,
	deleteClients,
	activateClients,
	deactivateClients,
})(ClientDetail);
