import React from "react";
import { openGlobalDialog } from "../actions/globalEditDialogActions";
import { connect } from "react-redux";
import ButtonLink from "./ButtonLink";

const MaximumItemsList = (props) => {
	const displayItems = props.items.slice(0, props.maxItems);

	return (
		<div>
			{displayItems.map((item) => {
				return <div key={Math.random() + "__33"}>{item}</div>;
			})}
			{props.items.length > props.maxItems ? (
				<div>
					<ButtonLink
						href="#"
						onClick={() =>
							props.openGlobalDialog(
								{
									label: "Attorneys",
									mainText: props.items.join(", "),
									canSubmit: true,
									hideTextfield: true,
									readOnly: true,
									cancelButtonLabel: "Ok",
								},
								null,
								() => ({ canSubmit: true })
							)
						}
					>
						See All...
					</ButtonLink>
				</div>
			) : null}
		</div>
	);
};
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {
	openGlobalDialog,
})(MaximumItemsList);
