import React from "react";
import { FormGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class LabeledTextInput extends React.Component {
	onFieldChanged(value) {
		this.props.onChange && this.props.onChange(this.props.name, value);
	}
	render() {
		return (
			<div
				className={
					"LabeledInput LabeledTextInput " +
					(this.props.className || "")
				}
			>
				<div style={{ display: "flex" }}>
					<div className="InputLabel">{this.props.label}</div>
					{this.props.hoverText && (
						<div title={this.props.hoverText}>
							<FontAwesomeIcon
								icon="info-circle"
								style={{ marginLeft: 4, marginTop: 2 }}
							/>
						</div>
					)}
				</div>
				<TextInput
					value={this.props.value}
					type={this.props.type}
					invalid={this.props.invalid}
					autocomplete={this.props.autocomplete || ""}
					defaultValue={this.props.defaultValue}
					placeholder={this.props.placeholder}
					onChange={this.onFieldChanged.bind(this)}
					onEnterKey={this.props.onEnterKey || null}
					disabled={this.props.disabled}
					clearAfterSubmit={this.props.clearAfterSubmit}
					formRef={this.props.formRef}
				/>
			</div>
		);
	}
}
export class TextInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
		};
		this.onKeyPress = this.onKeyPress.bind(this);
		this.clearInput = this.clearInput.bind(this);
	}
	onFieldChanged(event) {
		this.props.onChange && this.props.onChange(event.target.value);
	}
	onKeyPress(event) {
		if (event.key === "Enter") {
			if (this.props.clearAfterSubmit) {
				event.target.form.elements.textInput.value = "";
				this.clearInput();
			}
			this.props.onEnterKey && this.props.onEnterKey(event);
			event.preventDefault();
			return false;
		}
	}
	clearInput() {
		this.setState({ value: "" });
	}
	render() {
		return (
			<form ref={this.props.formRef}>
				<FormGroup>
					<FormControl
						name="textInput"
						type={this.props.type || "text"}
						className={this.props.invalid ? "InputInvalid" : ""}
						autoComplete={this.props.autocomplete || ""}
						defaultValue={this.props.defaultValue || ""}
						placeholder={this.props.placeholder}
						onChange={this.onFieldChanged.bind(this)}
						onKeyPress={this.onKeyPress}
						disabled={this.props.disabled}
					/>
				</FormGroup>
			</form>
		);
	}
}
