import React from "react";
import { setSubheaderText } from "../../actions/navigateActions";
import { connect } from "react-redux";
import {
	fetchWhatsNewItems,
	updateWhatsNewItems,
} from "../../actions/whatsNewActions";
import WhatsNewDropdown from "../WhatsNewDropdown";
import { CircularProgress } from "@material-ui/core";
import WhatsNewSortableList from "./EditWhatsNewSortableList";
import { ButtonWithIcon } from "../ButtonWithIcon";

class EditWhatsNew extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			whatsNewItems: [],
			newTempIdCounter: 0,
			editItemDialogOpen: false,
			editItemText: "",
			editItemUrl: "",
			editItemId: undefined,
			loading: true,
			isNewItem: false,
		};
		this.addWhatsNewItem = this.addWhatsNewItem.bind(this);
		this.deleteWhatsNewItem = this.deleteWhatsNewItem.bind(this);
		this.closeEditItemDialog = this.closeEditItemDialog.bind(this);
		this.openEditItemDialog = this.openEditItemDialog.bind(this);
		this.handleEditItemChange = this.handleEditItemChange.bind(this);
		this.submitEditItemDialog = this.submitEditItemDialog.bind(this);
		this.saveAllWhatsNewItems = this.saveAllWhatsNewItems.bind(this);
	}
	componentDidMount() {
		this.props.setSubheaderText("Edit What's New Dropdown");
		// fetch them again just in case there was an update
		this.props.fetchWhatsNewItems();
	}

	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = {
				loading: false,
				whatsNewItems: props.whatsNewItems,
			};
			return newState;
		} else {
			return null;
		}
	}

	handleEditItemChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	closeEditItemDialog() {
		this.setState({ editItemDialogOpen: false });
	}

	openEditItemDialog(item) {
		let { text, url, id } = item;
		this.setState({
			editItemDialogOpen: true,
			editItemText: text,
			editItemUrl: url,
			editItemId: id,
			isNewItem: false,
		});
	}

	deleteWhatsNewItem(id) {
		this.setState({
			whatsNewItems: this.state.whatsNewItems.filter(
				(item) => item.id !== id
			),
		});
	}

	addWhatsNewItem() {
		this.setState({
			editItemDialogOpen: true,
			editItemText: "",
			editItemUrl: "",
			editItemId: `temp-${this.state.newTempIdCounter}`,
			newTempIdCounter: this.state.newTempIdCounter + 1,
			isNewItem: true,
		});
	}

	submitEditItemDialog() {
		let { editItemId, editItemText, editItemUrl } = this.state;
		let idMatch = this.state.whatsNewItems.find(
			(item) => item.id === editItemId
		);
		if (idMatch) {
			let newItems = this.state.whatsNewItems.map((item) => {
				if (item.id === editItemId) {
					return {
						id: editItemId,
						text: editItemText,
						url: editItemUrl,
					};
				}
				return item;
			});
			this.setState({
				whatsNewItems: newItems,
				editItemDialogOpen: false,
			});
		} else {
			this.setState({
				whatsNewItems: [
					...this.state.whatsNewItems,
					{
						id: editItemId,
						text: editItemText,
						url: editItemUrl,
					},
				],
				editItemDialogOpen: false,
			});
		}
	}

	saveAllWhatsNewItems() {
		this.props.updateWhatsNewItems(this.state.whatsNewItems);
		this.setState({ loading: true });
	}

	render() {
		if (this.props.loading) {
			return <CircularProgress />;
		}

		return (
			<div className="noDefaultMargin" style={{ margin: "30px" }}>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<div>
						<h3>{`What's New Dropdown Preview`}</h3>
						<div style={{ marginBottom: 15 }}>
							The bell will not ring here
						</div>
						<div
							style={{
								backgroundColor: "var(--lw-blue)",
								width: "fit-content",
								borderRadius: 10,
							}}
						>
							<WhatsNewDropdown
								whatsNewDropdownLastHashViewed={
									this.props.whatsNewItemsHash
								}
								updateWhatsNewDropdownLastHashViewed={() => {}}
								whatsNewItems={this.state.whatsNewItems}
								whatsNewItemsHash={this.props.whatsNewItemsHash}
							/>
						</div>
					</div>
					<div style={{ width: "50%" }}>
						<WhatsNewSortableList
							whatsNewItems={this.state.whatsNewItems}
							updateWhatsNewItems={(items) =>
								this.setState({ whatsNewItems: items })
							}
							editItemDialogOpen={this.state.editItemDialogOpen}
							addWhatsNewItem={this.addWhatsNewItem}
							deleteWhatsNewItem={this.deleteWhatsNewItem}
							openEditItemDialog={this.openEditItemDialog}
							closeEditItemDialog={this.closeEditItemDialog}
							handleEditItemChange={this.handleEditItemChange}
							submitEditItemDialog={this.submitEditItemDialog}
							editItemText={this.state.editItemText}
							editItemUrl={this.state.editItemUrl}
							isNewItem={this.state.isNewItem}
						/>
						<div
							style={{
								display: "flex",
								justifyContent: "end",
								marginTop: 15,
							}}
						>
							<ButtonWithIcon
								className="blue"
								handler={this.saveAllWhatsNewItems}
								buttonText="Save"
								iconName="check"
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	whatsNewItems: state.whatsNew.items,
	loading: state.whatsNew.loading,
	whatsNewItemsHash: state.whatsNew.itemsHash,
});
export default connect(mapStateToProps, {
	setSubheaderText,
	fetchWhatsNewItems,
	updateWhatsNewItems,
})(EditWhatsNew);
