/*
	Handles New and Edit matter functionality.  props.editMode determines which.
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	createNewMatter,
	updateMatter,
	getEverythingForNewMatter,
	getEverythingForEditMatter,
	unmountNewEditMatter,
} from "../actions/matterActions";
import TwoColumnWrapper from "./TwoColumnWrapper";
import { setSubheaderText } from "../actions/navigateActions";
import { LabeledTextInput } from "./TextInput";
import { LabeledDropDownSelect } from "./DropDownSelect";
import UsersDropDownSelect from "./UsersDropDownSelect";
import { LabeledDatePicker } from "./LabeledDatePicker";
import { shortDateFormatOffset } from "../utilities/utilities";

import MaterialTabs from "./MaterialTabs";
import EditMatterPermissions from "./EditMatterPermissions";

const tagLabelMap = (tag) => ({
	value: tag.name,
	label: tag.name,
});
const propTagsMap = (tag) => ({
	label: tag.tagName,
	value: tag.tagName,
});
const userOptionsMap = (user) => {
	//note: here the value is the users ID, but the <EditMatterPermissions> component expects the sub
	return { ...user, value: user.id, label: user.displayName };
};
const matterTypeMap = (matterType) => ({
	label: matterType.name,
	value: matterType.name,
	matterTypeID: matterType.id,
});
const makeClientOption = (id, availableClients) => {
	var intId = parseInt(id, 10);
	var filtered = availableClients.filter((c) => c.id === intId);
	return filtered.length
		? {
				clientID: id,
				name: filtered[0].name,
				label: filtered[0].name,
				value: filtered[0].name, // needed to autopopulate
			}
		: null;
};
const tabMap = [
	{ label: "Matter Information", url: "" },
	{ label: "Matter Permissions", url: "/permissions" },
];

const initialState = {
	name: "",
	matterID: "",
	customMatterID: "",
	client: {},
	matterType: {},
	officeBranch: {},
	assignedStaff: [],
	matterTags: [],
	dateOpened: null,
	dateClosed: null,
};
const newMatterPermissions = {
	matterPermissionGlobalSetting: "allUsers",
	userAllowList: [],
	groupAllowList: [],
};
class NewMatter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			matterID: "",
			customMatterID: "",
			client: null,
			matterType: null,
			officeBranch: null,
			assignedStaff: [],
			matterTags: [],
			dateOpened: null,
			dateClosed: null,
			loading: true,
			matterPermissions: newMatterPermissions,
		};
		this.handleChange = this.handleChange.bind(this);
		this.switchedTab = this.switchedTab.bind(this);
		this.makeDictOfDisplayNamesBySub =
			this.makeDictOfDisplayNamesBySub.bind(this);
		this.makeDictOfGroupNamesByID =
			this.makeDictOfGroupNamesByID.bind(this);
		this.getDefaultDropdownValue = this.getDefaultDropdownValue.bind(this);
	}
	getDefaultDropdownValue(stateKey) {
		const returnValue =
			this.state[stateKey] && this.state[stateKey].value
				? this.state[stateKey]
				: null;
		return returnValue;
	}
	getDefaultMultiDropdownValue(stateKey) {
		const returnValue =
			this.state[stateKey] && this.state[stateKey].length
				? this.state[stateKey]
				: null;
		return returnValue;
	}
	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = props.editMode
				? {
						loading: false,
						name: props.matterDetails.name,
						customMatterID: props.matterDetails.customMatterID,
						matterTags: props.matterDetails.tags
							? props.matterDetails.tags.map(propTagsMap)
							: null,
						assignedStaff: props.matterDetails.assignedStaff
							? props.matterDetails.assignedStaff.map(
									userOptionsMap
								)
							: null,
						matterType: props.matterDetails.matterType
							? {
									name: props.matterDetails.matterType,
									label: props.matterDetails.matterType,
									matterTypeID:
										props.matterDetails.matterTypeID,
									value: props.matterDetails.matterType,
								}
							: null,
						client: props.matterDetails.clientName
							? {
									name: props.matterDetails.clientName,
									label: props.matterDetails.clientName,
									clientID: props.matterDetails.clientID,
									value: props.matterDetails.clientName,
								}
							: null,
						officeBranch: props.matterDetails.officeBranch
							? {
									name: props.matterDetails.officeBranch,
									label: props.matterDetails.officeBranch,
									officeBranchID:
										props.matterDetails.officeBranchID,
									value: props.matterDetails.officeBranch,
								}
							: null,
						dateOpened: props.matterDetails.dateOpened
							? new Date(props.matterDetails.dateOpened)
							: props.editMode
								? null
								: new Date(),
						dateClosed: props.matterDetails.dateClosed
							? new Date(props.matterDetails.dateClosed)
							: null,
						matterPermissions: props.matterPermissions,
					}
				: {
						...initialState,
						dateOpened: new Date(),
						client: props.match.params.clientID
							? makeClientOption(
									props.match.params.clientID,
									props.availableClients
								)
							: null,
						loading: false,
					};
			return newState;
		} else {
			return null;
		}
	}
	componentDidMount() {
		if (this.props.editMode) {
			this.props.getEverythingForEditMatter(this.props.match.params.id);
		} else {
			this.props.getEverythingForNewMatter();
			this.props.setSubheaderText("New Matter");
		}
	}
	componentWillUnmount() {
		this.props.unmountNewEditMatter();
	}
	handleChange(key, value) {
		this.setState({ [key]: value });
	}
	submit() {
		if (this.props.editMode) {
			this.props.updateMatter(this.props.match.params.id, this.state);
		} else {
			this.props.createNewMatter(this.state);
		}
	}
	switchedTab(value) {
		var newURL;

		const endOfURL = tabMap[value].url;
		if (this.props.editMode) {
			newURL = `/matters/${this.props.match.params.id}/edit${endOfURL}`;
		} else {
			newURL = `/matters/new${endOfURL}`;
		}

		this.props.history.push(newURL);
	}
	makeDictOfDisplayNamesBySub() {
		var out = {};
		this.props.availableAssignedStaff.forEach((u) => {
			out[u.sub] = u.displayName;
		});
		return out;
	}
	makeDictOfGroupNamesByID() {
		var out = {};
		this.props.permissionsGroups.forEach((g) => {
			out[g.id] = { name: g.name, type: g.type };
		});
		return out;
	}
	render() {
		if (this.state.loading) {
			return <div />;
		} else {
			return (
				<div>
					{this.props.hasPermissionsAccess ? (
						<MaterialTabs
							activeTab={this.props.permissions ? 1 : 0}
							tabs={tabMap}
							switchedTab={this.switchedTab}
						/>
					) : null}
					{this.props.permissions ? (
						<TwoColumnWrapper
							headingText="Matter Permissions"
							iconName="briefcase"
							canSubmit={this.state.name !== ""}
							submit={this.submit.bind(this)}
							submitButtonText={
								this.props.editMode
									? "Update Matter"
									: "Create Matter"
							}
							leftChildren={
								<div>
									<EditMatterPermissions
										onChange={(permissionsState) => {
											this.setState({
												matterPermissions:
													permissionsState,
											});
										}}
										matterPermissions={
											this.props.editMode
												? this.state.matterPermissions
												: newMatterPermissions
										}
										availableUsers={this.props.availableAssignedStaff.map(
											(user) => {
												return {
													...user,
													//note: here the value is the users sub, while in the base component the ID is the value
													value: user.sub,
													label: user.displayName,
												};
											}
										)}
										availableGroups={this.props.permissionsGroups.map(
											(p) => ({
												value: p.id,
												label: p.name,
												isFixed:
													p.type === "FIRM_ADMIN",
											})
										)}
										availableGroupNamesByID={this.makeDictOfGroupNamesByID()}
										userDisplayNamesBySub={this.makeDictOfDisplayNamesBySub()}
									/>
								</div>
							}
							rightChildren={null}
						/>
					) : (
						<TwoColumnWrapper
							headingText="Matter Information"
							iconName="briefcase"
							canSubmit={this.state.name !== ""}
							submit={this.submit.bind(this)}
							submitButtonText={
								this.props.editMode
									? "Update Matter"
									: "Create Matter"
							}
							leftChildren={
								<div>
									<LabeledTextInput
										label="Matter Name*"
										name="name"
										placeholder="New Matter Name"
										defaultValue={this.state.name}
										onChange={this.handleChange}
									/>
									<LabeledTextInput
										label="Custom Matter ID"
										name="customMatterID"
										placeholder="Custom Matter ID"
										defaultValue={this.state.customMatterID}
										onChange={this.handleChange}
									/>
									<LabeledDropDownSelect
										label="Client"
										name="client"
										placeholder="Client Name"
										isCreatable
										isClearable
										isAsync
										options={this.props.availableClients.map(
											(client) => ({
												label: client.name,
												value: client.name,
												clientID: client.id,
											})
										)}
										defaultValue={this.getDefaultDropdownValue(
											"client"
										)}
										handler={this.handleChange}
									/>
									<LabeledDropDownSelect
										label="Matter Type"
										name="matterType"
										placeholder="(none)"
										isCreatable
										isClearable
										isAsync
										options={this.props.availableMatterTypes
											.filter(
												(type) =>
													type.status === "active"
											)
											.map(matterTypeMap)}
										defaultValue={this.getDefaultDropdownValue(
											"matterType"
										)}
										handler={this.handleChange}
									/>
									<LabeledDropDownSelect
										label="Office / Branch"
										name="officeBranch"
										placeholder="(none)"
										isCreatable
										isClearable
										isAsync
										options={this.props.availableOfficeBranches
											.filter(
												(office) =>
													office.status === "active"
											)
											.map((officeBranch) => ({
												label: officeBranch.name,
												value: officeBranch.name,
												officeBranchID: officeBranch.id,
											}))}
										defaultValue={this.getDefaultDropdownValue(
											"officeBranch"
										)}
										handler={this.handleChange}
									/>
								</div>
							}
							rightChildren={
								<div>
									<UsersDropDownSelect
										label="Assigned Staff"
										isMulti
										name="assignedStaff"
										isAsync
										defaultValue={this.getDefaultMultiDropdownValue(
											"assignedStaff"
										)}
										options={this.props.availableAssignedStaff.map(
											userOptionsMap
										)}
										handler={this.handleChange}
									/>
									<LabeledDropDownSelect
										label="Matter Tag(s)"
										isMulti
										name="matterTags"
										isCreatable
										isAsync
										defaultValue={this.getDefaultMultiDropdownValue(
											"matterTags"
										)}
										options={this.props.availableMatterTags
											.filter(
												(tag) => tag.status === "active"
											)
											.map(tagLabelMap)}
										handler={this.handleChange}
									/>
									<LabeledDatePicker
										label="Opened Date"
										name="dateOpened"
										isClearable
										defaultValue={
											this.props.editMode
												? this.state.dateOpened
													? new Date(
															shortDateFormatOffset(
																this.state
																	.dateOpened
															)
														)
													: null
												: new Date()
										}
										placeholderText="Select a Date"
										handler={this.handleChange}
									/>

									<LabeledDatePicker
										label="Closed Date"
										name="dateClosed"
										isClearable
										defaultValue={
											this.props.editMode &&
											this.state.dateClosed
												? new Date(
														shortDateFormatOffset(
															this.state
																.dateClosed
														)
													)
												: null
										}
										placeholderText="Select a Date"
										handler={this.handleChange}
									/>
								</div>
							}
						/>
					)}
				</div>
			);
		}
	}
}
const mapStateToProps = (state) => {
	return {
		availableMatterTags: state.matter.availableMatterTags,
		availableMatterTypes: state.matter.availableMatterTypes,
		availableAssignedStaff: state.matter.availableAssignedStaff,
		availableOfficeBranches: state.matter.availableOfficeBranches,
		availableClients: state.matter.availableClients,
		matterDetails: state.matter.matterDetails,
		clientDetails: state.client.clientDetails,
		permissionsGroups: state.matter.permissionsGroups,
		matterPermissions: state.matter.matterPermissions,
		loading: state.matter.newEditMatterLoading,
		hasPermissionsAccess: state.user.hasPermissionsAccess,
	};
};
NewMatter.propTypes = {
	createNewMatter: PropTypes.func.isRequired,
	updateMatter: PropTypes.func.isRequired,
	getEverythingForNewMatter: PropTypes.func.isRequired,
	getEverythingForEditMatter: PropTypes.func.isRequired,
	unmountNewEditMatter: PropTypes.func.isRequired,
	setSubheaderText: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
	createNewMatter,
	updateMatter,
	getEverythingForNewMatter,
	getEverythingForEditMatter,
	unmountNewEditMatter,
	setSubheaderText,
})(NewMatter);
