import {
	UPDATE_USER_SETTING,
	GET_USER_SETTINGS_SUCCESS,
} from "../actions/types";

const initialState = {
	foldersOnTop: true,
};
export default function (state = initialState, action) {
	switch (action.type) {
		case UPDATE_USER_SETTING:
			var { settingKey, settingValue } = action.payload;
			return {
				...state,
				[settingKey]: settingValue,
			};
		case GET_USER_SETTINGS_SUCCESS:
			var json = action.payload;
			var settings = {};
			try {
				settings = JSON.parse(json);
			} catch (err) {
				console.log("error parsing user settings JSON");
				console.log(json);
			}

			return {
				...state,
				...settings,
			};
		default:
			return state;
	}
}
