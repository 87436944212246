import {
	SET_SEARCH_BAR_CONTEXT,
	SET_SEARCH_BAR_MATTER_IDS,
} from "../actions/types";

const initialState = {
	location: "/",
	searchPlaceholder: "Search LexWorkplace",
	searchUrlAfterQuery: "",
	allVisibleMatterIDsNotConsideringFilter: null,
	filteredMatterIDs: null,
	allVisibleMatterIDsOnScreen: [],
	menuOptions: [],
};

const searchQuerystringSuffix =
	"&runAfterNavigating=1&=&type=&from=&to=&docType=&docStatus=&docTags=&users=&lock=&userOp=&fav=0&order=0";

const defaultPlaceholder = "Search LexWorkplace";

const searchAllLexworkplaceOption = {
	placeholder: "Search All LexWorkplace",
	querystring: `&m=${searchQuerystringSuffix}`,
};

// since the filtered matters are passed via URL, and the referrer header has a limit of 2063 characters,
// we have to limit the number of matters to 2000 / (4 (avg number of characters matterID) + 3 (url encoded separator ":" = "%3A") =
// roughly 200 matters to be safe
const maximumMatters = 200;

const searchTheseMatters = (ids) => {
	const numMatters = ids ? ids.length : 0;

	return {
		querystring: `&m=${
			numMatters < maximumMatters && ids ? ids.join(":") : ""
		}${searchQuerystringSuffix}`,
		placeholder:
			ids && ids.length && numMatters < maximumMatters
				? ids.length > 1
					? `Search these ${ids.length} Matters`
					: `Search this Matter`
				: defaultPlaceholder,
	};
};

const specialSearchRoutes = [
	{
		regex: /^\/matters\/(\d+)\//,
		getContext: (match, ids) => {
			const matterID = match[1];
			const searchThisMatterOption = {
				querystring: `&m=${matterID}${searchQuerystringSuffix}`,
				placeholder: "Search this Matter",
			};
			return {
				...searchThisMatterOption,
				menuOptions: [
					searchThisMatterOption,
					searchAllLexworkplaceOption,
				],
			};
		},
	},
	{
		regex: /^\/firmDocs\/public\//,
		getContext: (match, ids) => {
			const searchPublicFirmDocsOption = {
				querystring: `&m=-1${searchQuerystringSuffix}`,
				placeholder: "Search Public Firm Docs",
			};
			return {
				...searchPublicFirmDocsOption,
				menuOptions: [
					searchPublicFirmDocsOption,
					searchAllLexworkplaceOption,
				],
			};
		},
	},
	{
		regex: /^\/firmDocs\/private\//,
		getContext: (match, ids) => {
			const searchPrivateFirmDocsOption = {
				querystring: `&m=0${searchQuerystringSuffix}`,
				placeholder: "Search Private Firm Docs",
			};
			return {
				...searchPrivateFirmDocsOption,
				menuOptions: [
					searchPrivateFirmDocsOption,
					searchAllLexworkplaceOption,
				],
			};
		},
	},

	{
		regex: /^\/matters\/$/,
		getContext: (match, ids) => {
			const searchTheseMattersOption = searchTheseMatters(ids);
			return {
				...searchTheseMattersOption,
				menuOptions:
					ids && ids.length
						? [
								searchTheseMattersOption,
								searchAllLexworkplaceOption,
							]
						: [searchAllLexworkplaceOption],
			};
		},
	},
	{
		regex: /^\/clients\/\d+/,
		getContext: (match, ids) => {
			const searchTheseMattersOption = searchTheseMatters(ids);
			return {
				...searchTheseMattersOption,
				menuOptions:
					ids && ids.length
						? [
								searchTheseMattersOption,
								searchAllLexworkplaceOption,
							]
						: [searchAllLexworkplaceOption],
			};
		},
	},
];

const checkLocation = (location, matterIDs) => {
	var placeholder = defaultPlaceholder;
	var querystring = `&m=${searchQuerystringSuffix}`;
	var menuOptions = [searchAllLexworkplaceOption];
	specialSearchRoutes.forEach((route) => {
		const matchAttempt = location.match(route.regex);
		if (matchAttempt) {
			const context = route.getContext(matchAttempt, matterIDs);

			querystring = context.querystring;
			placeholder = context.placeholder;
			menuOptions = context.menuOptions;
		}
	});
	return { placeholder, querystring, menuOptions };
};

export default function (state = initialState, action) {
	if (action.type === SET_SEARCH_BAR_CONTEXT) {
		if (action.payload.location) {
			const { querystring, placeholder, menuOptions } = checkLocation(
				action.payload.location,
				null
			);
			return {
				...state,
				location: action.payload.location,
				searchPlaceholder: placeholder,
				searchUrlAfterQuery: querystring,
				menuOptions,
			};
		} else {
			const { querystring, placeholder, menuOptions } = checkLocation(
				state.location,
				state.allVisibleMatterIDsOnScreen
			);
			return {
				...state,
				searchPlaceholder: placeholder,
				searchUrlAfterQuery: querystring,
				menuOptions,
			};
		}
	}
	if (action.type === SET_SEARCH_BAR_MATTER_IDS) {
		let newState = { ...state };

		if (action.payload.all !== undefined) {
			newState.allVisibleMatterIDsNotConsideringFilter =
				action.payload.all;
		}
		if (action.payload.filtered !== undefined) {
			newState.filteredMatterIDs = action.payload.filtered;
		}
		if (newState.filteredMatterIDs) {
			newState.allVisibleMatterIDsOnScreen = newState.filteredMatterIDs;
		} else {
			newState.allVisibleMatterIDsOnScreen =
				newState.allVisibleMatterIDsNotConsideringFilter;
		}

		return newState;
	}

	return state;
}
