import {
	PASTE_SUCCESS,
	SET_CHECKBOX,
	CUT_SUCCESS,
	COPY_SUCCESS,
	DELETE_SUCCESS,
	RESTORE_SUCCESS,
	SET_ALL_CHECKBOXES,
	UNSELECT_ALL,
} from "../actions/types";

const initialState = {
	selectedDocuments: { Object: {}, Folder: {}, header: false },
	selectedPublicDocuments: { Object: {}, Folder: {}, header: false },
	selectedPrivateDocuments: { Object: {}, Folder: {}, header: false },
	selectedEmails: { Object: {}, Folder: {}, header: false },
	selectedNotes: { Object: {}, Folder: {}, header: false },
	clipboard: {},
	sourceBasepath: "",
	sourceDirectoryID: null,
	cut: false,
};
const rootMap = {
	docs: "selectedDocuments",
	public: "selectedPublicDocuments",
	private: "selectedPrivateDocuments",
	email: "selectedEmails",
	notes: "selectedNotes",
};

const objectTypeMap = {
	File: "Object",
	Email: "Object",
	Note: "Object",
	Folder: "Folder",
};

export default function (state = initialState, action) {
	var listName;
	switch (action.type) {
		case SET_CHECKBOX:
			var newSelection = { ...state[action.payload.listName] };

			if (action.payload.checked) {
				newSelection[objectTypeMap[action.payload.type]][
					action.payload.id
				] = action.payload.row;
			} else {
				delete newSelection[objectTypeMap[action.payload.type]][
					action.payload.id
				];
			}
			return {
				...state,
				[action.payload.listName]: newSelection,
			};
		case SET_ALL_CHECKBOXES:
			var newState = {
				...state,
				[action.payload.listName]: !state[action.payload.listName]
					.header
					? { ...action.payload.config, header: true }
					: { Object: {}, Folder: {}, header: false },
			};
			return newState;
		case UNSELECT_ALL:
			return {
				...state,
				selectedDocuments: { Object: {}, Folder: {}, header: false },
				selectedEmails: { Object: {}, Folder: {}, header: false },
				selectedNotes: { Object: {}, Folder: {}, header: false },
				selectedPublicDocuments: {
					Object: {},
					Folder: {},
					header: false,
				},
				selectedPrivateDocuments: {
					Object: {},
					Folder: {},
					header: false,
				},
			};
		case CUT_SUCCESS:
			listName = rootMap[action.payload.sourceBasepath.split("/")[0]];
			return {
				...state,
				[listName]: { Object: {}, Folder: {}, header: false }, // Why doesnt clearState[listName] work here?
				clipboard: state[listName],
				sourceBasepath: action.payload.sourceBasepath,
				sourceDirectoryID: action.payload.sourceDirectoryID,
				cut: true,
			};
		case COPY_SUCCESS:
			listName = rootMap[action.payload.sourceBasepath.split("/")[0]];
			return {
				...state,
				[listName]: { Object: {}, Folder: {}, header: false },
				clipboard: state[listName],
				sourceBasepath: action.payload.sourceBasepath,
				sourceDirectoryID: action.payload.sourceDirectoryID,
				cut: false,
			};
		case PASTE_SUCCESS:
			listName = rootMap[action.payload.sourceBasepath.split("/")[0]];
			var mixinIfCameFromCut = state.cut
				? { clipboard: {}, sourceBasepath: "", sourceDirectoryID: null }
				: {};
			return {
				...state,
				...mixinIfCameFromCut,
				[listName]: { Object: {}, Folder: {}, header: false },
			};
		case DELETE_SUCCESS:
			listName = rootMap[action.payload.sourceBasepath.split("/")[0]];
			return {
				...state,
				[listName]: { Object: {}, Folder: {}, header: false },
			};
		case RESTORE_SUCCESS:
			listName = rootMap[action.payload.sourceBasepath.split("/")[0]];
			return {
				...state,
				[listName]: { Object: {}, Folder: {}, header: false },
			};
		default:
			return state;
	}
}
