import React, { Component } from "react";
import { connect } from "react-redux";
import CustomizableTable from "./CustomizableTable";

import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { setCheckbox } from "../../actions/multiselectActions";
import { LexWorkplaceCheckBoxIcon } from "../RowCheckbox";

const prependCheckbox = (data) =>
	data.map((item) => ({ ...item, selected: false }));

const RowCheckbox = withStyles({
	root: {
		padding: 0,
	},
})((props) => <Checkbox color="primary" {...props} />);

const rootMap = {
	"docs/": "selectedDocuments",
	"email/": "selectedEmails",
	"notes/": "selectedNotes",
	"public/": "selectedPublicDocuments",
	"private/": "selectedPrivateDocuments",
};
const objectTypeMap = {
	File: "Object",
	Email: "Object",
	Note: "Object",
	Folder: "Folder",
};
class SelectTable extends Component {
	constructor(props, context) {
		super(props, context);

		this.addColumnHeader = this.addColumnHeader.bind(this);
		this.checkboxClicked = this.checkboxClicked.bind(this);
	}
	checkboxClicked(e, id, type, row) {
		this.props.setCheckbox(
			rootMap[this.props.root],
			id,
			e.target.checked,
			type,
			row.original
		);
	}
	addColumnHeader(columns) {
		return [
			{
				Header: (
					<RowCheckbox
						style={{ marginLeft: 4 }}
						icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
						checkedIcon={
							<LexWorkplaceCheckBoxIcon fontSize="small" />
						}
						checked={this.props.headerChecked}
						onClick={
							this.props
								.headerBoxClicked /*Why onClick and not onChange as the checkboxes below use?  I have no idea but onChange was only working the first time*/
						}
					/>
				),
				width: 40,
				accessor: "selected",
				sortable: false,
				resizable: false,
				filterable: false,
				unhidable: true,
				Cell: (row) => (
					<RowCheckbox
						style={{ paddingLeft: 4 }}
						icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
						checkedIcon={
							<LexWorkplaceCheckBoxIcon fontSize="small" />
						}
						checked={
							this.props.selectedItems[
								objectTypeMap[row.original.type]
							] &&
							this.props.selectedItems[
								objectTypeMap[row.original.type]
							][row.original.id]
								? true
								: false
						}
						onChange={(e) =>
							this.checkboxClicked(
								e,
								row.original.id,
								row.original.type,
								row
							)
						}
					/>
				),
			},
		].concat(columns);
	}
	render() {
		return (
			<CustomizableTable
				{...this.props}
				data={prependCheckbox(this.props.data)}
				columns={this.addColumnHeader(this.props.columns)}
			/>
		);
	}
}
const mapStateToProps = (state, ownProps) => {
	return {
		selectedItems: state.multiselect[rootMap[ownProps.root]],
		headerChecked: state.multiselect[rootMap[ownProps.root]].header,
	};
};
export default connect(mapStateToProps, { setCheckbox })(SelectTable);
