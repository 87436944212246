import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { listDirectory } from "../actions/documentActions";
import {
	setSubheaderText,
	setCreatableContext,
} from "../actions/navigateActions";
import { unselectAll } from "../actions/multiselectActions";

import { encodeFullpath, decodeFullpath } from "../utilities/utilities";

import DocumentList from "./DocumentList";

import MaterialTabs from "./MaterialTabs";

import { withRouter } from "react-router-dom";

const basepathnameMap = {
	public: "Public",
	private: "Private",
};
const tabMap = [
	{ label: "Public", url: "public" },
	{ label: "Private", url: "private" },
];

//todo: this object is almost exactly duplicated in MatterDetail.js
const tabUrlMap = {
	"public/": {
		index: 0,
		customColumnsKey: "/documents",
		breadcrumbsIcon: "file",
		selectionListName: "selectedPublicDocuments",
		backendRoot: "/document",
		itemNoun: "documents",
	},
	"private/": {
		index: 1,
		customColumnsKey: "/documents",
		breadcrumbsIcon: "file",
		selectionListName: "selectedPrivateDocuments",
		backendRoot: "/document",
		itemNoun: "documents",
	},
	"docs/": {
		index: -1,
		customColumnsKey: "/documents",
		breadcrumbsIcon: "file",
		selectionListName: "selectedDocuments",
		backendRoot: "/document",
		itemNoun: "documents",
	},
	"email/": {
		index: -1,
		customColumnsKey: "/email",
		breadcrumbsIcon: "envelope",
		selectionListName: "selectedEmails",
		backendRoot: "/email",
		itemNoun: "emails",
	},
	"notes/": {
		index: -1,
		customColumnsKey: "/notes",
		breadcrumbsIcon: "pencil-alt",
		selectionListName: "selectedNotes",
		backendRoot: "/notes",
		itemNoun: "notes",
	},
};
const DocumentListWithRouter = withRouter(DocumentList);

class FirmDocs extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			s3Basepath: "",
			matterInfoHidden: false,
			lastLocation: {
				public: "public/",
				private: "private/",
			},
		};
		this.switchedTab = this.switchedTab.bind(this);
		this.getUrlPrefix = this.getUrlPrefix.bind(this);
		this.getAPIBasepath = this.getAPIBasepath.bind(this);
	}
	componentDidMount() {
		this.props.setSubheaderText("Firm Documents");
		this.props.listDirectory(
			0,
			this.getAPIBasepath(),
			this.props.match.params.root,
			false
		);
		this.props.setCreatableContext(true);
		this.props.unselectAll();
	}
	componentWillUnmount() {
		this.props.setCreatableContext(false);
	}
	componentDidUpdate(prevProps) {
		if (
			prevProps.match.params.fullpath !==
				this.props.match.params.fullpath ||
			prevProps.match.params.root !== this.props.match.params.root
		) {
			this.props.listDirectory(
				0,
				this.getAPIBasepath(),
				this.props.match.params.root,
				false
			);
			this.props.unselectAll();
			//scroll the document list to the top
			document.querySelector(".rt-tbody").scrollTop = 0;
		}
	}
	getUrlPrefix() {
		return "/firmDocs/" + this.props.match.params.root + "/";
	}
	getAPIBasepath() {
		return (
			this.props.match.params.root +
			"/" +
			(decodeFullpath(this.props.match.params.fullpath) || "")
		);
	}
	switchedTab(value) {
		const newURL =
			"/firmDocs/" + this.state.lastLocation[tabMap[value].url];
		this.props.history.push(newURL);
	}
	static getDerivedStateFromProps(props, state) {
		var lastLocation = { ...state.lastLocation };
		lastLocation[props.match.params.root] = encodeFullpath(
			props.match.params.root + "/" + (props.match.params.fullpath || "")
		);
		return { ...state, lastLocation };
	}
	render() {
		return (
			<div className="fullHeightFlex noDefaultMargin">
				<MaterialTabs
					style={{ height: "48px" }}
					activeTab={
						tabUrlMap[this.props.match.params.root + "/"].index
					}
					tabs={tabMap.filter((tab) => {
						return tab.url === "private"
							? this.props.canAccessPrivateFirmDocs
							: true;
					})}
					switchedTab={this.switchedTab}
				/>
				<DocumentListWithRouter
					style={{ flex: 1 }}
					urlPrefix={this.getUrlPrefix()}
					matterID={0}
					basepath={"/firmDocs/"}
					breadcrumbRootName={
						basepathnameMap[this.props.match.params.root]
					}
					root={this.props.match.params.root + "/"}
					apiBasepath={this.getAPIBasepath()}
					fullpath={this.props.match.params.fullpath}
					values={this.props.documentList}
					tabUrlMap={tabUrlMap}
				/>
			</div>
		);
	}
}
FirmDocs.propTypes = {
	loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
	loading: state.document.loading,
	documentList: state.document.documentList,
	canAccessPrivateFirmDocs: state.user.canAccessPrivateFirmDocs,
});
export default connect(mapStateToProps, {
	listDirectory,
	setSubheaderText,
	unselectAll,
	setCreatableContext,
})(FirmDocs);
