import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SmallNoPaddingIconButton } from "../ButtonWithIcon";
import { nextPage, previousPage } from "../../actions/globalPaginationActions";

class CustomPagination extends Component {
	constructor(props) {
		super();

		this.changePage = this.changePage.bind(this);

		this.state = {
			visiblePages: this.getVisiblePages(null, props.pages),
		};
		this.previousClicked = this.previousClicked.bind(this);
		this.nextClicked = this.nextClicked.bind(this);
	}

	static propTypes = {
		pages: PropTypes.number,
		page: PropTypes.number,
		PageButtonComponent: PropTypes.any,
		onPageChange: PropTypes.func,
		previousText: PropTypes.string,
		nextText: PropTypes.string,
	};

	previousClicked() {
		//this.props.previousPage();
		const activePage = this.props.page + 1;
		activePage > 1 && this.changePage(activePage - 1);
	}
	nextClicked() {
		//this.props.nextPage();
		const activePage = this.props.page + 1;
		activePage !== this.props.pages && this.changePage(activePage + 1);
	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.pages !== nextProps.pages) {
			this.setState({
				visiblePages: this.getVisiblePages(null, nextProps.pages),
			});
		}

		this.changePage(nextProps.page + 1);
	}

	filterPages = (visiblePages, totalPages) => {
		return visiblePages.filter((page) => page <= totalPages);
	};

	getVisiblePages = (page, total) => {
		if (total < 7) {
			return this.filterPages([1, 2, 3, 4, 5, 6], total);
		} else {
			if (page % 5 >= 0 && page > 4 && page + 2 < total) {
				return [1, page - 1, page, page + 1, total];
			} else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
				return [1, total - 3, total - 2, total - 1, total];
			} else {
				return [1, 2, 3, 4, 5, total];
			}
		}
	};
	changePage(page) {
		const activePage = this.props.page + 1;

		if (page === activePage) {
			return;
		}

		const visiblePages = this.getVisiblePages(page, this.props.pages);

		this.setState({
			visiblePages: this.filterPages(visiblePages, this.props.pages),
		});

		this.props.onPageChange(page - 1);
	}
	render() {
		return (
			<div
				style={{
					borderTop: "1px solid black",
					display: "flex",
					height: 36,
					lineHeight: "36px",
				}}
			>
				<div>
					<SmallNoPaddingIconButton
						aria-label="previous page"
						onClick={this.previousClicked}
						style={{
							marginRight: 20,
							paddingLeft: 8,
							paddingRight: 8,
						}}
						disabled={!this.props.previousEnabled}
					>
						<FontAwesomeIcon
							icon={"caret-left"}
							size="1x"
							style={{
								color: this.props.previousEnabled
									? "black"
									: "var(--lw-medium-grey-b)",
							}}
						/>
					</SmallNoPaddingIconButton>
					<SmallNoPaddingIconButton
						aria-label="next page"
						onClick={this.nextClicked}
						style={{
							marginRight: 20,
							paddingLeft: 8,
							paddingRight: 8,
						}}
						disabled={!this.props.nextEnabled}
					>
						<FontAwesomeIcon
							icon={"caret-right"}
							size="1x"
							style={{
								color: this.props.nextEnabled
									? "black"
									: "var(--lw-medium-grey-b)",
							}}
						/>
					</SmallNoPaddingIconButton>
				</div>
				<div>
					<span style={{ marginRight: 8 }}>{`Page ${
						this.props.page + 1
					} of ${this.props.pages || "1"}`}</span>
					<span> {`(${this.props.data.length} total items)`}</span>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state, ownProps) => ({
	previousEnabled: ownProps.page > 0,
	nextEnabled: ownProps.page !== ownProps.pages - 1 && ownProps.pages > 0,
});
export default connect(mapStateToProps, { nextPage, previousPage })(
	CustomPagination
);
