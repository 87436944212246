/*
Handles matter tags, client tags, matter types, document types, office/branch list, document statuses


*/

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { makeTagActions } from "../actions/tagActions";
import { openGlobalDialog } from "../actions/globalEditDialogActions";
import { setSubheaderText } from "../actions/navigateActions";

import CustomizableTable from "./Tables/CustomizableTable";
import InteractiveTableCell from "./Tables/InteractiveTableCell";
import { ButtonWithIcon } from "./ButtonWithIcon";
import "react-table/react-table.css";
import { shortDateFormat } from "../utilities/utilities";

const filterMap = {
	Active: (row) => row.status === "active",
	Inactive: (row) => row.status === "inactive",
	Custom: (row) => row.createdBy !== "LexWorkplace",
	All: () => true,
};

class TagManager extends Component {
	constructor(props, context) {
		super(props, context);

		this.deactivateRequest = this.deactivateRequest.bind(this);
		this.activateRequest = this.activateRequest.bind(this);
		this.newButtonHandler = this.newButtonHandler.bind(this);
		this.editHandler = this.editHandler.bind(this);

		this.columnConfig = [
			{ Header: "ID", accessor: "id", width: 40 },
			{
				Header: this.props.objectName,
				accessor: "name",
				Cell: (row) => (
					<InteractiveTableCell
						menuItems={
							row.original.status === "active"
								? [
										{
											text:
												"Edit " + this.props.objectName,
											onSelect: () =>
												this.editHandler(row.original),
										},
										{
											text:
												"Deactivate " +
												this.props.objectName,
											onSelect: () =>
												this.deactivateRequest(
													row.original.id
												),
										},
									]
								: [
										{
											text:
												"Edit " + this.props.objectName,
											onSelect: () =>
												this.editHandler(row.original),
										},
										{
											text:
												"Activate " +
												this.props.objectName,
											onSelect: () =>
												this.activateRequest(
													row.original.id
												),
										},
										{
											text:
												"Delete " +
												this.props.objectName,
											onSelect: () =>
												this.deleteRequest(
													row.original.id
												),
										},
									]
						}
					>
						<span>{row.original.name}</span>
					</InteractiveTableCell>
				),
				wildcard: true,
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: (row) => (
					<span style={{ textTransform: "capitalize" }}>
						{row.value}
					</span>
				),
			},
			{
				Header: "Created By",
				accessor: "createdBy",
				wildcard: true,
			},
			{
				Header: "Last Modified",
				accessor: "lastUpdated",
				Cell: (row) => (row.value ? shortDateFormat(row.value) : ""),
				filterValueMethod: (x) => shortDateFormat(x.lastUpdated),
			},
		];
	}
	componentDidMount() {
		this.props.setSubheaderText(this.props.subheaderText);
	}
	deactivateRequest(id) {
		this.props.deactivateTags([id]);
	}
	activateRequest(id) {
		this.props.activateTags([id]);
	}
	deleteRequest(id) {
		this.props.deleteTags([id]);
	}
	UNSAFE_componentWillMount() {
		this.props.fetchTags();
	}
	newButtonHandler() {
		this.props.openGlobalDialog(
			{
				label: "New " + this.props.objectName,
				submitButtonLabel: "Create " + this.props.objectName,
			},
			(state) => {
				this.props.createTags([state.value]);
			},
			(state) => {
				return {
					canSubmit: state.value !== "",
					erroMessage: "",
				};
			}
		);
	}
	editHandler(row) {
		this.props.openGlobalDialog(
			{
				label: "Edit " + this.props.objectName,
				value: row.name,
				submitButtonLabel: "Update " + this.props.objectName,
			},
			(state) => {
				this.props.updateTags([{ id: row.id, name: state.value }]);
			},
			(state) => {
				return {
					canSubmit: state.value !== "" && state.value !== row.name,
					erroMessage: "",
				};
			}
		);
	}

	render() {
		return (
			<div className="fullHeightFlex noDefaultMargin">
				<CustomizableTable
					actionButtons={
						<ButtonWithIcon
							handler={this.newButtonHandler}
							buttonText={"New " + this.props.objectName}
							iconName="tag"
						/>
					}
					tabsFilterMap={filterMap}
					defaultTabFilterValue="Active"
					leftSideIcon="tag"
					className="fullWidth"
					data={this.props.tagsData}
					autoResize
					columns={this.columnConfig}
					noResultsText={"No items here"}
					tableUniqueID={this.props.tableUniqueID}
				/>
			</div>
		);
	}
}
TagManager.propTypes = {
	fetchTags: PropTypes.func.isRequired,
};

const clientTagActions = makeTagActions("/client/tags");
const matterTagActions = makeTagActions("/matter/tags");
const matterTypeActions = makeTagActions("/matters/types");
const officeBranchActions = makeTagActions("/officeBranch");
const documentTagActions = makeTagActions("/document/tags");
const documentTypeActions = makeTagActions("/document/types");
const documentStatusActions = makeTagActions("/document/status");

const ClientTagManager = (() => {
	const tableUniqueID = "/manageClientTags";
	return connect(
		(state) => ({
			tableUniqueID,
			tagsData: state.client.availableClientTags,
			subheaderText: "Manage Client Tags",
			objectName: "Client Tag",
		}),
		{
			...clientTagActions,
			setSubheaderText,
			openGlobalDialog,
		}
	)(TagManager);
})();

const MatterTagManager = (() => {
	const tableUniqueID = "/manageMatterTags";
	return connect(
		(state) => ({
			tableUniqueID,
			tagsData: state.matter.availableMatterTags,
			subheaderText: "Manage Matter Tags",
			objectName: "Matter Tag",
		}),
		{
			...matterTagActions,
			setSubheaderText,
			openGlobalDialog,
		}
	)(TagManager);
})();

const MatterTypeManager = (() => {
	const tableUniqueID = "/manageMatterTypes";
	return connect(
		(state) => ({
			tableUniqueID,
			tagsData: state.matter.availableMatterTypes,
			subheaderText: "Manage Matter Types",
			objectName: "Matter Type",
		}),
		{
			...matterTypeActions,
			setSubheaderText,
			openGlobalDialog,
		}
	)(TagManager);
})();

const OfficeBranchManager = (() => {
	const tableUniqueID = "/manageOfficeBranch";
	return connect(
		(state) => ({
			tableUniqueID,
			tagsData: state.matter.availableOfficeBranches,
			subheaderText: "Manage Office/Branch List",
			objectName: "Office/Branch",
		}),
		{
			...officeBranchActions,
			setSubheaderText,
			openGlobalDialog,
		}
	)(TagManager);
})();

const DocumentTagManager = (() => {
	const tableUniqueID = "/manageDocumentTags";
	return connect(
		(state) => ({
			tableUniqueID,
			tagsData: state.document.availableDocumentTags,
			subheaderText: "Manage Document Tags",
			objectName: "Document Tag",
		}),
		{
			...documentTagActions,
			setSubheaderText,
			openGlobalDialog,
		}
	)(TagManager);
})();

const DocumentTypeManager = (() => {
	const tableUniqueID = "/manageDocumentTypes";
	return connect(
		(state) => ({
			tableUniqueID,
			tagsData: state.document.availableDocumentTypes,
			subheaderText: "Manage Document Types",
			objectName: "Document Type",
		}),
		{
			...documentTypeActions,
			setSubheaderText,
			openGlobalDialog,
		}
	)(TagManager);
})();

const DocumentStatusManager = (() => {
	const tableUniqueID = "/manageDocumentStatuses";
	return connect(
		(state) => ({
			tableUniqueID,
			tagsData: state.document.availableDocumentStatuses,
			subheaderText: "Manage Document Statuses",
			objectName: "Document Status",
		}),
		{
			...documentStatusActions,
			setSubheaderText,
			openGlobalDialog,
		}
	)(TagManager);
})();

export {
	ClientTagManager,
	MatterTagManager,
	MatterTypeManager,
	OfficeBranchManager,
	DocumentTagManager,
	DocumentTypeManager,
	DocumentStatusManager,
};
