import {} from "../actions/types";

const initialState = {
	doc1ID: null,
	doc2ID: null,
	doc1Filename: "",
	doc2Filename: "",
	doc1VersionID: "",
	doc2VersionID: "",
	type: "",
};

const documentCompareTypes = ["litera", "inline"];

export default function (state = initialState, action) {
	switch (action.type) {
		case "RESET_DOCUMENT_COMPARE":
			return {
				...state,
				doc1ID: null,
				doc2ID: null,
				doc1Filename: "",
				doc2Filename: "",
				doc1VersionID: "",
				doc2VersionID: "",
				type: "",
			};

		case "ADD_DOCUMENT_TO_COMPARE": {
			if (
				action?.payload?.type &&
				!documentCompareTypes.includes(action.payload.type)
			) {
				console.error(
					`Invalid document compare type: ${action.payload.type}`
				);
			}

			if (!state.doc1ID) {
				return {
					...state,
					doc1ID: action.payload.docID,
					doc1Filename: action.payload.filename,
					doc1VersionID: action.payload.versionID,
					type: action.payload.type,
				};
			} else if (!state.doc2ID) {
				return {
					...state,
					doc2ID: action.payload.docID,
					doc2Filename: action.payload.filename,
					doc2VersionID: action.payload.versionID,
				};
			} else {
				return state;
			}
		}
		default:
			return state;
	}
}
