import { combineReducers } from "redux";
import postReducer from "./postReducer";
import navigationReducer from "./navigationReducer";
import userReducer from "./userReducer";
import clientReducer from "./clientReducer";
import matterReducer from "./matterReducer";
import requestReducer from "./requestReducer";
import globalDialogReducer from "./globalDialogReducer";
import customColumnsReducer from "./customColumnsReducer";
import documentReducer from "./documentReducer";
import shareReducer from "./shareReducer";
import noteReducer from "./noteReducer";
import multiselectReducer from "./multiselectReducer";
import globalReducer from "./globalReducer";
import globalSnackbarReducer from "./globalSnackbarReducer";
import paginationReducer from "./paginationReducer";
import homepageReducer from "./homepageReducer";
import tenantReducer from "./tenantReducer";
import jobProgressReducer from "./jobProgressReducer";
import ocrJobProgressReducer from "./ocrJobProgressReducer";
import searchReducer from "./searchReducer";
import adminReducer from "./adminReducer";
import permissionsReducer from "./permissionsReducer";
import clioIntegrationReducer from "./clioIntegrationReducer";
import userSettingsReducer from "./userSettingsReducer";
import globalSearchbarReducer from "./globalSearchbarReducer";
import permissionFeaturesReducer from "./permissionFeaturesReducer";
import developerOverridesReducer from "./developerOverridesReducer";
import tableSortingReducer from "./tableSortingReducer";
import searchAuditReducer from "./searchAuditReducer";
import reactDialogReducer from "./reactDialogReducer";
import browserLocalstorageReducer from "./browserLocalstorageReducer";

import { connectRouter } from "connected-react-router";
import { USER_LOGGED_OUT } from "../actions/types";
import whatsNewReducer from "./whatsNewReducer";
import documentCompareReducer from "./documentCompareReducer";

export default (history) => {
	const rootReducer = (state, action) => {
		if (action.type === USER_LOGGED_OUT) {
			//reset all reducer states to initial state
			const clearStateOnLogout = {
				router: {
					action: "PUSH",
					location: {
						pathname: "/login",
					},
				},
				tableSorting: state.tableSorting,
			};
			return appReducer(clearStateOnLogout, action);
		}

		return appReducer(state, action);
	};
	// please try to keep this in alphabetical order
	const appReducer = combineReducers({
		admin: adminReducer,
		browserLocalstorage: browserLocalstorageReducer,
		client: clientReducer,
		clioIntegration: clioIntegrationReducer,
		currentPage: navigationReducer,
		customColumns: customColumnsReducer,
		developerOverrides: developerOverridesReducer,
		document: documentReducer,
		documentCompare: documentCompareReducer,
		global: globalReducer,
		globalDialog: globalDialogReducer,
		globalSearchbar: globalSearchbarReducer,
		homepage: homepageReducer,
		jobProgress: jobProgressReducer,
		matter: matterReducer,
		multiselect: multiselectReducer,
		note: noteReducer,
		ocrJobProgress: ocrJobProgressReducer,
		pagination: paginationReducer,
		permissions: permissionsReducer,
		permissionFeatures: permissionFeaturesReducer,
		posts: postReducer,
		reactDialog: reactDialogReducer,
		request: requestReducer,
		router: connectRouter(history),
		search: searchReducer,
		searchAudit: searchAuditReducer,
		share: shareReducer,
		snackbar: globalSnackbarReducer,
		tableSorting: tableSortingReducer,
		tenant: tenantReducer,
		user: userReducer,
		userSettings: userSettingsReducer,
		whatsNew: whatsNewReducer,
	});

	return rootReducer;
};
