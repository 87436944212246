import {} from "../actions/types";

//
const initialState = {};

export default function (state = initialState, action) {
	switch (action.type) {
		case "UPDATE_JOB_PROGRESS":
			console.log("job progress", action.payload);
			return {
				...state,
			};

		default:
			return state;
	}
}
