import React, { Component } from "react";
import { connect } from "react-redux";
import {
	fetchTenantDetail,
	rebuildSearchIndex,
	getS3BatchJobs,
	createAndRunBatchJobFromFailures,
	resendWelcomeEmail,
	setUserEnabledDisabled,
	createDatabaseUserRecord,
	deleteUser,
	setUserMFADisabled,
	unlockAllDocuments,
	adminSetEmailVerified,
} from "../actions/adminActions";

import CustomizableTable from "./Tables/CustomizableTable";
import InteractiveTableCell from "./Tables/InteractiveTableCell";
import { ButtonWithRouter, ButtonWithIcon } from "./ButtonWithIcon";
import { DropDownButtonWithRouter } from "./DropDownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { setSubheaderText } from "../actions/navigateActions";

import { openGlobalDialog } from "../actions/globalEditDialogActions";

const availablePills = {
	roles: {
		"Uptime Admin": { style: { backgroundColor: "var(--lw-dark-blue)" } },
		"Firm Admin": {
			style: { backgroundColor: "var(--lw-dark-grey)" },
		},
		"LexWorkplace Admin": {
			style: {
				color: "var(--lw-dark-grey)",
				backgroundColor: "var(--lw-gold)",
			},
			title: "User has access to LexWorkplace Admin page.  For Uptime colleagues only.",
		},
	},

	emailAddress: {
		EmailMismatch: {
			style: { color: "white", backgroundColor: "var(--lw-darker-red)" },
			title: `Email address in Cognito does not match address in database.  Run the "Create/Update LexWorkplace Database User Record" for this user.`,
			displayText: "Mismatch",
		},
		Unverified: {
			style: {
				color: "var(--lw-dark-grey)",
				backgroundColor: "var(--lw-gold)",
			},
			title: "User has not verified their email address.  They will not be able to log in via email address",
		},
	},
	username: {
		UsernameMismatch: {
			style: { color: "white", backgroundColor: "var(--lw-darker-red)" },
			title: `Username in Cognito does not match username in database.  Run the "Create/Update LexWorkplace Database User Record" for this user.  Also this shouldn't be possible...`,
			displayText: "Mismatch",
		},
	},
	displayName: {
		DisplayNameMismatch: {
			style: { color: "white", backgroundColor: "var(--lw-darker-red)" },
			title: `User display name in Cognito does not match display name in database.  Run the "Create/Update LexWorkplace Database User Record" for this user.`,
			displayText: "Mismatch",
		},
	},
	status: {
		"No Cognito User": {
			style: { color: "white", backgroundColor: "var(--lw-darker-red)" },
			title: `There is no Cognito user with this sub.`,
		},
		"No DB User": {
			style: { color: "white", backgroundColor: "var(--lw-darker-red)" },
			title: `There is no user in the database with this sub.`,
		},
		"Has Not Logged In": {
			style: {
				color: "var(--lw-dark-grey)",
				backgroundColor: "var(--lw-gold)",
			},
			title: `This user has not logged in for the first time yet`,
		},
	},
};

const TagPill = ({ value, availablePillsKey }) => {
	const pillsForThisKey = availablePills[availablePillsKey];
	if (pillsForThisKey) {
		const pillData = pillsForThisKey[value];
		if (pillData) {
			return (
				<div
					className="tagPill"
					key={value}
					title={pillData.title}
					style={pillData ? { ...pillData.style } : {}}
				>
					{pillData.displayText || value}
				</div>
			);
		}
	}

	return value || "";
};

const TextWithTagPills = (arr, availablePillsKey) => {
	if (arr && arr.length) {
		return arr.map((value, index) => (
			<TagPill
				key={index}
				value={value}
				availablePillsKey={availablePillsKey}
			/>
		));
	}
	return "";
};

class AdminUsersForTenant extends Component {
	constructor(props, context) {
		super(props, context);

		this.usersColumnConfig = [
			{
				Header: "User Name",
				accessor: "username",
				Cell: (row) => (
					<InteractiveTableCell
						menuItems={[
							{
								text: "Edit User",
								disabled:
									row.original.dbUserMissing ||
									row.original.cognitoUserMissing,
								href: "/admin/user/edit/" + row.original.sub,
							},
							{
								text: "Re-send confirmation email",
								disabled:
									row.original.cognitoStatus !==
									"FORCE_CHANGE_PASSWORD",
								onSelect: () =>
									this.props.resendWelcomeEmail(
										row.original.cognitoUsername
									),
							},
							{
								text: row.original.cognitoUserEnabled
									? "Disable User"
									: "Enable User",
								disabled:
									row.original.dbUserMissing ||
									row.original.cognitoUserMissing,
								onSelect: () =>
									row.original.cognitoUserEnabled
										? this.props.setUserEnabledDisabled(
												false,
												row.original.cognitoUsername,
												row.original.sub,
												this.props.refresh
											)
										: this.props.setUserEnabledDisabled(
												true,
												row.original.cognitoUsername,
												row.original.sub,
												this.props.refresh
											),
							},
							{
								text: "Create/Update LexWorkplace Database User Record",
								disabled: row.original.cognitoUserMissing,
								onSelect: () =>
									this.props.createDatabaseUserRecord(
										this.props.tenantID,
										row.original,
										this.props.refresh
									),
							},
							{
								/*
									no db user
									no cognito user
									only when disabled?
									only if not signed up?
								*/
								text: "Delete User",
								disabled: !(
									row.original.cognitoUserMissing ||
									row.original.cognitoStatus ===
										"FORCE_CHANGE_PASSWORD"
								),
								onSelect: () =>
									this.deleteUserIntent(
										row.original.cognitoUsername,
										row.original.sub,
										this.props.tenantID
									),
							},
							{
								text: "Disable MFA",
								disabled:
									row.original.dbUserMissing ||
									row.original.cognitoUserMissing,
								onSelect: () =>
									this.props.setUserMFADisabled(
										row.original.cognitoUsername,
										this.props.refresh
									),
							},
							{
								text: "Set Email as Verified",
								disabled: row.original.emailVerified,
								onSelect: () =>
									this.props.adminSetEmailVerified(
										row.original.cognitoUsername,
										this.props.refresh
									),
							},

							{
								text: row.original.lockedDocuments
									? `Unlock ${row.original.lockedDocuments} Documents`
									: "No Locked Documents",
								disabled: !row.original.lockedDocuments,
								onSelect: () =>
									this.props.unlockAllDocuments(
										row.original.sub,
										this.props.refresh
									),
							},
						]}
					>
						{TextWithTagPills(row.value, "username")}
					</InteractiveTableCell>
				),
			},
			{
				Header: "Display Name",
				accessor: "displayName",
				Cell: (row) => TextWithTagPills(row.value, "displayName"),
			},
			{
				Header: "Email",
				accessor: "emailAddress",
				Cell: (row) => TextWithTagPills(row.value, "emailAddress"),
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: (row) => TextWithTagPills(row.value, "status"),
			},
			{
				Header: "Roles",
				accessor: "roles",
				Cell: (row) => TextWithTagPills(row.value, "roles"),
			},
			{ Header: "Sub", accessor: "sub" },
			{ Header: "Created Date", accessor: "cognitoCreatedDate" },
		];
	}

	deleteUserIntent(username, sub, tenantID) {
		this.props.openGlobalDialog(
			{
				label: "Delete this user?",
				mainText: `This will complely delete both the Cognito and database user record for:"${username}".  If this is a testing/QA/internal user account, they will no longer be able to log in to any other lexworkplace environments (dev/newfeature/staging).  Please proceed with caution.  To delete the user, type "delete" in the text field.`,
				value: "",
				submitButtonLabel: "Delete",
			},
			() => {
				this.props.deleteUser(
					username,
					sub,
					tenantID,
					this.props.refresh
				);
			},
			(state) => {
				return {
					canSubmit: state.value === "delete",
					erroMessage: "",
				};
			}
		);
	}

	render() {
		return (
			<CustomizableTable
				actionButtons={
					<div
						style={{
							marginLeft: "auto",
							marginTop: 0,
						}}
						className="buttonGroup"
					>
						<ButtonWithIcon
							handler={this.props.refresh}
							buttonText="Refresh"
							iconName="sync"
						/>
						<ButtonWithRouter
							redirect={`/admin/tenant/${this.props.tenantID}/user/new`}
							buttonText="New User"
							iconName="user"
						/>
						<DropDownButtonWithRouter
							title="Actions"
							iconName="gavel"
							pullRight
							menuItems={[
								{
									text: "Edit Firm",
									href: `/admin/tenant/edit/${this.props.tenantID}`,
								},
							]}
						/>
					</div>
				}
				leftSideFilterSpace={
					<h4 style={{ paddingLeft: 12 }}>
						<FontAwesomeIcon
							icon="user"
							size="1x"
							style={{ marginRight: 10 }}
						/>
						{this.props.title}
					</h4>
				}
				className="fullWidth"
				loading={this.props.loading}
				data={this.props.users}
				columns={this.usersColumnConfig}
				noResultsText={"No Users"}
				autoResize
				tableUniqueID="/admin/usersForFirm"
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.request.loading,
});
export default connect(mapStateToProps, {
	fetchTenantDetail,
	setSubheaderText,
	getS3BatchJobs,
	createAndRunBatchJobFromFailures,
	rebuildSearchIndex,
	resendWelcomeEmail,
	setUserEnabledDisabled,
	createDatabaseUserRecord,
	openGlobalDialog,
	deleteUser,
	setUserMFADisabled,
	unlockAllDocuments,
	adminSetEmailVerified,
})(AdminUsersForTenant);
