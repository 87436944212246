import API from "../utilities/LocalApiProxy";
import store from "../store";
import { UPDATE_OCR_SNACKBAR_MESSAGE } from "./types";
import getOcrRequestFailureModal from "../components/OcrRequestFailureModal";

let stopStatusRequests = false;
let stopStatusRequestsTimeout;
const minutesUntilTimeout = 30;

export function requestOCR(documentID, filename) {
	return function (dispatch) {
		API.post("/ocr/onDemand", {
			body: {
				documentID: documentID,
			},
		}).then((res) => {
			if (stopStatusRequestsTimeout) {
				clearTimeout(stopStatusRequestsTimeout);
				stopStatusRequests = false;
			}
			// stop all requests to the /ocr/status endpoint after 30 minutes
			// the below commented code is useful if debugging is needed
			// let now = new Date();
			// let later = new Date(
			// 	new Date().setMinutes(now.getMinutes() + minutesUntilTimeout)
			// );
			stopStatusRequestsTimeout = setTimeout(
				() => {
					// useful for debugs logs if the above dates are uncommented
					// console.log("job started at", now.toJSON());
					// console.log("job should end at", later.toJSON());
					// console.log("going to end the requests", new Date().toJSON());
					stopStatusRequests = true;
				},
				1000 * 60 * minutesUntilTimeout
			);

			const {
				mysql: { documentID, transactionID },
			} = res;
			let currentOcrJobProgress = store.getState().ocrJobProgress;
			let pendingOcrTransactions = Object.keys(currentOcrJobProgress)
				.map((transactionID) => {
					return {
						transactionID,
						status: currentOcrJobProgress[transactionID].status,
					};
				})
				.filter(
					(record) =>
						record.status === "REQUESTED" ||
						record.status === "PENDING"
				);

			if (pendingOcrTransactions.length === 0) {
				dispatch({
					type: "OCR_JOB_PROGRESS_RESET",
				});
			}

			dispatch({
				type: "OCR_JOB_PROGRESS_ADD_TRANSACTION",
				payload: {
					transactionID: transactionID,
					documentID: documentID,
					filename: filename,
				},
			});
			beginOcrProgressCheck()(dispatch);
		});
	};
}

let ocrJobProgressTimeout;

function beginOcrProgressCheck() {
	let ocrJobProgress = store.getState().ocrJobProgress;
	let pendingTransactions = [];
	let failedTransactions = [];
	let successfullTransactions = [];
	let cancelledTransactions = [];
	Object.keys(ocrJobProgress).forEach((transactionID) => {
		let status = ocrJobProgress[transactionID].status;
		// possible transaction statuses
		// 'PENDING','SUCCESS','FAILED','REQUESTED','CANCELLED_OCR_DISABLED','CANCELLED_OVER_LIMIT','CANCELLED_NETWORK_ERR','CANCELLED_EB_EVENT_CREATE_ERROR'
		if (status === "SUCCESS") {
			successfullTransactions.push(transactionID);
		} else if (status === "PENDING" || status === "REQUESTED") {
			pendingTransactions.push(transactionID);
		} else if (status === "FAILED") {
			failedTransactions.push(transactionID);
		} else {
			cancelledTransactions.push(transactionID);
		}
	});
	let allTransactions = [
		...pendingTransactions,
		...failedTransactions,
		...successfullTransactions,
		...cancelledTransactions,
	];

	let allFailedOrCancelledTransactions = [
		...failedTransactions,
		...cancelledTransactions,
	];

	let allResolvedTransactions = [
		...failedTransactions,
		...cancelledTransactions,
		...successfullTransactions,
	];
	return function (dispatch) {
		dispatch({
			type: "OPEN_LOADING_SNACKBAR",
			payload: {
				message: `OCR in progress, ${
					allResolvedTransactions.length
				} of ${allTransactions.length} Done.${
					allFailedOrCancelledTransactions.length
						? ` ${allFailedOrCancelledTransactions.length} Failed.`
						: ""
				}`,
			},
		});
		if (ocrJobProgressTimeout) {
			clearTimeout(ocrJobProgressTimeout);
		}
		if (pendingTransactions.length) {
			getOcrProgress(pendingTransactions).then((res) => {
				let ocrTransactionArray = res.mysql;
				if (ocrTransactionArray.length) {
					if (ocrTransactionArray.length) {
						ocrTransactionArray.forEach((record) => {
							// each record will have a transactionID and a status
							if (
								ocrJobProgress[record.transactionID].status !==
								record.status
							) {
								dispatch({
									type: "OCR_JOB_PROGRESS_UPDATE_TRANSACTION",
									payload: {
										transactionID: record.transactionID,
										status: record.status,
									},
								});
							}
						});
					} else {
						console.error(
							"well I don't know, ocrTransactionArray=",
							ocrTransactionArray
						);
					}
					if (!stopStatusRequests) {
						ocrJobProgressTimeout = setTimeout(
							() => beginOcrProgressCheck()(dispatch),
							10000
						);
					} else {
						// after 18 minutes all transactions will be updated to failed so the user can request them again
						ocrTransactionArray.forEach((record) => {
							dispatch({
								type: "OCR_JOB_PROGRESS_UPDATE_TRANSACTION",
								payload: {
									transactionID: record.transactionID,
									status: "FAILED",
								},
							});
						});
						beginOcrProgressCheck()(dispatch);
					}
				}
			});
		} else if (
			pendingTransactions.length === 0 &&
			successfullTransactions.length === allTransactions.length
		) {
			// show success message
			dispatch({
				type: UPDATE_OCR_SNACKBAR_MESSAGE,
				payload: {
					message: `${successfullTransactions.length} OCR Request(s) Successfully completed.`,
					variant: "success",
				},
			});
		} else if (
			pendingTransactions.length === 0 &&
			allFailedOrCancelledTransactions.length === allTransactions.length
		) {
			// show error message
			dispatch({
				type: UPDATE_OCR_SNACKBAR_MESSAGE,
				payload: {
					message: getOcrRequestFailureModal(),
					variant: "error",
				},
			});
		} else if (
			pendingTransactions.length === 0 &&
			allResolvedTransactions.length === allTransactions.length
		) {
			// show warning message
			dispatch({
				type: UPDATE_OCR_SNACKBAR_MESSAGE,
				payload: {
					message: getOcrRequestFailureModal(),
					variant: "warning",
				},
			});
		}
	};
}

function getOcrProgress(transactionIdArray) {
	return API.post("/ocr/status", { body: { transactionIdArray } });
}
