import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FavoriteStar = ({ isFavorite, style }) => {
	return (
		<div
			className={isFavorite ? "IsFavorite" : "IsNotFavorite"}
			style={{
				position: "relative",
				width: "1em",
				height: "1em",
				...style,
			}}
		>
			<FontAwesomeIcon
				style={{ position: "absolute", left: 0 }}
				className={
					isFavorite
						? "documentPreviewFavoriteStar"
						: "documentPreviewNotFavoriteStar"
				}
				icon={isFavorite ? "star" : ["far", "star"]}
			/>
			<FontAwesomeIcon
				style={{ position: "absolute", left: 0 }}
				className={"documentPreviewNotFavoriteStar"}
				icon={["far", "star"]}
			/>
		</div>
	);
};

export default FavoriteStar;
