import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createBrowserHistory } from "history";

// this is used for the mailparser library,
// webpack doesnt know what to do with
// the dependency's call to setImmediate() otherwise
require("setimmediate");

const history = createBrowserHistory();

// eslint-disable-next-line
ReactDOM.render(<App history={history} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
