import React from "react";

import {
	checkIfEmailExistsInCognito,
	checkIfUsernameExistsInCognito,
} from "../actions/adminActions";

import { LabeledTextInputWithValidation } from "./TextFieldWithValidation";

const validateEmail = (email) => {
	//source: https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
	return String(email)
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
};

const EditUserEmailWithValidation = (props) => {
	return (
		<LabeledTextInputWithValidation
			{...props}
			autocomplete="one-time-code" //apparently the best way to disable chrome auto-complete
			syncValidationError={(newValue) => {
				const emailConforms = validateEmail(newValue);
				if (!emailConforms) {
					return "Email not valid";
				}
				return false;
			}}
			asyncValidationError={(newValue) => {
				return checkIfEmailExistsInCognito(newValue).then((res) => {
					const { emailInUse } = res.mysql;
					if (emailInUse) {
						if (newValue === props.defaultValue) {
							//we dont want to show a warning if the user enters their existing email
							return false;
						}
						return "Email Address not available";
					}
					//returning false means no error
					return false;
				});
			}}
		/>
	);
};
const EditUsernameWithValidation = ({ finalUsernameFn, ...props }) => {
	return (
		<LabeledTextInputWithValidation
			{...props}
			autocomplete="one-time-code" //apparently the best way to disable chrome auto-complete
			syncValidationError={(newValue) => {
				const usernameConforms = /[^\s-]/.test(newValue);
				if (!usernameConforms) {
					return "Username not valid";
				}
				return false;
			}}
			asyncValidationError={(newValue) => {
				return checkIfUsernameExistsInCognito(newValue).then((res) => {
					const { usernameInUse } = res.mysql;
					if (usernameInUse) {
						return "Username not available";
					}
					//returning false means no error
					return false;
				});
			}}
		/>
	);
};
const EditDisplayNameWithValidation = (props) => {
	return (
		<LabeledTextInputWithValidation
			{...props}
			autocomplete="one-time-code" //apparently the best way to disable chrome auto-complete
			syncValidationError={(newValue) => {
				const displayNameConforms = /[^\s-]/.test(newValue);
				if (!displayNameConforms) {
					return "Display Name not valid";
				}
				return false;
			}}
		/>
	);
};

export {
	EditUserEmailWithValidation,
	EditUsernameWithValidation,
	EditDisplayNameWithValidation,
};
