import React, { Component } from "react";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import { setSubheaderText } from "../actions/navigateActions";

const LinkRow = ({ href, text }) => {
	return (
		<div style={{ marginBottom: 10, marginLeft: 0 }}>
			<Link style={{ fontSize: "1.1em" }} to={href}>
				{text}
			</Link>
		</div>
	);
};
class SuperAdmin extends Component {
	componentDidMount() {
		this.props.setSubheaderText("Super Admin Page");
	}

	render() {
		return (
			<div style={{ paddingTop: 20, paddingLeft: 15 }}>
				<div>
					<h3 style={{ fontSize: 20 }}>{`What's New Dropdown`}</h3>
					<LinkRow
						href="superAdmin/editWhatsNewDropdown"
						text="Edit What's New Dropdown"
					/>
				</div>
			</div>
		);
	}
}

export default connect(null, { setSubheaderText })(SuperAdmin);
