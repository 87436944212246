import React, { Component } from "react";
import { connect } from "react-redux";
import { setSubheaderText } from "../actions/navigateActions";
import {
	getIntegrationSettings,
	setIntegrationSettings,
} from "../actions/tenantActions";
import AsyncSwitch from "./AsyncSwitch";
import { getDisabledFeatureCodeMessage } from "../utilities/utilities";

class IntegrationSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			canSubmit: false,
			integrationSettingsLoading: true,
		};
		this.getDisabledHoverText = this.getDisabledHoverText.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (
			state.integrationSettingsLoading &&
			!props.integrationSettingsLoading
		) {
			const newState = {
				integrationSettingsLoading: false,
			};
			return newState;
		} else {
			return null;
		}
	}

	componentDidMount() {
		this._isMounted = true;
		this.props.setSubheaderText("Manage Integrations");
		this.props.getIntegrationSettings();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	getDisabledHoverText = (featureCode) => {
		if (featureCode === "literaDocumentCompareEnabled") {
			return getDisabledFeatureCodeMessage(featureCode);
		}
		return null;
	};

	render() {
		return (
			<div style={{ width: 600, paddingTop: 20, paddingLeft: 15 }}>
				{this.state.integrationSettingsLoading ? null : (
					<div>
						{this.props.canEditIntegrationSettings ? (
							<></>
						) : (
							<span>
								Only Firm Administrators can edit these settings
							</span>
						)}
						<AsyncSwitch
							label={"Clio Manage"}
							switchValue={
								this.props.clioIntegrationSettingStatus
							}
							disabled={!this.props.canEditIntegrationSettings}
							updateSetting={(newValue) => {
								this.props.setIntegrationSettings({
									featureCode: "clioIntegration",
									featureValue: newValue,
								});
							}}
							updateRequestCount={
								this.props.clioIntegrationRequestCount
							}
						/>
						<AsyncSwitch
							label={"Litera Compare Desktop"}
							switchValue={
								this.props
									.literaDocumentCompareVisibleSettingStatus
							}
							disabled={
								!this.props.canEditIntegrationSettings ||
								!this.props.literaDocumentCompareEnabled
							}
							disabledHoverText={
								!this.props.canEditIntegrationSettings ||
								!this.props.literaDocumentCompareEnabled
									? this.getDisabledHoverText(
											"literaDocumentCompareEnabled"
										)
									: ""
							}
							updateSetting={(newValue) => {
								this.props.setIntegrationSettings({
									featureCode: "literaDocumentCompareVisible",
									featureValue: newValue,
								});
							}}
							updateRequestCount={
								this.props
									.literaDocumentCompareVisibleRequestCount
							}
						/>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	integrationSettingsLoading: state.tenant.integrationSettingsLoading,
	canEditIntegrationSettings: state.user.canEditIntegrationSettings,
	clioIntegrationSettingStatus: state.tenant.tenantFeatures.clioIntegration,
	clioIntegrationRequestCount:
		state.tenant.tenantFeaturesRequestCounts.clioIntegrationRequestCount,
	literaDocumentCompareVisibleSettingStatus:
		state.tenant.tenantFeatures.literaDocumentCompareVisible,
	literaDocumentCompareVisibleRequestCount:
		state.tenant.tenantFeaturesRequestCounts
			.literaDocumentCompareVisibleRequestCount,
	literaDocumentCompareEnabled:
		state.tenant.tenantFeatures.literaDocumentCompareEnabled,
});
export default connect(mapStateToProps, {
	getIntegrationSettings,
	setIntegrationSettings,
	setSubheaderText,
})(IntegrationSettings);
