import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import "./EmptyPagination.css";
import {
	nextPage,
	previousPage,
	setComponent,
} from "../actions/globalPaginationActions";

class Pagination extends React.Component {
	constructor(props) {
		super();

		this.changePage = this.changePage.bind(this);

		this.state = {
			visiblePages: this.getVisiblePages(null, props.pages),
		};
	}

	static propTypes = {
		pages: PropTypes.number,
		page: PropTypes.number,
		PageButtonComponent: PropTypes.any,
		onPageChange: PropTypes.func,
		previousText: PropTypes.string,
		nextText: PropTypes.string,
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.pages !== nextProps.pages) {
			this.setState({
				visiblePages: this.getVisiblePages(null, nextProps.pages),
			});
		}

		this.changePage(nextProps.page + 1);
	}
	componentDidUpdate(prevProps) {
		if (
			this.props.data.length !== prevProps.data.length ||
			(this.props.data.length === 0 && prevProps.data.length === 0)
		) {
			this.props.setComponent(
				this.changePage.bind(this),
				this.props.data.length,
				this.props.pageSize
			);
		}
	}
	filterPages = (visiblePages, totalPages) => {
		return visiblePages.filter((page) => page <= totalPages);
	};

	getVisiblePages = (page, total) => {
		if (total < 7) {
			return this.filterPages([1, 2, 3, 4, 5, 6], total);
		} else {
			if (page % 5 >= 0 && page > 4 && page + 2 < total) {
				return [1, page - 1, page, page + 1, total];
			} else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
				return [1, total - 3, total - 2, total - 1, total];
			} else {
				return [1, 2, 3, 4, 5, total];
			}
		}
	};

	changePage(page) {
		const activePage = this.props.page + 1;

		if (page === activePage) {
			return;
		}

		const visiblePages = this.getVisiblePages(page, this.props.pages);

		this.setState({
			visiblePages: this.filterPages(visiblePages, this.props.pages),
		});

		this.props.onPageChange(page - 1);

		document.querySelector(".rt-tbody").scrollTop = 0;
	}

	render() {
		return null;
	}
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {
	nextPage,
	previousPage,
	setComponent,
})(Pagination);
