import React, { Component } from "react";
import { connect } from "react-redux";

import TextDisplayWithCopyToClipboard from "./TextDisplayWithCopyToClipboard";
import { fetchTenantDetail, createUser } from "../actions/adminActions";

import {
	EditUserEmailWithValidation,
	EditUsernameWithValidation,
	EditDisplayNameWithValidation,
} from "./EditUserAttributesWithValidation";

import { LabeledDropDownSelect } from "./DropDownSelect";

import TwoColumnWrapper from "./TwoColumnWrapper";

const assumedDomainForUptimeAdminUser = "lexworkplace.com";

const adminStatusOptions = [
	{ value: "REGULAR_USER", label: "Regular User" },
	{
		value: "FIRM_ADMIN",
		label: "Firm Admin",
		description: `User will be added to the "Firm Admins" permission group, will have access to all matters, and will be able to edit matter and group permissions`,
	},
	{
		value: "UPTIME_ADMIN",
		label: "Uptime Admin",
		description: `Internal Uptime user to be used for onboarding or troubleshooting.  User will have access to all matters, and will be able to edit matter and group permissions`,
	},
];

class NewUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tenant: {},
			users: [],
			cognito: [],
			emailAddress: "",
			usernameOverride: "",
			displayName: "",
			finalUsername: "",
			adminStatus: adminStatusOptions[0],
			loading: true,
			emailIsValid: false,
			usernameIsValid: false,
			displayNameIsValid: false,
		};
		this.handleChange = this.handleChange.bind(this);
		this.finalUsername = this.finalUsername.bind(this);
	}

	componentDidMount() {
		fetchTenantDetail(this.props.match.params.tenantID).then((res) => {
			this.setState({
				loading: false,
				tenant: res.mysql.tenant,
				users: res.mysql.users,
				cognito: res.mysql.cognito,
			});
		});
	}

	handleChange(key, value) {
		this.setState({ [key]: value });
	}
	finalUsername() {
		var baseUsername;
		if (this.state.usernameOverride) {
			baseUsername = this.state.usernameOverride
				? this.state.usernameOverride
				: "";
		} else {
			baseUsername = this.state.displayName ? this.state.displayName : "";
		}
		return `${this.state.tenant.usernamePrefix}.${baseUsername
			.replaceAll(" ", ".")
			.toLowerCase()
			.replace(/[^a-z0-9\-.]/gi, "")}`;
	}
	submit() {
		this.props.createUser(
			this.props.match.params.tenantID,
			this.finalUsername(),
			this.state.displayName,
			this.state.emailAddress,
			this.state.adminStatus.value
		);

		setTimeout(() => {
			this.props.history.push(
				`/admin/tenant/${this.props.match.params.tenantID}/users`
			);
		}, 1000);
	}
	render() {
		if (this.state.loading) {
			return (
				<div>
					<h1>
						Loading<span className="Loading">...</span>
					</h1>
				</div>
			);
		} else {
			return (
				<TwoColumnWrapper
					headingText="User Information"
					iconName="user"
					submit={this.submit.bind(this)}
					canSubmit={
						this.state.username !== "" &&
						this.state.emailAddress !== "" &&
						this.state.emailIsValid &&
						this.state.usernameIsValid
					}
					submitButtonText={"Create User"}
					leftChildren={
						<div>
							<EditDisplayNameWithValidation
								label="Display Name"
								name="displayName"
								placeholder="Firstname Lastname"
								fieldIsValidAndReadyChanged={(
									displayNameIsValid
								) => {
									this.setState({
										displayNameIsValid,
									});
								}}
								onChange={this.handleChange}
							/>
							<EditUserEmailWithValidation
								label="Email Address"
								name="emailAddress"
								placeholder="test@example.com"
								onChange={this.handleChange}
								defaultValue={""}
								fieldIsValidAndReadyChanged={(emailIsValid) => {
									this.setState({
										emailIsValid,
									});
								}}
							/>
							<div>
								<LabeledDropDownSelect
									label="Admin Status"
									options={adminStatusOptions}
									defaultValue={adminStatusOptions[0]}
									name="adminStatus"
									handler={this.handleChange}
								/>

								<div style={{ minHeight: 120 }}>
									<div
										style={{
											color: "var(--lw-darker-red)",
										}}
									>
										{this.state.emailAddress
											.toLowerCase()
											.endsWith(
												assumedDomainForUptimeAdminUser
											)
											? this.state.adminStatus.value !==
												"UPTIME_ADMIN"
												? `Should this user be an Uptime Admin?  The email address is a "${assumedDomainForUptimeAdminUser}" domain`
												: ""
											: this.state.adminStatus.value ===
												  "UPTIME_ADMIN"
												? `Should this user be an Uptime Admin?  The email address is NOT a "${assumedDomainForUptimeAdminUser}" domain`
												: ""}
									</div>
									<div>
										{this.state.adminStatus.description ||
											""}
									</div>
								</div>
							</div>
						</div>
					}
					rightChildren={
						<div>
							<EditUsernameWithValidation
								label="Username Override"
								name="usernameOverride"
								placeholder="firstname.lastname"
								onChange={this.handleChange}
								validationValueOverride={this.finalUsername()}
								fieldIsValidAndReadyChanged={(
									usernameIsValid
								) => {
									this.setState({
										usernameIsValid,
									});
								}}
							>
								<div>
									<div style={{ height: 40 }} />
									<TextDisplayWithCopyToClipboard
										label="Final Username:"
										value={this.finalUsername()}
									/>
								</div>
							</EditUsernameWithValidation>
						</div>
					}
				/>
			);
		}
	}
}
const mapStateToProps = () => {
	return {};
};

export default connect(mapStateToProps, {
	createUser,
})(NewUser);
