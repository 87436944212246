import React from "react";
import {
	DialogContent,
	DialogTitle,
	Dialog,
	TextField,
} from "@material-ui/core";
import { ButtonWithIcon } from "../ButtonWithIcon";

export default function EditItemDialog({
	editItemDialogOpen,
	closeEditItemDialog,
	title,
	editItemText,
	editItemUrl,
	handleEditItemChange,
	submitEditItemDialog,
}) {
	return (
		<Dialog maxWidth="sm" fullWidth open={editItemDialogOpen}>
			<DialogTitle>
				<div
					style={{
						display: "flex",
						gap: 10,
						alignItems: "center",
					}}
				>
					<span>{title}</span>
				</div>
			</DialogTitle>
			<DialogContent>
				<div style={{ marginBottom: 10 }}>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: 20,
							marginBottom: 20,
						}}
					>
						<TextField
							label="Item Text"
							placeholder="Enter Item Text"
							name="editItemText"
							value={editItemText}
							variant="outlined"
							onChange={(e) =>
								handleEditItemChange(e, "editItemText")
							}
						/>
						<TextField
							label="Item URL"
							placeholder="Enter Item URL"
							name="editItemUrl"
							value={editItemUrl}
							variant="outlined"
							onChange={(e) =>
								handleEditItemChange(e, "editItemUrl")
							}
						/>
					</div>
					<div style={{ display: "flex", gap: 10 }}>
						<ButtonWithIcon
							handler={closeEditItemDialog}
							buttonText="Cancel"
							iconName="times"
						/>

						<ButtonWithIcon
							className="blue"
							handler={submitEditItemDialog}
							buttonText="Save"
							iconName="check"
						/>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
}
