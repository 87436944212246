import {
	get as amplifyGet,
	put as amplifyPut,
	post as amplifyPost,
} from "aws-amplify/api";

import { fetchAuthSession } from "aws-amplify/auth";

import { handleLexworkplaceAPIError } from "../actions/errorActions";

// legalworks.frontend.config is automatically generated by the "setConfig <variant>" npm-script in the root of the project.
import legalworks_config from "../config/legalworks.frontend.config";

let API = {};

/*
	Localstack allows locally running an instance of API Gateway,
	but it seems that full functionality for HTTP and HTTP proxy is not
	yet supported.  For this reason, all HTTP requests are turned into POST requests,
	with the queryParams, path, and headers sent via request body
	in the post request.  A wrapper at the backend server level should unpack this and send
	the parameters as appropriate to rest of the server.
*/
const useProxy =
	legalworks_config.useProxy && process.env.REACT_APP_ENVIRONMENT === "local";
const gatewayName = useProxy ? "localstack" : legalworks_config.apiName;
console.log("using proxy:", { useProxy, gatewayName });

const amplifyGetPromise = async ({ apiName, path, options }) => {
	const restOperation = amplifyGet({ apiName, path, options });

	const { body } = await restOperation.response;
	return await body.json();
};
const amplifyPutPromise = async ({ apiName, path, options }) => {
	const restOperation = amplifyPut({ apiName, path, options });

	const { body } = await restOperation.response;
	return await body.json();
};
const amplifyPostPromise = async ({ apiName, path, options }) => {
	const restOperation = amplifyPost({ apiName, path, options });

	const { body } = await restOperation.response;
	return await body.json();
};

const getCurrentlyConfiguredDeveloperOverrides = () => {
	if (window && window.localStorage) {
		const devOverridesString =
			window.localStorage.getItem("developerOverrides");
		if (devOverridesString) {
			return JSON.parse(devOverridesString);
		}
	}
	return false;
};

const mixinDevOverrides = (httpArgs) => {
	const httpArgsWithDevOverrides = httpArgs || {};
	const developerOverrides = getCurrentlyConfiguredDeveloperOverrides();
	if (developerOverrides) {
		httpArgsWithDevOverrides.body = {
			...httpArgsWithDevOverrides.body,
			developerOverrides,
		};
	}
	return httpArgsWithDevOverrides;
};

const deepEncode = (input) => {
	if (typeof input === "object" && Array.isArray(input)) {
		//array
		return input.map(deepEncode);
	} else if (typeof input === "object" && input !== null) {
		//object
		var out = {};
		for (const property in input) {
			var val = input[property];
			out[property] = deepEncode(val);
		}
		return out;
	} else if (typeof input === "string" || input instanceof String) {
		//string
		return encodeURIComponent(input);
	} else {
		//neither array, object or string type
		return input;
	}
};

window.deepEncode = deepEncode;

const makeLocalProxy = async (method, path, httpArgs) => {
	var newArgs = { ...httpArgs };

	var mixinPart = {
		originalPath: path,
		originalMethod: method.toUpperCase(),
		originalQueryString: httpArgs && deepEncode(httpArgs.queryParams),
		originalHeaders: httpArgs && httpArgs.headers,
	};
	var body = httpArgs && httpArgs.body ? httpArgs.body : {};

	if (httpArgs && httpArgs.body) {
		// localstack v1 crashes if non-latin characters are passed in querystring
		// or request body.  This will url encode everything recursively.  The
		// locally hosted backend (localApi.js) will decode querystring and body
		body = deepEncode(body);
	}

	newArgs.body = body;
	newArgs.body.localApiBodyMixin = mixinPart;

	if (endpointIsPublic(path)) {
		return amplifyPostPromise({
			apiName: getApiNameForEndpoint(path),
			path,
			options: newArgs,
		});
	} else {
		return fetchAuthSession().then((res) => {
			//localstack v1 doesnt pass along the authorization header to the proxy
			newArgs.body.localApiBodyMixin.originalAuth =
				res.tokens.idToken.toString();

			return amplifyPostPromise({
				apiName: getApiNameForEndpoint(path),
				path,
				options: newArgs,
			});
		});
	}
};
const endpointIsPublic = (path) => path.split("/")[1] === "public";

const getApiNameForEndpoint = (path) => {
	return `${gatewayName}-${endpointIsPublic(path) ? "public" : "authorized"}`;
};

const checkForLexworkplaceError = (errorHandlingArgs) => (errorFromRequest) => {
	let responseBody;
	try {
		responseBody = JSON.parse(errorFromRequest.response.body);
	} catch (jsonParseError) {
		console.error("error in parsing JSON response.body of error");
		console.error(jsonParseError);
	}
	if (responseBody && responseBody.info && responseBody.info.error) {
		//its a defined LexWorkplace error with a code, defined in lexworkplace-constants.json

		handleLexworkplaceAPIError(
			responseBody.info.error,
			responseBody.info.errorData,
			errorHandlingArgs
		);
		return Promise.reject({
			lxwErrorCode: responseBody.info.error.code,
			lxwError: responseBody.info.error,
			lxwErrorData: responseBody.info.errorData,
		});
	} else {
		console.error(
			"Server returned an error without a LexWorkplace error code",
			responseBody
		);
	}
	return Promise.reject(responseBody);
};

/*
	http method functions that ultimately all send a POST request
	to the locally running Localstack server.  At the time of development,
	the version of Localstack only supports POST requests for proxy integrations
*/
const APIProxy = {
	get: ({ apiName, path, options }) => makeLocalProxy("get", path, options),
	put: ({ apiName, path, options }) => makeLocalProxy("put", path, options),
	post: ({ apiName, path, options }) => makeLocalProxy("post", path, options),
};

const sanitizeQueryParams = (queryParams) => {
	/*
		As of Amplify v6, keys with an undefined or null value
		will be serialized in the query string as (for example):

		https://api.example.com/?key1=undefined

		or

		https://api.example.com/?key2=null

		which ends up getting to and having the server parse these as strings.

		This function filters out any keys with undefined or null values
	*/
	if (!queryParams) {
		return {};
	}
	return Object.entries(queryParams).reduce(
		(queryParamsWithoutNullOrUndefined, [key, value]) => {
			if (value !== undefined && value !== null) {
				queryParamsWithoutNullOrUndefined[key] = value;
			}
			return queryParamsWithoutNullOrUndefined;
		},
		{}
	);
};

/*
	Helper function that figures out the REST API name,
	and mixes in the authorization header to the request options
*/
const lxwAPIRequest = async ({
	httpRequestFunction,
	path,
	httpArgs,
	errorHandlingArgs,
}) => {
	if (endpointIsPublic(path)) {
		const operation = httpRequestFunction({
			apiName: getApiNameForEndpoint(path),
			path,
			options: {
				...httpArgs,
				queryParams: sanitizeQueryParams(httpArgs?.queryParams),
			},
		});

		return operation.catch((err) => {
			return checkForLexworkplaceError(errorHandlingArgs)(err);
		});
	} else {
		const authToken = (
			await fetchAuthSession()
		).tokens?.idToken?.toString();

		if (authToken) {
			const operation = httpRequestFunction({
				apiName: getApiNameForEndpoint(path),
				path,
				options: {
					...httpArgs,
					queryParams: sanitizeQueryParams(httpArgs?.queryParams),
					headers: {
						Authorization: authToken,
					},
				},
			});

			return operation.catch((err) => {
				return checkForLexworkplaceError(errorHandlingArgs)(err);
			});
		} else {
			//It is a request to an authenticated endpoint, but the user is not authenticated for some reason
			console.error(
				"caught user in unauthenticated state prior to attempting to make a request to the LexWorkplace API, sending them to login screen"
			);

			// note that changing window.location.pathname will cause the page to reload, which
			// means the above console.error will barely be visible
			window.location.pathname = "/login";
		}
	}
};

API.get = async (path, httpArgs, errorHandlingArgs) => {
	if (httpArgs && httpArgs.body) {
		console.warn(
			"warning: attempting to pass requestBody in a GET operation",
			path,
			httpArgs
		);
	}
	return lxwAPIRequest({
		httpRequestFunction: useProxy ? APIProxy.get : amplifyGetPromise,
		path,
		httpArgs,
		errorHandlingArgs,
	});
};
API.put = async (path, httpArgs, errorHandlingArgs) => {
	return lxwAPIRequest({
		httpRequestFunction: useProxy ? APIProxy.put : amplifyPutPromise,
		path,
		httpArgs,
		errorHandlingArgs,
	});
};
API.post = async (path, httpArgs, errorHandlingArgs) => {
	var httpArgsWithDevOverrides = mixinDevOverrides(httpArgs);
	return lxwAPIRequest({
		httpRequestFunction: useProxy ? APIProxy.post : amplifyPostPromise,
		path,
		httpArgs: httpArgsWithDevOverrides,
		errorHandlingArgs,
	});
};

window.APIProxy = APIProxy;
window.API = API;
window.lxw = {};

window.lxw.get = (uri, queryParams) =>
	API.get(uri, { queryParams })
		.then((res) => console.log(res.mysql))
		.catch((err) => console.log(err));
window.lxw.post = (uri, body) =>
	API.post(uri, { body })
		.then((res) => console.log(res.mysql))
		.catch((err) => console.log(err));

export default API;
