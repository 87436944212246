import MsgReader from "@kenjiuno/msgreader";
import { simpleParser } from "mailparser";
import CryptoJS, { MD5 } from "crypto-js";

window.MD5 = MD5;
window.CryptoJS = CryptoJS;

export async function getEmailData(files, progressCallback) {
	var emailList = [];
	var parsed, spl;

	for (var i = 0; i < files.length; i++) {
		var file = files[i];
		spl = file.name.split(".");
		const emailFileExtensionLowerCase = spl[spl.length - 1].toLowerCase();

		progressCallback &&
			progressCallback({
				counter: i + 1,
				total: files.length,
				filename: file.name,
			});

		if (emailFileExtensionLowerCase === "eml") {
			parsed = await parseEml(file);
			let nextEmail = handleEmlData(parsed, file);
			emailList.push(nextEmail);
		} else if (emailFileExtensionLowerCase === "msg") {
			parsed = await parseMsg(file);
			let nextEmail = handleMsgData(parsed, file);

			emailList.push(nextEmail);
		}
	}
	return emailList;
}

function parseEml(source) {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();

		reader.onload = function () {
			var contents = reader.result;
			var checksum = MD5(contents);
			var md5checksum = checksum.toString(CryptoJS.enc.Hex);
			simpleParser(contents, {}, (err, parsed) => {
				if (err) {
					reject(err);
				} else {
					resolve({ ...parsed, md5checksum });
				}
			});
		};
		reader.onerror = function () {
			reader.abort();
		};
		reader.onabort = function () {
			reject("file read aborted");
		};
		reader.readAsText(source);
	});
}

function handleEmlData(parsed, file) {
	let myDate = parsed.date ? parsed.date : new Date();
	let myEmailData = {
		date: myDate.toISOString().slice(0, 19).replace("T", " "),
		subjectline: parsed.subject ? parsed.subject : "(No Subject)",
		originalFilename: file.name,
		emailFrom: parsed.from ? toFromMap(parsed.from) : "",
		emailTo: parsed.to ? toFromMap(parsed.to) : "",
		filesize: file.size,
		numAttachments: parsed.attachments.length,
		md5checksum: parsed.md5checksum,
		dateObj: myDate,
	};
	return myEmailData;
}

function toFromMap(obj) {
	var valueArr = obj.value;
	if (valueArr && Array.isArray(valueArr)) {
		return valueArr.map((val) => val.name || val.address).join(", ");
	} else {
		return "";
	}
}

function parseMsg(source) {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();

		reader.onload = function () {
			var contents = reader.result;

			try {
				let parsedMsg = new MsgReader(contents);
				let parsedMsgInfo = parsedMsg.getFileData();
				// MD5 doesn't work with the array buffer from contents
				// headers has a lot of good info for an MD5 hash though
				var checksum = MD5(parsedMsgInfo.headers);
				var md5checksum = checksum.toString(CryptoJS.enc.Hex);
				resolve({ ...parsedMsgInfo, md5checksum });
			} catch (err) {
				reject(err);
			}
		};
		reader.onerror = function () {
			reader.abort();
		};
		reader.onabort = function () {
			reject("file read aborted");
		};
		reader.readAsArrayBuffer(source);
	});
}

function handleMsgData(parsed, file) {
	let myDate = parsed.clientSubmitTime
		? new Date(parsed.clientSubmitTime)
		: new Date();

	return {
		date: myDate.toISOString().slice(0, 19).replace("T", " "),
		subjectline: parsed.subject ? parsed.subject : "(No Subject)",
		originalFilename: file.name,
		emailFrom: parsed.senderName || parsed.senderSmtpAddress || " ",
		emailTo: parsed.recipients ? msgToMap(parsed.recipients) : "",
		filesize: file.size,
		numAttachments: msgGetNumAttached(parsed.attachments),
		md5checksum: parsed.md5checksum,
		dateObj: myDate,
	};
}

function msgToMap(data) {
	if (data && Array.isArray(data)) {
		return data.map((val) => val.name || val.smtpAddress).join(", ");
	} else {
		return "";
	}
}

function msgGetNumAttached(attachmentsArray) {
	let notHiddenAttachments = attachmentsArray.filter(
		(a) => !a.attachmentHidden
	);
	return notHiddenAttachments.length;
}
