import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	getUsersAndGroupsForPermissionFeature,
	updatePermissionFeature,
	unmountEditPermissionFeature,
} from "../actions/permissionFeaturesActions";
import { setSubheaderText } from "../actions/navigateActions";
import { ButtonWithIcon, ButtonWithRouter } from "./ButtonWithIcon";
import { LabeledDropDownSelect } from "./DropDownSelect";
import UsersDropDownSelect from "./UsersDropDownSelect";
import {
	allowListDropdownMap,
	findAdminGroups,
	groupListDropdownMap,
	makeDictOfDisplayNamesBySub,
	makeDictOfGroupNamesByID,
	permissionFeatureGlobalSettingLookup,
	permissionFeatureGlobalSettingOptions,
} from "../utilities/utilities";

const labelMap = {
	CREATE_CLIENT_ALLOWED: "who can create clients and matters",
};

class EditPermissionFeature extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,

			userAllowList: [],
			groupAllowList: [],
			permissionFeatureGlobalSetting:
				permissionFeatureGlobalSettingOptions[
					permissionFeatureGlobalSettingLookup[
						props.permissionFeatureGlobalSetting
					]
				],
			featureCode: props.match.params.featureCode,
			groupIDMap: {},
			subMap: {},
		};
		this.handleChange = this.handleChange.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const subMap = makeDictOfDisplayNamesBySub(props.allUsers);
			const groupIDMap = makeDictOfGroupNamesByID(props.allGroups);
			const newState = {
				availableGroups: props.allGroups.map((p) => ({
					value: p.id,
					label: p.name,
					isFixed: p.type === "FIRM_ADMIN",
				})),
				availableUsers: props.allUsers.map((user) => ({
					...user,
					value: user.sub,
					label: user.displayName,
				})),
				groupAllowList: props.allowedGroups.map((g) =>
					groupListDropdownMap(g, groupIDMap)
				),
				userAllowList: props.allowedUsers.map((u) =>
					allowListDropdownMap(u, subMap)
				),
				permissionFeatureGlobalSetting:
					props.allowedUsers.length || props.allowedGroups.length
						? permissionFeatureGlobalSettingOptions[
								permissionFeatureGlobalSettingLookup[
									"specifiedUsers"
								]
							]
						: permissionFeatureGlobalSettingOptions[
								permissionFeatureGlobalSettingLookup["allUsers"]
							],
				loading: false,
				subMap,
				groupIDMap,
			};
			return newState;
		} else {
			return null;
		}
	}
	componentDidMount() {
		if (this.state.featureCode === "CREATE_CLIENT_ALLOWED") {
			// currently CREATE_CLIENT_ALLOWED and CREATE_MATTER_ALLOWED are tied together
			this.props.setSubheaderText("Limit Client/Matter Creation");
		} else {
			this.props.setSubheaderText(this.state.featureCode);
		}
		this.props.getUsersAndGroupsForPermissionFeature(
			this.state.featureCode
		);
	}

	componentWillUnmount() {
		this.props.unmountEditPermissionFeature();
	}

	handleChange(key, value) {
		this.setState({ [key]: value }, () => {
			const setToAllUsers =
				this.state.permissionFeatureGlobalSetting.value === "allUsers";

			this.setState({
				permissionFeatureGlobalSetting:
					this.state.permissionFeatureGlobalSetting,
				userAllowList:
					this.state.userAllowList && !setToAllUsers
						? this.state.userAllowList.map((u) => ({
								...u,
							}))
						: [],
				groupAllowList:
					this.state.groupAllowList && !setToAllUsers
						? this.state.groupAllowList.map((g) => ({
								...g,
							}))
						: [],
			});

			if (!setToAllUsers && this.state.groupAllowList.length === 0) {
				this.setState({
					groupAllowList: findAdminGroups(this.state.availableGroups),
				});
			}
		});
	}
	submit() {
		const userList = this.state.userAllowList.map((u) => u.value);
		const groupList = this.state.groupAllowList.map((g) => g.value);

		this.props.updatePermissionFeature(
			this.state.featureCode,
			userList,
			groupList
		);
	}

	render() {
		if (this.state.loading) {
			return null;
		} else {
			return (
				<div>
					<div>
						<div
							style={{
								backgroundColor: "var(--lw-light-grey-a)",
								padding: 30,
							}}
						>
							<div style={{ width: 300 }}>
								<LabeledDropDownSelect
									label="CLIENTS AND MATTERS CAN BE CREATED BY"
									options={
										permissionFeatureGlobalSettingOptions
									}
									defaultValue={
										this.state
											.permissionFeatureGlobalSetting
									}
									name="permissionFeatureGlobalSetting"
									handler={this.handleChange}
								/>
							</div>
							{this.state.permissionFeatureGlobalSetting.value ===
							"specifiedUsers" ? (
								<div>
									<div>
										<UsersDropDownSelect
											label={`Users ${
												labelMap[this.state.featureCode]
											}`}
											isMulti
											name="userAllowList"
											isAsync
											defaultValue={
												this.state.userAllowList
											}
											options={this.state.availableUsers}
											handler={this.handleChange}
										/>
									</div>
									<div>
										<LabeledDropDownSelect
											label={`Groups ${
												labelMap[this.state.featureCode]
											}`}
											isMulti
											name="groupAllowList"
											isAsync
											isClearable
											value={this.state.groupAllowList}
											defaultValue={
												this.state.groupAllowList
											}
											options={this.state.availableGroups}
											handler={this.handleChange}
										/>
									</div>
								</div>
							) : null}
						</div>

						<div
							style={{
								paddingTop: 10,
							}}
						>
							<ButtonWithRouter
								type="go-back"
								buttonText={this.cancelButtonText || "Cancel"}
								iconName="times"
								style={{ marginRight: 8 }}
							/>
							<ButtonWithIcon
								handler={() => this.submit()}
								buttonText="Apply"
								submit={this.submit.bind(this)}
								className="blue"
								iconName="check"
							/>
						</div>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = (state) => {
	return {
		loading: state.permissionFeatures.loading,
		allowedUsers: state.permissionFeatures.allowedUsers,
		allowedGroups: state.permissionFeatures.allowedGroups,
		allUsers: state.permissionFeatures.allUsers,
		allGroups: state.permissionFeatures.allGroups,
	};
};
EditPermissionFeature.propTypes = {
	setSubheaderText: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
	getUsersAndGroupsForPermissionFeature,
	setSubheaderText,
	updatePermissionFeature,
	unmountEditPermissionFeature,
})(EditPermissionFeature);
