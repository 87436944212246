import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class LabeledDatePicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			startDate: null,
			unchanged: true,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(date) {
		this.setState({
			startDate: date,
			unchanged: false,
		});
		this.props.handler && this.props.handler(this.props.name, date);
	}
	static getDerivedStateFromProps(props, state) {
		if (state.unchanged && props.defaultValue) {
			return {
				startDate: isNaN(props.defaultValue.valueOf())
					? null
					: props.defaultValue,
			};
		} else {
			return null;
		}
	}
	render() {
		return (
			<div
				className={
					"LabeledInput LabeledDatePicker " +
					(this.props.className || "")
				}
			>
				<div style={{ display: "flex" }}>
					<div className="InputLabel">{this.props.label}</div>
					{this.props.hoverText && (
						<div title={this.props.hoverText}>
							<FontAwesomeIcon
								icon="info-circle"
								style={{ marginLeft: 4, marginTop: 2 }}
							/>
						</div>
					)}
				</div>
				<DatePicker
					{...this.props}
					selected={this.state.startDate}
					onChange={this.handleChange}
				/>
			</div>
		);
	}
}
