import React from "react";
import { Link } from "react-router-dom";

import "./TextGrid.css";

const TextGrid = (props) => {
	return (
		<div
			className={"TextGrid " + (props.className || "")}
			style={{
				gridTemplateColumns: `repeat(${props.numColumns || 3},1fr)`,
				minHeight: 32,
			}}
		>
			{props.columns.map((item) => {
				return (
					<div className="TextGridColumn" key={Math.random()}>
						{item.map((row) => {
							if (row && row.href) {
								return (
									<div
										className="TextGridElement"
										key={Math.random() + "aa"}
									>
										<Link to={row.href}>{row.text}</Link>
									</div>
								);
							} else {
								return (
									<div
										className="TextGridElement"
										key={Math.random() + "aa"}
									>
										{row}
									</div>
								);
							}
						})}
					</div>
				);
			})}
		</div>
	);
};
export default TextGrid;
