import {
	GET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
	GET_CLIO_INTEGRATION_SETTINGS_FAILURE,
	GET_TENANT_FEATURES_USAGE_AND_FEATURES_SUCCESS,
} from "../actions/types";

//
const initialState = {
	ewsUrl: null,
	loading: true,
	tenantFeaturesLoading: true,
	integrationSettingsLoading: true,
	tenantFeaturesRequestCounts: {
		clioIntegrationRequestCount: 0,
		literaDocumentCompareVisibleRequestCount: 0,
	},
	tenantFeatures: {},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case "GET_EWS_URL_SUCCESS":
			var ewsUrl = action.payload;
			return {
				...state,
				loading: false,
				ewsUrl,
			};
		case "SET_EWS_URL_SUCCESS":
			return {
				...state,
				loading: true,
			};
		case "GET_INTEGRATION_SETTINGS_SUCCESS":
			return {
				...state,
				integrationSettingsLoading: false,
				tenantFeatures: { ...state.tenantFeatures, ...action.payload },
			};
		case "SET_INTEGRATION_SETTINGS_REQUEST": {
			return {
				...state,
				integrationSettingsLoading: true,
			};
		}
		case "SET_INTEGRATION_SETTINGS_SUCCESS":
			return {
				...state,
				integrationSettingsLoading: false,
				tenantFeaturesRequestCounts: {
					...state.tenantFeaturesRequestCounts,
					[action.payload.featureCode + "RequestCount"]:
						state.tenantFeaturesRequestCounts[
							action.payload.featureCode + "RequestCount"
						] + 1,
				},
				tenantFeatures: {
					...state.tenantFeatures,
					[action.payload.featureCode]: action.payload.status,
				},
			};
		case "SET_INTEGRATION_SETTINGS_FAILURE":
			return {
				...state,
				integrationSettingsLoading: false,
				tenantFeaturesRequestCounts: {
					...state.tenantFeaturesRequestCounts,
					[action.payload.featureCode + "RequestCount"]:
						state.tenantFeaturesRequestCounts[
							action.payload.featureCode + "RequestCount"
						] + 1,
				},
			};
		case GET_CLIO_INTEGRATION_SETTINGS_SUCCESS:
			return {
				...state,
				loading: false,
				clioIntegrationSettingStatus: action.payload,
			};
		case GET_CLIO_INTEGRATION_SETTINGS_FAILURE:
			return {
				...state,
				loading: false,
				clioIntegrationSettingStatus: 0,
			};
		case GET_TENANT_FEATURES_USAGE_AND_FEATURES_SUCCESS:
			return {
				...state,
				tenantFeaturesLoading: false,
				...action.payload,
			};
		default:
			return state;
	}
}
