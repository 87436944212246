import React, { Component } from "react";
import ButtonLink from "./ButtonLink";
import { openGlobalDialog } from "../actions/globalEditDialogActions";
import { connect } from "react-redux";
import SortableTable from "./Tables/SortableTable";
import CustomPagination from "./Tables/CustomPagination";

class OcrRequestFailureModalUnconnected extends Component {
	render() {
		const { ocrJobProgress, openGlobalDialog } = this.props;

		let ocrTransactionArray = Object.keys(ocrJobProgress).map(
			(transactionID) => {
				let record = ocrJobProgress[transactionID];
				return {
					transactionID,
					...record,
				};
			}
		);
		let failedOcrTransactionArray = ocrTransactionArray.filter(
			(e) => e.status === "FAILED" || e.status.startsWith("CANCELLED")
		);

		let successfulOcrTranactionsArray = ocrTransactionArray.filter(
			(e) => e.status === "SUCCESS"
		);

		return (
			<div style={{ whiteSpace: "pre" }}>
				{successfulOcrTranactionsArray.length
					? `${successfulOcrTranactionsArray.length} OCR Request(s) Successful\n`
					: ""}
				{failedOcrTransactionArray.length} OCR Request(s) Failed{" "}
				<ButtonLink
					onClick={() =>
						openGlobalDialog(
							{
								label: `${failedOcrTransactionArray.length} OCR Request(s) Failed`,
								mainText: (
									<div className="autoResizeTableContainer">
										<SortableTable
											className="fullWidth"
											data={failedOcrTransactionArray}
											style={{ height: 458 }}
											tableUniqueID="ocrRequestFailureModalTable"
											columns={[
												{
													Header: "id",
													accessor: "documentID",
													width: 75,
												},
												{
													Header: "filename",
													accessor: "filename",
													width: 150,
												},
												{
													Header: "Status",
													accessor: "status",
												},
											]}
											PaginationComponent={
												CustomPagination
											}
											loading={false}
											noResultsText="No Failed OCRs"
										/>
									</div>
								),
								canSubmit: true,
								hideTextfield: true,
								hideCancelButton: true,
								submitButtonLabel: "OK",
							},
							() => () => ({ canSubmit: true }),
							() => {}
						)
					}
				>
					View Failed Requests
				</ButtonLink>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	ocrJobProgress: state.ocrJobProgress,
});

const OcrRequestFailureModal = connect(mapStateToProps, {
	openGlobalDialog,
})(OcrRequestFailureModalUnconnected);

function getOcrRequestFailureModal() {
	return <OcrRequestFailureModal />;
}

export default getOcrRequestFailureModal;
