import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
	updateDisplayName,
	updateEmailAddress,
	verifyEmail,
	sendEmailVerificationCode,
} from "../actions/userAuthActions";
import { setSubheaderText } from "../actions/navigateActions";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { LabeledTextInput } from "./TextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const errorMessages = {
	newPasswordsMatch: "New passwords do not match",
	newPasswordLength: "New password must be at least 8 characters",
	oldPasswordEntered: "Please enter existing password",
	specialCharactersCompliant:
		"New password must include a lowercase letter, uppercase letter, number AND special character",
	codeIsEntered:
		"Please enter the verification code you received in an email",
};

class ChangeUserAttributes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			code: "",
			canSubmitEmail: false,
			canSubmitDisplayName: false,
			nameIsDifferent: false,
			nameConforms: false,
			emailIsDifferent: false,
			emailConforms: false,
			errorMessage: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.submitDisplayName = this.submitDisplayName.bind(this);
		this.submitEmail = this.submitEmail.bind(this);
		this.submitCode = this.submitCode.bind(this);
		this.resendCode = this.resendCode.bind(this);
		this.validate = this.validate.bind(this);
		this.generateErrorMessage = this.generateErrorMessage.bind(this);
	}
	componentDidMount() {
		this.validate();
		this.props.setSubheaderText("Change User Attributes");
	}
	handleChange(key, value) {
		this.setState({ [key]: value }, this.validate);
	}
	generateErrorMessage() {
		return Object.keys(errorMessages)
			.filter((errorKey) => {
				return !this.state[errorKey];
			})
			.map((errorKey) => {
				return <div key={errorKey}>{errorMessages[errorKey]}</div>;
			});
	}
	validate() {
		const nameIsDifferent = this.state.name !== this.props.userDisplayName;
		const nameConforms =
			this.state.name.length > 0 && this.state.name.length < 65;
		const emailIsDifferent = this.state.email !== this.props.email;
		const emailConforms = /.+@.+\..+/.test(this.state.email);
		const canSubmitDisplayName = nameIsDifferent && nameConforms;
		const canSubmitEmail = emailIsDifferent && emailConforms;
		this.setState({
			nameIsDifferent,
			nameConforms,
			emailIsDifferent,
			emailConforms,
			canSubmitDisplayName,
			canSubmitEmail,
		});
	}
	submitDisplayName() {
		const { name } = this.state;
		if (this.state.canSubmitDisplayName) {
			this.props.updateDisplayName(name);
		}
	}
	submitEmail() {
		const { email } = this.state;
		if (this.state.canSubmitEmail) {
			this.props.updateEmailAddress(email);
		}
	}
	submitCode() {
		this.props.verifyEmail(this.state.code);
	}
	resendCode() {
		const stateEmail = this.state.email;
		const propsEmail = this.props.email;
		console.log({ stateEmail, propsEmail });
		this.props.sendEmailVerificationCode(propsEmail);
	}
	render() {
		return (
			<div className="center450" style={{ padding: 16 }}>
				<LabeledTextInput
					label="Display name"
					name="name"
					defaultValue={this.props.userDisplayName}
					onChange={this.handleChange}
				/>
				<ButtonWithIcon
					disabled={!this.state.canSubmitDisplayName}
					handler={this.submitDisplayName}
					buttonText="Update Display Name"
					iconName="check"
				/>
				<div style={{ marginTop: 24 }}>
					<div>
						<LabeledTextInput
							label="Email Address"
							name="email"
							defaultValue={this.props.email}
							onChange={this.handleChange}
						/>

						<ButtonWithIcon
							disabled={!this.state.canSubmitEmail}
							handler={this.submitEmail}
							buttonText="Update Email Address"
							iconName="check"
						/>
					</div>
					{!this.props.emailVerified ? (
						<div style={{ marginTop: 12 }}>
							<div
								style={{
									marginBottom: 12,
									display: "flex",
									gap: 16,
								}}
							>
								<div
									style={{
										display: "flex",
										// vertically centers the icon
										flexDirection: "column",
										justifyContent: "space-around",
									}}
								>
									<FontAwesomeIcon
										style={{
											color: "var(--lw-darker-gold)",
											fontSize: "2em",
										}}
										icon="exclamation-triangle"
									/>
								</div>
								<div>
									Check your email for a verification code.
									Until the new email address is verified, you
									will not be able to log in using the new
									email address
								</div>
							</div>
							<LabeledTextInput
								label="Verification Code"
								name="code"
								placeholder="Code"
								onChange={this.handleChange}
							/>

							<div style={{ display: "flex", gap: 12 }}>
								<ButtonWithIcon
									buttonText="Re-send Code"
									iconName="check"
									handler={this.resendCode}
								/>
								<ButtonWithIcon
									disabled={this.state.code === ""}
									buttonText="Verify Email"
									iconName="check"
									handler={this.submitCode}
								/>
							</div>
						</div>
					) : null}
				</div>
			</div>
		);
	}
}
ChangeUserAttributes.propTypes = {
	userDisplayName: PropTypes.string,
	email: PropTypes.string.isRequired,
	emailVerified: PropTypes.bool,
	errorMessage: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
	userDisplayName: state.user.userDisplayName,
	email: state.user.userEmail,
	emailVerified: state.user.emailVerified,
	errorMessage: state.user.errorMessage,
});
export default connect(mapStateToProps, {
	verifyEmail,
	sendEmailVerificationCode,
	setSubheaderText,
	updateDisplayName,
	updateEmailAddress,
})(ChangeUserAttributes);
