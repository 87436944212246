import React, { useState, useRef, useEffect } from "react";
import { LabeledTextInput } from "./TextInput";
import TypingAnimation from "./TypingAnimation";
import { ButtonWithIcon } from "./ButtonWithIcon";

const ConversationInterface = ({
	messages,
	submitQuestion,
	IconComponent,
	loading,
	endOfMessagesComponent,
	userTextInputAllowed,
}) => {
	const [userInput, setUserInput] = useState("");
	const [currentlyTyping, setCurrentlyTyping] = useState(false);

	const messagesEndRef = useRef(null);
	const textInputFormRef = useRef(null);

	const submitUserQuestion = () => {
		submitQuestion(userInput);
		textInputFormRef.current.textInput.value = "";
	};
	const scrollToBottom = () => {
		messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
	};
	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				flexGrow: "1",
				overflow: "hidden",
			}}
		>
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					flex: "1 1 auto",
					overflow: "auto",
					gap: 16,
				}}
			>
				{messages.map(({ source, content, animateTyping }, index) => {
					return (
						<div
							key={index}
							style={{ margin: 8, display: "flex", gap: 8 }}
						>
							<div style={{ width: 32 }}>
								<IconComponent sourceName={source} />
							</div>
							<div>
								<div
									style={{
										fontWeight: "bold",
										fontSize: 16,
										lineHeight: "32px",
										height: 32,
									}}
								>
									{source}
								</div>
								<div
									style={{
										fontSize: 14,
										background: "#eeeeee",
										whiteSpace: "pre",
										textWrap: "wrap",
										lineHeight: "1.75em",
										padding: 16,
									}}
								>
									{animateTyping ? (
										<TypingAnimation
											fullText={content}
											onType={() => {
												scrollToBottom();
											}}
											startTyping={() => {
												setCurrentlyTyping(true);
											}}
											finishedTyping={() => {
												setCurrentlyTyping(false);
												scrollToBottom();
											}}
										/>
									) : (
										<span>{content}</span>
									)}
								</div>
							</div>
						</div>
					);
				})}
				{(!currentlyTyping && endOfMessagesComponent) || null}
				{<div ref={messagesEndRef} key="endOfMessages" />}
			</div>
			<div>
				<div
					style={{ display: "flex", gap: 16, alignItems: "stretch" }}
				>
					<div style={{ flexGrow: 1 }}>
						<LabeledTextInput
							formRef={textInputFormRef}
							onChange={(key, val) => {
								setUserInput(val);
							}}
							onEnterKey={(e) => {
								submitUserQuestion();
								e.preventDefault();
								return false;
							}}
							disabled={!userTextInputAllowed}
							placeholder={"Message LexWorkplace AI..."}
							autocomplete="one-time-code"
						/>
					</div>
					<div style={{ marginLeft: "auto" }}>
						<ButtonWithIcon
							handler={() => submitUserQuestion()}
							disabled={userInput.trim() === ""}
							buttonText="Send"
							icon={"arrow-up"}
						/>
					</div>
				</div>
				<div
					style={{
						fontFamily: "MainFont-Light",
					}}
				>
					<div>
						LexWorkplace AI can make mistakes. Consider checking
						important information.
					</div>
					<div>
						This AI conversation will not be saved unless you
						explicitly save it as a Matter Note.
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConversationInterface;
