import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
	getPermissionGroups,
	deletePermissionGroup,
} from "../actions/permissionsActions";

import { setResizeElement } from "../actions/globalScreenSizeActions";
import { setSubheaderText } from "../actions/navigateActions";

import CustomizableTable from "./Tables/CustomizableTable";
import InteractiveTableCell from "./Tables/InteractiveTableCell";
import { ButtonWithRouter } from "./ButtonWithIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const tableUniqueID = "/permissions";

class PermissionsGroupList extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			filter: "Open",
		};
		this.switchedTab = this.switchedTab.bind(this);
		const makeGroupThreeDotMenuItems = (row) => {
			var menu = [
				{
					text: "Edit Group",
					href: "/permissions/groups/edit/" + row.original.id,
				},
			];
			menu = menu.concat([
				{
					text: "Delete Group",
					onSelect: () =>
						this.props.deletePermissionGroup(row.original.id),
					disabled: row.original.type === "FIRM_ADMIN",
				},
			]);
			return menu;
		};
		this.columnConfig = [
			{ Header: "ID", accessor: "id", width: 40 },
			{
				Header: "Group Name",
				accessor: "name",
				Cell: (row) => (
					<InteractiveTableCell
						menuItems={makeGroupThreeDotMenuItems(row)}
					>
						<Link
							to={"/permissions/groups/edit/" + row.original.id}
						>
							{row.value}
						</Link>
					</InteractiveTableCell>
				),
				wildcard: true,
			},
			{
				Header: "Group Members",
				accessor: "members",
				Cell: (row) =>
					row.original.members
						? row.original.members
								.map((x) => x.displayName)
								.join(", ")
						: "",
				filterValueMethod: (x) =>
					x.members.map((gm) => gm.displayName).join(", "),
			},
		];
	}
	UNSAFE_componentWillMount() {
		this.props.setSubheaderText("Permission Groups");
		this.props.getPermissionGroups();
		this.props.setResizeElement("mattersListTableContainer");
	}
	switchedTab(newTabTitle) {
		this.setState({ filter: newTabTitle });
	}

	render() {
		return (
			<div className="fullHeightFlex noDefaultMargin">
				<CustomizableTable
					actionButtons={
						<div style={{ display: "flex", gap: 8 }}>
							<ButtonWithRouter
								redirect="/permissions/groups/create"
								buttonText="New Group"
								iconName="users"
							/>
						</div>
					}
					leftSideFilterSpace={
						<div style={{ display: "flex", marginTop: 8 }}>
							<FontAwesomeIcon
								icon="users"
								size="2x"
								style={{
									marginRight: 10,
									marginLeft: 10,
									marginTop: 12,
									fontSize: "1.5em",
								}}
							/>
							<span style={{ fontSize: "1.5em", marginTop: 6 }}>
								Groups
							</span>
						</div>
					}
					className="fullWidth"
					loading={this.props.loading}
					data={this.props.availablePermissionGroups}
					autoResize
					columns={this.columnConfig}
					noResultsText={"No Groups"}
					tableUniqueID={tableUniqueID}
				/>
			</div>
		);
	}
}
PermissionsGroupList.propTypes = {
	setSubheaderText: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
	title: state.router.location.pathname,
	myUserID: state.user.userID,
	loading: state.request.loading,
	cognitoSub: state.user.cognitoUser.attributes.sub,
	availablePermissionGroups: state.permissions.permissionGroups,
});
export default connect(mapStateToProps, {
	setSubheaderText,
	setResizeElement,
	getPermissionGroups,
	deletePermissionGroup,
})(PermissionsGroupList);
