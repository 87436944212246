import React, { Component } from "react";
import { connect } from "react-redux";
import "./DropDownButton.css";
import { setClioIntegrationToken } from "../actions/clioIntegrationActions";
import { Redirect } from "react-router-dom";
import queryString from "query-string";

/*
	This page is rendered as the clio redirect location
	after the user clicks "accept" on the clio website
*/

class ClioIntegrationRedirectUnconnected extends Component {
	constructor(props, context) {
		super(props, context);
	}

	componentDidMount() {
		const {
			location: { search },
		} = this.props;
		const clioCode = queryString.parse(search);
		this.props.setClioIntegrationToken(clioCode.code);
	}

	render() {
		return <Redirect to="/" />;
	}
}
const mapStateToProps = () => ({});

const ClioIntegrationRedirect = connect(mapStateToProps, {
	setClioIntegrationToken,
})(ClioIntegrationRedirectUnconnected);

export { ClioIntegrationRedirect };
