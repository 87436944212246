import {} from "../actions/types";
import { getFirmDocsDisplayName, getMatterLink } from "../utilities/utilities";

//
const initialState = {
	myOpenMatters: [],
	myFavoriteDocuments: [],
	dataUsage: {},
	usageLimits: {},
	tenantFeatures: {},
	tenantUsage: {},
	loading: true,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case "FETCH_HOMEPAGE_SUCCESS":
			return {
				...state,
				myOpenMatters: action.payload.myOpenMatters,
				myFavoriteDocuments: action.payload.myFavoriteDocuments.map(
					(d) => ({ ...d, type: "File" })
				),
				myFavoriteFolders: action.payload.myFavoriteFolders.map((d) => {
					let folderPrefix =
						d.matterID === 0
							? "/firmDocs"
							: "/matters/" + d.matterID;
					let linkToMatter = getMatterLink(d.mappedMatterID);
					let matterName = d.matterName
						? d.matterName
						: getFirmDocsDisplayName(d.mappedMatterID);

					return {
						...d,
						type: "Folder",
						folderPrefix,
						linkToMatter,
						matterName,
					};
				}),
				dataUsage: action.payload.dataUsage,
				usageLimits: action.payload.tenantLimits,
				tenantFeatures: action.payload.tenantFeatures,
				tenantUsage: action.payload.tenantUsage,
				loading: false,
			};
		case "UPDATE_LIMITS_AND_FEATURES":
			return {
				...state,
				usageLimits: action.payload.tenantLimits,
				tenantFeatures: action.payload.tenantFeatures,
			};
		default:
			return state;
	}
}
