import React, { Component } from "react";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

class TextDisplayWithCopyToClipboard extends Component {
	render() {
		return (
			<div {...this.props}>
				<div className="InputLabel">{this.props.label}</div>
				<div style={{ display: "flex" }}>
					<pre style={{ marginRight: 8, flex: 1 }}>
						{this.props.value}
					</pre>
					<CopyToClipboard text={this.props.value}>
						<button style={{ height: 39, borderRadius: 4 }}>
							Copy
						</button>
					</CopyToClipboard>
				</div>
			</div>
		);
	}
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps, {})(TextDisplayWithCopyToClipboard);
