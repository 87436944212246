import API from "../utilities/LocalApiProxy";
import {
	CREATE_NEW_MATTER_SUCCESS,
	CREATE_NEW_MATTER_FAILURE,
	CREATE_NEW_MATTER_REQUEST,
	LINK_CLIO_MATTER_REQUEST,
	LINK_CLIO_MATTER_SUCCESS,
	LINK_CLIO_MATTER_FAILURE,
	UNLINK_CLIO_MATTER_SUCESS,
	CLIO_ACCESS_CODE_SUCESS,
} from "./types";

import { openGlobalDialog } from "./globalEditDialogActions";

export function openPullMatterFromClioDialog() {
	return function (dispatch) {
		openGlobalDialog(
			{
				label: "Pull Matter from Clio Manage",
				submitButtonLabel: "Pull Matter",
				type: "clioDropdown",
			},
			(state) => {
				const clioMatterId = `${state.clioMatterList.id}`;
				const clioMatterName = `${state.clioMatterList.value}`;
				createNewMatterFromClioMatter(
					clioMatterId,
					clioMatterName
				)(dispatch);
			},
			() => {
				return {
					canSubmit: true,
				};
			}
		)(dispatch);
	};
}

export function openLinkExistingMatterToClioDialog(lxwMatterID) {
	return function (dispatch) {
		openGlobalDialog(
			{
				label: "Link to Matter in Clio Manage",
				submitButtonLabel: "Link Matter",
				type: "clioDropdown",
			},
			(state) => {
				const clioMatterId = `${state.clioMatterList.id}`;
				linkLXMatterWithClio(clioMatterId, lxwMatterID)(dispatch);
			},
			() => {
				return {
					canSubmit: true,
				};
			}
		)(dispatch);
	};
}

export function setClioIntegrationToken(clioCode) {
	return function (dispatch) {
		API.post("/integrations/clio/authorize", {
			body: { clioAccessCode: clioCode },
		}).then((results) => {
			dispatch({
				type: CLIO_ACCESS_CODE_SUCESS,
				payload: results.mysql.clioAuthTokenStatus,
			});
		});
	};
}

export function clioIntegrationAccessRedirectCodeStatus() {
	return function (dispatch) {
		API.get("/integrations/clio/authorizeStatus", {}).then((results) => {
			dispatch({
				type: CLIO_ACCESS_CODE_SUCESS,
				payload: results.mysql.returnClioObject.clioAuthTokenStatus,
			});
		});
	};
}

export function createNewMatterFromClioMatter(clioMatterId, clioMatterName) {
	return function (dispatch) {
		dispatch({ type: CREATE_NEW_MATTER_REQUEST });
		API.post("/integrations/clio/newClioMatter", {
			body: {
				clioMatterId: clioMatterId,
				clioMatterName: clioMatterName,
			},
		})
			.then((results) => {
				window.globalHistory.push(
					`/matters/${results.mysql.newMatterID}/docs/`
				);
				dispatch({ type: CREATE_NEW_MATTER_SUCCESS });
			})
			.catch(() => {
				dispatch({ type: CREATE_NEW_MATTER_FAILURE });
			});
	};
}

export function linkLXMatterWithClio(clioMatterId, matterID) {
	return function (dispatch) {
		dispatch({ type: LINK_CLIO_MATTER_REQUEST });
		API.post("/integrations/clio/linkLXMatterWithClio", {
			body: {
				clioMatterId: clioMatterId,
				matterId: matterID,
			},
		})
			.then((results) => {
				dispatch({
					type: LINK_CLIO_MATTER_SUCCESS,
					payload: results.mysql,
				});
			})
			.catch(() => {
				dispatch({ type: LINK_CLIO_MATTER_FAILURE });
			});
	};
}

// note this function doenst use redux.  The only place this data is
// used is in a popup dialog
export function fetchMattersListFromClioNoRedux(clioMatterQuery) {
	return API.get("/integrations/clio/listMatters", {
		queryParams: { clioMatterQuery },
	}).then(({ mysql }) => {
		if (!mysql.clioAuthTokenStatus) {
			window.location.href = mysql.clioRedirectURL;
			return {};
		} else {
			return mysql;
		}
	});
}

export function unlinkLXMatterfromClio(clioMatterId, matterID) {
	return function (dispatch) {
		API.post("/integrations/clio/unlinkLXMatterFromClio", {
			body: { clioMatterId: clioMatterId, matterId: matterID },
		}).then((result) => {
			dispatch({
				type: UNLINK_CLIO_MATTER_SUCESS,
				payload: result.mysql,
			});
		});
	};
}
