import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { SmallNoPaddingIconButton } from "./ButtonWithIcon";
import { nextPage, previousPage } from "../actions/globalPaginationActions";
import { numberWithCommas } from "../utilities/utilities";

class GlobalPagination extends Component {
	constructor(props, context) {
		super(props, context);
		this.previousClicked = this.previousClicked.bind(this);
		this.nextClicked = this.nextClicked.bind(this);
	}
	previousClicked() {
		this.props.previousPage();
	}
	nextClicked() {
		this.props.nextPage();
	}

	render() {
		return (
			<div className="GlobalPagination">
				<div>
					<SmallNoPaddingIconButton
						aria-label="previous page"
						onClick={this.previousClicked}
						style={{
							marginRight: 20,
							paddingLeft: 8,
							paddingRight: 8,
						}}
						disabled={!this.props.previousEnabled}
					>
						<FontAwesomeIcon
							icon={"caret-left"}
							size="1x"
							style={{
								color: this.props.previousEnabled
									? "black"
									: "var(--lw-medium-grey-b)",
							}}
						/>
					</SmallNoPaddingIconButton>
					<SmallNoPaddingIconButton
						aria-label="next page"
						onClick={this.nextClicked}
						style={{
							marginRight: 20,
							paddingLeft: 8,
							paddingRight: 8,
						}}
						disabled={!this.props.nextEnabled}
					>
						<FontAwesomeIcon
							icon={"caret-right"}
							size="1x"
							style={{
								color: this.props.nextEnabled
									? "black"
									: "var(--lw-medium-grey-b)",
							}}
						/>
					</SmallNoPaddingIconButton>
				</div>
				<div>
					<span style={{ marginRight: 8 }}>{`Page ${numberWithCommas(
						this.props.currentPage
					)} of ${numberWithCommas(this.props.totalPages)}`}</span>
					<span>
						{" "}
						{`(${
							this.props.isFiltered
								? "Results based on your filter: " +
									numberWithCommas(this.props.filteredCount) +
									" of "
								: ""
						}${numberWithCommas(
							this.props.totalResults
						)} total items)`}
					</span>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		currentPage: state.pagination.currentPage,
		totalPages: state.pagination.totalPages,
		resultsPerPage: state.pagination.resultsPerPage,
		totalResults: state.pagination.totalResults,
		previousEnabled: state.pagination.currentPage > 1,
		nextEnabled:
			state.pagination.currentPage !== state.pagination.totalPages,
		filteredCount: state.pagination.filteredCount,
		isFiltered: state.pagination.filteredCount > -1,
	};
};
export default connect(mapStateToProps, { nextPage, previousPage })(
	GlobalPagination
);
