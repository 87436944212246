import {
	GET_PERMISSION_FEATURES_SUCCESS,
	GET_PERMISSION_FEATURE_DETAILS_REQUEST,
	GET_PERMISSION_FEATURE_DETAILS_SUCCESS,
	UNMOUNT_EDIT_PERMISSION_FEATURE,
} from "../actions/types";

const initialState = {
	allowedFeatures: {},
	permissionFeature: "",
	allowedUsers: [],
	allowedGroups: [],
	allUsers: [],
	allGroups: [],
	loading: true,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_PERMISSION_FEATURES_SUCCESS:
			return {
				...state,
				allowedFeatures: {
					...state.allowedFeatures,
					...action.payload.permissionFeatures,
				},
			};
		case GET_PERMISSION_FEATURE_DETAILS_SUCCESS:
			return {
				...state,
				permissionFeature: action.payload.permissionFeature,
				allowedUsers: action.payload.allowedUsers,
				allowedGroups: action.payload.allowedGroups,
				allUsers: action.payload.allUsers,
				allGroups: action.payload.allGroups,
				loading: false,
			};
		case GET_PERMISSION_FEATURE_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case UNMOUNT_EDIT_PERMISSION_FEATURE:
			return {
				...state,
				loading: true,
				permissionFeature: "",
				allowedUsers: [],
				allowedGroups: [],
				allUsers: [],
				allGroups: [],
			};
		default:
			return state;
	}
}
