import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ChipsArray from "./ChipsArray";
import MaterialThreeDotMenu from "./MaterialThreeDotMenu";
import { openGlobalDialog } from "../actions/globalEditDialogActions";

function debounce(func, wait) {
	let timeout;
	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
}
class SubHeader extends Component {
	constructor() {
		super();
		this.state = {
			windowInnerWith: window.innerWidth,
			subheaderTitleMaxWidth: window.innerWidth,
			addedEventListener: false,
			sidenavElementWidth: 64, //width of side nav when collapsed
		};

		this.addWindowResizeListener = this.addWindowResizeListener.bind(this);
		this.updateWindowInnerWidth = debounce(
			this.updateWindowInnerWidth.bind(this),
			300
		);
		this.getSideNavWidth = debounce(this.getSideNavWidth.bind(this), 300);
	}

	updateWindowInnerWidth() {
		// get the clientWidth of the body element
		let clientWidthOfBody = document.querySelector("body").clientWidth;
		if (clientWidthOfBody !== this.state.windowInnerWith) {
			this.setState({ windowInnerWith: clientWidthOfBody });
		}
	}

	addWindowResizeListener() {
		window.addEventListener(
			"resize",
			() => {
				this.updateWindowInnerWidth();
			},
			true
		);
	}

	componentDidMount() {
		this.addWindowResizeListener();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.isSidenavExpanded !== prevProps.isSidenavExpanded) {
			this.updateWindowInnerWidth();
			this.getSideNavWidth();
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWindowInnerWidth, true);
	}

	getSideNavWidth() {
		let sideNavElement = document.querySelector(".LeftSidebar");
		let sidenavElementWidth = sideNavElement
			? sideNavElement.clientWidth
			: 0;
		if (sidenavElementWidth !== this.state.sidenavElementWidth) {
			this.setState({ sidenavElementWidth });
		}
	}

	render() {
		const subheaderContentMaxWidth =
			this.state.windowInnerWith - this.state.sidenavElementWidth;

		const subheaderTitleMaxWidth = this.props.chips.length ? "85%" : "100%";

		return (
			<div className="SubHeaderContainer">
				<div
					className="SubHeader"
					style={{
						justifyContent: "space-between",
						width: "100%",
						maxWidth: subheaderContentMaxWidth,
					}}
				>
					<div
						className="SubHeaderTitleContainer"
						style={{
							paddingLeft: 24,
							maxWidth: subheaderTitleMaxWidth,
						}}
					>
						<div
							className="SubheaderTitle"
							style={{
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}
						>
							{this.props.title}
							{this.props.title === "Welcome to LexWorkplace" ? (
								<span className="TrademarkSymbol">™</span>
							) : null}
						</div>
					</div>

					<div
						id="chipsDiv"
						style={{
							alignContent: "center",
							paddingRight: 12,
							minWidth: "15%",
						}}
					>
						{this.props.chips.length ? (
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<div
									style={{
										textOverflow: "ellipsis",
										whiteSpace: "nowrap",
										overflow: "hidden",
									}}
								>
									<ChipsArray
										chips={this.props.chips}
										ignoreRootClass
									/>
								</div>

								<div>
									<MaterialThreeDotMenu
										position={"relative"}
										buttonContent={"..."}
										menuItems={[
											{
												text: "See all tags",
												onSelect: () =>
													this.props.openGlobalDialog(
														{
															label:
																this.props
																	.title +
																" tags:",
															mainText: (
																<ChipsArray
																	chips={
																		this
																			.props
																			.chips
																	}
																/>
															),
															canSubmit: true,
															hideTextfield: true,
															readOnly: true,
															cancelButtonLabel:
																"Ok",
														},
														null,
														() => ({
															canSubmit: true,
														})
													),
											},
										]}
									/>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}
SubHeader.propTypes = {
	title: PropTypes.string.isRequired,
	loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
	title: state.currentPage.title,
	chips: state.currentPage.chips,
	loading: state.request.loading,
	isSidenavExpanded: state.browserLocalstorage.sidenavExpanded,
});
export default connect(mapStateToProps, { openGlobalDialog })(SubHeader);
