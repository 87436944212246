import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { forgotPassword } from "../actions/userAuthActions";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { LabeledTextInput } from "./TextInput";
import { Redirect } from "react-router-dom";
import {
	USER_FORGOT_PASSWORD_REQUEST_FAILURE,
	USER_FORGOT_PASSWORD_REQUEST_SUCCESS,
} from "../actions/types";

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.submit = this.submit.bind(this);
	}
	handleChange(key, value) {
		this.setState({ [key]: value });
	}
	submit() {
		this.props.forgotPassword(this.state.username);
	}
	render() {
		if (this.props.successfulForgotPasswordAttempt) {
			return <Redirect to="/changePassword" />;
		}
		return (
			<div className="center300">
				<h2 className="centeredH2">Find Your Account</h2>

				<LabeledTextInput
					label="Email address or Username"
					name="username"
					placeholder="Username"
					onChange={this.handleChange}
				/>
				<div style={{ minHeight: 40 }}>
					{this.props.unsuccessfulForgotPasswordAttempt ? (
						<div className="inlineErrorIndicator">
							{this.props.errorMessage}
						</div>
					) : null}
				</div>
				<ButtonWithIcon
					handler={this.submit}
					buttonText="Reset Password"
					iconName="check"
				/>
			</div>
		);
	}
}
ForgotPassword.propTypes = {
	forgotPassword: PropTypes.func.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
	unsuccessfulForgotPasswordAttempt: PropTypes.bool.isRequired,
	successfulForgotPasswordAttempt: PropTypes.bool.isRequired,
	errorMessage: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
	isLoggedIn: state.user.isLoggedIn,
	redirectLocation: state.user.redirectLocation,
	unsuccessfulForgotPasswordAttempt:
		state.user.authState === USER_FORGOT_PASSWORD_REQUEST_FAILURE,
	successfulForgotPasswordAttempt:
		state.user.authState === USER_FORGOT_PASSWORD_REQUEST_SUCCESS,
	errorMessage: state.user.errorMessage,
});
export default connect(mapStateToProps, { forgotPassword })(ForgotPassword);
