import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { withRouter } from "react-router-dom";
import Divider from "@material-ui/core/Divider";

function MaterialThreeDotMenu(props) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className={props.className || ""}>
			<Button
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
				style={{
					// position needs to be absolute for the three dot menus in the table cells
					// position need to be relative for the three dot menus in the subheader
					position:
						props.position === "relative" ? "relative" : "absolute",
				}}
			>
				{props.buttonContent ? (
					props.buttonContent
				) : (
					<FontAwesomeIcon icon="ellipsis-v" />
				)}
			</Button>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{props.menuItems.map((item, i) => {
					if (item.divider) {
						return <Divider key={i} />;
					}
					return (
						item && (
							<span
								title={
									item.disabled
										? item.disabledHoverText
										: null
								}
								key={i}
							>
								<MenuItem
									disabled={item.disabled}
									onClick={
										item.href
											? () => {
													const { history } = props;
													item.selectCallback &&
														item.selectCallback();
													history.push(item.href);
												}
											: () => {
													item.onSelect();
													handleClose();
												}
									}
								>
									{item.text}
								</MenuItem>
							</span>
						)
					);
				})}
			</Menu>
		</div>
	);
}
export default withRouter(MaterialThreeDotMenu);
