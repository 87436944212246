import {
	TOGGLE_COLUMN,
	GET_ALL_CUSTOM_COLUMNS_SUCCESS,
} from "../actions/types";

const initialState = {
	"/clients": {
		clientID: true,
		clientName: true,
		clientStatus: true,
		primaryAttorneys: true,
		tags: true,
	},
	"/matters": {
		id: true,
		name: true,
		clientName: true,
		customMatterID: true,
		matterType: true,
		officeBranch: true,
		dateOpened: true,
		dateClosed: true,
		tags: true,
		assignedStaff: true,
	},
	"/documents": {
		id: true,
		filename: true,
		versionNumber: true,
		size: true,
		filesize: true,
		tags: true,
		documentType: true,
		documentStatus: true,
		lockedBy: true,
		createdAt: true,
		lastModified: true,
	},
	"/email": {
		id: true,
		subjectline: true,
		emailFrom: true,
		emailTo: true,
		filesize: true,
		date: true,
		numAttachments: true,
		notes: true,
	},
	"/notes": {
		id: true,
		name: true,
		createdOn: true,
		createdBy: true,
		modifiedOn: true,
		modifiedBy: true,
	},
	"/manageClientTags": {
		id: true,
		name: true,
		status: true,
		createdBy: true,
		lastUpdated: false,
	},
	"/manageMatterTags": {
		id: true,
		name: true,
		status: true,
		createdBy: true,
		lastUpdated: false,
	},
	"/manageMatterTypes": {
		id: true,
		name: true,
		status: true,
		createdBy: true,
		lastUpdated: false,
	},
	"/manageOfficeBranch": {
		id: true,
		name: true,
		status: true,
		createdBy: true,
		lastUpdated: false,
	},
	"/manageDocumentTags": {
		id: true,
		name: true,
		status: true,
		createdBy: true,
		lastUpdated: false,
	},
	"/manageDocumentTypes": {
		id: true,
		name: true,
		status: true,
		createdBy: true,
		lastUpdated: false,
	},
	"/manageDocumentStatuses": {
		id: true,
		name: true,
		status: true,
		createdBy: true,
		lastUpdated: false,
	},
	"/permissions": {
		id: true,
		name: true,
		type: true,
		status: true,
		members: true,
	},
	"/sharing/outgoing": {
		createdByDisplayName: true,
		displayStatus: true,
		recipients: true,
		matterName: true,
		createdBy: true,
		expiresAt: true,
		lastEmailSent: true,
		totalObjects: true,
		hasBeenDownloaded: true,
	},
	"/admin/firms": {
		id: true,
		customerID: true,
		name: true,
		lxwEdition: true,
		activeUserCount: true,
		dataUsage: true,
		dataUsageLimitBytes: true,
		dataUsageOverLimit: true,
		documentCount: true,
		emailCount: true,
		matterCount: true,
		searchIndexDocs: true,
		ocrEnabled: true,
	},
	"/admin/usersForFirm": {
		username: true,
		displayName: true,
		emailAddress: true,
		status: true,
		roles: true,
		sub: true,
		cognitoCreatedDate: true,
	},
	"/admin/batchJobsForFirm": {
		CreationTime: true,
		JobId: true,
		Description: true,
		Status: true,
		ProgressSummary: true,
	},
};

export default function (state = initialState, action) {
	switch (action.type) {
		case TOGGLE_COLUMN:
			var listName = action.payload.listName;
			var key = action.payload.columnName;
			var previousVisibility = state[listName][key];
			var newColumns = { ...state[listName] };
			newColumns[key] = !previousVisibility;
			return {
				...state,
				[listName]: newColumns,
			};
		case GET_ALL_CUSTOM_COLUMNS_SUCCESS:
			return { ...state, ...action.payload };
		default:
			return state;
	}
}
