import React, { Component } from "react";
import { connect } from "react-redux";

import { LabeledDropDownSelect } from "./DropDownSelect";

const searchAuditOperationsOptions = [
	{ value: "audit", label: "Audit Search Index (This Firm)" },
	{ value: "rebuild", label: "Delete and Rebuild Search Index (This Firm)" },
];
const operationNameLookup = {
	audit: 0,
	rebuild: 1,
};

import {
	rebuildSearchIndex,
	auditSearchIndex,
	auditAndReIndexMissingItems,
	accessSearchAuditPage,
	setAuditPageOperation,
} from "../actions/adminActions";

import { openGlobalDialog } from "../actions/globalEditDialogActions";

import { ButtonWithIcon } from "./ButtonWithIcon";

import {
	numberWithCommas,
	msToTime,
	mediumDateFormatMinusTimezone,
} from "../utilities/utilities";

const BulkJobTimeDisplay = ({ startTime, endTime }) => {
	const startDate = new Date(startTime);
	const endDate = new Date(endTime);

	const durationMS = endDate - startDate;

	return (
		<div>
			<div>{`Job Started on: ${mediumDateFormatMinusTimezone(
				startDate
			)}`}</div>
			<div>{`Elapsed Time: ${msToTime(durationMS)}`}</div>
		</div>
	);
};

const AuditResultsDisplay = ({
	auditFailed,
	searchAuditResults,
	searchAuditFailedData,
	jobMetadata,
	indexMissingItemsHandler,
}) => {
	return auditFailed ? (
		<div>
			<h4>Audit Operation Results</h4>
			<div>Audit Failed</div>
			<div>{JSON.stringify(searchAuditFailedData)}</div>
			<div>{JSON.stringify(jobMetadata)}</div>
		</div>
	) : (
		<AuditSuccessResults
			{...searchAuditResults}
			jobMetadata={jobMetadata}
			indexMissingItemsHandler={indexMissingItemsHandler}
		/>
	);
};
const AuditSuccessResults = ({
	missingItemsCount,
	allSearchResults,
	jobMetadata,
	indexMissingItemsHandler,
}) => {
	const keyMap = [
		{ keyname: "documents", noun: "Documents" },
		{ keyname: "documentVersions", noun: "Document Versions" },
		{ keyname: "emails", noun: "Emails" },
		{ keyname: "matterNotes", noun: "Matter Notes" },
	];

	var sumOfMissingItems = 0;

	return (
		<div style={{ display: "flex" }}>
			<div style={{ flex: "0 0 50%" }}>
				<h4>Audit Operation Results</h4>
				<div>Audit Succeeded</div>
				<BulkJobTimeDisplay {...jobMetadata} />
				<div style={{ marginTop: 8 }}>
					{keyMap.map(({ keyname, noun }) => {
						const numberOfMissingItems = missingItemsCount[keyname];
						sumOfMissingItems += numberOfMissingItems;
						return (
							<div
								key={keyname}
								style={{ display: "flex", gap: 16 }}
							>
								<div
									style={{ width: 200 }}
								>{`Missing ${noun}:`}</div>
								<div style={{ width: 300 }}>
									{`${numberWithCommas(
										numberOfMissingItems
									)} (${numberWithCommas(
										allSearchResults[keyname]
									)} total in Search Index)`}
								</div>
							</div>
						);
					})}
				</div>
				<div style={{ marginTop: 16 }}>
					<ButtonWithIcon
						handler={indexMissingItemsHandler}
						disabled={sumOfMissingItems === 0}
						buttonText="Add Missing Items to Index"
						iconName="sync"
					/>
				</div>
			</div>
			<div style={{ flex: "0 0 50%" }}>
				<div style={{ marginTop: 32 }}>
					<div>
						{`Missing items can be indexed by pressing the button "Add Missing Items to Index".
					 Progress of the re-index job can be monitored in the
					"Batch Jobs" tab for this Firm.`}
					</div>
				</div>
			</div>
		</div>
	);
};

class AdminSearchIndexManagement extends Component {
	constructor(props, context) {
		super(props, context);
		this.rebuildSearchIndexIntent =
			this.rebuildSearchIndexIntent.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.goButtonPressed = this.goButtonPressed.bind(this);
	}
	componentDidUpdate() {
		this.props.tenantID &&
			this.props.accessSearchAuditPage(this.props.tenantID);
	}
	handleChange(key, { value }) {
		this.props.setAuditPageOperation(value);
	}
	goButtonPressed() {
		if (this.props.operation === "audit") {
			this.props.auditSearchIndex(this.props.tenantID);
		} else if (this.props.operation === "rebuild") {
			this.rebuildSearchIndexIntent();
		}
	}
	rebuildSearchIndexIntent() {
		this.props.openGlobalDialog(
			{
				label: "Delete and Rebuild Search Index?",
				mainText: `This will complely delete and rebuild the search index for firm:"${this.props.tenantName}", tenantID:${this.props.tenantID}.  To proceed, please type "rebuild" in the text field.`,
				value: "",
				submitButtonLabel: "Rebuild",
			},
			() => {
				this.props
					.rebuildSearchIndex(this.props.tenantID)
					.then((res) => console.log(res))
					.catch((err) => console.log(err));
			},
			(state) => {
				return {
					canSubmit: state.value === "rebuild",
					erroMessage: "",
				};
			}
		);
	}

	render() {
		return (
			<div
				style={{
					padding: 12,
					paddingLeft: 32,
					paddingRight: 32,
					display: "flex",
					flexDirection: "column",
					gap: 32,
				}}
			>
				<div>
					<div style={{ display: "flex", width: "100%" }}>
						<div style={{ flex: "0 0 50%" }}>
							<h3>Search Index Operations</h3>
							<div style={{ display: "flex" }}>
								<div style={{ width: 400, marginRight: 24 }}>
									<LabeledDropDownSelect
										options={searchAuditOperationsOptions}
										value={
											searchAuditOperationsOptions[
												operationNameLookup[
													this.props.operation
												]
											]
										}
										name="operation"
										handler={this.handleChange}
									/>
								</div>
								<div>
									<ButtonWithIcon
										handler={this.goButtonPressed}
										iconName="sync"
										buttonText="Go"
									/>
								</div>
							</div>
						</div>{" "}
						<div style={{ flex: "0 0 50%" }}>
							<div style={{ marginTop: 24, marginBottom: 48 }}>
								<h4>Audit Search Index</h4>
								<div>
									This process will look for Documents,
									Document Versions, Emails, and Matter Notes
									that exist in storage but are missing from
									the search index. Once complete, you will
									have the opportunity to add missing items to
									the search index.
								</div>
							</div>
							<div>
								<h4>
									Delete and re-build Search Index for Firm
								</h4>
								<div>
									This process will completely delete and
									re-build the search index from items in
									storage. This process can take a long time
									and should be considered a last resort
								</div>
							</div>
						</div>
					</div>

					<div
						style={{
							marginTop: 16,
							marginBottom: 16,
							borderBottom: "1px solid #cbcbcb",
						}}
					/>

					{this.props.auditResultsLoaded && (
						<AuditResultsDisplay
							searchAuditResults={this.props.searchAuditResults}
							auditFailed={this.props.auditFailed}
							searchAuditFailedData={
								this.props.searchAuditFailedData
							}
							jobMetadata={this.props.jobMetadata}
							indexMissingItemsHandler={() =>
								this.props.auditAndReIndexMissingItems(
									this.props.tenantID
								)
							}
						/>
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.request.loading,
	auditResultsLoaded: state.searchAudit.resultsLoaded,
	searchAuditResults: state.searchAudit.auditResults,
	auditFailed: state.searchAudit.auditFailed,
	searchAuditFailedData: state.searchAudit.auditFailedData,
	jobMetadata: state.searchAudit.jobMetadata,
	operation: state.searchAudit.activeOperationOnAuditPage,
});
export default connect(mapStateToProps, {
	rebuildSearchIndex,
	openGlobalDialog,
	auditSearchIndex,
	auditAndReIndexMissingItems,
	accessSearchAuditPage,
	setAuditPageOperation,
})(AdminSearchIndexManagement);
