import {
	SET_TABLE_COLUMN_SORTING,
	SET_TABLE_TAB_FILTER,
	SET_TABLE_COLUMN_WIDTHS,
	RESET_TABLE_COLUMN_WIDTHS,
} from "./types";

export function setTableSort(tableUniqueID, newSorted, additive) {
	return function (dispatch) {
		dispatch({
			type: SET_TABLE_COLUMN_SORTING,
			payload: { tableUniqueID, newSorted, additive },
		});
	};
}
export function setTableTabFilter(tableUniqueID, tabName) {
	return function (dispatch) {
		dispatch({
			type: SET_TABLE_TAB_FILTER,
			payload: { tableUniqueID, tabName },
		});
	};
}
export function setTableColumnWidths(tableUniqueID, newResized) {
	return function (dispatch) {
		dispatch({
			type: SET_TABLE_COLUMN_WIDTHS,
			payload: { tableUniqueID, newResized },
		});
	};
}
export function resetTableColumnWidths(tableUniqueID) {
	return function (dispatch) {
		dispatch({
			type: RESET_TABLE_COLUMN_WIDTHS,
			payload: { tableUniqueID },
		});
	};
}
