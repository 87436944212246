import React, { Component } from "react";
import { connect } from "react-redux";

import buildData from "../buildData/buildData";
import config from "../config/legalworks.frontend.config";

import { setSubheaderText } from "../actions/navigateActions";

import TextDisplayWithCopyToClipboard from "./TextDisplayWithCopyToClipboard";
class About extends Component {
	componentDidMount() {
		this.props.setSubheaderText("About LexWorkplace");
	}

	render() {
		return (
			<div style={{ padding: 24 }}>
				<h1>frontend.config.json</h1>
				<div>
					Variant Name:<pre>{config.variantName}</pre>
				</div>
				<div>
					apiName:<pre>{config.apiName}</pre>
				</div>
				<div>
					domain:<pre>{config.domain}</pre>
				</div>
				<h1>Environment Variables</h1>
				<div>
					Execution Environment:
					<pre>{process.env.REACT_APP_ENVIRONMENT}</pre>
				</div>
				<div>
					Running In:<pre>{process.env.REACT_APP_RUNNING_IN}</pre>
				</div>
				<h1>buildData.json</h1>
				<div>
					Environment:<pre>{buildData.environment}</pre>
				</div>
				<div>
					Build Number:<pre>{buildData.buildNumber}</pre>
				</div>
				<div>
					Branch:<pre>{buildData.branch}</pre>
				</div>
				<div>
					Commit Hash:<pre>{buildData.lastCommit}</pre>
				</div>
				<h1>User Info</h1>
				{this.props.cognitoSub ? (
					<div>
						<TextDisplayWithCopyToClipboard
							style={{ width: "800px" }}
							label="Cognito Sub:"
							value={this.props.cognitoSub}
						/>
					</div>
				) : (
					<div>
						<pre>No Authenticaed User</pre>
					</div>
				)}
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	cognitoSub: state?.user?.cognitoUser?.attributes?.sub,
});
export default connect(mapStateToProps, { setSubheaderText })(About);
