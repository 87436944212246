import { NAVIGATE, SET_SUBHEADER_TEXT, SET_CREATABLE_CONTEXT } from "./types";

export function navigate(newPath) {
	return function (dispatch) {
		console.log("navigated", newPath);
		dispatch({
			type: NAVIGATE,
			location: newPath,
		});
	};
}
export function setSubheaderText(text, component) {
	return function (dispatch) {
		dispatch({
			type: SET_SUBHEADER_TEXT,
			payload: { subheaderText: text, subheaderChips: [], component },
		});
	};
}
export function setCreatableContext(creatable) {
	return function (dispatch) {
		dispatch({
			type: SET_CREATABLE_CONTEXT,
			payload: creatable,
		});
	};
}
