import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonWithIcon } from "./ButtonWithIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
	fetchPublicShareDetails,
	getSignedUrlForZipDownload,
} from "../actions/shareActions.js";

import ScrollableListOfFiles from "./ScrollableListOfFiles";

const shareNotAvailable = () => (
	<div>
		<h2 style={{ textAlign: "center", marginTop: 100 }}>
			This link is invalid or has expired.
		</h2>
	</div>
);

class PublicShareDetails extends Component {
	constructor(props) {
		super(props);

		this.downloadFiles = this.downloadFiles.bind(this);
	}

	downloadFiles() {
		this.props.getSignedUrlForZipDownload(
			this.props.shareDetails.accessHash
		);
	}

	render() {
		return (
			<div className="center50pct">
				{this.props.loading ? (
					<div>
						<h1 style={{ marginTop: 200 }}>
							<center>
								<CircularProgress />
							</center>
						</h1>
					</div>
				) : !this.props.shareAvailable ? (
					shareNotAvailable()
				) : (
					<div>
						<h2>{this.props.shareDetails.firmName}</h2>
						<div style={{ marginTop: 25, marginBottom: 10 }}>
							{this.props.shareDetails.senderName} has shared the
							following documents with you:
						</div>
						<ScrollableListOfFiles
							documentList={this.props.shareDetails.documentList}
						/>

						<div style={{ color: "#666666", marginTop: 10 }}>
							{this.props.shareDetails.documentCount} documents
							total
						</div>
						<div style={{ marginTop: 25, marginBottom: 10 }}>
							Message from {this.props.shareDetails.senderName}:
						</div>
						<div
							className="GreyContainer"
							style={{
								marginTop: 10,
								marginBottom: 25,
								maxHeight: 200,
								width: "100%",
								overflowY: "scroll",
								whiteSpace: "pre",
							}}
						>
							<div>{this.props.shareDetails.message}</div>
						</div>

						<div>
							<ButtonWithIcon
								buttonText="Download Files"
								iconName="download"
								className="blue"
								handler={this.downloadFiles}
							/>
						</div>
						{this.props.showFooter ? (
							<div
								style={{ position: "absolute", bottom: 20 }}
								className="ShareFooter"
							>
								Powered by{" "}
								<a href="https://lexworkplace.com">
									LexWorkplace&trade;
								</a>
							</div>
						) : null}
					</div>
				)}
			</div>
		);
	}
}

class PublicShareDetailsStandaloneUnconnected extends PublicShareDetails {
	componentDidMount() {
		this.props.fetchPublicShareDetails(this.props.match.params.accessHash);
	}
}

const PublicSharePageBase = connect(
	(state) => {
		return {
			shareDetails: state.share.publicShareDetails,
			loading: state.share.loading,
			shareAvailable: state.share.shareAvailable,
			showFooter: false,
		};
	},
	{ getSignedUrlForZipDownload }
)(PublicShareDetails);

const PublicSharePageStandalone = connect(
	(state) => {
		return {
			shareDetails: state.share.publicShareDetails,
			loading: state.share.loading,
			shareAvailable: state.share.shareAvailable,
			showFooter: true,
		};
	},
	{ fetchPublicShareDetails, getSignedUrlForZipDownload }
)(PublicShareDetailsStandaloneUnconnected);

export { PublicSharePageStandalone, PublicSharePageBase };
