import API from "../utilities/LocalApiProxy";

import {
	GET_PERMISSION_FEATURE_DETAILS_REQUEST,
	GET_PERMISSION_FEATURE_DETAILS_SUCCESS,
	UPDATE_PERMISSION_FEATURE_DETAILS_ERROR,
	UPDATE_PERMISSION_FEATURE_DETAILS_REQUEST,
	UPDATE_PERMISSION_FEATURE_DETAILS_SUCCESS,
	UNMOUNT_EDIT_PERMISSION_FEATURE,
} from "./types";

export function getUsersAndGroupsForPermissionFeature(featureCode) {
	return function (dispatch) {
		dispatch({
			type: GET_PERMISSION_FEATURE_DETAILS_REQUEST,
			payload: { loading: true },
		});
		API.post("/permission/feature/details", { body: { featureCode } }).then(
			(res) => {
				dispatch({
					type: GET_PERMISSION_FEATURE_DETAILS_SUCCESS,
					payload: {
						allowedUsers: res.mysql.allowedUsers,
						allowedGroups: res.mysql.allowedGroups,
						allUsers: res.mysql.allUsers,
						allGroups: res.mysql.allGroups,
						permissionFeature: featureCode,
					},
				});
			}
		);
	};
}

export function updatePermissionFeature(
	featureCode,
	allowedUsers,
	allowedGroups
) {
	return function (dispatch) {
		dispatch({
			type: UPDATE_PERMISSION_FEATURE_DETAILS_REQUEST,
		});
		API.post("/permission/feature/update", {
			body: { featureCode, allowedUsers, allowedGroups },
		})
			.then(() => {
				dispatch({
					type: UPDATE_PERMISSION_FEATURE_DETAILS_SUCCESS,
				});
			})
			.catch(() => {
				dispatch({
					type: UPDATE_PERMISSION_FEATURE_DETAILS_ERROR,
				});
			});
	};
}

export function unmountEditPermissionFeature() {
	return function (dispatch) {
		dispatch({
			type: UNMOUNT_EDIT_PERMISSION_FEATURE,
		});
	};
}
