import {
	CLOSE_GLOBAL_EDIT_DIALOG,
	OPEN_GLOBAL_EDIT_DIALOG,
	GLOBAL_DIALOG_VALIDATE,
} from "../actions/types";

const initialState = {
	dialogOpen: false,
	label: "",
	value: "",
	mainText: "",
	canSubmit: false,
	errorMessage: "",
	readOnly: false,
	hideTextfield: false,
	submitButtonLabel: "Submit",
	cancelButtonLabel: "Cancel",
	suffix: "",
	recipients: [],
	type: "text",
	textAreaContent: "",
	hideCancelButton: false,
	extraCSSClass: "",
	bulkMatterPermissionsObject: {},
	disabledHoverTextForSubmitButton: "",
};

export default function (state = initialState, action) {
	switch (action.type) {
		case CLOSE_GLOBAL_EDIT_DIALOG:
			return {
				...state,
				dialogOpen: false,
				readOnly: false,
				hideTextfield: false,
				label: "",
				value: "",
				mainText: "",
				canSubmit: false,
				errorMessage: "",
				type: "text",
				textAreaContent: "",
				hideCancelButton: false,
				extraCSSClass: "",
				bulkMatterPermissionsObject: {},
				disabledHoverTextForSubmitButton: "",
			};
		case OPEN_GLOBAL_EDIT_DIALOG:
			return {
				...state,
				label: action.payload.label,
				submitButtonLabel: action.payload.submitButtonLabel,
				value: action.payload.value,
				readOnly: action.payload.readOnly,
				hideTextfield: action.payload.hideTextfield,
				cancelButtonLabel: action.payload.cancelButtonLabel,
				canSubmit: action.payload.canSubmit,
				mainText: action.payload.mainText,
				submitIcon: action.payload.submitIcon,
				textAreaLabel: action.payload.textAreaLabel,
				placeHolder: action.payload.placeHolder,
				suffix: action.payload.suffix,
				type: action.payload.type,
				recipients: action.payload.recipients,
				dialogOpen: true,
				hideCancelButton: action.payload.hideCancelButton,
				extraCSSClass: action.payload.extraCSSClass,
				bulkMatterPermissionsObject:
					action.payload.bulkMatterPermissionsObject,
				disabledHoverTextForSubmitButton:
					action.payload.disabledHoverTextForSubmitButton,
			};
		case GLOBAL_DIALOG_VALIDATE:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
}
