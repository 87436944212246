import React, { Component } from "react";
import { connect } from "react-redux";

import { increaseMonthlyOCR } from "../actions/homepageActions";

import { humanFileSize, numberWithCommas } from "../utilities/utilities";

import { MultipleLinearProgressMetersWithLabel } from "./LinearProgressMeter";

import ButtonLink from "./ButtonLink";

class TenantUsageWidget extends Component {
	constructor(props, context) {
		super(props, context);
	}
	render() {
		var usageMeters = [
			{
				label: "Storage",
				progress: this.props.dataUsagePercent,
				description: `${this.props.totalSizeHuman} of ${this.props.dataLimitHuman} used`,
			},
		];

		if (this.props.ocrEnabled) {
			usageMeters.push({
				label: "OCR",
				progress: this.props.ocrUsagePercent,
				description: (
					<div style={{ display: "flex" }}>
						<div>{`${numberWithCommas(
							this.props.ocrUsage
						)} of ${numberWithCommas(
							this.props.ocrLimit
						)} used`}</div>
						{this.props.canRequestMonthlyOCRLimitIncrease && (
							<div style={{ marginLeft: "auto" }}>
								<ButtonLink
									onClick={() =>
										this.props.increaseMonthlyOCR()
									}
								>
									Increase Limit
								</ButtonLink>
							</div>
						)}
					</div>
				),
				//button
			});
		}

		return this.props.loading ? null : (
			<MultipleLinearProgressMetersWithLabel
				style={{ marginTop: "auto" }}
				topLabel="Usage"
				meters={usageMeters}
			/>
		);
	}
}
const mapStateToProps = (state) => {
	const { totalSizeHuman, totalBytes } = state.homepage.dataUsage;
	const dataLimitGB = state.homepage.usageLimits.STORAGE_LIMIT_GB;
	const dataLimitBytes = dataLimitGB * 1000 * 1000 * 1000;
	const dataLimitHuman = humanFileSize(dataLimitBytes, true);
	const dataUsagePercent = (totalBytes / dataLimitBytes) * 100;

	const ocrEnabled = state.homepage.tenantFeatures.automaticOCR === 1;

	const ocrLimit = state.homepage.usageLimits.OCR_PER_MONTH_NET;
	const ocrUsage = state.homepage.tenantUsage.OCR_TRANSACTIONS_THIS_MONTH;
	const ocrUsagePercent = (ocrUsage / ocrLimit) * 100;
	return {
		totalSizeHuman: totalSizeHuman || "???",
		totalBytes: totalBytes || 0,
		dataLimitHuman,
		dataUsagePercent,
		ocrLimit,
		ocrUsage,
		ocrUsagePercent,
		ocrEnabled,
		loading: state.homepage.loading,
		canRequestMonthlyOCRLimitIncrease:
			state.user.canRequestMonthlyOCRLimitIncrease,
	};
};
export default connect(mapStateToProps, { increaseMonthlyOCR })(
	TenantUsageWidget
);
