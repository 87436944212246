import { CLOSE_GLOBAL_SNACKBAR } from "./types";

export function closeSnackbar({ snackbarKey }) {
	return function (dispatch) {
		dispatch({
			type: CLOSE_GLOBAL_SNACKBAR,
			payload: { snackbarKey },
		});
	};
}
