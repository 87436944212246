import {
	FETCH_CLIENT_DETAIL_REQUEST,
	FETCH_CLIENT_DETAIL_SUCCESS,
	FETCH_CLIENT_DETAIL_FAILURE,
	UPDATE_CLIENT,
	FETCH_CLIENT_LIST_REQUEST,
	FETCH_CLIENT_LIST_SUCCESS,
	FETCH_ALL_USERS_SUCCESS,
	GET_EVERYTHING_FOR_NEW_EDIT_CLIENT_REQUEST,
	GET_EVERYTHING_FOR_NEW_CLIENT_SUCCESS,
	GET_EVERYTHING_FOR_NEW_CLIENT_FAILURE,
	GET_EVERYTHING_FOR_EDIT_CLIENT_SUCCESS,
	GET_EVERYTHING_FOR_EDIT_CLIENT_FAILURE,
	UNMOUNT_NEW_EDIT_CLIENT,
	SET_SUBHEADER_TEXT,
} from "./types";

import API from "../utilities/LocalApiProxy";

export function fetchClientDetail(clientID) {
	return function (dispatch) {
		dispatch({ type: FETCH_CLIENT_DETAIL_REQUEST });
		API.get("/client", {
			queryParams: { clientID: clientID },
		})
			.then((result) => {
				dispatch({
					type: FETCH_CLIENT_DETAIL_SUCCESS,
					payload: {
						clientDetails: result.mysql[0],
					},
				});
				dispatch({
					type: SET_SUBHEADER_TEXT,
					payload: {
						subheaderText: result.mysql[0].clientName,
						subheaderChips: result.mysql[0].tags.length
							? result.mysql[0].tags.map((tag, index) => {
									return { label: tag.tagName, key: index };
								})
							: [],
					},
				});
			})
			.catch(() => {
				window.globalHistory.push("/");
				dispatch({
					type: FETCH_CLIENT_DETAIL_FAILURE,
				});
			});
	};
}

export function createNewClient(clientData) {
	//todo: this should not just copy the client state.  ONly take whats needed.  Also bug: unedited default "active" value not being sent properly

	const newClientData = { ...clientData };
	newClientData.clientTags = clientData.clientTags
		? clientData.clientTags.map((tag) => tag.label)
		: [];
	newClientData.primaryAttorneys = clientData.primaryAttorneys
		? clientData.primaryAttorneys.map((atty) => atty.value)
		: [];
	newClientData.newClientStatus = clientData.newClientStatus.value;
	newClientData.newClientName = newClientData.newClientName.trim();
	return function () {
		API.post("/clients/new", {
			body: { newClientData: newClientData },
		});
	};
}
export function updateClient(clientID, clientData) {
	const newClientData = { ...clientData };
	newClientData.clientTags = clientData.clientTags
		? clientData.clientTags.map((tag) => tag.label)
		: [];
	newClientData.primaryAttorneys = clientData.primaryAttorneys
		? clientData.primaryAttorneys.map((atty) => atty.value)
		: [];
	newClientData.newClientStatus = clientData.newClientStatus.value;
	newClientData.newClientName = newClientData.newClientName.trim();
	return function () {
		API.put("/client/update", {
			queryParams: { clientID: clientID },
			body: { newClientData: newClientData },
		});
	};
}
export function deleteClients(clientIDs) {
	return function (dispatch) {
		API.put("/clients/delete", {
			body: { clientIDs },
		}).then(() => {
			fetchClientList()(dispatch);
		});
	};
}
export function deactivateClients(clientIDs) {
	return function (dispatch) {
		API.put("/clients/deactivate", {
			body: { clientIDs },
		}).then(() => {
			fetchClientList()(dispatch);
		});
	};
}
export function activateClients(clientIDs) {
	return function (dispatch) {
		API.put("/clients/activate", {
			body: { clientIDs },
		}).then(() => {
			fetchClientList()(dispatch);
		});
	};
}

export function updateClientAction() {
	return {
		type: UPDATE_CLIENT,
	};
}

export function fetchClientList() {
	return function (dispatch) {
		dispatch({ type: FETCH_CLIENT_LIST_REQUEST });

		API.get("/clients", {})
			.then((result) => {
				dispatch({
					type: FETCH_CLIENT_LIST_SUCCESS,
					payload: {
						clientListData: result.mysql,
					},
				});
			})
			.catch((err) => console.log(err));
	};
}
export function getEverythingForNewClient() {
	return function (dispatch) {
		var helperPromise = API.get("/client/newClientHelper");

		dispatch({ type: GET_EVERYTHING_FOR_NEW_EDIT_CLIENT_REQUEST });
		helperPromise
			.then((results) => {
				dispatch({
					type: GET_EVERYTHING_FOR_NEW_CLIENT_SUCCESS,
					payload: {
						availablePrimaryAttorneys: results.mysql.Users,
						clientTags: results.mysql.ClientTags,
					},
				});
				dispatch({
					type: SET_SUBHEADER_TEXT,
					payload: { subheaderText: "New Client" },
				});
			})
			.catch(() => {
				dispatch({
					type: GET_EVERYTHING_FOR_NEW_CLIENT_FAILURE,
				});
			});
	};
}
export function getEverythingForEditClient(clientID) {
	return function (dispatch) {
		dispatch({ type: GET_EVERYTHING_FOR_NEW_EDIT_CLIENT_REQUEST });
		API.get("/client/editClientHelper", { queryParams: { clientID } })
			.then((res) => {
				const { Client, ClientTags, Users } = res.mysql;
				dispatch({
					type: GET_EVERYTHING_FOR_EDIT_CLIENT_SUCCESS,
					payload: {
						availablePrimaryAttorneys: Users,
						clientTags: ClientTags,
						clientDetails: Client,
					},
				});
				dispatch({
					type: SET_SUBHEADER_TEXT,
					payload: {
						subheaderText: "Editing " + Client.clientName,
					},
				});
			})
			.catch(() => {
				dispatch({
					type: GET_EVERYTHING_FOR_EDIT_CLIENT_FAILURE,
				});
				window.globalHistory.push("/");
			});
	};
}

//todo: not perfect.  This sets the loading state to true,
// but really its not loading.  This is a workaround so that the
// previous "false" loading state doesn't mess up the
// edit dialog AFTER already accessing edit dialog
export function unmountNewEditClient() {
	return function (dispatch) {
		dispatch({
			type: UNMOUNT_NEW_EDIT_CLIENT,
		});
	};
}
export function getAvailablePrimaryAttorneys() {
	return function (dispatch) {
		API.get("/users", {}).then((result) => {
			dispatch({
				type: FETCH_ALL_USERS_SUCCESS,
				payload: { availablePrimaryAttorneys: result.mysql },
			});
		});
	};
}
