import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";

const LxwLinearProgress = withStyles((theme) => ({
	root: {
		height: 4,
	},
	colorPrimary: {
		backgroundColor: "var(--lw-light-blue-b)",
	},
	bar: {
		backgroundColor: "var(--lw-blue)",
	},
}))(LinearProgress);

const LabeledLinearProgressMeter = (props) => {
	return (
		<div>
			<div style={{ display: "flex" }}>
				<div>{props.label}</div>
				<div
					style={{
						marginLeft: "auto",
						width: 300,
						paddingTop: 8,
					}}
				>
					<LxwLinearProgress
						variant="determinate"
						value={Math.min(Math.max(0, props.progress), 100)}
					/>
				</div>
			</div>
			{props.description && (
				<div style={{ marginTop: 8 }}>{props.description}</div>
			)}
		</div>
	);
};
const MultipleLinearProgressMetersWithLabel = (props) => {
	return (
		<div style={props.style}>
			<div
				className="MainFontBold"
				style={{
					borderBottom: "1px solid",
					borderColor: "var(--lw-dark-grey)",
					marginBottom: 16,
					paddingBottom: 8,
				}}
			>
				{props.topLabel}
			</div>
			<div style={{ width: 380 }}>
				{props.meters.map(({ label, progress, description }, index) => (
					<div key={index} style={{ marginBottom: 12 }}>
						<LabeledLinearProgressMeter
							label={label}
							description={description}
							progress={progress}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default LabeledLinearProgressMeter;
export { MultipleLinearProgressMetersWithLabel };
