import React, { useState } from "react";
import { DropDownSelect } from "./DropDownSelect";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SearchBox({
	searchUrlAfterQuery,
	allMatters,
	allClients,
	searchMenuOptions,
	placeholder,
}) {
	const [query, setQuery] = useState("");

	const navigateToSearch = (querystring) => {
		const url = `/search/${Math.floor(
			Math.random() * 10000
		)}?q=${query}${querystring || searchUrlAfterQuery}`;
		setQuery("");
		window.globalHistory.push(url);
	};
	const navigateToMatter = (matterID) => {
		const url = `/matters/${matterID}/docs/`;
		setQuery("");
		window.globalHistory.push(url);
	};
	const navigateToClientDetail = (clientID) => {
		const url = `/clients/${clientID}`;
		setQuery("");
		window.globalHistory.push(url);
	};

	const handleChange = (key) => {
		if (key.type === "matter") {
			navigateToMatter(key.value);
		} else if (key.type === "client") {
			navigateToClientDetail(key.value);
		} else if (key.type === "search") {
			navigateToSearch(key.value);
		}
	};
	const getMatterOptions = () => {
		return allMatters
			? allMatters.map((x) => ({
					label: x.name,
					value: x.id,
					type: "matter",
				}))
			: [];
	};
	const getClientOptions = () => {
		return allClients
			? allClients.map((x) => ({
					label: x.name,
					value: x.id,
					type: "client",
				}))
			: [];
	};

	const filterOptions = (candidate) => {
		return !candidate.data.__isNew__;
	};

	const searchOptionsNoSearchString = () => {
		return searchMenuOptions.map((s) => {
			return {
				label: s.placeholder,
				value: s.querystring,
				type: "search",
			};
		});
	};
	const searchOptionsWithSearchString = (searchString) => {
		return searchMenuOptions.map((s) => {
			return {
				label: `${s.placeholder} for "${searchString}"`,
				value: s.querystring,
				type: "search",
			};
		});
	};
	const Option = (props) => {
		const typeMap = {
			search: { icon: "search", actionText: "Search" },
			matter: {
				icon: "briefcase",
				actionText: "Go to Matter →",
			},
			client: { icon: "user", actionText: "Go to Client →" },
		};

		const { icon, actionText } = typeMap[props.data.type];

		return (
			<div
				className="globalSearchboxOption"
				style={{ position: "relative" }}
			>
				<span>
					<FontAwesomeIcon
						icon={icon}
						size="1x"
						style={{ marginRight: 10, position: "absolute" }}
					/>
				</span>
				<components.Option {...props} />
				<span
					className="searchboxActionPill"
					style={{
						position: "absolute",
						right: 10,
						top: 7,
						backgroundColor: "var(--lw-light-grey-a)",
						borderRadius: 2,
						outline: "1px solid rgba(0,0,0,0.05)",
						pointerEvents: "none",
					}}
				>
					{actionText}
				</span>
			</div>
		);
	};
	const DropdownIndicator = (props) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<FontAwesomeIcon icon={"search"} />
				</components.DropdownIndicator>
			)
		);
	};
	const getTextboxValue = () => {
		return query ? { value: query, label: query } : null;
	};
	const getDefaultSearchDropdown = () => {
		return searchOptionsNoSearchString();
	};
	const loadOptions = (inputValue) => {
		return Promise.resolve(
			filterOptionsX(inputValue, getMatterOptions(), getClientOptions())
		);
	};
	const filterOptionsX = (inputValue, matterOptions, clientOptions) => {
		if (inputValue) {
			const matterMatches = matterOptions
				.filter((option) =>
					option.label
						.toLowerCase()
						.includes(inputValue.toLowerCase())
				)
				.sort((a, b) => a.label.localeCompare(b.label));
			const clientMatches = clientOptions
				.filter((option) =>
					option.label
						.toLowerCase()
						.includes(inputValue.toLowerCase())
				)
				.sort((a, b) => a.label.localeCompare(b.label));
			return searchOptionsWithSearchString(inputValue)
				.concat(matterMatches.slice(0, 3))
				.concat(clientMatches.slice(0, 3));
		} else {
			return getDefaultSearchDropdown();
		}
	};

	return (
		<div
			style={{
				minWidth: 340,
				position: "relative",
				top: 2,
			}}
		>
			<div
				style={{
					top: -24,
					position: "absolute",
					zIndex: 151,
					width: "100%",
					height: 28,
					textAlign: "left",
				}}
			>
				<div
					style={{
						lineHeight: 1,
						height: 30,
					}}
				>
					<DropDownSelect
						label="Modified By"
						name="usersFilter"
						disableCache
						placeholder={placeholder}
						isClearable
						isCreatable
						isAsync
						isDisabled={false}
						defaultValue={null}
						components={{ Option, DropdownIndicator }}
						onKeyDown={(e) =>
							e.key === "Enter" && navigateToSearch()
						}
						value={getTextboxValue()}
						onInputChange={setQuery}
						options={getDefaultSearchDropdown()}
						handler={handleChange}
						loadOptions={loadOptions}
						filterOption={filterOptions}
						classNamePrefix={"globalSearchbox"}
					/>
				</div>
			</div>
		</div>
	);
}

export { SearchBox };
