import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import { ButtonWithIcon } from "./ButtonWithIcon";
import { LabeledTextInput } from "./TextInput";

import {
	setupTOTP,
	verifyTotpToken,
	initMFA,
} from "../actions/userAuthActions";
import { setSubheaderText } from "../actions/navigateActions";

import {
	TOTP_SETUP_CODE_GENERATED,
	VERIFY_TOTP_TOKEN_SUCCESS,
	VERIFY_TOTP_TOKEN_FAILURE,
} from "../actions/types";

class ConfigureMFA extends Component {
	constructor(props) {
		super(props);
		this.state = {
			code: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.submit = this.submit.bind(this);
		this.generateErrorMessage = this.generateErrorMessage.bind(this);
		this.generateQRCodeUrl = this.generateQRCodeUrl.bind(this);
	}

	handleChange(key, value) {
		this.setState({ [key]: value }, this.validate);
	}
	generateErrorMessage() {}
	componentDidMount() {
		this.props.initMFA();
		this.props.setSubheaderText("Configure Multi-Factor Authentication");
	}
	submit() {
		if (
			(this.props.codeGenerated || this.props.mfaConfiguredFailure) &&
			this.state.code.length > 0
		) {
			this.props.verifyTotpToken(this.state.code);
		} else {
			this.props.setupTOTP();
		}
	}
	generateQRCodeUrl(secret) {
		const issuer = "LexWorkplace";
		const str =
			"otpauth://totp/" +
			this.props.userEmail +
			"?secret=" +
			secret +
			"&issuer=" +
			issuer;
		return str;
	}
	render() {
		if (this.props.mfaConfiguredSuccess) {
			return (
				<div className="center300">
					<div className="inlineInfoMessage">
						{this.props.infoMessage}
					</div>
					<Link to="/settings">Back to Settings</Link>
				</div>
			);
		} else {
			return (
				<div className="center300">
					{this.props.mfaConfiguredFailure ? (
						<div className="inlineErrorIndicator">
							{this.props.errorMessage}
						</div>
					) : null}
					{this.props.infoMessage ? (
						<div className="inlineInfoMessage">
							{this.props.infoMessage}
						</div>
					) : null}
					{this.props.codeGenerated ||
					this.props.mfaConfiguredFailure ? (
						<div>
							<div />
							<QRCode
								value={this.generateQRCodeUrl(
									this.props.mfaSetupCode
								)}
								size={256}
								style={{ display: "block", margin: "0px auto" }}
							/>

							<LabeledTextInput
								label="MFA Code"
								name="code"
								placeholder="code"
								onChange={this.handleChange}
							/>
						</div>
					) : null}
					<ButtonWithIcon
						disabled={
							this.props.codeGenerated ||
							this.props.mfaConfiguredFailure
								? this.state.code.length > 0
									? false
									: true
								: false
						}
						handler={this.submit}
						buttonText={
							this.props.codeGenerated ||
							this.props.mfaConfiguredFailure
								? "Confirm MFA"
								: "Generate QR Code"
						}
						key={this.props.codeGenerated}
						iconName="check"
					/>
				</div>
			);
		}
	}
}
ConfigureMFA.propTypes = {
	errorMessage: PropTypes.string.isRequired,
	infoMessage: PropTypes.string,
	username: PropTypes.string.isRequired,
	userEmail: PropTypes.string.isRequired,
	mfaSetupCode: PropTypes.string.isRequired,
	setupTOTP: PropTypes.func.isRequired,
	verifyTotpToken: PropTypes.func.isRequired,
	initMFA: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
	errorMessage: state.user.errorMessage,
	infoMessage: state.user.infoMessage,
	username: state.user.username,
	userEmail: state.user.userEmail,
	mfaSetupCode: state.user.mfaSetupCode,
	codeGenerated: state.user.authState === TOTP_SETUP_CODE_GENERATED,
	mfaConfiguredSuccess: state.user.authState === VERIFY_TOTP_TOKEN_SUCCESS,
	mfaConfiguredFailure: state.user.authState === VERIFY_TOTP_TOKEN_FAILURE,
});
export default connect(mapStateToProps, {
	setupTOTP,
	verifyTotpToken,
	initMFA,
	setSubheaderText,
})(ConfigureMFA);
