import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
	fetchMatterDetail,
	closeMatter,
	setEditMatterRedirectLocation,
} from "../actions/matterActions";
import { unselectAll } from "../actions/multiselectActions";
import { listDirectory } from "../actions/documentActions";
import { setCreatableContext } from "../actions/navigateActions";

import { DropDownButtonWithRouter } from "./DropDownButton";
import DocumentList from "./DocumentList";
import TextGrid from "./TextGrid";
import MaterialTabs from "./MaterialTabs";
import {
	encodeFullpath,
	decodeFullpath,
	shortDateFormatOffset,
} from "../utilities/utilities";
import MaximumItemsList from "./MaximumItemsList";

import { withRouter } from "react-router-dom";
import cliofavicon from "../images/clio-favicon.png";
import { getClioIntegrationSettingStatus } from "../actions/tenantActions";
import { toggleMatterInfo } from "../actions/browserLocalstorageActions";

import { ExternalSharingManagementForMatter } from "./ExternalSharingManagement";

const basepathnameMap = {
	docs: "Documents",
	email: "Email",
	notes: "Notes",
	sharing: "Sharing",
};
const tabMap = [
	{ label: "Documents", url: "docs" },
	{ label: "Email", url: "email" },
	{ label: "Notes", url: "notes" },
	{ label: "Sharing", url: "sharing" },
];

//todo: this config is duplicated in firmDocs
const tabUrlMap = {
	"docs/": {
		index: 0,
		customColumnsKey: "/documents",
		breadcrumbsIcon: "file",
		selectionListName: "selectedDocuments",
		backendRoot: "/document",
		itemNoun: "documents",
	},
	"email/": {
		index: 1,
		customColumnsKey: "/email",
		breadcrumbsIcon: "envelope",
		selectionListName: "selectedEmails",
		backendRoot: "/email",
		itemNoun: "emails",
	},
	"notes/": {
		index: 2,
		customColumnsKey: "/notes",
		breadcrumbsIcon: "pencil-alt",
		selectionListName: "selectedNotes",
		backendRoot: "/notes",
		itemNoun: "notes",
	},
	"sharing/": {
		index: 3,
		customColumnsKey: "/sharing",
		breadcrumbsIcon: "pencil-alt",
		selectionListName: "selectedNotes",
		backendRoot: "/notes",
		itemNoun: "notes",
	},
	"public/": {
		index: -1,
		customColumnsKey: "/documents",
		breadcrumbsIcon: "file",
		selectionListName: "selectedPublicDocuments",
		backendRoot: "/document",
		itemNoun: "documents",
	},
	"private/": {
		index: -1,
		customColumnsKey: "/documents",
		breadcrumbsIcon: "file",
		selectionListName: "selectedPrivateDocuments",
		backendRoot: "/document",
		itemNoun: "documents",
	},
};
const DocumentListWithRouter = withRouter(DocumentList);
class MatterDetail extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			s3Basepath: "",
			lastLocation: {
				docs: "docs/",
				email: "email/",
				notes: "notes/",
				sharing: "sharing/",
			},
		};
		this.getAPIBasepath = this.getAPIBasepath.bind(this);
		this.switchedTab = this.switchedTab.bind(this);
		this.makeEditMenu = this.makeEditMenu.bind(this);
		this.refresh = this.refresh.bind(this);
	}
	getUrlPrefix() {
		return (
			"/matters/" +
			this.props.match.params.id +
			"/" +
			this.props.match.params.root +
			"/"
		);
	}
	getAPIBasepath() {
		return (
			this.props.match.params.root +
			"/" +
			(decodeFullpath(this.props.match.params.fullpath) || "")
		);
	}

	switchedTab(value) {
		const newURL =
			"/matters/" +
			this.props.match.params.id +
			"/" +
			this.state.lastLocation[tabMap[value].url];
		this.props.history.push(newURL);
	}
	//Open in ClioMatter
	setClioMatterID() {
		return `https://app.clio.com/nc/#/matters/${this.props.matterDetails.clioMatterID}`;
	}
	refresh(includeMatterDetails) {
		const activeTab = this.props.match.params.root;
		if (activeTab === "sharing") {
			this.props.fetchMatterDetail(this.props.match.params.id);
		} else {
			//docs, email, or notes
			this.props.listDirectory(
				this.props.match.params.id,
				this.getAPIBasepath(),
				this.props.match.params.root,
				includeMatterDetails
			);
			this.props.setCreatableContext(true);
			this.props.unselectAll();
			var table = document.querySelector(".rt-tbody");
			if (table) {
				document.querySelector(".rt-tbody").scrollTop = 0;
			}
		}
	}
	componentDidMount() {
		this.refresh(true);
		this.props.getClioIntegrationSettingStatus();
	}
	componentWillUnmount() {
		this.props.setCreatableContext(false);
	}
	static getDerivedStateFromProps(props, state) {
		var lastLocation = { ...state.lastLocation };
		lastLocation[props.match.params.root] = encodeFullpath(
			props.match.params.root + "/" + (props.match.params.fullpath || "")
		);
		return { ...state, lastLocation };
	}
	componentDidUpdate(prevProps) {
		if (
			prevProps.match.params.fullpath !==
				this.props.match.params.fullpath ||
			prevProps.match.params.root !== this.props.match.params.root ||
			prevProps.match.params.id !== this.props.match.params.id
		) {
			this.refresh(false);
		}
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.refresh(true);
		}
	}
	makeEditMenu() {
		const postEditLocation = `/matters/${this.props.match.params.id}/docs`;
		var menu = [
			{
				text: "Edit Matter Info",
				href: "/matters/" + this.props.match.params.id + "/edit/",
				selectCallback: () =>
					this.props.setEditMatterRedirectLocation(postEditLocation),
			},
		];
		if (this.props.hasPermissionsAccess) {
			menu.push({
				text: "Edit Matter Permissions",
				href:
					"/matters/" +
					this.props.match.params.id +
					"/edit/permissions",
				selectCallback: () =>
					this.props.setEditMatterRedirectLocation(postEditLocation),
			});
		}
		menu.push(
			!this.props.matterDetails.dateClosed
				? {
						text: "Close Matter",
						onSelect: () =>
							this.props.closeMatter(
								this.props.match.params.id,
								false
							),
					}
				: {
						text: "Reopen Matter",
						onSelect: () =>
							this.props.closeMatter(
								this.props.match.params.id,
								true
							),
					}
		);

		return menu;
	}
	render() {
		return (
			<div className="fullHeightFlex noDefaultMargin">
				<div
					className="clientInfoHeader"
					style={{
						position: "relative",
						minHeight: 48,
						padding: 24,
						display: this.props.matterInfoHidden ? "none" : "block",
					}}
				>
					<div
						style={{
							display: "grid",
							gridTemplateColumns: "4fr 2fr",
						}}
					>
						<div>
							<TextGrid
								numColumns={2}
								columns={[
									[
										"Client",
										this.props.matterDetails.clientName
											? {
													href: `/clients/${this.props.matterDetails.clientID}`,
													text: this.props
														.matterDetails
														.clientName,
												}
											: "-",
									],
									[
										"Custom Matter ID",
										this.props.matterDetails
											.customMatterID || "-",
									],
								]}
							/>

							<TextGrid
								numColumns={2}
								columns={[
									[
										"Matter Type",
										this.props.matterDetails.matterType,
									],
									[
										"Office/Branch",
										this.props.matterDetails.officeBranch ||
											"-",
									],
								]}
							/>
							<TextGrid
								numColumns={2}
								columns={[
									[
										"Date Opened",
										this.props.matterDetails.dateOpened
											? shortDateFormatOffset(
													this.props.matterDetails
														.dateOpened
												)
											: "-",
									],

									[
										"Date Closed",
										this.props.matterDetails.dateClosed
											? shortDateFormatOffset(
													this.props.matterDetails
														.dateClosed
												)
											: "-",
									],
								]}
							/>
							<TextGrid
								numColumns={1}
								columns={[
									[
										this.props.matterDetails
											.clioMatterID ? (
											<div
												style={{
													paddingTop: 10,
												}}
											>
												<a
													target="_blank"
													rel="noopener noreferrer"
													href={this.setClioMatterID()}
												>
													<img
														alt="someImage"
														align="center"
														src={cliofavicon}
														className="legalworks-clio-logo"
													/>
													Open in Clio Manage
												</a>
											</div>
										) : (
											""
										),
									],
								]}
							/>
						</div>
						<div>
							<TextGrid
								numColumns={1}
								columns={[
									[
										"Assigned Staff",
										<MaximumItemsList
											maxItems={5}
											key="AssignedStaff"
											items={
												this.props.matterDetails
													.assignedStaff
													? this.props.matterDetails.assignedStaff.map(
															(x) => x.displayName
														)
													: []
											}
										/>,
									],
								]}
							/>
						</div>
					</div>
					<div style={{ position: "absolute", top: 16, right: 16 }}>
						<DropDownButtonWithRouter
							title="Edit"
							pullRight
							menuItems={this.makeEditMenu()}
						/>
					</div>
				</div>
				<MaterialTabs
					activeTab={
						tabUrlMap[this.props.match.params.root + "/"].index
					}
					tabs={tabMap}
					switchedTab={this.switchedTab}
					toggleHide={this.props.toggleMatterInfo}
					hidden={this.props.matterInfoHidden}
				/>
				{this.props.match.params.root === "sharing" ? (
					<ExternalSharingManagementForMatter
						matterIDFilter={this.props.match.params.id}
					/>
				) : (
					<DocumentListWithRouter
						urlPrefix={this.getUrlPrefix()}
						matterID={this.props.match.params.id}
						basepath={
							"/matters/" + this.props.match.params.id + "/"
						}
						breadcrumbRootName={
							basepathnameMap[this.props.match.params.root]
						}
						root={this.props.match.params.root + "/"}
						apiBasepath={this.getAPIBasepath()}
						fullpath={this.props.match.params.fullpath}
						values={this.props.documentList}
						tabUrlMap={tabUrlMap}
						clioMatterID={this.props.matterDetails.clioMatterID}
						clioIntegrationSettingStatus={
							this.props.clioIntegrationSettingStatus
						}
						matterInfoVisible={!this.props.matterInfoHidden}
					/>
				)}
			</div>
		);
	}
}
MatterDetail.propTypes = {
	loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
	matterDetails: state.matter.matterDetails,
	documentList: state.document.documentList,
	prefix: state.document.prefix,
	loading: state.matter.matterDetailsLoading,
	hasPermissionsAccess: state.user.hasPermissionsAccess,
	clioIntegrationSettingStatus: state.tenant.clioIntegrationSettingStatus,
	matterInfoHidden: state.browserLocalstorage.matterInfoHidden,
});
export default connect(mapStateToProps, {
	fetchMatterDetail,
	closeMatter,
	listDirectory,
	unselectAll,
	setCreatableContext,
	setEditMatterRedirectLocation,
	getClioIntegrationSettingStatus,
	toggleMatterInfo,
})(MatterDetail);
