export const FETCH_POSTS = "FETCH_POSTS";
export const NEW_POST = "NEW_POST";
export const NAVIGATE = "NAVIGATE";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_UNAUTHENTICATED = "USER_UNAUTHENTICATED";
export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED";
export const USER_LOGIN_UNSUCCESSFUL = "USER_LOGIN_UNSUCCESSFUL";
export const USER_FORGOT_PASSWORD_REQUEST_SUCCESS =
	"USER_FORGOT_PASSWORD_REQUEST_SUCCESS";
export const USER_FORGOT_PASSWORD_REQUEST_FAILURE =
	"USER_FORGOT_PASSWORD_REQUEST_FAILURE";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const SOFTWARE_TOKEN_MFA_CHALLENGE = "SOFTWARE_TOKEN_MFA_CHALLENGE";
export const MFA_VERIFICATION_ERROR = "MFA_VERIFICATION_ERROR";
export const MFA_SESSION_EXPIRED = "MFA_SESSION_EXPIRED";
export const UPDATE_ATTRIBUTES_SUCCESS = "UPDATE_ATTRIBUTES_SUCCESS";
export const UPDATE_ATTRIBUTES_FAILURE = "UPDATE_ATTRIBUTES_FAILURE";

export const TOTP_SETUP_CODE_GENERATED = "TOTP_SETUP_CODE_GENERATED";
export const VERIFY_TOTP_TOKEN_SUCCESS = "VERIFY_TOTP_TOKEN_SUCCESS";
export const VERIFY_TOTP_TOKEN_FAILURE = "VERIFY_TOTP_TOKEN_FAILURE";
export const VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const INIT_MFA = "INIT_MFA";
export const SET_REDIRECT_URL = "SET_REDIRECT_URL";

export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_EXTERNAL_SAVED_EMAIL_ADDRESSES_SUCCESS =
	"GET_EXTERNAL_SAVED_EMAIL_ADDRESSES_SUCCESS";

export const FETCH_CLIENT_LIST = "FETCH_CLIENT_LIST";
export const NEW_CLIENT = "NEW_CLIENT";
export const TOGGLE_CLIENT_LIST_COLUMN = "TOGGLE_CLIENT_LIST_COLUMN";

export const TOGGLE_CLIENT_TAG_COLUMN = "TOGGLE_CLIENT_TAG_COLUMN";

export const TOGGLE_COLUMN = "TOGGLE_COLUMNN";

export const FETCH_CLIENT_DETAIL = "FETCH_CLIENT_DETAIL";
export const FETCH_CLIENT_DETAIL_REQUEST = "FETCH_CLIENT_DETAIL_REQUEST";
export const FETCH_CLIENT_DETAIL_SUCCESS = "FETCH_CLIENT_DETAIL_SUCCESS";
export const FETCH_CLIENT_DETAIL_FAILURE = "FETCH_CLIENT_DETAIL_FAILURE";

export const UPDATE_CLIENT = "UPDATE_CLIENT";

export const FETCH_CLIENT_LIST_REQUEST = "FETCH_CLIENT_LIST_REQUEST";
export const FETCH_CLIENT_LIST_SUCCESS = "FETCH_CLIENT_LIST_SUCCESS";
export const FETCH_CLIENT_LIST_FAILURE = "FETCH_CLIENT_LIST_FAILURE";

export const GET_EVERYTHING_FOR_NEW_EDIT_CLIENT_REQUEST =
	"GET_EVERYTHING_FOR_NEW_EDIT_CLIENT_REQUEST";
export const GET_EVERYTHING_FOR_NEW_CLIENT_SUCCESS =
	"GET_EVERYTHING_FOR_NEW_CLIENT_SUCCESS";
export const GET_EVERYTHING_FOR_NEW_CLIENT_FAILURE =
	"GET_EVERYTHING_FOR_NEW_CLIENT_FAILURE";
export const GET_EVERYTHING_FOR_EDIT_CLIENT_SUCCESS =
	"GET_EVERYTHING_FOR_EDIT_CLIENT_SUCCESS";
export const GET_EVERYTHING_FOR_EDIT_CLIENT_FAILURE =
	"GET_EVERYTHING_FOR_EDIT_CLIENT_FAILURE";

export const UNMOUNT_NEW_EDIT_CLIENT = "UNMOUNT_NEW_EDIT_CLIENT";

export const FETCH_CLIENT_TAGS_SUCCESS = "FETCH_CLIENT_TAGS_SUCCESS";

export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";

export const FETCH_MATTERS_LIST_REQUEST = "FETCH_MATTERS_LIST_REQUEST";
export const FETCH_MATTERS_LIST_SUCCESS = "FETCH_MATTERS_LIST_SUCCESS";
export const FETCH_MATTERS_LIST_FAILURE = "FETCH_MATTERS_LIST_FAILURE";

export const FETCH_MATTER_LIST = "FETCH_MATTER_LIST";
export const NEW_MATTER = "NEW_MATTER";
export const TOGGLE_MATTER_LIST_COLUMN = "TOGGLE_MATTER_LIST_COLUMN";

export const TOGGLE_MATTER_TAG_COLUMN = "TOGGLE_MATTER_TAG_COLUMN";

export const FETCH_MATTER_DETAIL = "FETCH_MATTER_DETAIL";
export const FETCH_MATTER_DETAIL_REQUEST = "FETCH_MATTER_DETAIL_REQUEST";
export const FETCH_MATTER_DETAIL_SUCCESS = "FETCH_MATTER_DETAIL_SUCCESS";
export const FETCH_MATTER_DETAIL_FAILURE = "FETCH_MATTER_DETAIL_FAILURE";

export const FETCH_MATTER_LIST_REQUEST = "FETCH_MATTER_LIST_REQUEST";
export const FETCH_MATTER_LIST_SUCCESS = "FETCH_MATTER_LIST_SUCCESS";
export const FETCH_MATTER_LIST_FAILURE = "FETCH_MATTER_LIST_FAILURE";

export const CREATE_NEW_MATTER_REQUEST = "CREATE_NEW_MATTER_REQUEST";
export const CREATE_NEW_MATTER_SUCCESS = "CREATE_NEW_MATTER_SUCCESS";
export const CREATE_NEW_MATTER_FAILURE = "CREATE_NEW_MATTER_FAILURE";

export const UPDATE_MATTER_REQUEST = "UPDATE_MATTER_REQUEST";
export const UPDATE_MATTER_SUCCESS = "UPDATE_MATTER_SUCCESS";
export const UPDATE_MATTER_FAILURE = "UPDATE_MATTER_FAILURE";

export const CLOSE_MATTER_SUCCESS = "CLOSE_MATTER_SUCCESS";
export const REOPEN_MATTER_SUCCESS = "REOPEN_MATTER_SUCCESS";

export const GET_EVERYTHING_FOR_NEW_EDIT_MATTER_REQUEST =
	"GET_EVERYTHING_FOR_NEW_EDIT_MATTER_REQUEST";
export const GET_EVERYTHING_FOR_NEW_MATTER_SUCCESS =
	"GET_EVERYTHING_FOR_NEW_MATTER_SUCCESS";
export const GET_EVERYTHING_FOR_NEW_MATTER_FAILURE =
	"GET_EVERYTHING_FOR_NEW_MATTER_FAILURE";
export const GET_EVERYTHING_FOR_EDIT_MATTER_SUCCESS =
	"GET_EVERYTHING_FOR_EDIT_MATTER_SUCCESS";
export const GET_EVERYTHING_FOR_EDIT_MATTER_FAILURE =
	"GET_EVERYTHING_FOR_EDIT_MATTER_FAILURE";

export const UNMOUNT_NEW_EDIT_MATTER = "UNMOUNT_NEW_EDIT_MATTER";
export const UNMOUNT_MATTER_DETAIL = "UNMOUNT_MATTER_DETAIL";

export const FETCH_MATTER_TAGS_SUCCESS = "FETCH_MATTER_TAGS_SUCCESS";
export const FETCH_MATTER_TYPES_SUCCESS = "FETCH_MATTER_TYPES_SUCCESS";
export const FETCH_OFFICE_BRANCH_SUCCESS = "FETCH_OFFICE_BRANCH_SUCCESS";

export const ACCESS_MATTERS_LIST = "ACCESS_MATTERS_LIST";

export const SET_EDIT_MATTER_REDIRECT_LOCATION =
	"SET_EDIT_MATTER_REDIRECT_LOCATION";

export const FETCH_MATTERS_TAGS_SUCCESS = "FETCH_MATTERS_TAGS_SUCCESS";

export const SET_SUBHEADER_TEXT = "SET_SUBHEADER_TEXT";

export const REQUEST = "REQUEST";

export const CLOSE_GLOBAL_EDIT_DIALOG = "CLOSE_GLOBAL_EDIT_DIALOG";
export const OPEN_GLOBAL_EDIT_DIALOG = "OPEN_GLOBAL_EDIT_DIALOG";
export const GLOBAL_DIALOG_VALIDATE = "GLOBAL_DIALOG_VALIDATE";

export const OPEN_GLOBAL_SNACKBAR = "OPEN_GLOBAL_SNACKBAR";
export const CLOSE_GLOBAL_SNACKBAR = "CLOSE_GLOBAL_SNACKBAR";
export const OPEN_PERSISTENT_ERROR_SNACKBAR = "OPEN_PERSISTENT_ERROR_SNACKBAR";
export const OPEN_PERSISTENT_SNACKBAR = "OPEN_PERSISTENT_SNACKBAR";

export const GET_ALL_CUSTOM_COLUMNS_SUCCESS = "GET_ALL_CUSTOM_COLUMNS_SUCCESS";
export const GET_ALL_CUSTOM_COLUMNS_FAILURE = "GET_ALL_CUSTOM_COLUMNS_FAILURE";

export const UPDATE_USER_SETTING = "UPDATE_USER_SETTING";
export const GET_USER_SETTINGS_SUCCESS = "GET_USER_SETTINGS_SUCCESS";

export const LIST_PREFIX_REQUEST = "LIST_PREFIX_REQUEST";
export const LIST_PREFIX_SUCCESS = "LIST_PREFIX_SUCCESS";
export const LIST_PREFIX_FAILURE = "LIST_PREFIX_FAILURE";

export const LIST_VERSIONS_REQUEST = "LIST_VERSIONS_REQUEST";
export const LIST_VERSIONS_SUCCESS = "LIST_VERSIONS_SUCCESS";
export const LIST_VERSIONS_FAILURE = "LIST_VERSIONS_FAILURE";

export const GET_SIGNED_URL_SUCCESS = "GET_SIGNED_URL_SUCCESS";
export const MULTIPLE_FILE_UPLOAD_REQUEST = "MULTIPLE_FILE_UPLOAD_REQUEST";
export const MULTIPLE_FILE_UPLOAD_PROGRESS = "MULTIPLE_FILE_UPLOAD_PROGRESS";
export const MULTIPLE_FILE_UPLOAD_SUCCESS = "MULTIPLE_FILE_UPLOAD_SUCCESS";
export const MULTIPLE_FILE_UPLOAD_FAILURE = "MULTIPLE_FILE_UPLOAD_FAILURE";
export const MULTIPLE_FILE_UPLOAD_CANCELED = "MULTIPLE_FILE_UPLOAD_CANCELED";
export const MULTIPLE_FILE_UPLOAD_DUPLICATES =
	"MULTIPLE_FILE_UPLOAD_DUPLICATES";
export const BULK_FILE_UPLOAD_FAILURE = "BULK_FILE_UPLOAD_FAILURE";
export const FILE_UPLOAD_FAILURES = "FILE_UPLOAD_FAILURES";
export const RESET_FILE_UPLOAD_FAILURES = "RESET_FILE_UPLOAD_FAILURES";

export const DOCUMENT_DETAILS_SUCCESS = "DOCUMENT_DETAILS_SUCCESS";
export const DOCUMENT_DETAILS_FAILURE = "DOCUMENT_DETAILS_FAILURE";
export const DOCUMENT_DETAILS_REQUEST = "DOCUMENT_DETAILS_REQUEST";

export const UPDATE_DOCUMENT_PROPERTIES_REQUEST =
	"UPDATE_DOCUMENT_PROPERTIES_REQUEST";
export const UPDATE_DOCUMENT_PROPERTIES_SUCCESS =
	"UPDATE_DOCUMENT_PROPERTIES_SUCCESS";
export const UPDATE_DOCUMENT_PROPERTIES_FAILURE =
	"UPDATE_DOCUMENT_PROPERTIES_FAILURE";

export const GET_NOTE_SUCCESS = "GET_NOTE_SUCCESS";
export const GET_NOTE_FAILURE = "GET_NOTE_FAILURE";
export const GET_NOTE_REQUEST = "GET_NOTE_REQUEST";

export const UNMOUNT_NOTE_EDITOR = "UNMOUNT_NOTE_EDITOR";
export const MOUNT_CREATE_NOTE = "MOUNT_CREATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const ACCESS_CREATE_NOTE = "ACCESS_CREATE_NOTE";

export const FETCH_DOCUMENT_TAGS_SUCCESS = "FETCH_DOCUMENT_TAGS_SUCCESS";
export const FETCH_DOCUMENT_TYPES_SUCCESS = "FETCH_DOCUMENT_TYPES_SUCCESS";
export const FETCH_DOCUMENT_STATUSES_SUCCESS =
	"FETCH_DOCUMENT_STATUSES_SUCCESS";

export const FETCH_RECENT_DOCS_REQUEST_NOGLOBAL =
	"FETCH_RECENT_DOCS_REQUEST_NOGLOBAL";
export const FETCH_RECENT_DOCS_FAILURE_NOGLOBAL =
	"FETCH_RECENT_DOCS_FAILURE_NOGLOBAL";
export const FETCH_RECENT_DOCS_SUCCESS_NOGLOBAL =
	"FETCH_RECENT_DOCS_SUCCESS_NOGLOBAL";
export const FETCH_FAVORITE_DOCS_REQUEST_NOGLOBAL =
	"FETCH_FAVORITE_DOCS_REQUEST_NOGLOBAL";
export const FETCH_FAVORITE_DOCS_FAILURE_NOGLOBAL =
	"FETCH_FAVORITE_DOCS_FAILURE_NOGLOBAL";
export const FETCH_FAVORITE_DOCS_SUCCESS_NOGLOBAL =
	"FETCH_FAVORITE_DOCS_SUCCESS_NOGLOBAL";

export const UNMOUNT_DOCUMENT_DETAILS = "UNMOUNT_DOCUMENT_DETAILS";

export const UPLOAD_NEW_VERSION_REQUEST = "UPLOAD_NEW_VERSION_REQUEST";
export const UPLOAD_NEW_VERSION_PROGRESS = "UPLOAD_NEW_VERSION_PROGRESS";
export const UPLOAD_NEW_VERSION_SUCCESS = "UPLOAD_NEW_VERSION_SUCCESS";
export const UPLOAD_NEW_VERSION_FAILURE = "UPLOAD_NEW_VERSION_FAILURE";

export const SET_DOCUMENT_LOCK_SUCCESS = "SET_DOCUMENT_LOCK_SUCCESS";

export const CREATE_NEW_FOLDER_REQUEST = "CREATE_NEW_FOLDER_REQUEST";
export const CREATE_NEW_FOLDER_SUCCESS = "CREATE_NEW_FOLDER_SUCCESS";
export const CREATE_NEW_FOLDER_FAILURE = "CREATE_NEW_FOLDER_FAILURE";

export const FOLDER_NAME_CONFLICT = "FOLDER_NAME_CONFLICT";

export const EMAIL_DUPLICATES_FOUND_SUCCESS = "EMAIL_DUPLICATES_FOUND_SUCCESS";
export const OBJECT_AND_FOLDER_DUPLICATE_NAMES_FOUND_SUCCESS =
	"OBJECT_AND_FOLDER_DUPLICATE_NAMES_FOUND_SUCCESS";
export const EMAIL_NOTE_UPDATED = "EMAIL_NOTE_UPDATED";

//multiselect
export const CUT_SUCCESS = "CUT_SUCCESS";
export const COPY_SUCCESS = "COPY_SUCCESS";

export const PASTE_REQUEST = "PASTE_REQUEST";
export const PASTE_SUCCESS = "PASTE_SUCCESS";
export const PASTE_FAILURE = "PASTE_FAILURE";
export const SET_CHECKBOX = "SET_CHECKBOX";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const DELETE_SUCCESS = "DELETE_SUCCESS";

export const DELETE_FAILURE = "DELETE_FAILURE";

export const RESTORE_REQUEST = "RESTORE_REQUEST";
export const RESTORE_SUCCESS = "RESTORE_SUCCESS";
export const RESTORE_FAILURE = "RESTORE_FAILURE";

export const SET_ALL_CHECKBOXES = "SET_ALL_CHECKBOXES";
export const UNSELECT_ALL = "UNSELECT_ALL";
export const RENAME_REQUEST = "RENAME_REQUEST";
export const RENAME_SUCCESS = "RENAME_SUCCESS";
export const RENAME_FAILURE = "RENAME_FAILURE";

export const SEARCH_RESULTS_REQUEST_NOGLOBAL =
	"SEARCH_RESULTS_REQUEST_NOGLOBAL";
export const SEARCH_RESULTS_SUCCESS_NOGLOBAL =
	"SEARCH_RESULTS_SUCCESS_NOGLOBAL";
export const SEARCH_RESULTS_FAILURE_NOGLOBAL =
	"SEARCH_RESULTS_FAILURE_NOGLOBAL";

export const SEARCH_HELPER_REQUEST_NOGLOBAL = "SEARCH_HELPER_REQUEST_NOGLOBAL";
export const SEARCH_HELPER_SUCCESS_NOGLOBAL = "SEARCH_HELPER_SUCCESS_NOGLOBAL";
export const SEARCH_HELPER_FAILURE_NOGLOBAL = "SEARCH_HELPER_FAILURE_NOGLOBAL";

export const REINDEX_SUCCESS = "REINDEX_SUCCESS";
export const REINDEX_FAILURE = "REINDEX_FAILURE";

export const SET_CREATABLE_CONTEXT = "SET_CREATABLE_CONTEXT";

export const CREATE_NEW_DOCUMENT_FROM_TEMPLATE_REQUEST =
	"CREATE_NEW_DOCUMENT_FROM_TEMPLATE_REQUEST";
export const CREATE_NEW_DOCUMENT_FROM_TEMPLATE_SUCCESS =
	"CREATE_NEW_DOCUMENT_FROM_TEMPLATE_SUCCESS";
export const CREATE_NEW_DOCUMENT_FROM_TEMPLATE_FAILURE =
	"CREATE_NEW_DOCUMENT_FROM_TEMPLATE_FAILURE";

export const GET_EVERYTHING_FOR_NEW_TENANT_REQUEST =
	"GET_EVERYTHING_FOR_NEW_TENANT_REQUEST";
export const GET_EVERYTHING_FOR_NEW_TENANT_SUCCESS =
	"GET_EVERYTHING_FOR_NEW_TENANT_SUCCESS";
export const GET_EVERYTHING_FOR_EDIT_TENANT_REQUEST =
	"GET_EVERYTHING_FOR_EDIT_TENANT_REQUEST";
export const GET_EVERYTHING_FOR_EDIT_TENANT_SUCCESS =
	"GET_EVERYTHING_FOR_EDIT_TENANT_SUCCESS";

export const UNMOUNT_NEW_EDIT_TENANT = "UNMOUNT_NEW_EDIT_TENANT";

export const CREATE_TENANT_DUPLICATE_ID_FAILURE =
	"CREATE_TENANT_DUPLICATE_ID_FAILURE";
export const CREATE_TENANT_SUCCESS = "CREATE_TENANT_SUCCESS";
export const EDIT_TENANT_FAILURE = "EDIT_TENANT_FAILURE";
export const EDIT_TENANT_SUCCESS = "EDIT_TENANT_SUCCESS";

export const EXPORT_MATTER_REQUEST = "EXPORT_MATTER_REQUEST";
export const EXPORT_MATTER_SUCCESS = "EXPORT_MATTER_SUCCESS";
export const EXPORT_MATTER_FAILURE = "EXPORT_MATTER_FAILURE";

export const BULK_DOWNLOAD_REQUEST = "BULK_DOWNLOAD_REQUEST";
export const BULK_DOWNLOAD_SUCCESS = "BULK_DOWNLOAD_SUCCESS";
export const BULK_DOWNLOAD_FAILURE = "BULK_DOWNLOAD_FAILURE";

export const GET_PERMISSION_GROUPS_REQUEST = "GET_PERMISSION_GROUPS_REQUEST";
export const GET_PERMISSION_GROUPS_SUCCESS = "GET_PERMISSION_GROUPS_SUCCESS";
export const GET_PERMISSION_GROUPS_FAILURE = "GET_PERMISSION_GROUPS_FAILURE";

export const BULK_EXTERNAL_SEND_REQUEST = "BULK_EXTERNAL_SEND_REQUEST";
export const BULK_EXTERNAL_SEND_SUCCESS = "BULK_EXTERNAL_SEND_SUCCESS";
export const BULK_EXTERNAL_SEND_FAILURE = "BULK_EXTERNAL_SEND_FAILURE";

export const CREATE_PERMISSION_GROUP_REQUEST =
	"CREATE_PERMISSION_GROUP_REQUEST";
export const CREATE_PERMISSION_GROUP_SUCCESS =
	"CREATE_PERMISSION_GROUP_SUCCESS";
export const CREATE_PERMISSION_GROUP_FAILURE =
	"CREATE_PERMISSION_GROUP_FAILURE";
export const EDIT_PERMISSION_GROUP_REQUEST = "EDIT_PERMISSION_GROUP_REQUEST";
export const EDIT_PERMISSION_GROUP_SUCCESS = "EDIT_PERMISSION_GROUP_SUCCESS";
export const EDIT_PERMISSION_GROUP_FAILURE = "EDIT_PERMISSION_GROUP_FAILURE";
export const LINK_CLIO_MATTER_REQUEST = "LINK_CLIO_MATTER_REQUEST";
export const LINK_CLIO_MATTER_SUCCESS = "LINK_CLIO_MATTER_SUCCESS";
export const LINK_CLIO_MATTER_FAILURE = "LINK_CLIO_MATTER_FAILURE";

export const DELETE_PERMISSION_GROUP_REQUEST =
	"DELETE_PERMISSION_GROUP_REQUEST";
export const DELETE_PERMISSION_GROUP_SUCCESS =
	"DELETE_PERMISSION_GROUP_SUCCESS";
export const DELETE_PERMISSION_GROUP_FAILURE =
	"DELETE_PERMISSION_GROUP_FAILURE";

export const UPDATE_DOCUMENT_VERSION_COMMENT_REQUEST =
	"UPDATE_DOCUMENT_VERSION_COMMENT_REQUEST";
export const UPDATE_DOCUMENT_VERSION_COMMENT_SUCCESS =
	"UPDATE_DOCUMENT_VERSION_COMMENT_SUCCESS";
export const UPDATE_DOCUMENT_VERSION_COMMENT_FAILURE =
	"UPDATE_DOCUMENT_VERSION_COMMENT_FAILURE";
export const UNLINK_CLIO_MATTER_SUCESS = "UNLINK_CLIO_MATTER_SUCESS";
export const CLIO_ACCESS_CODE_SUCESS = "CLIO_ACCESS_CODE_SUCESS";

export const GET_CLIO_INTEGRATION_SETTINGS_REQUEST =
	"GET_CLIO_INTEGRATION_SETTINGS_REQUEST";
export const GET_CLIO_INTEGRATION_SETTINGS_SUCCESS =
	"GET_CLIO_INTEGRATION_SETTINGS_SUCCESS";
export const GET_CLIO_INTEGRATION_SETTINGS_FAILURE =
	"GET_CLIO_INTEGRATION_SETTINGS_FAILURE";

export const DOCUMENT_PREVIEW_CONTENT_SUCCESS =
	"DOCUMENT_PREVIEW_CONTENT_SUCCESS";
export const DOCUMENT_PREVIEW_CONTENT_FAILURE =
	"DOCUMENT_PREVIEW_CONTENT_FAILURE";
export const FETCH_DOCUMENT_PREVIEW_CONTENT = "FETCH_DOCUMENT_PREVIEW_CONTENT";

export const FETCH_SHARE_DETAILS_REQUEST = "FETCH_SHARE_DETAIL_REQUEST";
export const FETCH_SHARE_DETAILS_SUCCESS = "FETCH_SHARE_DETAIL_SUCCESS";
export const FETCH_SHARE_DETAILS_FAILURE = "FETCH_SHARE_DETAIL_FAILURE";

export const FETCH_PUBLIC_SHARE_DETAILS_REQUEST =
	"FETCH_PUBLIC_SHARE_DETAILS_REQUEST";
export const FETCH_PUBLIC_SHARE_DETAILS_SUCCESS =
	"FETCH_PUBLIC_SHARE_DETAILS_SUCCESS";
export const FETCH_PUBLIC_SHARE_DETAILS_FAILURE =
	"FETCH_PUBLIC_SHARE_DETAILS_FAILURE";

export const FETCH_SHARE_LIST_REQUEST = "FETCH_SHARE_LIST_REQUEST";
export const FETCH_SHARE_LIST_SUCCESS = "FETCH_SHARE_LIST_SUCCESS";
export const FETCH_SHARE_LIST_FAILURE = "FETCH_SHARE_LIST_FAILURE";

export const MULTI_DOWNLOAD_REQUEST = "MULTI_DOWNLOAD_REQUEST";
export const MULTI_DOWNLOAD_SUCCESS = "MULTI_DOWNLOAD_SUCCESS";
export const MULTI_DOWNLOAD_STARTED = "MULTI_DOWNLOAD_STARTED";
export const MULTI_DOWNLOAD_FAILURE = "MULTI_DOWNLOAD_FAILURE";
export const EXTERNAL_SEND_REQUEST = "EXTERNAL_SEND_REQUEST";
export const EXTERNAL_SEND_SUCCESS = "EXTERNAL_SEND_SUCCESS";
export const EXTERNAL_SEND_STARTED = "EXTERNAL_SEND_STARTED";
export const EXTERNAL_SEND_FAILURE = "EXTERNAL_SEND_FAILURE";
export const GET_SHARE_DOWNLOAD_URL_REQUEST = "GET_SHARE_DOWNLOAD_URL_REQUEST";
export const GET_SHARE_DOWNLOAD_URL_SUCCESS = "GET_SHARE_DOWNLOAD_URL_SUCCESS";
export const GET_SHARE_DOWNLOAD_URL_FAILURE = "GET_SHARE_DOWNLOAD_URL_FAILURE";

export const DELETE_EXTERNAL_EMAIL_REQUEST = "DELETE_EXTERNAL_EMAIL_REQUEST";
export const DELETE_EXTERNAL_EMAIL_SUCCESS = "DELETE_EXTERNAL_EMAIL_SUCCESS";
export const DELETE_EXTERNAL_EMAIL_FAILURE = "DELETE_EXTERNAL_EMAIL_FAILURE";

export const CONVERT_TO_PDF_REQUEST = "CONVERT_TO_PDF_REQUEST";
export const CONVERT_TO_PDF_SUCCESS = "CONVERT_TO_PDF_SUCCESS";
export const CONVERT_TO_PDF_STARTED = "CONVERT_TO_PDF_STARTED";
export const CONVERT_TO_PDF_FAILURE = "CONVERT_TO_PDF_FAILURE";
export const CONVERT_TO_PDF_CANCELED = "CONVERT_TO_PDF_CANCELED";
export const SET_SEARCH_BAR_CONTEXT = "SET_SEARCH_BAR_CONTEXT";
export const SET_SEARCH_BAR_MATTER_IDS = "SET_SEARCH_BAR_MATTER_IDS";

export const GET_PERMISSION_FEATURES_SUCCESS =
	"GET_PERMISSION_FEATURES_SUCCESS";
export const GET_PERMISSION_FEATURE_DETAILS_SUCCESS =
	"GET_PERMISSION_FEATURE_DETAILS_SUCCESS";
export const GET_PERMISSION_FEATURE_DETAILS_REQUEST =
	"GET_PERMISSION_FEATURE_DETAILS_REQUEST";
export const UPDATE_PERMISSION_FEATURE_DETAILS_REQUEST =
	"UPDATE_PERMISSION_FEATURE_DETAILS_REQUEST";
export const UPDATE_PERMISSION_FEATURE_DETAILS_SUCCESS =
	"UPDATE_PERMISSION_FEATURE_DETAILS_SUCCESS";
export const UPDATE_PERMISSION_FEATURE_DETAILS_ERROR =
	"UPDATE_PERMISSION_FEATURE_DETAILS_ERROR";
export const UNMOUNT_EDIT_PERMISSION_FEATURE =
	"UNMOUNT_EDIT_PERMISSION_FEATURE";

export const SET_DEVELOPER_OVERRIDE = "SET_DEVELOPER_OVERRIDE";

export const SET_TABLE_COLUMN_SORTING = "SET_TABLE_COLUMN_SORTING";
export const SET_TABLE_TAB_FILTER = "SET_TABLE_TAB_FILTER";
export const SET_TABLE_COLUMN_WIDTHS = "SET_TABLE_COLUMN_WIDTHS";
export const RESET_TABLE_COLUMN_WIDTHS = "RESET_TABLE_COLUMN_WIDTHS";

export const SET_PAGINATION_INFO = "SET_PAGINATION_INFO";
export const NEXT_PAGE = "NEXT_PAGE";
export const PREVIOUS_PAGE = "PREVIOUS_PAGE";
export const GO_TO_PAGE = "GO_TO_PAGE";
export const SET_FILTERED_COUNT = "SET_FILTERED_COUNT";

export const REQUEST_OCR_SUCCESS = "REQUEST_OCR_SUCCESS";
export const REQUEST_OCR_FAILURE = "REQUEST_OCR_FAILURE";

export const UPDATE_OCR_SNACKBAR_MESSAGE = "UPDATE_OCR_SNACKBAR_MESSAGE";
export const GET_TENANT_FEATURES_USAGE_AND_FEATURES_SUCCESS =
	"GET_TENANT_FEATURES_USAGE_AND_FEATURES_SUCCESS";
export const ACCESS_SEARCH_AUDIT_PAGE = "ACCESS_SEARCH_AUDIT_PAGE";
export const SET_ACTIVE_OPERATION_ON_AUDIT_PAGE =
	"SET_ACTIVE_OPERATION_ON_AUDIT_PAGE";

export const AUDIT_SEARCH_REQUEST = "AUDIT_SEARCH_REQUEST";
export const AUDIT_SEARCH_SUCCESS = "AUDIT_SEARCH_SUCCESS";
export const AUDIT_SEARCH_FAILURE = "AUDIT_SEARCH_FAILURE";

export const INDEX_MISSING_SEARCH_ITEMS_SUCCESS =
	"INDEX_MISSING_SEARCH_ITEMS_SUCCESS";
export const INDEX_MISSING_SEARCH_ITEMS_FAILURE =
	"INDEX_MISSING_SEARCH_ITEMS_FAILURE";

export const CLOSE_REACT_DIALOG = "CLOSE_REACT_DIALOG";
export const OPEN_REACT_DIALOG = "OPEN_REACT_DIALOG";
export const UPDATE_REACT_DIALOG_CONTENT = "UPDATE_REACT_DIALOG_CONTENT";

export const SET_LXW_LOCALSTORAGE_VALUE = "SET_LXW_LOCALSTORAGE_VALUE";

export const DELETE_DOCUMENT_VERSION_REQUEST =
	"DELETE_DOCUMENT_VERSION_REQUEST";
export const DELETE_DOCUMENT_VERSION_SUCCESS =
	"DELETE_DOCUMENT_VERSION_SUCCESS";
export const DELETE_DOCUMENT_VERSION_FAILURE =
	"DELETE_DOCUMENT_VERSION_FAILURE";
