import {} from "../actions/types";

const initialState = {
	items: [],
	itemsHash: "",
	loading: true,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case "GET_WHATS_NEW_REQUEST": {
			return {
				...state,
				loading: true,
			};
		}

		case "GET_WHATS_NEW_SUCCESS": {
			return {
				...state,
				items: action.payload.items,
				itemsHash: action.payload.itemsHash,
				loading: false,
			};
		}

		case "UPDATE_WHATS_NEW_ITEMS_REQUEST": {
			return { ...state, loading: true };
		}

		case "UPDATE_WHATS_NEW_ITEMS_SUCCESS": {
			return {
				...state,
				items: action.payload.items,
				itemsHash: action.payload.itemsHash,
				loading: false,
			};
		}

		default:
			return state;
	}
}
