import { GET_PERMISSION_GROUPS_SUCCESS } from "../actions/types";

const initialState = {
	permissionGroups: [],
	users: [],
	usersBySub: {},
	newEditPermissionsLoading: true,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case GET_PERMISSION_GROUPS_SUCCESS:
			var usersBySub = {};
			action.payload.users.forEach((u) => {
				usersBySub[u.sub] = { ...u };
			});

			return {
				...state,
				newEditPermissionsLoading: false,
				permissionGroups: action.payload.availableGroups.map((g) => {
					return {
						...g,
						members: action.payload.groupMembership
							.filter((gm) => gm.groupID === g.id)
							.map((gm) => ({
								...gm,
								sub: gm.userSub,
								displayName: usersBySub[gm.userSub].displayName,
							})),
					};
				}),
				users: action.payload.users,
				usersBySub,
			};
		default:
			return state;
	}
}
