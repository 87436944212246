import React from "react";
import "./TabLookingRadioButtons.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TabLookingRadioButtons extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTabIndex: props.initialTab || 0,
		};
		this.clicked = this.clicked.bind(this);
	}

	clicked(tabTitle, tabIndex) {
		this.setState({ activeTabIndex: tabIndex }, () => {
			this.props.switchedTab &&
				this.props.switchedTab(tabTitle, this.state.activeTabIndex);
		});
	}
	render() {
		return (
			<div
				className={
					"TabLookingRadioButtons noselect " +
					(this.props.className || "")
				}
				style={{
					display: "flex",
				}}
			>
				{this.props.columns.map((tabName, index) => {
					var isActiveTab;
					if (this.props.activeTabName) {
						//controlled component
						isActiveTab = this.props.activeTabName === tabName;
					} else {
						isActiveTab = this.state.activeTabIndex === index;
					}
					return (
						<div
							title={
								this.props.tabDescriptions
									? this.props.tabDescriptions[tabName] ||
										null
									: null
							}
							className={
								"TabLookingRadioButtonsElement " +
								(isActiveTab ? "TLRB-Active" : "")
							}
							onClick={() => this.clicked(tabName, index)}
							key={"TLRBE-" + index}
						>
							{tabName}
						</div>
					);
				})}
			</div>
		);
	}
}
const TabLookingRadioButtonsWithIcon = (props) => {
	return (
		<div style={{ display: "flex" }}>
			<FontAwesomeIcon
				icon={props.icon || "briefcase"}
				size="2x"
				style={{
					marginRight: 10,
					marginLeft: 10,
					marginTop: 12,
					fontSize: "1.5em",
				}}
			/>
			<TabLookingRadioButtons {...props} />
		</div>
	);
};
export default TabLookingRadioButtons;
export { TabLookingRadioButtonsWithIcon };
