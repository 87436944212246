import React, { useState, useEffect } from "react";

import { ButtonWithIcon } from "./ButtonWithIcon";
import ButtonLink from "./ButtonLink";

import ConversationInterface from "./ConversationInterface";

import CircularProgress from "@material-ui/core/CircularProgress";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import uptimeSquare from "../images/Uptime Square - Trans BG.png";

import {
	aiConversationAboutDocument,
	suggestQuestions,
	summarizeDocument,
} from "../actions/documentActions";

//if the remaining tokens are less than this value, the AI will only consider the last two messages
const remainingTokensThreshold = 900;

const PreCannedQuestionButtons = ({ prompts, headingText }) => {
	return prompts.length ? (
		<div>
			<span style={{ fontFamily: "MainFont-Bold", margin: 8 }}>
				{headingText}
			</span>
			<div
				style={{
					display: "grid",
				}}
			>
				{prompts.map(({ text, handler }, index) => {
					return (
						<div
							key={"q" + index}
							style={{
								margin: 12,
								maxWidth: 650,
							}}
						>
							<ButtonWithIcon
								handler={() => handler(text)}
								buttonText={text}
								iconName="sparkles"
							/>
						</div>
					);
				})}
			</div>
		</div>
	) : null;
};

const userSourceName = "You";
const aiSourceName = "LexWorkplace AI";

const ConversationIcon = ({ sourceName }) => {
	if (sourceName === "You") {
		return <FontAwesomeIcon icon="user" size="2x" style={{ width: 32 }} />;
	} else if (sourceName === "LexWorkplace AI") {
		return (
			<img
				style={{ width: 32 }}
				alt="LxW AI"
				align="center"
				src={uptimeSquare}
			/>
		);
	}
	return <FontAwesomeIcon icon="briefcase" />;
};

const DocumentAIPane = ({
	documentID,
	setHeaderExtraInfo,
	messagesChanged,
	openDocument,
	downloadCopyOfDocument,
	docCanBeOpenedInLauncher,
}) => {
	const [suggestedQuestions, setSuggestedQuestions] = useState([]);
	const [messages, setMessages] = useState([]);
	const [loading, setLoading] = useState(true);
	const [firstQuestion, setFirstQuestion] = useState(true);
	const [responseCode, setResponseCode] = useState(null);
	const [messageContextStartIndex, setMessageContextStartIndex] = useState(0);
	const [lastMessageInError, setLastMessageInError] = useState(false);

	useEffect(() => {
		messagesChanged(messages);
	}, [messages]);

	//initally request suggested questions about the doc
	useEffect(() => {
		//if the user navigates away before response arrives, the component
		//is unmounted and attempting to set state results in an error
		let componentIsUnmounted = false;

		setHeaderExtraInfo({ display: "", tooltip: "" });
		suggestQuestions(documentID)
			.then(({ code, followup, meta }) => {
				if (!componentIsUnmounted) {
					setLoading(false);
					setResponseCode(code);
					if (code === "OKAY") {
						checkTruncation(meta);
						setSuggestedQuestions(followup);
					}
				}
			})
			.catch((err) => {
				setLoading(false);
				setLastMessageInError(true);
			});
		return () => {
			componentIsUnmounted = true;
		};
	}, []);

	const checkTruncation = ({ proportionUsed, pctUsed, usage }) => {
		if (proportionUsed < 1) {
			setHeaderExtraInfo({
				display: `(${pctUsed} Considered)`,
				tooltip: `      There is a limit to how much of a document LexWorkplace AI can keep in its "context window".  If the document is longer than this limit, it will use as much of the document as possible.  In this case, LexWorkplace AI's responses are only considering the first ${pctUsed} of the document.`,
			});
		} else {
			setHeaderExtraInfo({ display: "", tooltip: "" });
		}
		if (usage) {
			if (usage.remainingTokens) {
				if (usage.remainingTokens < remainingTokensThreshold) {
					console.log(
						"AI token context threshhold exceeded, resetting the message context"
					);
					setMessageContextStartIndex(messages.length - 2);
				}
			}
		}
	};

	const appendMessage = (source, content) => {
		setMessages((oldArray) => [
			...oldArray,
			{ source, content, animateTyping: source === "LexWorkplace AI" },
		]);
	};

	const priorMessages = messages.slice(messageContextStartIndex).map((m) => {
		return {
			role: m.source === "You" ? "user" : "assistant",
			content: m.content,
		};
	});

	const processUserPromptAndAIResponse = (userPrompt, fn) => {
		appendMessage(userSourceName, userPrompt);
		setLoading(true);
		fn()
			.then(({ reply, followup, meta }) => {
				setLastMessageInError(false);
				checkTruncation(meta);
				setFirstQuestion(false);
				setLoading(false);
				if (followup) {
					setSuggestedQuestions(followup);
				}
				appendMessage(aiSourceName, reply);
			})
			.catch((err) => {
				setFirstQuestion(false);
				setLoading(false);
				setLastMessageInError(true);
			});
	};

	const preCannedPrompts = (
		firstQuestion
			? [
					{
						text: "Summarize this Document",
						handler: () =>
							processUserPromptAndAIResponse(
								"Please provide a brief summary of this document",
								() => summarizeDocument(documentID)
							),
					},
				]
			: []
	).concat(
		suggestedQuestions.map((suggestedPrompt) => {
			return {
				text: suggestedPrompt,
				handler: (userPrompt) => {
					processUserPromptAndAIResponse(userPrompt, () =>
						aiConversationAboutDocument({
							documentID,
							userPrompt,
							priorMessages,
						})
					);
				},
			};
		})
	);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				flexGrow: 1,
				overflow: "hidden",
				padding: 16,
			}}
		>
			<ConversationInterface
				messages={messages}
				submitQuestion={(userPrompt) => {
					processUserPromptAndAIResponse(userPrompt, () =>
						aiConversationAboutDocument({
							documentID,
							userPrompt,
							priorMessages,
						})
					);
				}}
				IconComponent={ConversationIcon}
				userTextInputAllowed={responseCode !== "NO_CONTENT"}
				endOfMessagesComponent={
					loading ? (
						<div style={{ padding: 24 }}>
							<center>
								<CircularProgress size={28} thickness={5} />
							</center>
						</div>
					) : lastMessageInError ? (
						<div style={{ padding: 24 }}>
							<div
								style={{
									width: "100%",
									textAlign: "center",
								}}
							>
								<p>
									<FontAwesomeIcon
										style={{
											color: "var(--lw-darker-red)",
											fontSize: "1.5em",
										}}
										icon="exclamation-triangle"
									/>
									<span
										style={{ paddingLeft: 4 }}
									>{`LexWorkplace AI encountered an error`}</span>
								</p>
								{docCanBeOpenedInLauncher ? (
									<ButtonLink onClick={openDocument}>
										Open Document
									</ButtonLink>
								) : (
									<ButtonLink
										onClick={downloadCopyOfDocument}
									>
										Download a Copy of Document
									</ButtonLink>
								)}
							</div>
						</div>
					) : responseCode === "NO_CONTENT" ? (
						<div style={{ padding: 24 }}>
							<div
								style={{
									width: "100%",
									textAlign: "center",
									paddingTop: "40%",
								}}
							>
								<p>{`The content of this document is not available for AI analysis.`}</p>
								{docCanBeOpenedInLauncher ? (
									<ButtonLink onClick={openDocument}>
										Open Document
									</ButtonLink>
								) : (
									<ButtonLink
										onClick={downloadCopyOfDocument}
									>
										Download a Copy of Document
									</ButtonLink>
								)}
							</div>
						</div>
					) : (
						<PreCannedQuestionButtons
							headingText={
								firstQuestion
									? "LexWorkplace AI Suggested Prompts"
									: "Suggested Followup Questions"
							}
							prompts={preCannedPrompts}
						/>
					)
				}
			/>
		</div>
	);
};

export default DocumentAIPane;
