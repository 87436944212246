import {
	FETCH_SHARE_DETAILS_REQUEST,
	FETCH_SHARE_DETAILS_SUCCESS,
	FETCH_SHARE_DETAILS_FAILURE,
	FETCH_PUBLIC_SHARE_DETAILS_REQUEST,
	FETCH_PUBLIC_SHARE_DETAILS_SUCCESS,
	FETCH_PUBLIC_SHARE_DETAILS_FAILURE,
	FETCH_SHARE_LIST_SUCCESS,
} from "../actions/types";

import {
	capitalize,
	getFirmDocsDisplayName,
	getMatterLink,
} from "../utilities/utilities";

//
const initialState = {
	/*
		The share details page will show the "private" share details if the user
		accessing it is A) the user who created the share, or B) the firm admin.
		Otherwise, the user will see the public share page.  This works even if
		It is viewed by a user of another firm.  publicSharePage keeps track of which
		version the user ought to see
	*/
	publicSharePage: true,
	outgoingShareList: [],
	shareDetails: {},
	shareEmails: [],
	publicShareDetails: {},
	shareAvailable: false,
	loading: true,
};

const shareDataMap = (share, emails) => {
	const now = new Date().getTime();
	var shareData = { recipients: ["???"], message: "???" };
	try {
		//no guarantee the JSON is valid
		const shareDataObj = JSON.parse(share.shareDataJSON);
		shareData = { ...shareDataObj };
	} catch (e) {
		console.error("error parsing shareDataJSON");
		console.error(e);
	}

	const recipients = shareData.recipients;
	const message = shareData.message;
	const expDate = new Date(share.expiresAt).getTime();
	var lastEmailSentDate;
	if (emails.length) {
		var lastEmailID = emails.reduce(
			(acc, cur) => (cur.id > acc ? cur.id : acc),
			-1
		);
		if (lastEmailID > -1) {
			var lastEmail = emails.filter((e) => e.id === lastEmailID)[0];
			if (lastEmail && lastEmail.status === "sent") {
				lastEmailSentDate = new Date(lastEmail.sentAt).getTime();
			}
		}
	} else {
		lastEmailSentDate = new Date(share.lastEmailSent).getTime();
	}

	const shareCreatedAt = new Date(share.createdAt).getTime();
	const shareAccessedAt = new Date(share.accessedAt).getTime();

	const hasExpired = now > expDate;

	const matterName =
		share.matterID <= 0
			? getFirmDocsDisplayName(share.matterID)
			: share.matterName;

	const matterLink = getMatterLink(share.matterID);

	let displayStatus = capitalize(share.status);

	if (hasExpired) {
		if (share.status === "active") {
			displayStatus = "Expired";
		}
		/*
			Shares expire after 30 days.
			TODO: there should be a better way to clean up 
			shares that didn't complete processing.
			For now, we just dont want to show them in the "Active" tab
		*/
		if (share.status === "processing") {
			displayStatus = "Never Finished";
		}
	}

	const lastEmailFailed = share.lastEmailStatus === "failed";
	return {
		...share,
		recipients,
		displayStatus,
		hasExpired,
		matterName,
		matterLink,
		shareCreatedAt,
		shareAccessedAt,
		lastEmailSentDate,
		expDate,
		message,
		hasBeenDownloaded: share.accessedAt || false,
		createdOrSentForSort: share.lastEmailSent
			? lastEmailSentDate
			: shareCreatedAt,
		lastEmailFailed,
	};
};

export default function (state = initialState, action) {
	switch (action.type) {
		case FETCH_SHARE_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_SHARE_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				publicSharePage: false,
				shareDetails: shareDataMap(
					action.payload.shareDetails,
					action.payload.shareEmails
				),
				shareEmails: action.payload.shareEmails,
			};
		case FETCH_SHARE_DETAILS_FAILURE:
			return {
				...state,
				shareAvailable: false,
				loading: false,
			};
		case FETCH_PUBLIC_SHARE_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_PUBLIC_SHARE_DETAILS_SUCCESS:
			return {
				...state,
				publicSharePage: true,
				publicShareDetails: {
					...action.payload,
				},
				shareAvailable: true,
				loading: false,
			};
		case FETCH_PUBLIC_SHARE_DETAILS_FAILURE:
			return {
				...state,
				shareAvailable: false,
				loading: false,
			};
		case FETCH_SHARE_LIST_SUCCESS:
			var outgoingShareList = action.payload.outgoingShareList.map(
				(share) => shareDataMap(share, [])
			);
			return {
				...state,
				outgoingShareList,
			};
		default:
			return state;
	}
}
