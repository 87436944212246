import { getEmailData } from "../utilities/emailParser";
import { listDirectory, uploadFileRequest } from "./documentActions";
import { openGlobalDialog } from "./globalEditDialogActions";
import API from "../utilities/LocalApiProxy";
import { mapFirmDocsMatterID } from "../utilities/utilities";
import {
	MULTIPLE_FILE_UPLOAD_SUCCESS,
	EMAIL_DUPLICATES_FOUND_SUCCESS,
	MULTIPLE_FILE_UPLOAD_FAILURE,
	EMAIL_NOTE_UPDATED,
	OPEN_REACT_DIALOG,
	UPDATE_REACT_DIALOG_CONTENT,
	CLOSE_REACT_DIALOG,
} from "./types";
import { createFilesUploadingContentForDialog } from "../components/reactDialog";

async function createEmailData(files, progressCallback) {
	let emailList = await getEmailData(files, progressCallback);
	return emailList;
}

export function uploadMultipleEmails(
	matterID,
	basepath,
	directoryID,
	files,
	skipMD5Check,
	callback
) {
	return function (dispatch) {
		const emailParseProgressFn = ({ counter, total, filename }) => {
			dispatch({
				type: UPDATE_REACT_DIALOG_CONTENT,
				payload: {
					showSpinnerInTitle: true,
					mainContent: createFilesUploadingContentForDialog(
						[filename],
						`Preparing ${counter} of ${total} Emails:`
					),
					counter: counter,
				},
			});
		};

		dispatch({
			type: OPEN_REACT_DIALOG,
			payload: {
				showSpinnerInTitle: true,
				title: "Uploading Emails",
				mainContent: `Preparing to upload emails`,
			},
		});

		createEmailData(files, emailParseProgressFn)
			.then((emailList) => {
				if (emailList.length === 0) {
					dispatch({ type: MULTIPLE_FILE_UPLOAD_FAILURE });
				} else {
					insertEmailRecords(directoryID, emailList, skipMD5Check)
						.then((response) => {
							var id;
							const start = response.mysql.insertId;
							var now = new Date();
							for (
								var i = 0;
								i < response.mysql.affectedRows;
								i++
							) {
								id = start + i;
								emailList[i].id = id;
								emailList[i].uuid =
									id + "_" + emailList[i].originalFilename;

								emailList[i].metadata = {
									//at the time this was developed, emails can only be added to matters so this is not strictly necessary.  If emails can ever be added to firm docs then... this should still work!
									matterID:
										"" +
										mapFirmDocsMatterID(matterID, basepath),
									emailDate:
										"" + emailList[i].dateObj.getTime(),
									emailFrom: encodeURIComponent(
										emailList[i].emailFrom
									),
									emailTo: encodeURIComponent(
										emailList[i].emailTo
									),
									emailSubject: encodeURIComponent(
										emailList[i].subjectline
									),
									emailAddedDate: "" + now.getTime(),
									uploadSource: "web",
								};
							}
							asyncUploadMultipleEmails(
								basepath,
								files,
								emailList,
								dispatch
							)
								.then(() =>
									confirmEmailUpload(
										emailList.map((e) => e.id)
									)
								)
								.then((res) => {
									listDirectory(matterID, basepath)(dispatch);
									dispatch({
										type: MULTIPLE_FILE_UPLOAD_SUCCESS,
										payload: {
											num: emailList.length,
											objectType: "Email",
										},
									});
									dispatch({
										type: CLOSE_REACT_DIALOG,
									});
									callback();
								})
								.catch((err) => {
									console.error(
										"problem in multiple upload",
										err
									);
								});
						})
						.catch(({ lxwErrorCode, lxwErrorData }) => {
							if (lxwErrorCode === 6510) {
								dispatch({
									type: EMAIL_DUPLICATES_FOUND_SUCCESS,
								});
								dispatch({
									type: CLOSE_REACT_DIALOG,
								});
								const { num } = lxwErrorData;
								openGlobalDialog(
									{
										label: `${num} Duplicate Email${
											num > 1 ? "s" : ""
										} Found`,
										mainText: `${
											num > 1
												? num + " of these emails have"
												: "This email has"
										} already been uploaded to this matter.  Are you sure you want to upload ${
											num > 1
												? "them again?"
												: "it again?"
										}`,
										errorMessage: "",
										canSubmit: true,
										hideTextfield: true,
										submitButtonLabel: "Yes",
									},
									() =>
										uploadMultipleEmails(
											matterID,
											basepath,
											directoryID,
											files,
											true, //user clicked "yes upload anyways" so skipMD5Check=true
											callback
										)(dispatch),
									() => ({ canSubmit: true })
								)(dispatch);
							}
						});
				}
			})
			.catch((err) => {
				console.error("problem parsing or uploading emails");
				console.error(err);
				dispatch({
					type: CLOSE_REACT_DIALOG,
				});
				dispatch({ type: MULTIPLE_FILE_UPLOAD_FAILURE });
			});
	};
}
function insertEmailRecords(directoryID, emailList, skipMD5Check) {
	return API.put("/email/upload", {
		body: { directoryID, emailList, skipMD5Check },
	});
}
function confirmEmailUpload(IDs) {
	return API.post("/email/confirmUpload", {
		body: { IDs },
	});
}
export function updateEmailNote(id, value) {
	return function (dispatch) {
		API.post("/email/note", { body: { id, value } }).then(() => {
			dispatch({ type: EMAIL_NOTE_UPDATED, payload: { id, value } });
		});
	};
}
function uploadEmailToS3(file, emailID, metadata) {
	//first get the signed url
	return API.post("/signedUrlForEmailUpload", {
		body: {
			emailID,
			metadata,
		},
	}).then((res) => uploadFileRequest(res.mysql.url, file));
}
async function asyncUploadMultipleEmails(basepath, files, fileList, dispatch) {
	const num = files.length;

	for (let i = 0; i < num; i++) {
		dispatch({
			type: UPDATE_REACT_DIALOG_CONTENT,
			payload: {
				showSpinnerInTitle: true,
				title: "Uploading Emails",
				mainContent: createFilesUploadingContentForDialog(
					[files[i].name],
					`Uploading ${i + 1} of ${num} Emails:`
				),
				counter: i,
			},
		});

		if (files[i] && fileList[i]) {
			await uploadEmailToS3(
				files[i],
				fileList[i].id,
				fileList[i].metadata
			);
		}
	}
	return true;
}
export function getSignedUrlForEmailAndDownload(emailID) {
	return function (dispatch) {
		API.get("/signedUrlForEmail", { queryParams: { emailID } })
			.then((res) => {
				window.location.href = res.mysql.url;
				dispatch({
					type: "GET_SIGNED_URL_SUCCESS",
				});
			})
			.catch((err) => console.log(err));
	};
}
