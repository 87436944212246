// import store from "../store";
import React from "react";

import { openLauncherLink } from "./launcherActions";
import path from "path";
import API from "../utilities/LocalApiProxy";
import { updateInlineCompareUrl } from "./browserLocalstorageActions";

export function resetDocumentCompare() {
	return function (dispatch) {
		dispatch({
			type: "RESET_DOCUMENT_COMPARE",
		});
	};
}

export function addDocumentToCompare({ docID, filename, versionID, type }) {
	return function (dispatch) {
		dispatch({
			type: "ADD_DOCUMENT_TO_COMPARE",
			payload: { docID, filename, versionID, type },
		});
		dispatch(checkCompareStatus());
	};
}
function checkCompareStatus() {
	return function (dispatch, getState) {
		const {
			doc1ID,
			doc2ID,
			doc1Filename,
			doc2Filename,
			doc1VersionID,
			doc2VersionID,
			type,
		} = getState().documentCompare;

		dispatch(
			handleSnackbars({ doc1ID, doc2ID, doc1Filename, doc2Filename })
		);

		if (doc1ID && doc2ID && type === "litera") {
			dispatch({
				type: "RESET_DOCUMENT_COMPARE",
			});
			// send command to launcher to compare the two documents
			openLauncherLink(
				`documentCompare?doc1ID=${doc1ID}&doc2ID=${doc2ID}${doc1VersionID ? `&doc1VersionID=${doc1VersionID}` : ""}${doc2VersionID ? `&doc2VersionID=${doc2VersionID}` : ""}`
			);
		} else if (doc1ID && doc2ID && type === "inline") {
			// make the request to the backend to compare the two documents
			// it will return a link to the comparison
			API.post("/document/compare/inline", {
				body: {
					docCompareArray: [
						{ documentID: doc1ID, versionID: doc1VersionID },
						{ documentID: doc2ID, versionID: doc2VersionID },
					],
				},
			})
				.then((response) => {
					if (response.mysql?.url) {
						updateInlineCompareUrl(response.mysql.url)(dispatch);

						window.globalHistory.push("/document/compare");

						dispatch({
							type: "RESET_DOCUMENT_COMPARE",
						});
					} else {
						dispatch({
							type: "OPEN_GLOBAL_SNACKBAR",
							payload: {
								variant: "error",
								message:
									"LexWorkplace Encountered an Error; Please Contact Support",
							},
						});
					}
				})
				.catch((err) => {
					console.error("document comparison error:", err);
					dispatch({
						type: "OPEN_GLOBAL_SNACKBAR",
						payload: {
							variant: "error",
							message:
								"LexWorkplace Encountered an Error; Please Contact Support",
						},
					});
				});
		}
	};
}

function handleSnackbars({ doc1ID, doc2ID, doc1Filename, doc2Filename }) {
	return function (dispatch) {
		if (doc1ID && doc2ID) {
			if (doc1Filename.length > 30) {
				const { name, ext } = path.parse(doc1Filename);
				doc1Filename = name.slice(0, 30) + "..." + ext;
			}
			if (doc2Filename.length > 30) {
				const { name, ext } = path.parse(doc2Filename);
				doc2Filename = name.slice(0, 30) + "..." + ext;
			}
			dispatch({
				type: "OPEN_GLOBAL_SNACKBAR",
				payload: {
					variant: "info",
					message: `Comparing ${doc1Filename} and ${doc2Filename}`,
					loadingSpinnerVisible: true,
					disableWindowBlurListener: true,
					snackbarKey: "documentCompare",
				},
			});
		} else {
			const { name, ext } = path.parse(doc1Filename);
			if (name.length > 30) {
				doc1Filename = name.slice(0, 30) + "..." + ext;
			}
			const chooseAnotherItemCompareMessage = (
				<div style={{ textAlign: "left" }}>
					Select a second document to compare to {doc1Filename}. (Or{" "}
					<a
						style={{
							color: "#ffff",
							textDecoration: "underline",
							cursor: "pointer",
						}}
						onClick={() => {
							dispatch({
								type: "RESET_DOCUMENT_COMPARE",
							});
							dispatch({
								type: "CLOSE_GLOBAL_SNACKBAR",
								payload: {
									snackbarKey: "documentCompare",
								},
							});
						}}
					>
						click here
					</a>{" "}
					to start over.)
				</div>
			);
			dispatch({
				type: "OPEN_PERSISTENT_SNACKBAR_NOT_CLOSEABLE",
				payload: {
					variant: "info",
					message: chooseAnotherItemCompareMessage,
					snackbarKey: "documentCompare",
				},
			});
		}
	};
}
