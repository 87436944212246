import React from "react";

import buildData from "./buildData/buildData";

import HeaderBar from "./components/HeaderBar";
import SubHeader from "./components/SubHeader";
import Login from "./components/Login";
import { PublicSharePageStandalone } from "./components/Share.js";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import ChangeUserAttributes from "./components/ChangeUserAttributes";
import UpdateExchangeUrl from "./components/UpdateExchangeUrl";
import ConfigureMFA from "./components/ConfigureMFA";
import Settings from "./components/Settings";
import About from "./components/About";
import Homepage from "./components/Homepage";
import FirmDocs from "./components/FirmDocs";
import UnderSubHeader from "./components/UnderSubHeader";
import Launcher from "./components/Launcher";
import ActivateLauncher from "./components/ActivateLauncher";
import Search from "./components/Search";
import Admin from "./components/Admin";
import TenantDetail from "./components/TenantDetail";
import NewUser from "./components/NewUser";
import EditUser from "./components/EditUser";

import NewTenant from "./components/NewTenant";
import { ClioIntegrationRedirect } from "./components/ClioIntegration";
import IntegrationSettings from "./components/IntegrationSettings.js";
import { ExternalSharingManagementFullPage } from "./components/ExternalSharingManagement";
import { ExternalShareDetail } from "./components/ExternalShareDetail";
import DeveloperOverridesSettings from "./components/DeveloperOverridesSettings";
import lxwConstants from "./config/lexworkplace-constants";
const lexWorkplaceEditions = lxwConstants.lexWorkplaceEditions;

import {
	ClientTagManager,
	MatterTagManager,
	MatterTypeManager,
	OfficeBranchManager,
	DocumentTagManager,
	DocumentTypeManager,
	DocumentStatusManager,
} from "./components/TagManager";

import EditDialog from "./components/EditDialog";
import GlobalSnackbar from "./components/GlobalSnackbar";
import GlobalScreenSize from "./components/GlobalScreenSize";

import { LeftSidebarWithRouter } from "./components/LeftSidebar";
import ClientDetail from "./components/ClientDetail";
import NewClient from "./components/NewClient";
import ClientList from "./components/ClientList";
import AllMattersList from "./components/AllMattersList";
import NoteEditor from "./components/NoteEditor";

import DocumentProperties from "./components/DocumentProperties";

import NewMatter from "./components/NewMatter";
import MatterDetail from "./components/MatterDetail";
import {
	checkCognitoAuthentication,
	logOut,
	setRedirectUrl,
	getMyUserDataFromDatabase,
} from "./actions/userAuthActions";
import { navigated } from "./actions/globalSearchbarActions";

import { getCustomColumns } from "./actions/customColumnActions";

import NewEditPermissionsGroup from "./components/NewEditPermissionsGroup";
import EditPermissionFeature from "./components/EditPermissionFeature";

import PermissionsGroupList from "./components/PermissionsGroupList";
import AIDashboard from "./components/AIDashboard/AIDashboard";

import { connect } from "react-redux";
import { initIcons } from "./iconLibrary";

import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";

import { Provider } from "react-redux";
import PropTypes from "prop-types";
import store from "./store";

import TextDisplayWithCopyToClipboard from "./components/TextDisplayWithCopyToClipboard";

import "./components/LabeledInput.css";
import "./components/SubHeader.css";

import "./App.css";
import "./globalColors.css";
import ReactDialog from "./components/reactDialog";
import SuperAdmin from "./components/SuperAdmin.js";
import EditWhatsNewWrapper from "./components/EditWhatsNew/EditWhatsNewWrapper.js";
import DocumentCompare from "./components/DocumentCompare.js";
// these components could be useful for the office add in, if microsoft says we can't communicate with the launcher directly from the add in
// import LaunchFromAddIn from "./components/LaunchFromAddIn";
// import WatchFromAddIn from "./components/WatchFromAddIn";

//setup the fontAwesome icons
initIcons();

class UnconnectedLogout extends React.Component {
	UNSAFE_componentWillMount() {
		this.props.logOut();
	}
	render() {
		return <Loading />;
	}
}
const Logout = connect(null, { logOut })(UnconnectedLogout);

const errorRoute = () => {
	return <div>There is no page here... sorry!</div>;
};

const testRoute = () => {
	return <div>Test</div>;
};

class RouteWrapper extends React.Component {
	componentDidMount() {}
	render() {
		return <Route {...this.props} />;
	}
}
RouteWrapper.propTypes = {};

const CustomRoute = connect(null, {})(RouteWrapper);

const EditMatter = (props) => <NewMatter {...props} editMode />;

const EditClient = (props) => <NewClient {...props} editMode />;

const EditTenant = (props) => <NewTenant {...props} editMode />;

class UnconnectedUserData extends React.Component {
	componentDidMount() {
		/*
			when user refreshes page, this ends up getting called twice, once in AppContainer and then again here
			
			todo: fix this behavior but dont forget:
				- new user first login
				- login in general

		*/
		this.props.getMyUserDataFromDatabase();

		this.props.getCustomColumns();
	}
	render() {
		return null;
	}
}
const UserData = connect(null, { getCustomColumns, getMyUserDataFromDatabase })(
	UnconnectedUserData
);

const LoginRequired = ({ history, user, hasPermissionsAccess }) => {
	const cognitoUser = user.cognitoUser;
	const cognitoGroups = cognitoUser.cognitoGroups;

	const isLexworkplaceAdmin =
		cognitoGroups && cognitoGroups.includes("LexWorkplace_Admin");

	const isSuperAdmin =
		cognitoGroups && cognitoGroups.includes("LexWorkplace_Super_Admin");

	return (
		<div className="App">
			<UserData />
			<EditDialog />
			<ReactDialog />
			<GlobalSnackbar />
			<GlobalScreenSize />
			<HeaderBar />

			<div className="UnderHeader">
				<LeftSidebarWithRouter
					history={history}
					isLexworkplaceAdmin={isLexworkplaceAdmin}
					isSuperAdmin={isSuperAdmin}
				/>
				<div className="App-content">
					<SubHeader />
					<UnderSubHeader>
						<Switch>
							<CustomRoute path="/" component={Homepage} exact />
							<CustomRoute
								path="/launcher"
								component={Launcher}
								exact
							/>
							<CustomRoute
								path="/activateLauncher"
								component={ActivateLauncher}
								exact
							/>
							{/* <CustomRoute
								path="/launchFromAddIn/:documentID(\d+)/:launcherCode"
								component={LaunchFromAddIn}
								exact
							/>
							<CustomRoute
								path="/watchFromAddIn/:documentID(\d+)/:launcherCode/:filePath"
								component={WatchFromAddIn}
								exact
							/> */}
							<CustomRoute
								path="/matters/"
								component={AllMattersList}
								exact
							/>
							<CustomRoute
								path="/docs/:fullpath(\d+)"
								component={DocumentProperties}
								exact
							/>
							<CustomRoute
								path="/notes/:fullpath(\d+)"
								component={NoteEditor}
								exact
							/>
							<CustomRoute
								path="/notes/new/:matterID(\d+)/:directoryID(\d+)/:fullpath(.+)"
								render={(props) => (
									<NoteEditor {...props} createMode />
								)}
								exact
							/>
							<CustomRoute
								path="/firmDocs/:root(public|private)/:fullpath(.+/)"
								component={FirmDocs}
								exact
							/>
							<CustomRoute
								path="/firmDocs/:root(public|private)"
								component={FirmDocs}
								exact
							/>

							<CustomRoute
								path="/clients"
								component={ClientList}
								exact
							/>
							<CustomRoute
								path="/clients/:id(\d+)"
								component={ClientDetail}
								exact
							/>
							<CustomRoute
								path="/clients/:id(\d+)/edit"
								render={(props) => (
									<EditClient {...props} editMode />
								)}
								exact
							/>
							<CustomRoute
								path="/clients/new"
								component={NewClient}
								exact
							/>
							<CustomRoute
								path="/settings/manageDocumentTags"
								component={DocumentTagManager}
								exact
							/>
							<CustomRoute
								path="/settings/manageDocumentTypes"
								component={DocumentTypeManager}
								exact
							/>
							<CustomRoute
								path="/settings/manageDocumentStatuses"
								component={DocumentStatusManager}
								exact
							/>

							<CustomRoute
								path="/settings/manageClientTags"
								component={ClientTagManager}
								exact
							/>
							<CustomRoute
								path="/settings/manageMatterTags"
								component={MatterTagManager}
								exact
							/>
							<CustomRoute
								path="/settings/manageMatterTypes"
								component={MatterTypeManager}
								exact
							/>
							<CustomRoute
								path="/settings/manageOfficeBranch"
								component={OfficeBranchManager}
								exact
							/>
							<CustomRoute
								path="/settings/developer"
								component={DeveloperOverridesSettings}
								exact
							/>

							<CustomRoute
								path="/matters/new"
								exact
								component={NewMatter}
							/>

							<CustomRoute
								path="/matters/new/:clientID(\d+)"
								render={(props) => <NewMatter {...props} />}
								exact
							/>

							<CustomRoute
								path="/matters/:id(\d+)/edit"
								render={(props) => <EditMatter {...props} />}
								exact
							/>

							{hasPermissionsAccess && (
								<CustomRoute
									path="/matters/:id(\d+)/edit/permissions"
									render={(props) => (
										<EditMatter {...props} permissions />
									)}
									exact
								/>
							)}
							{hasPermissionsAccess && (
								<CustomRoute
									path="/matters/new/permissions"
									exact
									render={(props) => (
										<NewMatter {...props} permissions />
									)}
								/>
							)}

							{hasPermissionsAccess && (
								<CustomRoute
									path="/permissions/groups/list"
									exact
									render={() => <PermissionsGroupList />}
								/>
							)}

							{hasPermissionsAccess && (
								<CustomRoute
									path="/permissions/groups/create"
									exact
									render={() => (
										<NewEditPermissionsGroup createNew />
									)}
								/>
							)}
							{hasPermissionsAccess && (
								<CustomRoute
									path="/permissions/groups/edit/:groupID(\d+)"
									exact
									render={(props) => (
										<NewEditPermissionsGroup
											{...props}
											editMode
										/>
									)}
								/>
							)}
							{hasPermissionsAccess && (
								<CustomRoute
									path="/permissions/:featureCode(CREATE_CLIENT_ALLOWED)"
									render={(props) => (
										<EditPermissionFeature
											{...props}
											permissions
										/>
									)}
									exact
								/>
							)}

							<CustomRoute
								path="/matters/:id(\d+)/:root(docs|email|notes|sharing)"
								component={MatterDetail}
								exact
							/>
							<CustomRoute
								path="/matters/:id(\d+)/:root(docs|email|notes)/:fullpath(.+/)"
								component={MatterDetail}
								exact
								strict
							/>

							<CustomRoute
								path="/settings"
								exact
								component={Settings}
							/>
							<CustomRoute
								path="/settings/changePassword"
								component={ChangePassword}
							/>
							<CustomRoute
								path="/settings/changeUserAttributes"
								component={ChangeUserAttributes}
							/>
							<CustomRoute
								path="/settings/updateExchangeUrl"
								component={UpdateExchangeUrl}
							/>
							<CustomRoute
								path="/settings/outlookAddinAddress"
								component={() => (
									<div>
										<p>
											Copy the following URL to the
											&quot;get add-ins&quot; section of
											Microsoft Outlook. See the{" "}
											<a
												target="_blank"
												rel="noopener noreferrer"
												href="https://lexworkplace.com/setup-guide"
											>
												setup guide
											</a>{" "}
											for more detailed instructions.
										</p>
										<TextDisplayWithCopyToClipboard
											style={{ width: "800px" }}
											label="Outlook Addin URL:"
											value="https://addin.lexworkplace.app/manifest-dist.xml"
										/>
									</div>
								)}
							/>

							<CustomRoute
								path="/settings/configureMFA"
								component={ConfigureMFA}
							/>
							<CustomRoute
								path="/settings/integrations"
								component={IntegrationSettings}
							/>
							<CustomRoute
								path="/search"
								component={Search}
								exact
							/>

							<CustomRoute
								path="/search/:hack(\d+)"
								render={(props) => {
									//changing a components key is apparently the only way to get react to re-mount a component
									//this is necessary so that when user clicks on another search link while on search page
									//the dropdowns are updated properly from the URL
									return (
										<Search
											{...props}
											key={"" + props.match.params.hack}
											hack={props.match.params.hack}
										/>
									);
								}}
							/>

							<CustomRoute path="/about" component={About} />

							<CustomRoute path="/logout" component={Logout} />

							<CustomRoute path="/test" component={testRoute} />

							<CustomRoute
								path="/integrations/clio/authorize"
								component={ClioIntegrationRedirect}
							/>

							<CustomRoute
								path="/sharing/outgoing"
								component={ExternalSharingManagementFullPage}
								exact
							/>

							<CustomRoute
								path="/sharing/outgoing/:shareID(\d+)"
								component={ExternalShareDetail}
								exact
							/>
							<CustomRoute
								path="/share/download/:accessHash"
								exact
								component={ExternalShareDetail}
							/>
							<CustomRoute
								path="/ai"
								exact
								component={AIDashboard}
							/>

							<CustomRoute
								path="/document/compare"
								exact
								component={DocumentCompare}
							/>

							{isLexworkplaceAdmin && (
								<CustomRoute
									path="/admin/tenant/:tenantID(\d+)/:tenantSubpage"
									component={TenantDetail}
									exact
								/>
							)}

							{isLexworkplaceAdmin && (
								<CustomRoute
									path="/admin"
									component={Admin}
									exact
								/>
							)}

							{isLexworkplaceAdmin && (
								<CustomRoute
									path="/admin/tenant/:tenantID(\d+)/user/new"
									component={NewUser}
									exact
								/>
							)}
							{isLexworkplaceAdmin && (
								<CustomRoute
									path="/admin/user/edit/:sub([0-9a-fA-F\-]+)"
									component={EditUser}
									exact
								/>
							)}
							{isLexworkplaceAdmin && (
								<CustomRoute
									path="/admin/tenant/edit/:tenantID(\d+)"
									component={EditTenant}
									exact
								/>
							)}
							{isLexworkplaceAdmin && (
								<CustomRoute
									path={`/admin/tenant/new/:newFirmEditionCode(${Object.keys(
										lexWorkplaceEditions.options
									).join("|")})`}
									component={NewTenant}
									exact
								/>
							)}

							{/* super admin routes */}
							{isSuperAdmin && (
								<CustomRoute
									path="/superAdmin"
									component={SuperAdmin}
									exact
								/>
							)}
							{isSuperAdmin && (
								<CustomRoute
									path="/superAdmin/editWhatsNewDropdown"
									component={EditWhatsNewWrapper}
									exact
								/>
							)}

							<CustomRoute
								path="/"
								component={AuthenticatedRedirect}
							/>

							<CustomRoute component={errorRoute} />
						</Switch>
					</UnderSubHeader>
				</div>
			</div>
		</div>
	);
};
const Loading = () => {
	return (
		<h1>
			<center>Loading...</center>
		</h1>
	);
};

class UnconnectedUnauthenticatedRedirect extends React.Component {
	componentDidMount() {
		console.log("currently at", this.props.location.pathname);
		this.props.setRedirectUrl(this.props.location.pathname);
	}
	render() {
		return <Redirect to="/login" />;
	}
}
const UnauthenticatedRedirect = connect(null, { setRedirectUrl })(
	UnconnectedUnauthenticatedRedirect
);

class UnconnectedAuthenticatedRedirect extends React.Component {
	componentDidMount() {
		this.props.setRedirectUrl("/");
	}
	render() {
		return <Redirect to={this.props.redirectLocation} />;
	}
}
const AuthenticatedRedirect = connect(
	(state) => ({ redirectLocation: state.user.redirectLocation }),
	{ setRedirectUrl }
)(UnconnectedAuthenticatedRedirect);

class AppContainer extends React.Component {
	componentDidMount() {
		const routeChange = (location) => {
			this.props.navigated(location.pathname);
		};

		window.globalHistory = this.props.history;

		this.props.checkCognitoAuthentication();

		routeChange(this.props.history.location);

		this.unlisten = this.props.history.listen((location) => {
			routeChange(location);
		});

		if (process.env.REACT_APP_ENVIRONMENT !== "production") {
			document.title = `LexWorkplace (${
				buildData.branch !== "Unknown" && buildData.branch !== "master"
					? buildData.branch
					: process.env.REACT_APP_ENVIRONMENT
			})`;
		}
	}
	componentWillUnmount() {
		this.unlisten();
	}
	render() {
		const { history } = this.props;

		if (this.props.determiningAuthStatus) {
			return <Loading />;
		} else {
			if (!this.props.isLoggedIn) {
				//non-logged in ie unauthenticated routes
				return (
					<div className="App">
						<HeaderBar />
						<GlobalSnackbar />
						<Switch>
							<CustomRoute
								path="/login"
								exact
								component={Login}
							/>
							<CustomRoute
								path="/forgotPassword"
								exact
								component={ForgotPassword}
							/>
							<CustomRoute
								path="/changePassword"
								exact
								component={ChangePassword}
							/>
							<CustomRoute
								path="/about"
								component={About}
								label="About LexWorkplace"
							/>
							<CustomRoute
								path="/office"
								exact
								render={() => <div>Office add-in</div>}
							/>
							<CustomRoute
								path="/share/download/:accessHash"
								exact
								component={PublicSharePageStandalone}
							/>
							<CustomRoute
								path="/"
								component={UnauthenticatedRedirect}
							/>
						</Switch>
					</div>
				);
			} else {
				//logged in / authenticated routes
				return (
					<div
						className={
							(this.props.allowUnderHeaderOverflow
								? "allowUnderHeaderOverflow"
								: "") +
							(this.props.forcePaginationVisible
								? " forcePaginationVisible"
								: "")
						}
					>
						<LoginRequired
							history={history}
							user={this.props.user}
							hasPermissionsAccess={
								this.props.hasPermissionsAccess
							}
						/>
					</div>
				);
			}
		}
	}
}
AppContainer.propTypes = {
	loading: PropTypes.bool.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
	checkCognitoAuthentication: PropTypes.func.isRequired,
	navigated: PropTypes.func.isRequired,
};
const appContainerMapStateToProps = (state) => ({
	loading: state.user.loading,
	redirectLocation: state.user.redirectLocation,
	isLoggedIn: state.user.isLoggedIn,
	determiningAuthStatus: state.user.determiningAuthStatus,
	allowUnderHeaderOverflow: state.global.allowUnderHeaderOverflow,
	forcePaginationVisible: state.global.forcePaginationVisible,
	user: state.user,
	hasPermissionsAccess: state.user.hasPermissionsAccess,
});
const ConnectedAppContainer = withRouter(
	connect(appContainerMapStateToProps, {
		checkCognitoAuthentication,
		setRedirectUrl,
		getCustomColumns,
		navigated,
	})(AppContainer)
);

const App = ({ history }) => {
	return (
		<div>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<div>
						<ConnectedAppContainer history={history} />
					</div>
				</ConnectedRouter>
			</Provider>
		</div>
	);
};

App.propTypes = {
	history: PropTypes.object,
};
export default App;
