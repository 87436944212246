import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./HomepageTable.css";
import {
	setTableSort,
	setTableColumnWidths,
} from "../../actions/tableSortingActions";

class UnconnectedHomepageTable extends Component {
	constructor(props, context) {
		super(props, context);

		this.mixinColumnVisibility = this.mixinColumnVisibility.bind(this);
	}
	mixinColumnVisibility() {
		return this.props.columns.map((item) => {
			var newColumnConfig = {
				...item,
				headerClassName: "leftAlignedTableHeader",
				resizable: false,
			};

			if (this.props.columnVisibility) {
				newColumnConfig.show =
					item.accessor === "selected" ||
					this.props.columnVisibility[item.accessor];
			}
			return newColumnConfig;
		});
	}
	render() {
		var propsWithResizeStuff = { ...this.props };
		if (this.props.autoResize) {
			propsWithResizeStuff.getTheadProps = () => ({
				style: {
					paddingRight: this.props.scrollbarWidth + "px",
				},
			});
			propsWithResizeStuff.getTheadFilterProps = () => ({
				style: {
					paddingRight: this.props.scrollbarWidth + "px",
				},
			});
		}

		const columnsWithVisibility = this.mixinColumnVisibility();

		return (
			<div
				className={
					"HomepageTable -striped -highlight " +
					(this.props.className || "")
				}
			>
				<ReactTable
					{...propsWithResizeStuff}
					data={this.props.loading ? [] : this.props.data}
					columns={columnsWithVisibility}
					loading={this.props.loading || false}
					loadingText={""}
					defaultPageSize={this.props.defaultPageSize || 50}
					page={
						this.props.uncontrolledPagination
							? undefined
							: this.props.currentPage - 1
					}
					defaultSorted={
						this.props.userOverriddenSort &&
						this.props.userOverriddenSort.sort
							? this.props.userOverriddenSort.sort
							: this.props.defaultSorted
					}
					onResizedChange={(newResized, event) => {
						this.props.setTableColumnWidths(
							this.props.tableUniqueID,
							newResized
						);
					}}
					onSortedChange={(newSorted, column, additive) => {
						this.props.setTableSort(
							this.props.tableUniqueID,
							newSorted,
							additive
						);
						this.props.onSortedChange &&
							this.props.onSortedChange(
								newSorted,
								column,
								additive
							);
					}}
					resized={
						this.props.userOverriddenSort
							? this.props.userOverriddenSort.resizedColumns
							: []
					}
					pageSize={
						this.props.defaultPageSize ||
						Math.min(50, this.props.data.length)
					}
					className="-striped -highlight"
					NoDataComponent={() => (
						<h5 className="rt-noData">
							{this.props.noResultsText}
						</h5>
					)}
				/>
			</div>
		);
	}
}

const HomepageTable = connect(
	(state, ownProps) => ({
		scrollbarWidth: state.global.scrollbarWidth,
		userOverriddenSort: state.tableSorting[ownProps.tableUniqueID],
		currentPage: state.pagination.currentPage,
	}),
	{ setTableSort, setTableColumnWidths }
)(UnconnectedHomepageTable);

export default HomepageTable;
