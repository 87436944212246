import React, { Component } from "react";
import ButtonLink from "./ButtonLink";
import { openGlobalDialog } from "../actions/globalEditDialogActions";
import { connect } from "react-redux";
import SortableTable from "./Tables/SortableTable";
import CustomPagination from "./Tables/CustomPagination";

class BulkUploadFailureMessageUnconnected extends Component {
	render() {
		const { bulkUploadFilesFailed, openGlobalDialog } = this.props;

		return (
			<div>
				{bulkUploadFilesFailed.length} Files failed to upload{" "}
				<ButtonLink
					onClick={() =>
						openGlobalDialog(
							{
								label: `${bulkUploadFilesFailed.length} Files failed to upload`,
								mainText: (
									<div className="autoResizeTableContainer">
										<SortableTable
											className="fullWidth"
											data={bulkUploadFilesFailed}
											style={{ height: 458 }}
											tableUniqueID="bulkUploadFailureModalTable"
											columns={[
												{
													Header: "File Name",
													accessor:
														"lxwFilenameWithExtension",
													width: 150,
												},
												{
													Header: "Folder",
													accessor: "LXW_FOLDER",
													Cell: (row) =>
														row.original.original
															.LXW_FOLDER,
												},
											]}
											PaginationComponent={
												CustomPagination
											}
											loading={false}
											noResultsText="No Failed Files"
										/>
									</div>
								),
								canSubmit: true,
								hideTextfield: true,
								hideCancelButton: true,
								submitButtonLabel: "OK",
							},
							() => () => ({ canSubmit: true }),
							() => {}
						)
					}
				>
					View Failed Files
				</ButtonLink>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	bulkUploadFilesFailed: state.document.bulkUploadFilesFailed,
});

const BulkUploadFailureMessage = connect(mapStateToProps, {
	openGlobalDialog,
})(BulkUploadFailureMessageUnconnected);

// this function is necessary to prevent importing react into documentActions.js
function getBulkUploadFailureMessage() {
	return <BulkUploadFailureMessage />;
}

export default getBulkUploadFailureMessage;
