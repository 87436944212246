import React, { Component } from "react";
import { connect } from "react-redux";

import { setSubheaderText } from "../../actions/navigateActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import screenshot1 from "./ai-shot-1-1200x800.png";
import screenshot2 from "./ai-shot-2-1200x800.png";

class AIDashboard extends Component {
	componentDidMount() {
		this.props.setSubheaderText("LexWorkplace AI (Beta)");
	}

	render() {
		return (
			<div
				style={{
					paddingTop: 20,
					paddingLeft: 24,
					paddingRight: 24,
					display: "flex",
					flexDirection: "column",
					gap: 24,
					height: "100%",
					position: "relative",
					lineHeight: "2em",
				}}
			>
				<style>
					{
						"#documentAISparklesIcon path {color:var(--lw-light-blue);}"
					}
				</style>
				<div style={{}}>
					<center>
						<h3>LexWorkplace AI (Beta)</h3>
						<p style={{ maxWidth: 900 }}>
							{" "}
							{`Welcome to LexWorkplace's generative AI! Document AI, our first generative AI feature (now in beta), is your own dedicated AI assistant. With Document AI, you can interact with any document in LexWorkplace in a conversational manner`}
						</p>
					</center>
				</div>
				<h4>
					<span
						style={{ marginRight: 8 }}
					>{`Document AI » How it Works`}</span>
					<FontAwesomeIcon
						id="documentAISparklesIcon"
						icon="sparkles"
						style={{ color: "--lw-blue" }}
					/>
				</h4>
				<div
					style={{
						display: "grid",
						gridTemplateColumns: "1fr 1fr",
						gridGap: 50,
					}}
				>
					<div style={{ maxWidth: "45vw" }}>
						<p style={{ fontWeight: "bold" }}>STEP 1</p>
						<p style={{ minHeight: 150 }}>
							{`Document AI lets you summarize, ask questions and have
					conversations about the content of any document in
					LexWorkplace. To start a Document AI session for any
					document or email, simply mouse-over the document, and click
					the AI icon. This will open the Document AI sidebar.`}
						</p>
						<img
							style={{ width: "100%" }}
							src={screenshot1}
							alt="Screenshot showing how to access LexWorkplace AI"
						/>
					</div>
					<div style={{ maxWidth: "45vw" }}>
						<p style={{ fontWeight: "bold" }}>STEP 2</p>
						<p style={{ minHeight: 150 }}>
							{`From here you can
					ask LexWorkplace AI to summarize the document, ask a
					pre-defined question, or ask your own custom question. (Note
					that the pre-defined questions are specifically relevant to
					this document, and are generated from the content of the
					document itself.) Like other AI tools, LexWorkplace's Document AI will respond
					to your questions and prompts. From here, you can continue
					to ask follow-up questions.`}
						</p>
						<img
							style={{ width: "100%" }}
							src={screenshot2}
							alt="Screenshot showing how to access LexWorkplace AI"
						/>
					</div>
				</div>

				<center
					style={{
						marginTop: "auto",
						//marginBottom: 16,
						fontWeight: "bold",
						//position: "absolute",
						//bottom: 0,
						//left: "50%",
						//marginLeft: "auto",
					}}
				>
					<p>
						{`Check back here to see new,
					future AI capabilities.`}
					</p>
				</center>
			</div>
		);
	}
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { setSubheaderText })(AIDashboard);
