import React from "react";
import { SortableList } from "../SortableList";
import { ButtonWithIcon, SmallNoPaddingIconButton } from "../ButtonWithIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditItemDialog from "./EditWhatsNewItemDialog";

export default function EditWhatsNewSortableList({
	whatsNewItems,
	updateWhatsNewItems,
	addWhatsNewItem,
	deleteWhatsNewItem,
	openEditItemDialog,
	editItemDialogOpen,
	closeEditItemDialog,
	editItemText,
	editItemUrl,
	handleEditItemChange,
	submitEditItemDialog,
	isNewItem,
}) {
	return (
		<div>
			<EditItemDialog
				editItemDialogOpen={editItemDialogOpen}
				title={`${isNewItem ? "Add" : "Edit"} Item`}
				mainContent={"This is the edit item dialog"}
				closeEditItemDialog={closeEditItemDialog}
				editItemText={editItemText}
				editItemUrl={editItemUrl}
				handleEditItemChange={handleEditItemChange}
				submitEditItemDialog={submitEditItemDialog}
			/>
			<div>
				The order of the items display below is the order they will be
				displayed in the whats new dropdown
			</div>
			<div style={{ paddingBottom: 10, paddingTop: 10 }}>
				<ButtonWithIcon
					handler={addWhatsNewItem}
					buttonText="Add Item"
					iconName="plus-circle"
				/>
			</div>
			<div className="SortableListContainer">
				<SortableList
					items={whatsNewItems}
					onChange={updateWhatsNewItems}
					renderItem={(item) => (
						<SortableList.Item id={item.id}>
							<div>
								<div>{item.text}</div>
							</div>
							<div
								style={{
									display: "flex",
									gap: 8,
								}}
							>
								<span style={{ minWidth: 80 }}>
									<ButtonWithIcon
										handler={() => openEditItemDialog(item)}
										buttonText="Edit"
										iconName="pen-square"
										className="blue"
										id={item.id}
										item={item}
									/>
								</span>
								<SmallNoPaddingIconButton
									aria-label="delete"
									className="trash"
									onClick={() => deleteWhatsNewItem(item.id)}
								>
									<FontAwesomeIcon icon={"trash"} />
								</SmallNoPaddingIconButton>
								<SortableList.DragHandle />
							</div>
						</SortableList.Item>
					)}
				/>
			</div>
		</div>
	);
}
