import {
	SEARCH_RESULTS_REQUEST_NOGLOBAL,
	SEARCH_RESULTS_SUCCESS_NOGLOBAL,
	SEARCH_RESULTS_FAILURE_NOGLOBAL,
	SEARCH_HELPER_SUCCESS_NOGLOBAL,
} from "../actions/types";
//
const initialState = {
	query: "",
	executedQuery: "",
	results: { docs: [], email: [], matterNote: [], aggregations: {} },
	queryTook: 0,
	error: false,
	customErrorMessage: null,
	helperDataLoaded: false,
	matters: [],
	availableDocumentStatuses: [],
	availableDocumentTypes: [],
	availableDocumentTags: [],
	users: [],
	loading: false,
	lastQueryParseError: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case SEARCH_RESULTS_REQUEST_NOGLOBAL:
			return {
				...state,
				loading: true,
			};
		case SEARCH_RESULTS_SUCCESS_NOGLOBAL:
			return {
				...state,
				results: action.payload.results,
				executedQuery: action.payload.executedQuery,
				queryTook:
					Math.floor(parseInt(action.payload.queryTook, 10) / 10) /
					100,
				error: false,
				loading: false,
				lastQueryParseError: false,
			};
		case SEARCH_RESULTS_FAILURE_NOGLOBAL: {
			let customErrorMessage;
			let lastQueryParseError = false;
			switch (action.payload.lxwErrorCode) {
				case 8202:
					{
						customErrorMessage =
							"Your search index has not been configured yet.  Please check back again later";
					}
					break;
				case 8410: {
					customErrorMessage =
						"LexWorkplace had a problem interpreting your search query. Please check your syntax and try again. Remember, you can use operators like AND, OR, NOT as well as use (parentheses) to group conditions. If you still need assistance, please contact support.";
					lastQueryParseError = true;
					break;
				}
				default:
			}
			return {
				...state,
				results: [],
				executedQuery: action.payload.executedQuery,
				queryTook:
					Math.floor(parseInt(action.payload.queryTook, 10) / 10) /
					100,
				error: true,
				customErrorMessage,
				loading: false,
				lastQueryParseError,
			};
		}
		case SEARCH_HELPER_SUCCESS_NOGLOBAL:
			return {
				...state,
				...action.payload,
				helperDataLoaded: true,
			};
		default:
			return state;
	}
}
