import { UPDATE_USER_SETTING } from "./types";
import API from "../utilities/LocalApiProxy";
import store from "../store";

var debounceTimeout;

export function updateUserSetting(settingKey, settingValue) {
	return function (dispatch) {
		window.clearTimeout(debounceTimeout);
		debounceTimeout = window.setTimeout(() => {
			saveUserSettings()(dispatch);
		}, 1000);
		dispatch({
			type: UPDATE_USER_SETTING,
			payload: { settingKey, settingValue },
		});
	};
}
export function saveUserSettings() {
	return function () {
		var settingsJSON = JSON.stringify(store.getState().userSettings);
		API.put("/user/settings", { body: { settingsJSON } }).then(() => {
			console.log("successfully saved user settings");
		});
	};
}
