import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
	getOutgoingShareList,
	revokeShare,
	resendShare,
} from "../actions/shareActions";
import { setResizeElement } from "../actions/globalScreenSizeActions";
import { setSubheaderText } from "../actions/navigateActions";
import { openValueCopyDialog } from "../actions/globalEditDialogActions";
import CustomizableTable from "./Tables/CustomizableTable";
import InteractiveTableCell from "./Tables/InteractiveTableCell";
import { mediumDateFormat, pluralizeWithNum } from "../utilities/utilities";

const tableUniqueID = "/sharing/outgoing";

class ShareList extends Component {
	constructor(props, context) {
		super(props, context);
		this.getColumns = this.getColumns.bind(this);
		this.makeThreeDotMenuItems = (row) => {
			var menu = [
				{
					text: "View Share Info",
					href: `/sharing/outgoing/${row.original.id}`,
				},
				{
					text: "View Share Download Link",
					onSelect: () => {
						const downloadURL = row.original.downloadURL;
						this.props.openValueCopyDialog(
							downloadURL,
							"Share Download Link",
							"Download Link Copied to Clipboard"
						);
					},
				},
			];

			if (row.original.displayStatus !== "Processing") {
				menu.push({
					text: "Re-Share and Resend Email",
					onSelect: () =>
						this.props.resendShare(
							row.original.id,
							"ExternalSharingManagement",
							this.props.matterIDFilter
						),
				});
			}

			if (row.original.displayStatus === "Active") {
				menu.push({
					text: "Revoke Share",
					onSelect: () =>
						this.props.revokeShare(
							row.original.id,
							"ExternalSharingManagement",
							this.props.matterIDFilter
						),
				});
			}

			return menu;
		};

		this.filterMap = {
			All: () => true,
			Active: (row) =>
				row.displayStatus === "Active" ||
				row.displayStatus === "Processing",
			Expired: (row) => row.displayStatus === "Expired",
			Revoked: (row) => row.displayStatus === "Revoked",
			Failed: (row) =>
				row.displayStatus === "Failed" ||
				row.displayStatus === "Never Finished",
		};
	}
	getColumns(includeMatterName) {
		var columns = [
			{ Header: "Sender", accessor: "createdByDisplayName" },
			{
				Header: "Recipients",
				accessor: "recipients",
				Cell: (row) => (
					<InteractiveTableCell
						menuItems={this.makeThreeDotMenuItems(row)}
					>
						{row.value ? row.value.join(", ") : ""}
					</InteractiveTableCell>
				),
			},
			{
				Header: "Sent",
				accessor: "lastEmailSent",
				Cell: (row) =>
					row.value ? (
						mediumDateFormat(row.value)
					) : row.original.status === "Processing" ? (
						<span
							style={{ fontFamily: "MainFont-Italic" }}
							title="Email not sent yet, still processing"
						>
							{mediumDateFormat(row.original.createdAt) + "*"}
						</span>
					) : (
						""
					),
			},
			{
				Header: "Documents Sent",
				accessor: "totalObjects",
				Cell: (row) => (
					<Link to={`/sharing/outgoing/${row.original.id}`}>
						{row.value === null
							? ""
							: pluralizeWithNum("Document", row.value)}
					</Link>
				),
			},
			{
				Header: "Status",
				accessor: "displayStatus",
				Cell: (row) =>
					row.original.lastEmailFailed && row.value === "Active" ? (
						<span
							style={{ fontFamily: "MainFont-Italic" }}
							title="Last email failed to send"
						>
							{row.value + "*"}
						</span>
					) : (
						<span>{row.value}</span>
					),
			},
		];
		if (includeMatterName) {
			columns.push({
				Header: "Matter",
				accessor: "matterName",
				Cell: (row) => (
					<Link to={row.original.matterLink}>
						{row.original.matterName}
					</Link>
				),
			});
		}
		columns = columns.concat([
			{
				Header: "Expires",
				accessor: "expiresAt",
				Cell: (row) => (row.value ? mediumDateFormat(row.value) : ""),
			},
			{
				Header: "D/L",
				accessor: "hasBeenDownloaded",
				width: 40,
				filterable: false,
				Cell: (row) => (row.value ? "✓" : ""),
			},
		]);

		return columns;
	}
	UNSAFE_componentWillMount() {
		this.props.getOutgoingShareList(this.props.matterIDFilter);
		this.props.setResizeElement("mattersListTableContainer");
	}

	render() {
		return (
			<div className="fullHeightFlex noDefaultMargin">
				<CustomizableTable
					tabsFilterMap={this.filterMap}
					defaultTabFilterValue="Active"
					leftSideIcon="share-square"
					className="fullWidth"
					loading={this.props.loading}
					data={this.props.outgoingShareList}
					autoResize
					columns={this.getColumns(this.props.includeMatterColumn)}
					noResultsText={"No Outgoing Shares to show"}
					defaultSorted={[{ id: "createdOrSentForSort", desc: true }]}
					tableUniqueID={tableUniqueID}
				/>
			</div>
		);
	}
}
ShareList.propTypes = {
	loading: PropTypes.bool.isRequired,
};
const mapStateToProps = (state, ownProps) => ({
	outgoingShareList: state.share.outgoingShareList,
	myUserID: state.user.userID,
	loading: state.request.loading,
	cognitoSub: state.user.cognitoUser.attributes.sub,
	hasPermissionsAccess: state.user.hasPermissionsAccess,
	matterIDFilter: ownProps.matterIDFilter,
});
const ExternalSharingManagementBase = connect(mapStateToProps, {
	setResizeElement,
	getOutgoingShareList,
	revokeShare,
	resendShare,
	openValueCopyDialog,
})(ShareList);

class ExternalSharingManagementFullPageUnconnected extends Component {
	componentDidMount() {
		this.props.setSubheaderText("Manage Document Sharing");
	}
	render() {
		return <ExternalSharingManagementBase includeMatterColumn={true} />;
	}
}
const ExternalSharingManagementFullPage = connect(null, {
	setSubheaderText,
})(ExternalSharingManagementFullPageUnconnected);

class ExternalSharingManagementForMatterUnconnected extends Component {
	render() {
		return (
			<ExternalSharingManagementBase
				includeMatterColumn={false}
				matterIDFilter={this.props.matterIDFilter}
			/>
		);
	}
}
const ExternalSharingManagementForMatter = connect((state, ownProps) => ({
	matterIDFilter: ownProps.matterIDFilter,
}))(ExternalSharingManagementForMatterUnconnected);

export {
	ExternalSharingManagementForMatter,
	ExternalSharingManagementFullPage,
};
