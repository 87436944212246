import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
	getShareDetailUser,
	revokeShare,
	resendShare,
} from "../actions/shareActions";

import { PublicSharePageBase } from "./Share.js";

import { ButtonWithIcon } from "./ButtonWithIcon";
import { setSubheaderText } from "../actions/navigateActions";

import { openValueCopyDialog } from "../actions/globalEditDialogActions";

import { mediumDateFormat } from "../utilities/utilities";

import ScrollableListOfFiles from "./ScrollableListOfFiles";

const PropertyValue = (propertyName, value) => {
	return (
		<div style={{ marginLeft: 12, marginBottom: 12 }}>
			<span style={{ fontFamily: "MainFont-Bold", marginRight: 12 }}>
				{propertyName}
			</span>
			<span>{value}</span>
		</div>
	);
};

class ShareDetail extends Component {
	constructor(props, context) {
		super(props, context);

		this.refresh = this.refresh.bind(this);
	}
	refresh() {
		if (this.props.match.params.shareID) {
			this.props.getShareDetailUser({
				shareID: this.props.match.params.shareID,
			});
		} else if (this.props.match.params.accessHash) {
			this.props.getShareDetailUser({
				accessHash: this.props.match.params.accessHash,
			});
		}
	}

	UNSAFE_componentWillMount() {
		this.refresh();
		this.props.setSubheaderText("Share Detail");
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.match.params.shareID !== this.props.match.params.shareID
		) {
			this.refresh();
		}
	}
	componentDidMount() {
		this.refresh();
	}
	render() {
		return this.props.loading ? null : this.props.publicSharePage ? (
			<div>
				<PublicSharePageBase />
			</div>
		) : (
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "1fr 1fr",
					gridGap: 50,
				}}
			>
				<div style={{ position: "relative" }}>
					<div>
						{PropertyValue(
							"Sender",
							this.props.shareDetails.senderName
						)}
						{PropertyValue(
							"Date/Time Sent",
							mediumDateFormat(
								this.props.shareDetails.lastEmailSentDate
							)
						)}
						{PropertyValue(
							"Expires",
							mediumDateFormat(this.props.shareDetails.expDate)
						)}
						{PropertyValue(
							"Share Status",
							this.props.shareDetails.displayStatus
						)}
						{PropertyValue(
							"Download Link",
							<Link
								to="#"
								onClick={() => {
									this.props.openValueCopyDialog(
										this.props.shareDetails.downloadURL,
										"Share Download Link",
										"Download Link Copied to Clipboard"
									);
								}}
							>
								View
							</Link>
						)}

						{PropertyValue(
							"Matter",
							<Link to={this.props.shareDetails.matterLink}>
								{this.props.shareDetails.matterName}
							</Link>
						)}
						{PropertyValue(
							"Has Recipient Downloaded?",
							this.props.shareDetails.hasBeenDownloaded
								? `Yes: ${mediumDateFormat(
										this.props.shareDetails.shareAccessedAt
									)}`
								: "No"
						)}
						{PropertyValue("Recipients")}
						<div style={{ marginLeft: 12 }}>
							{this.props.shareDetails.recipients.map(
								(email, index) => (
									<div key={index}>{email}</div>
								)
							)}
						</div>
					</div>
					<div
						style={{
							bottom: 0,
							position: "absolute",
							marginLeft: 12,
							display: "flex",
						}}
					>
						<div style={{ marginRight: 8 }}>
							<ButtonWithIcon
								handler={() =>
									this.props.revokeShare(
										this.props.shareDetails.id,
										"ExternalShareDetail"
									)
								}
								buttonText="Revoke Share"
								disabled={
									this.props.shareDetails.status !== "active"
								}
								iconName={"undo"}
							/>
						</div>
						<div style={{ marginRight: 8 }}>
							<ButtonWithIcon
								buttonText="Re-Share and Resend Email"
								iconName={"share-square"}
								handler={() =>
									this.props.resendShare(
										this.props.shareDetails.id,
										"ExternalShareDetail"
									)
								}
							/>
						</div>
					</div>
				</div>
				<div>
					{PropertyValue("Documents Shared")}
					<ScrollableListOfFiles
						documentList={this.props.shareDetails.documentList}
					/>
					{PropertyValue(
						<div style={{ color: "#666666", marginTop: 10 }}>
							{this.props.shareDetails.documentCount} documents
							total
						</div>
					)}
					{PropertyValue("Message to Recipients")}

					<div
						className="GreyContainer"
						style={{
							marginTop: 10,
							height: 260,
							overflowY: "scroll",
							whiteSpace: "pre",
						}}
					>
						{this.props.shareDetails.message}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.share.loading,
	shareDetails: state.share.shareDetails,
	shareEmails: state.share.shareEmails,
	publicSharePage: state.share.publicSharePage,
});
const ExternalShareDetail = connect(mapStateToProps, {
	setSubheaderText,
	getShareDetailUser,
	revokeShare,
	resendShare,
	openValueCopyDialog,
})(ShareDetail);

export { ExternalShareDetail };
