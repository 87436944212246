import {
	CircularProgress,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Dialog,
} from "@material-ui/core";

import React, { Component } from "react";
import { connect } from "react-redux";
import { splitFilename } from "../utilities/utilities";

export const createFilesUploadingContentForDialog = (
	fileNameArray,
	messageBeforeFileNames
) => {
	let filesBeingUploaded = fileNameArray.map((fileName, index) => {
		let splitName = splitFilename(fileName);
		return (
			<span style={{ display: "flex" }} key={`${fileName}-${index}`}>
				<span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
					{splitName.basename}
				</span>
				<span>.{splitName.extension}</span>
			</span>
		);
	});
	return (
		<span
			style={{
				display: "flex",
				flexDirection: "column",
				flexBasis: "auto",
				width: "100%",
			}}
		>
			<span>{messageBeforeFileNames}</span>
			{/* Needed to put a space between the message and the files being uploaded */}
			<span> </span>
			{filesBeingUploaded}
		</span>
	);
};

class ReactDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			value: "",
			textAreaContent: "",
			recipients: null,
			convertToPDFAsNewVersion: true,
		};
	}
	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading) {
			const newState = {
				loading: false,
				textAreaContent: "",
			};
			return newState;
		} else {
			return null;
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.dialogOpen !== prevProps.dialogOpen) {
			this.setState({ value: "", textAreaContent: "", recipients: null });
		}
	}

	render() {
		if (!this.props.dialogOpen) {
			return null;
		} else {
			if (this.state.loading) {
				return (
					<Dialog
						maxWidth="sm"
						fullWidth
						open={this.props.dialogOpen}
					>
						<DialogTitle id="alert-dialog-title">
							Loading...
						</DialogTitle>
					</Dialog>
				);
			} else {
				return (
					<Dialog
						maxWidth="sm"
						fullWidth
						open={this.props.dialogOpen}
					>
						<DialogTitle>
							<div
								style={{
									display: "flex",
									gap: 10,
									alignItems: "center",
								}}
							>
								{this.props.showSpinnerInTitle ? (
									<CircularProgress />
								) : null}
								<span>{this.props.title}</span>
							</div>
						</DialogTitle>
						<DialogContent>
							<DialogContentText
								style={{
									whiteSpace: "pre",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{this.props.mainContent}
							</DialogContentText>
						</DialogContent>
					</Dialog>
				);
			}
		}
	}
}
const mapStateToProps = (state) => {
	return {
		dialogOpen: state.reactDialog.dialogOpen,
		showSpinnerInTitle: state.reactDialog.showSpinnerInTitle,
		title: state.reactDialog.title,
		mainContent: state.reactDialog.mainContent,
	};
};
ReactDialog.propTypes = {};

export default connect(mapStateToProps)(ReactDialog);
