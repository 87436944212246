import React from "react";

import { MenuItem, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./DropDownButton.css";

class DropDownCheckboxSelector extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = { isOpen: false };
		this.makeCheckboxesMenu = this.makeCheckboxesMenu.bind(this);
		this.makeNonCheckboxesMenu = this.makeNonCheckboxesMenu.bind(this);
		this.customToggle = this.customToggle.bind(this);
	}
	customToggle(open, event, source) {
		const closeAfterSelect =
			event.currentTarget &&
			event.currentTarget.getAttribute &&
			event.currentTarget.getAttribute("closeafterselect");

		if (closeAfterSelect) {
			this.setState({ isOpen: false });
		} else {
			const newOpenState = open || source.source === "select";
			this.setState({ isOpen: newOpenState });
		}
	}

	makeNonCheckboxesMenu() {
		return this.props.nonCheckboxItems
			.map((item, i) => {
				return (
					<MenuItem
						onSelect={item.onSelect}
						key={"non" + i}
						closeafterselect={1}
					>
						<FontAwesomeIcon icon={"undo"} size="1x" /> {item.text}
					</MenuItem>
				);
			})
			.concat([<MenuItem key="divider" divider />]);
	}

	makeCheckboxesMenu() {
		return this.props.checkboxItems.map((item, i) => {
			return (
				<MenuItem
					onSelect={item.onSelect}
					eventKey={item.accessor}
					key={"checkbox" + i}
				>
					<FontAwesomeIcon
						icon={
							item.selected
								? ["far", "check-square"]
								: ["far", "square"]
						}
						size="1x"
					/>{" "}
					{item.text}
				</MenuItem>
			);
		});
	}

	render() {
		return (
			<Dropdown
				className={
					"DropDownButton RegularDropDown" +
					(this.props.className || "")
				}
				id={"dropdown-custom-" + Math.floor(Math.random() * 100000)}
				pullRight={this.props.pullRight}
				open={this.state.isOpen}
				onToggle={this.customToggle}
			>
				<Dropdown.Toggle>
					<FontAwesomeIcon
						icon={this.props.iconName || "briefcase"}
						size="1x"
					/>
					<span>{this.props.title}</span>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{[
						...this.makeNonCheckboxesMenu(),
						...this.makeCheckboxesMenu(),
					]}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

export { DropDownCheckboxSelector };
