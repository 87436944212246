import React from "react";
import GlobalPagination from "./GlobalPagination";
import { DropDownButton } from "./DropDownButton";
import { connect } from "react-redux";
import { setLauncherInstalled } from "../actions/launcherActions";

import lxwConstants from "../config/lexworkplace-constants";
const lexWorkplaceEditions = lxwConstants.lexWorkplaceEditions;

class Footer extends React.Component {
	render() {
		return (
			<div
				className="noselect"
				style={{
					backgroundColor: "var(--lw-light-grey-a)",
					borderTop: "1px solid",
					borderColor: "var(--lw-dark-grey)",
					display: "flex",
					lineHeight: "48px",
				}}
			>
				{" "}
				<div style={{ marginLeft: 12 }}>
					<GlobalPagination />
				</div>
				<div
					style={{
						marginRight: 12,
						marginLeft: "auto",
						display: "flex",
					}}
				>
					{!this.props.launcherInstalled && (
						<div style={{ marginRight: 12, display: "flex" }}>
							<div className="goldButton">
								<DropDownButton
									title="Launcher not installed!"
									pullRight
									dropup
									iconName={"exclamation"}
									menuItems={[
										{
											text: "Download Launcher",
											onSelect: () =>
												//https://stackoverflow.com/questions/10527983/best-way-to-detect-mac-os-x-or-windows-computers-with-javascript-or-jquery
												window.open(
													this.props.launcherURL
												),
										},
										{
											text: "Launcher is Already Installed (Activate Launcher)",
											onSelect: () => {
												this.props.setLauncherInstalled(
													true
												);
											},
										},
									]}
								/>
							</div>
						</div>
					)}
					<div>
						<span>LexWorkplace™</span>{" "}
						<span style={{ fontFamily: "MainFont-SemiBold" }}>
							{this.props.lxwEditionDisplay}
						</span>
					</div>
				</div>
			</div>
		);
	}
}
const mapStateToProps = (state) => {
	const lxwEditionDisplay = lexWorkplaceEditions.options[
		state.user.lxwEdition
	]
		? lexWorkplaceEditions.options[state.user.lxwEdition].display
		: "";
	return {
		launcherInstalled: state.global.launcherInstalled,
		launcherURL: state.global.launcherURL,
		lxwEditionDisplay,
	};
};
export default connect(mapStateToProps, { setLauncherInstalled })(Footer);
