import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconMap } from "../utilities/utilities";

const ScrollableListOfFiles = (props) => (
	<div
		className="GreyContainer"
		style={{
			marginTop: 10,
			maxHeight: 300,
			overflowY: "scroll",
		}}
	>
		{props.documentList.map((documentName, index) => {
			const { icon, color } = iconMap({
				original: {
					type: "File",
					filename: documentName,
				},
			});
			return (
				<div
					className="shareFileListItem"
					key={index}
					style={{ lineHeight: "2em" }}
				>
					<FontAwesomeIcon
						icon={icon}
						style={{
							color,
							marginRight: 10,
						}}
					/>
					<span>{documentName}</span>
				</div>
			);
		})}
	</div>
);
export default ScrollableListOfFiles;
