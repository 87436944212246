import React from "react";

import { MenuItem, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { iconMap } from "../utilities/utilities";
import { getRecentDocs, getFavoriteDocs } from "../actions/documentActions";
import "./DropDownButton.css";

class Recent extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = { loading: false };
		this.redirect = this.redirect.bind(this);
		this.onOpen = this.onOpen.bind(this);
		this.SeeAllMenuItem = this.SeeAllMenuItem.bind(this);
	}
	onOpen() {
		this.setState({ loading: true }, this.props.loadData);
	}
	redirect(eventKey) {
		// history comes from routers withRouter method
		const { history } = this.props;

		history.push(eventKey);
	}
	SeeAllMenuItem(props) {
		return (
			<MenuItem
				onSelect={() => {
					this.redirect(props.url || "/search");
				}}
				key={Math.random() + "_" + Math.random()}
			>
				See All...
			</MenuItem>
		);
	}

	menuItem() {
		return (
			<MenuItem onSelect={this.props.firsthandler} eventKey="{i}">
				Matter
			</MenuItem>
		);
	}
	render() {
		return (
			<Dropdown
				className={
					"DropDownButton " +
					(this.props.className || "RegularDropDown")
				}
				id={"dropdown-custom-" + Math.floor(Math.random() * 100000)}
				pullRight={this.props.pullRight}
				onClick={this.onOpen}
			>
				<Dropdown.Toggle>
					{this.props.noIcon ? null : (
						<FontAwesomeIcon
							icon={this.props.iconName || "briefcase"}
							size="1x"
						/>
					)}
					<span>{this.props.title}</span>
				</Dropdown.Toggle>
				<Dropdown.Menu style={{ minWidth: 180 }}>
					{this.props.recentDocs && !this.props.loading ? (
						this.props.recentDocs.length > 0 ? (
							this.props.recentDocs
								.concat([null])
								.map((item, i) => {
									if (!item) {
										//hackey but easy way to indicate the "see all..." link to search results page
										return this.SeeAllMenuItem({
											url: this.props.url,
										});
									} else {
										const { icon, color } = iconMap({
											original: {
												...item,
												type: item.docType
													? item.docType
													: "File",
											},
										});
										return (
											<MenuItem
												onSelect={(eventKey) => {
													item.selectCallback &&
														item.selectCallback();
													this.redirect(eventKey);
												}}
												eventKey={
													item.docType &&
													item.docType === "Folder"
														? `/matters/${item.matterID}/docs/${item.filename}/`
														: `/docs/${item.id}`
												}
												key={i}
											>
												<div
													style={{
														paddingTop: 5,
														paddingBottom: 5,
													}}
												>
													<FontAwesomeIcon
														icon={icon}
														style={{
															color,
															marginRight: 12,
														}}
													/>

													<span>{item.filename}</span>
												</div>
											</MenuItem>
										);
									}
								})
						) : (
							<div style={{ paddingLeft: 8 }}>
								{this.props.noneText}
							</div>
						)
					) : (
						<div style={{ paddingLeft: 20, paddingTop: 20 }}>
							<CircularProgress />
						</div>
					)}
				</Dropdown.Menu>
			</Dropdown>
		);
	}
}

const RecentDocsDropdown = connect(
	(state) => {
		return {
			recentDocs: state.document.recentDocs,
			loading: state.document.recentDocsLoading,
			noneText: "No Recent Documents",
			url: `/search/${Math.floor(
				Math.random() * 10000
			)}?runAfterNavigating=1&q=&m=&type=0&from=&to=&docType=&docStatus=&docTags=&users=${
				state.user.userID
			}&lock=&userOp=&fav=0&order=0`,
		};
	},
	{
		loadData: getRecentDocs,
	}
)(withRouter(Recent));

const FavoriteDocsDropdown = connect(
	(state) => {
		return {
			recentDocs: state.document.favoriteDocs,
			recentFolders: state.document.favoriteFolders,
			loading: state.document.favoriteDocsLoading,
			noneText: "No Favorite Documents",
			url: `/search/${Math.floor(
				Math.random() * 10000
			)}?runAfterNavigating=1&q=&m=&type=0&from=&to=&docType=&docStatus=&docTags=&users=&lock=&userOp=&fav=1&order=0`,
		};
	},
	{
		loadData: getFavoriteDocs,
	}
)(withRouter(Recent));

export { RecentDocsDropdown, FavoriteDocsDropdown };
