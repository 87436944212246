import React, { Component } from "react";
import { connect } from "react-redux";
import { ButtonWithIcon, ButtonWithRouter } from "./ButtonWithIcon";
import { LabeledTextInput } from "./TextInput";

import { setSubheaderText } from "../actions/navigateActions";

import {
	getNote,
	updateNote,
	createNote,
	unmountNoteEditor,
} from "../actions/documentActions";

class NoteEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			content: "",
			loading: true,
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleTextfieldChange = this.handleTextfieldChange.bind(this);
		this.submit = this.submit.bind(this);
	}
	componentDidMount() {
		if (this.props.createMode) {
			this.props.setSubheaderText("Create New Matter Note");
		} else {
			this.props.getNote(this.props.match.params.fullpath);
			this.props.setSubheaderText("Editing Matter Note");
		}
	}
	static getDerivedStateFromProps(props, state) {
		if (state.loading && !props.loading && !props.createMode) {
			const newState = {
				loading: false,
				name: props.note.name,
				content: props.note.content,
			};
			return newState;
		} else {
			return null;
		}
	}

	componentWillUnmount() {
		this.props.unmountNoteEditor();
	}
	handleTextfieldChange(event) {
		this.setState({ content: event.target.value });
	}
	handleChange(key, value) {
		var newState = { [key]: value };
		this.setState(newState);
	}
	submit() {
		var redirectLocation;
		if (this.props.createMode) {
			//since no note exists at this point, we must use the url path to determine redirect url
			redirectLocation = `/matters/${this.props.match.params.matterID}/${this.props.match.params.fullpath}`;

			this.props.createNote(
				this.props.match.params.matterID,
				this.props.match.params.directoryID,
				this.state,
				redirectLocation
			);
		} else {
			redirectLocation = `/matters/${this.props.note.matterID}${
				this.props.note.path || ""
			}/`;
			this.props.updateNote(
				this.props.match.params.fullpath,
				this.state,
				redirectLocation
			);
		}
	}

	render() {
		if (this.props.loading && !this.props.createMode) {
			return <div>Loading...</div>;
		} else {
			const noteNameEmpty = this.state.name.trim().length === 0;

			let submitButtonDisabled;
			if (this.props.createMode) {
				submitButtonDisabled = noteNameEmpty;
			} else {
				//When editing an existing note, we do allow the user to clear the note and save

				const contentUnchanged =
					this.state.content === this.props.note.content;
				const noteNameUnchanged =
					this.state.name === this.props.note.name;

				submitButtonDisabled =
					noteNameEmpty || (noteNameUnchanged && contentUnchanged);
			}

			return (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						height: "100%",
						maxWidth: 1000,
						gap: 8,
					}}
				>
					<LabeledTextInput
						label="Matter Note Name"
						name="name"
						placeholder=""
						defaultValue={
							this.props.createMode ? null : this.state.name
						}
						onChange={this.handleChange}
					/>
					<textarea
						name="content"
						style={{
							height: "100%",
							boxSizing: "border-box",
							minHeight: 400,
							padding: 16,
						}}
						value={this.state.content}
						onChange={this.handleTextfieldChange}
					/>
					{this.props.createMode ? null : (
						<div>
							<div>
								Created on{" "}
								{new Date(
									this.props.note.createdOn
								).toLocaleDateString() +
									" by " +
									this.props.note.createdBy}
							</div>

							<div>
								Last Modified{" "}
								{this.props.note &&
									new Date(
										this.props.note.modifiedOn
									).toLocaleDateString() + " "}{" "}
								by{" "}
								{this.props.note && this.props.note.modifiedBy}
							</div>
						</div>
					)}

					<div>
						<ButtonWithRouter
							type="go-back"
							buttonText="Cancel"
							iconName="times"
							style={{ marginRight: 8 }}
						/>
						<ButtonWithIcon
							handler={this.submit}
							buttonText={
								this.props.createMode
									? "Create Note"
									: "Update Note"
							}
							className="blue"
							iconName="check"
							disabled={submitButtonDisabled}
						/>
					</div>
				</div>
			);
		}
	}
}
const mapStateToProps = (state) => ({
	note: state.note.details,
	loading: state.note.loading,
	matterID: state.note.matterID,
	fullpath: state.note.fullpath,
	directoryID: state.note.directoryID,
});
export default connect(mapStateToProps, {
	setSubheaderText,
	getNote,
	updateNote,
	createNote,
	unmountNoteEditor,
})(NoteEditor);
