import { SET_DEVELOPER_OVERRIDE } from "../actions/types";

import lxwConstants from "../config/lexworkplace-constants";
const availableDeveloperOverrides = lxwConstants.availableDeveloperOverrides;

import frontendConfig from "../config/legalworks.frontend.config.json";

const getApiNameOverrideValue = () => {
	const reactAppEnvironment = process.env.REACT_APP_ENVIRONMENT;

	const defaultApiName =
		reactAppEnvironment === "local" ? "localstack" : reactAppEnvironment;

	return frontendConfig.apiName !== defaultApiName
		? frontendConfig.apiName
		: "OVERRIDE_DISABLED";
};

const currentLocalstorageDeveloperOverrides = (() => {
	const localString = window.localStorage.getItem("developerOverrides");
	if (localString) {
		return JSON.parse(localString);
	} else {
		return {};
	}
})();

const initialState = {
	bulkDownloadServerAuthority: checkIfValueIsAllowed(
		"bulkDownloadServerAuthority",
		currentLocalstorageDeveloperOverrides.bulkDownloadServerAuthority
	)
		? currentLocalstorageDeveloperOverrides.bulkDownloadServerAuthority
		: "OVERRIDE_DISABLED",
	apiName: getApiNameOverrideValue(),
};

function checkIfValueIsAllowed(key, value) {
	if (availableDeveloperOverrides[key].options) {
		if (
			availableDeveloperOverrides[key].options[value] ||
			value === "OVERRIDE_DISABLED"
		) {
			return true;
		}
	}
	return false;
}

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_DEVELOPER_OVERRIDE: {
			let { key, value } = action.payload;
			let mixin = checkIfValueIsAllowed(key, value)
				? { [key]: value }
				: {};

			const newState = {
				...state,
				...mixin,
			};
			window.localStorage.setItem(
				"developerOverrides",
				JSON.stringify(newState)
			);

			return newState;
		}
		default:
			return state;
	}
}
