import React, { useState, useRef, useEffect, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LabeledTextInput } from "./TextInput";

const LabeledTextInputWithValidation = ({
	syncValidationError = (newValue) => false,
	//return an error message string, or false for "the value is valid"

	asyncValidationError = (newValue) => Promise.resolve(false),
	// promise returning an error message, or false for "the value is valid"

	fieldIsValidAndReadyChanged = (isValid) => {},
	// like the onChange callback, but for whether or not the field is valid AND not pending validation

	onChange,

	validationValueOverride, //pass something if thing to be validated is not the exact text field value

	debounceTimeMS = 500,

	...props
}) => {
	const [validationInProgress, setValidationInProgress] = useState(false);

	const [validationMessage, setValidationMessage] = useState("");

	const debounceTimeout = useRef();

	useEffect(() => {
		fieldIsValidAndReadyChanged(
			!validationMessage && !validationInProgress
		);
	}, [validationMessage, validationInProgress]);

	useEffect(() => {
		if (validationValueOverride) {
			validate(validationValueOverride);
		}
	}, [validationValueOverride]);

	const validate = useCallback(
		(value) => {
			setValidationInProgress(true);
			setValidationMessage("");

			// use a debounce timer so that the async call
			// (eg a request to a server) doesn't execute
			// on every keystroke
			clearTimeout(debounceTimeout.current);

			debounceTimeout.current = setTimeout(() => {
				//first check the synchronous validation, eg: does the value conform to a regex
				const syncInvalidMessage = syncValidationError(value);

				if (syncInvalidMessage) {
					setValidationMessage(syncInvalidMessage);
					setValidationInProgress(false);
					// if the syncValidation fails, no need to attempt the async
				} else {
					asyncValidationError(value)
						.then((asyncInvalidMessage) => {
							if (asyncInvalidMessage) {
								setValidationMessage(asyncInvalidMessage);
							} else {
								setValidationMessage("");
							}
							setValidationInProgress(false);
						})
						.catch((err) => {
							setValidationInProgress(false);
							setValidationMessage(
								"The async validation request failed"
							);
						});
				}
			}, debounceTimeMS);
		},
		[syncValidationError, asyncValidationError]
	);

	const validateChange = useCallback(
		(key, newTextFieldValue) => {
			//call the parent's onChange
			onChange(key, newTextFieldValue);
			validate(
				validationValueOverride
					? validationValueOverride
					: newTextFieldValue
			);
		},
		[
			onChange,
			syncValidationError,
			asyncValidationError,
			validationValueOverride,
		]
	);

	return (
		<div>
			<div style={{ position: "relative" }}>
				<LabeledTextInput
					{...props}
					onChange={validateChange}
					invalid={validationMessage ? true : false}
				/>

				{props.children || null}

				{validationInProgress ? (
					<div
						style={{
							position: "absolute",
							top: 25,
							right: 12,
						}}
					>
						<CircularProgress size={28} thickness={5} />
					</div>
				) : null}

				{validationMessage ? (
					<div
						style={{
							position: "absolute",
							top: 28,
							right: 12,
						}}
					>
						<FontAwesomeIcon
							style={{
								color: "var(--lw-darker-gold)",
								fontSize: "1.5em",
							}}
							icon="exclamation-triangle"
						/>
					</div>
				) : null}
			</div>
			<div style={{ minHeight: 40, color: "var(--lw-darker-red)" }}>
				{validationMessage}
			</div>
		</div>
	);
};

export { LabeledTextInputWithValidation };
