import React, { Component } from "react";
import { Link } from "react-router-dom";
import { decodeFullpath } from "../utilities/utilities";
import "./Breadcrumbs.css";

function remapPath(path, name, replacedThing = "/docs/") {
	var joined = path + name;
	var regexp = RegExp(`^${replacedThing}?`);
	return joined.replace(regexp, "");
	//basically just remove "docs" from the full path
}

const splitPath = (path, basepath, basepathname, linkEachComponent) => {
	var buildup = basepath;
	var pathArr = path ? path.split("/") : [];
	return (
		<div>
			<span className="breadcrumbEl" key={buildup}>
				<Link to={basepath}>{basepathname}</Link>
			</span>
			{pathArr && pathArr.length
				? pathArr.map((item, index) => {
						buildup += item + "/";
						if (item !== "") {
							if (
								!linkEachComponent &&
								index === pathArr.length - 2
							) {
								return (
									<span
										className="breadcrumbEl"
										key={buildup}
									>
										<span>{decodeFullpath(item)}</span>
									</span>
								);
							} else {
								return (
									<span
										className="breadcrumbEl"
										key={buildup}
									>
										<Link to={buildup}>
											{decodeFullpath(item)}
										</Link>
									</span>
								);
							}
						} else {
							return null;
						}
					})
				: null}
		</div>
	);
};
class Breadcrumbs extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			filter: "All",
		};
	}

	render() {
		return (
			<div className="Breadcrumbs" style={this.props.style || null}>
				{" "}
				{splitPath(
					this.props.fullpath,
					this.props.basepath,
					this.props.basepathname,
					this.props.linkEachComponent
				)}
			</div>
		);
	}
}

const BreadcrumbsWithMatterName = (props) => {
	return (
		<div
			style={{
				display: "flex",
			}}
		>
			<div style={{ display: "flex" }}>
				<div className="MainFontBold">
					{props.matterID > 0
						? props.matterName || ""
						: "Firm Documents"}
				</div>

				<div
					style={{
						marginLeft: 8,
						marginRight: 8,
					}}
				>
					{">"}
				</div>
			</div>
			<NormalizedBreadcrumbs {...props} />
		</div>
	);
};

const NormalizedBreadcrumbs = (props) => {
	if (props.matterID > 0) {
		return (
			<Breadcrumbs
				fullpath={
					props.path &&
					remapPath(props.path, props.name, props.rootPath)
				}
				linkEachComponent
				basepath={
					"/matters/" + props.matterID + (props.rootPath || "/docs/")
				}
				basepathname={props.rootName || "Documents"}
			/>
		);
	} else if (!props.matterID) {
		const firmdocsNameMap = { public: "Public", private: "Private" };
		const pathJoin = props.path + props.name;
		const rootPath = pathJoin.split("/")[1];
		const wordPrefix = firmdocsNameMap[rootPath];
		const regexp = new RegExp("^/" + rootPath + "/?");
		return (
			<Breadcrumbs
				fullpath={pathJoin.replace(regexp, "")}
				linkEachComponent
				basepath={"/firmDocs/" + rootPath + "/"}
				basepathname={wordPrefix}
			/>
		);
	} else {
		return null;
	}
};

export default Breadcrumbs;
export { BreadcrumbsWithMatterName };
