import React from "react";

import { DropDownButton } from "./DropDownButton";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { iconMap } from "../utilities/utilities";
import { bulkUploadMultipleFiles } from "../actions/documentActions";
import { createNewDocumentFromTemplate } from "../actions/newDocumentActions";

import { openGlobalDialog } from "../actions/globalEditDialogActions";
import "./DropDownButton.css";
import {
	createNewMatterFromClioMatter,
	clioIntegrationAccessRedirectCodeStatus,
	openPullMatterFromClioDialog,
} from "../actions/clioIntegrationActions";
import { showNewFolderDialog } from "../actions/folderActions";

const DropdownWithRouter = withRouter(DropDownButton);

const documentTypes = {
	docx: "Word Document",
	xlsx: "Excel Spreadsheet",
	pptx: "PowerPoint Presentation",
	txt: "Text File",
};

class NewItemDropdown extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.openDialog = this.openDialog.bind(this);

		this.allowCreateNew = this.allowCreateNew.bind(this);
	}

	componentDidMount() {
		if (!this.props.clioAccessCodeStatus)
			this.props.clioIntegrationAccessRedirectCodeStatus();
	}

	openDialog(extension) {
		const { directoryID } = this.props;
		this.props.openGlobalDialog(
			{
				label: `Create New ${documentTypes[extension]}`,
				value: "",
				suffix: extension,
				submitButtonLabel: "Create and Open Document",
			},
			(state) => {
				const filename = `${state.value}.${extension}`;
				this.props.createNewDocumentFromTemplate(
					this.props.directoryID,
					filename,
					extension,
					this.props.cognitoSub
				);
			},
			(state) => {
				const matchesFilename = this.props.documentList.filter(
					(item) =>
						item.type === "File" &&
						item.filename.toUpperCase() ===
							`${state.value}.${extension}`.toUpperCase() &&
						item.id !== directoryID
				).length;
				const deletedFileMatch = this.props.documentList.filter(
					(item) =>
						item.type === "File" &&
						item.filename.toUpperCase() ===
							`${state.value}.${extension}`.toUpperCase() &&
						item.id !== directoryID &&
						item.isDeleted
				).length;

				this.props.documentList
					.filter((item) => item.type === "File")
					.map((item) => item.filename);

				const isEmpty = state.value.trim() === "";
				const hasIllegalCharacters = state.value.match(/[<>:"/\\|?*%]/);
				return {
					canSubmit:
						!isEmpty && !matchesFilename && !hasIllegalCharacters,
					errorMessage: matchesFilename
						? "There is already a " +
							(deletedFileMatch ? "deleted" : "") +
							" file in the current directory with this name"
						: hasIllegalCharacters
							? 'New File names cannot contain any of the following characters: < > : " / \\ | ? * %'
							: isEmpty
								? "Please enter a new file name"
								: "",
				};
			}
		);
	}
	allowCreateNew(currentRoot) {
		const onCreatablePage = this.props.creatableContext;

		return (
			onCreatablePage &&
			(currentRoot.indexOf("docs/") === 0 ||
				currentRoot.indexOf("public/") === 0 ||
				currentRoot.indexOf("private/") === 0)
		);
	}

	getMenuItems() {
		const disableNewDoc = !this.allowCreateNew(this.props.currentRoot);
		const newDocMenuItem = (extension) => {
			const { icon, color } = iconMap({
				original: { filename: "." + extension, type: "File" },
			});
			return {
				text: documentTypes[extension],
				onSelect: () => this.openDialog(extension),
				disabled: disableNewDoc,
				iconName: icon,
				iconColor: disableNewDoc ? "#aaaaaa" : color,
				disabledHoverText:
					"Documents can only be created within the Documents tab of a matter",
			};
		};

		const disableMatter = !this.props.canCreateMatter;
		const disableClient = !this.props.canCreateClient;

		var menuItems = [
			{
				text: "Matter",
				href: "/matters/new",
				iconName: "briefcase",
				iconColor: disableMatter ? "#aaaaaa" : "var(--lw-blue)",
				disabled: disableMatter,
				disabledHoverText:
					"You do not have permissions to create new matters",
			},
		];

		if (
			this.props.clioIntegrationSettingStatus !== undefined &&
			this.props.clioIntegrationSettingStatus
		) {
			menuItems.push({
				text: "Matter (From Clio)",
				onSelect: () => this.props.openPullMatterFromClioDialog(),
				iconName: "briefcase",
				iconColor: disableMatter ? "#aaaaaa" : "var(--lw-blue)",
				disabled: disableMatter,
				disabledHoverText:
					"You do not have permissions to create new matters",
			});
		}

		menuItems = menuItems.concat([
			{
				text: "Client",
				href: "/clients/new",
				iconName: "user",
				iconColor: disableClient ? "#aaaaaa" : "var(--lw-blue)",
				disabled: disableClient,
				disabledHoverText:
					"You do not have permissions to create new clients",
			},
			newDocMenuItem("docx"),
			newDocMenuItem("xlsx"),
			newDocMenuItem("pptx"),
			newDocMenuItem("txt"),
			{
				text: "Folder",
				onSelect: () =>
					this.props.showNewFolderDialog({
						matterID: this.props.matterID,
						apiBasepath: this.props.currentLxwFolderBasepath,
						currentLocationFilesAndFolders: this.props.documentList,
					}),
				disabled: disableNewDoc,
				iconName: "folder",
				iconColor: disableNewDoc
					? "#aaaaaa"
					: "var(--lw-folder-icon-yellow)",
				disabledHoverText:
					"Folders can only be created within the Documents tab of a matter",
			},
			{
				text: "Upload a Document",
				type: "multi-file-upload",
				onSelect: () => console.log("UPLOAD"),
				disabledHoverText:
					"Documents can only be uploaded to the Documents tab of a matter",
				disabled: disableNewDoc,
				iconName: "file-upload",
				iconColor: disableNewDoc ? "#aaaaaa" : "black",
				fileListChangeHandler: (files) => {
					console.log("party files", files);
					let myMatterID = this.props.matterID;
					if (this.props.matterID !== 0) {
						// need to send matterID as string if it is not 0
						myMatterID = `${this.props.matterID}`;
					}
					this.props.bulkUploadMultipleFiles(
						myMatterID,
						this.props.currentLxwFolderBasepath,
						this.props.directoryID,
						files,
						false, //autoRename parameter.  False on first call
						() => console.log("callback")
					);
				},
			},
		]);
		return menuItems;
	}
	render() {
		return (
			<DropdownWithRouter
				title="New"
				noIcon
				menuItems={this.getMenuItems()}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	const matterID =
		state.document.currentRoot.indexOf("public/") === 0 ||
		state.document.currentRoot.indexOf("private/") === 0
			? 0
			: state.matter.matterDetails.id;

	return {
		documentList: state.document.documentList,
		currentLxwFolderBasepath: state.document.originalPrefix, //eg: docs/FolderA/FolderB/  or public/folder1/ (for firm docs)`
		directoryID: state.document.directoryID,
		currentRoot: state.document.currentRoot,
		creatableContext: state.currentPage.creatableContext,
		matterID,
		dropDownListData: state.clioIntegration.clioMatterListData,
		clioAccessCodeStatus: state.clioIntegration.clioAccessCodeStatus,
		clioIntegrationSettingStatus: state.tenant.clioIntegrationSettingStatus,
		canCreateClient:
			state.permissionFeatures.allowedFeatures.CREATE_CLIENT_ALLOWED,
		canCreateMatter:
			state.permissionFeatures.allowedFeatures.CREATE_MATTER_ALLOWED,
		cognitoSub: state.user.cognitoUser.attributes.sub,
	};
};
export default connect(mapStateToProps, {
	openGlobalDialog,
	createNewDocumentFromTemplate,
	bulkUploadMultipleFiles,
	createNewMatterFromClioMatter,
	clioIntegrationAccessRedirectCodeStatus,
	openPullMatterFromClioDialog,
	showNewFolderDialog,
})(NewItemDropdown);
