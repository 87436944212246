import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchMattersList } from "../actions/matterActions";

import { setSubheaderText } from "../actions/navigateActions";

import MattersList from "./MattersList";

class AllMattersList extends Component {
	constructor(props, context) {
		super(props, context);
	}
	componentDidMount() {
		this.props.setSubheaderText("Matters");
		this.props.fetchMattersList({
			filter: {},
		});
	}

	render() {
		return <MattersList showMatterFilterTabs />;
	}
}
const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, { setSubheaderText, fetchMattersList })(
	AllMattersList
);
