import {
	SET_PAGINATION_INFO,
	NEXT_PAGE,
	PREVIOUS_PAGE,
	GO_TO_PAGE,
	SET_FILTERED_COUNT,
} from "../actions/types";

//
const initialState = {
	currentPage: 1,
	totalPages: 2,
	resultsPerPage: 30,
	totalResults: 101,
	filteredCount: -1, //-1 used as "no filter"
};

var currentPage;

function calculatePages(filteredCount, totalResults, resultsPerPage) {
	let totalItems = filteredCount > -1 ? filteredCount : totalResults;
	return totalItems ? Math.ceil(totalItems / resultsPerPage) : 1;
}

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_PAGINATION_INFO: {
			return {
				...state,
				totalPages: calculatePages(
					state.filteredCount,
					action.payload.totalResults,
					action.payload.resultsPerPage
				),
				currentPage: action.payload.currentPage || 1,
				resultsPerPage: action.payload.resultsPerPage,
				totalResults: action.payload.totalResults,
			};
		}
		case NEXT_PAGE:
			currentPage =
				state.currentPage < state.totalPages
					? state.currentPage + 1
					: state.currentPage;
			action.payload.fn(currentPage);
			return {
				...state,
				currentPage,
			};
		case PREVIOUS_PAGE:
			currentPage =
				state.currentPage > 1
					? state.currentPage - 1
					: state.currentPage;
			action.payload.fn(currentPage);
			return {
				...state,
				currentPage,
			};
		case GO_TO_PAGE:
			currentPage = Math.min(
				Math.max(0, action.payload.pageNum),
				state.totalPages
			);

			action.payload.fn(currentPage);
			return {
				...state,
				currentPage,
			};
		case SET_FILTERED_COUNT:
			return {
				...state,
				filteredCount: action.payload.filteredCount,
				totalPages: calculatePages(
					action.payload.filteredCount,
					state.totalResults,
					state.resultsPerPage
				),
				currentPage:
					action.payload.filteredCount < 0 ? 1 : state.currentPage,
			};
		default:
			return state;
	}
}
