import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MarginIcon = (props) => (
	<FontAwesomeIcon
		style={{
			margin: 12,
			position: "relative",
			top: props.offset || "0",
			transform: `rotate(${props.rotate}deg)`,
		}}
		icon={props.icon}
		size="2x"
	/>
);

const DropFilesHere = () => {
	return (
		<div
			style={{
				height: "100%",

				backgroundColor: "var(--lw-bluish-white)",
				textAlign: "center",
				color: "var(--lw-light-blue)",
				outline: "1px dashed blue",
				outlineOffset: -2,
			}}
		>
			<div style={{ position: "relative", top: "40%" }}>
				<div>
					<MarginIcon icon="file-pdf" rotate="-15" />
					<MarginIcon icon="file-word" rotate="0" offset="-5" />
					<MarginIcon icon="file-video" rotate="15" />
				</div>
				<div style={{ fontSize: 24, fontFamily: "MainFont-SemiBold" }}>
					Drop files here
				</div>
				<div>to upload to this matter</div>
			</div>
		</div>
	);
};

function FileDropzone(props) {
	const onDrop = useCallback((acceptedFiles) => {
		const reader = new FileReader();

		reader.onabort = () => console.log("file reading was aborted");
		reader.onerror = () => console.log("file reading has failed");
		reader.onload = () => {
			// Do whatever you want with the file contents
			const binaryStr = reader.result;
			console.log(binaryStr);
		};

		//acceptedFiles.forEach(file => console.log(file));
		props.fileHandler(acceptedFiles, () =>
			console.log("drop files complete")
		);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		noClick: true,
	});

	return props.enabled ? (
		<div
			{...getRootProps()}
			style={{ height: "100%" }}
			className={isDragActive ? " dropzoneDragActive" : ""}
		>
			<input {...getInputProps()} />
			{isDragActive ? <DropFilesHere /> : props.children}
		</div>
	) : (
		<div style={{ height: "100%" }}>{props.children}</div>
	);
}
export default FileDropzone;
