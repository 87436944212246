import React, { Component } from "react";
import { connect } from "react-redux";

import { setSubheaderText } from "../actions/navigateActions";

class DocumentCompare extends Component {
	componentDidMount() {
		this.props.setSubheaderText("Document Compare");
	}

	render() {
		return (
			<>
				{this.props.inlineCompareUrl ? (
					<div
						style={{
							height: "100%",
							// marginTop: 0,
							// marginBottom: 0,
						}}
					>
						<iframe
							src={this.props.inlineCompareUrl}
							style={{
								width: "100%",
								height: "100%",
								border: "1px solid #e0e0e0",
							}}
							allowFullScreen
						/>
					</div>
				) : (
					<div>Comparison not found</div>
				)}
			</>
		);
	}
}
const mapStateToProps = (state) => ({
	inlineCompareUrl: state.browserLocalstorage.inlineCompareUrl,
});
export default connect(mapStateToProps, { setSubheaderText })(DocumentCompare);
