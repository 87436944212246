import API from "../utilities/LocalApiProxy";

import {
	SEARCH_RESULTS_REQUEST_NOGLOBAL,
	SEARCH_RESULTS_SUCCESS_NOGLOBAL,
	SEARCH_RESULTS_FAILURE_NOGLOBAL,
	SEARCH_HELPER_REQUEST_NOGLOBAL,
	SEARCH_HELPER_SUCCESS_NOGLOBAL,
	SEARCH_HELPER_FAILURE_NOGLOBAL,
	SET_PAGINATION_INFO,
} from "./types";

import config from "../config/legalworks.frontend.config";

export function runSearchQuery(query, criteria, startPage) {
	//note start page starts at 1
	return function (dispatch) {
		dispatch({ type: SEARCH_RESULTS_REQUEST_NOGLOBAL });
		API.post("/search", {
			body: { query, criteria, aggs: [1, 2, 3], startPage },
		})
			.then((res) => {
				dispatch({
					type: SEARCH_RESULTS_SUCCESS_NOGLOBAL,
					payload: {
						results: res.mysql.data,
						executedQuery: query,
						queryTook: res.mysql.queryTook,
					},
				});
				dispatch({
					type: SET_PAGINATION_INFO,
					payload: {
						resultsPerPage: config.searchResultsPerPage,
						totalResults: res.mysql.data.totalHits,
						currentPage: startPage,
					},
				});
			})
			.catch(({ lxwErrorCode, lxwError }) => {
				console.log("search error", lxwError);
				dispatch({
					type: SEARCH_RESULTS_FAILURE_NOGLOBAL,
					payload: { lxwErrorCode },
				});
			});
	};
}
export function getEverythingForSearchPage() {
	return function (dispatch) {
		dispatch({ type: SEARCH_HELPER_REQUEST_NOGLOBAL });
		API.get("/search/helper")
			.then((res) => {
				dispatch({
					type: SEARCH_HELPER_SUCCESS_NOGLOBAL,
					payload: res.mysql,
				});
			})
			.catch(() => {
				dispatch({ type: SEARCH_HELPER_FAILURE_NOGLOBAL });
			});
	};
}
