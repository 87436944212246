import React from "react";
import { connect } from "react-redux";

import { LabeledDropDownSelect } from "./DropDownSelect";
import { toggleDropdownSelectShowAll } from "../actions/browserLocalstorageActions";

const mixinHiddenProperty = (users) => {
	return users.map((user) => {
		const hidden = user.type === "uptimeAdmin" || user.status === 0;
		return {
			...user,
			hidden,
		};
	});
};

class UsersDropDownSelect extends React.Component {
	render() {
		return (
			<LabeledDropDownSelect
				{...this.props}
				options={mixinHiddenProperty(this.props.options)}
				showHiddenItemsToggle
				dropdownSelectShowAll={this.props.dropdownSelectShowAll}
				toggleDropdownSelectShowAll={() => {
					this.props.toggleDropdownSelectShowAll("users");
				}}
			/>
		);
	}
}

export default connect(
	(state) => {
		return {
			dropdownSelectShowAll:
				state?.browserLocalstorage?.dropdownSelectShowAll?.users,
		};
	},
	{
		toggleDropdownSelectShowAll,
	}
)(UsersDropDownSelect);
