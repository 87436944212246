import API from "../utilities/LocalApiProxy";
import {
	GET_CLIO_INTEGRATION_SETTINGS_REQUEST,
	GET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
	GET_CLIO_INTEGRATION_SETTINGS_FAILURE,
	OPEN_GLOBAL_SNACKBAR,
} from "./types";
export function getEWSUrl() {
	return function (dispatch) {
		dispatch({ type: "GET_EWS_URL_REQUEST" });
		API.get("/ews/exchangeServer")
			.then((res) => {
				console.log(res.mysql);
				dispatch({
					type: "GET_EWS_URL_SUCCESS",
					payload: res.mysql[0].exchangeUrl || "",
				});
			})
			.catch(() => {
				dispatch({ type: "GET_EWS_URL_FAILURE" });
			});
	};
}
export function setEWSUrl(exchangeUrl) {
	return function (dispatch) {
		dispatch({ type: "SET_EWS_URL_REQUEST" });
		API.post("/ews/exchangeServer", { body: { exchangeUrl } })
			.then(() => {
				dispatch({ type: "SET_EWS_URL_SUCCESS" });
				window.globalHistory.push("/settings");
			})
			.catch(() => {
				dispatch({ type: "SET_EWS_URL_FAILURE" });
			});
	};
}
export function unmountEWSPage() {
	return function (dispatch) {
		dispatch({ type: "UNMOUNT_EWS_PAGE" });
	};
}

export function getClioIntegrationSettingStatus() {
	return function (dispatch) {
		dispatch({ type: GET_CLIO_INTEGRATION_SETTINGS_REQUEST });
		API.get("/integrations/clio")
			.then((res) => {
				dispatch({
					type: GET_CLIO_INTEGRATION_SETTINGS_SUCCESS,
					payload:
						res.mysql.length && res.mysql[0].status
							? res.mysql[0].status
							: 0,
				});
			})
			.catch(() => {
				dispatch({ type: GET_CLIO_INTEGRATION_SETTINGS_FAILURE });
			});
	};
}

const firmAdminEditableFeatures = [
	"clioIntegration",
	"literaDocumentCompareVisible",
];

export function getIntegrationSettings() {
	return function (dispatch) {
		API.get("/integrations/settings").then((res) => {
			dispatch({
				type: "GET_INTEGRATION_SETTINGS_SUCCESS",
				payload: res.mysql,
			});
		});
	};
}

export function setIntegrationSettings({ featureCode, featureValue }) {
	return function (dispatch) {
		dispatch({ type: "SET_INTEGRATION_SETTINGS_REQUEST" });

		if (!firmAdminEditableFeatures.includes(featureCode)) {
			console.error(`Invalid feature code: ${featureCode}`);
			dispatch({
				type: OPEN_GLOBAL_SNACKBAR,
				payload: {
					variant: "error",
					message: `Invalid feature code: ${featureCode}`,
				},
			});
			setTimeout(() => {
				dispatch({
					type: "SET_INTEGRATION_SETTINGS_FAILURE",
					payload: {
						featureCode,
						status: featureValue ? 0 : 1,
					},
				}),
					1000;
			});

			return;
		}
		const status = featureValue ? 1 : 0;
		console.log("setIntegrationSettings", featureCode, status);
		API.post("/integrations/settings", {
			body: {
				featureCode,
				status,
			},
		})
			.then(() => {
				dispatch({
					type: "SET_INTEGRATION_SETTINGS_SUCCESS",
					payload: { featureCode, status },
				});
			})
			.catch(() => {
				dispatch({ type: "SET_INTEGRATION_SETTINGS_FAILURE" });
			});
	};
}
